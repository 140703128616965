import { Form, Formik } from 'formik';
import { Button } from 'molecules/Button';
import FormikSelect from 'molecules/FormikSelect';
import React, { useState } from 'react';
import * as yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormSubmitWithLoading } from 'utils/hooks/useFormSubmitWithLoading';
import { deformatPhone } from 'utils/utility';
import { toast } from 'react-toastify';
import axios from 'axios';

const validationSchema = yup.object().shape({
  findUs: yup.string().required('required'),
});

function RequestConfirmPage({
  setFormStep,
  initialValues,
  setPreInitialValues,
  formInitials,
}: any) {
  const [verifyCaptcha, setVerifyCaptcha] = useState(true);
  const [verifyToken, setVerifyToken] = useState('');
  const recaptchaRef = React.useRef<ReCAPTCHA>();
  function onChange(value: any) {
    if (value) {
      setVerifyToken(value);
      setVerifyCaptcha(false);
    }
  }
  const onFinalSubmit = async (values: any, token: string) => {
    let appointmentSlot: string[] = [];
    appointmentSlot = values?.MORNING ? [...appointmentSlot, 'MORNING'] : [...appointmentSlot];
    appointmentSlot = values?.AFTERNOON ? [...appointmentSlot, 'AFTERNOON'] : [...appointmentSlot];
    appointmentSlot = values?.EVENING ? [...appointmentSlot, 'EVENING'] : [...appointmentSlot];
    appointmentSlot = values?.ASAP ? [...appointmentSlot, 'ASAP'] : [...appointmentSlot];

    const postData = {
      token: token,
      firstName: values?.firstName,
      lastName: values?.lastName,
      DOB: values?.dateOfBirth,
      gender: values?.gender,
      primarySymptoms: values?.primarySymptom,
      serviceIds: values?.serviceIds,
      preferedAppointmentDate: values?.preferedAppointmentDate,
      appointmentSlot: appointmentSlot,
      serviceAddress: {
        address1: values?.address1,
        address2: values?.address2,
        postalCode: values?.postalCode,
        city: values?.city,
        state: values?.state,
      },
      locationType: values?.locationType,
      specialInstruction: values?.entryInstruction,
      isPatient: values?.isPatient === 'yes' ? true : false,
      phoneNumber: deformatPhone(values?.phone),
      email: values?.email,
      howDidUHeardAboutus: values?.findUs,
    };

    try {
      const { data } = await axios?.post(`/appointment/add-web-appointment`, postData);
      recaptchaRef.current?.reset();

      setPreInitialValues(formInitials);
      toast.success(data.message);
      setFormStep(6);

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      recaptchaRef.current?.reset();
      setVerifyCaptcha(true);
    }
  };

  const { onSubmitHandler, loading } = useFormSubmitWithLoading((val) =>
    onFinalSubmit(val, verifyToken),
  );

  return (
    <div className="">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={validationSchema}
      >
        {() => (
          <Form className="flex flex-col gap-6 sm:grid  mt-2">
            <div className="flex flex-col gap-4 sm:grid sm:gap-10 sm:grid-cols-2 ">
              <FormikSelect
                label="how.did.you.find"
                name="findUs"
                options={[
                  { label: 'Web Search', value: 'Web Search' },
                  { label: 'Instagram', value: 'Instagram' },
                  { label: 'Facebook', value: 'Facebook' },
                  { label: 'Tik-Tok', value: 'Tik-Tok' },
                  { label: 'Work of Mouth', value: 'Work of Mouth' },
                  { label: 'Direct Mail', value: 'Direct Mail' },
                ]}
                fieldRequired
              />
            </div>
            <div>
              <ReCAPTCHA
                sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                onChange={onChange}
                ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
              />
            </div>
            <div className="flex justify-start gap-3 sm:gap-6 border-t-2 border-gray-100 py-6">
              <Button
                type="button"
                onClick={() => setFormStep(4)}
                textid="previous"
                className="border-primary border-1 w-full px-0 justify-center sm:w-auto sm:px-16 sm:py-3 text-primary hover:bg-primary hover:text-white bg-lightBlue"
              />
              <Button
                disabled={verifyCaptcha}
                loading={loading}
                textid="submit"
                className="w-full px-0 justify-center sm:w-auto sm:px-16 sm:py-3"
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RequestConfirmPage;
