import React, { FC } from 'react';
import { IntlProvider } from 'react-intl';
import { en } from './en';

export type LanguageProviderProps = {};

export const LanguageProvider: FC<LanguageProviderProps> = ({ children }) => {
  return (
    <IntlProvider locale="en" messages={en}>
      {children}
    </IntlProvider>
  );
};
