import { Text } from 'molecules/Text';
import React, { useState } from 'react';
import RequestPatientInfo from './RequestPatientInfo';
import RequestSelectService from './RequestSelectService';
import RequestServiceAddress from './RequestServiceAddress';
import RequestContactInfo from './RequestContactInfo';
import RequestConfirmPage from './RequestConfirmPage';
import AfterSubmission from './AfterSubmission';

function RequestAppointment() {
  const [formStep, setFormStep] = useState(1);
  const initialValues = {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    primarySymptom: '',
  };
  const [preInitialValues, setPreInitialValues] = useState(initialValues);

  return (
    <div>
      <div className="text-red-700 bg-gray-300 py-3 font-bold">
        <Text className="px-4 max-w-5xl mx-auto" id="info.emrgency.message" />
      </div>
      <div className="px-4 max-w-5xl mx-auto py-8 overflow-y-auto">
        <div className="flex flex-col gap-5 border-b-2 py-3">
          <Text className="font-semibold text-4xl" id="request.appointment" />
          <Text className="font-medium text-xl text-gray-700" id="important.info" />
          <div className="py-4">
            <ul className="space-y-2">
              <li>
                <Text className="font-semibold text-base text-primary" id="info.point.1" />
              </li>
              <li>
                <Text className="font-semibold text-base text-primary" id="info.point.2" />
              </li>
              <li>
                <Text className="font-semibold text-base text-primary" id="info.point.3" />
              </li>
              <li>
                <Text className="font-semibold text-base text-primary">
                  "<span className="text-red-600 text-lg">*</span>" &nbsp;indicates required fields
                </Text>
              </li>
            </ul>
          </div>
        </div>
        {formStep !== 6 && (
          <>
            <div className="hidden md:block py-8 ">
              <div className="flex items-center gap-2">
                <Text
                  className={`whitespace-nowrap ${
                    formStep === 1 ||
                    formStep === 2 ||
                    formStep === 3 ||
                    formStep === 4 ||
                    formStep === 5
                      ? 'text-primary font-bold'
                      : 'font-semibold'
                  } `}
                  id="patient.info"
                />
                <div className="flex w-24 mt-1 border-b-2" />

                <Text
                  className={`whitespace-nowrap ${
                    formStep === 2 || formStep === 3 || formStep === 4 || formStep === 5
                      ? 'text-primary font-bold'
                      : 'text-gray-400 font-semibold'
                  } `}
                  id="select.service"
                />
                <div className="flex w-24 mt-1 border-b-2" />

                <Text
                  className={`whitespace-nowrap ${
                    formStep === 3 || formStep === 4 || formStep === 5
                      ? 'text-primary font-bold'
                      : 'text-gray-400 font-semibold'
                  } `}
                  id="date.and.time"
                />
                <div className="flex w-24 mt-1 border-b-2" />

                <Text
                  className={`whitespace-nowrap ${
                    formStep === 4 || formStep === 5
                      ? 'text-primary font-bold'
                      : 'text-gray-400 font-semibold'
                  } `}
                  id="contact.info"
                />
                <div className="flex w-24 mt-1 border-b-2" />

                <Text
                  className={`whitespace-nowrap ${
                    formStep === 5 ? 'text-primary font-bold' : 'text-gray-400 font-semibold'
                  } `}
                  id="confirm"
                />
              </div>
            </div>

            <div className="md:hidden py-6 ">
              <div className="flex items-center gap-2">
                <div className="grow flex-1 border-primary border-b " />
                <div className="flex">
                  {formStep === 1 && (
                    <Text
                      className={`text-lg whitespace-nowrap text-primary font-bold`}
                      id="patient.info"
                    />
                  )}
                  {formStep === 2 && (
                    <Text
                      className={`text-lg whitespace-nowrap text-primary font-bold`}
                      id="select.service"
                    />
                  )}{' '}
                  {formStep === 3 && (
                    <Text
                      className={`text-lg whitespace-nowrap text-primary font-bold`}
                      id="date.and.time"
                    />
                  )}{' '}
                  {formStep === 4 && (
                    <Text
                      className={`text-lg whitespace-nowrap text-primary font-bold`}
                      id="contact.info"
                    />
                  )}{' '}
                  {formStep === 5 && (
                    <Text
                      className={`text-lg whitespace-nowrap text-primary font-bold`}
                      id="confirm"
                    />
                  )}
                </div>
                <div className="grow flex-1 border-primary border-b" />
              </div>
            </div>
          </>
        )}
        {/* {appointmentsLoading ? (
      <Loader />
    ) : ( */}
        <div className="">
          {formStep === 1 && (
            <RequestPatientInfo
              setFormStep={setFormStep}
              initialValues={preInitialValues}
              onSubmit={(values: any) => {
                setPreInitialValues({ ...preInitialValues, ...values });
              }}
            />
          )}
          {formStep === 2 && (
            <RequestSelectService
              setFormStep={setFormStep}
              initialValues={preInitialValues}
              onSubmit={(values: any) => {
                setPreInitialValues({ ...preInitialValues, ...values });
                setFormStep(3);
              }}
            />
          )}
          {formStep === 3 && (
            <RequestServiceAddress
              setFormStep={setFormStep}
              preInitialValues={preInitialValues}
              onSubmit={(values: any) => {
                setPreInitialValues({ ...preInitialValues, ...values });
                setFormStep(4);
              }}
            />
          )}
          {formStep === 4 && (
            <RequestContactInfo
              setFormStep={setFormStep}
              preInitialValues={preInitialValues}
              onSubmit={(values: any) => {
                setPreInitialValues({ ...preInitialValues, ...values });
                setFormStep(5);
              }}
            />
          )}
          {formStep === 5 && (
            <RequestConfirmPage
              setFormStep={setFormStep}
              initialValues={{ ...preInitialValues, findUs: '' }}
              setPreInitialValues={setPreInitialValues}
              formInitials={initialValues}
            />
          )}
          {formStep === 6 && <AfterSubmission setFormStep={setFormStep} />}
        </div>
        {/* )} */}
      </div>
    </div>
  );
}

export default RequestAppointment;
