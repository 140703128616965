import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Text } from 'molecules/Text';
import { LocaleString } from 'locales';
import Modal from 'atoms/Modal';
import { Dialog } from '@headlessui/react';

type ConfirmModalTemplateProps = {
  onClose: () => any;
  open: boolean;
  headerTextId: LocaleString;
  children: React.ReactNode;
  doNot_Allow_Out_Side_Close?: boolean;
};

const ConfirmModalTemplate = ({
  onClose,
  open,
  headerTextId,
  children,
  doNot_Allow_Out_Side_Close,
}: ConfirmModalTemplateProps) => {
  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={open}
        onClose={() => {
          if (doNot_Allow_Out_Side_Close) return;
          onClose();
        }}
      >
        {/* for above sm screen */}

        <div className="min-w-small-screen sm:min-w-large-screen ">
          <Dialog.Title
            style={{ cursor: 'move' }}
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900 mx-auto"
          >
            <div
              className="flex justify-between border-b mb-4 pb-2 px-0 pr-2"
              id="draggable-dialog-title"
            >
              <Text id={headerTextId} className="font-bold text-black" />
              <XIcon className="z-50 w-6 h-6 text-primary cursor-pointer" onClick={onClose} />
            </div>
          </Dialog.Title>
          {children}
        </div>
        {/* for below sm screen */}

        {/* <div className="block sm:hidden" style={{ minWidth: '80vw' }}>
          <Dialog.Title
            style={{ cursor: 'move' }}
            as="h3"
            className="text-lg font-medium leading-6 text-gray-900 mx-auto"
          >
            <div className="flex justify-between border-b mb-4 pb-2 px-0 pr-2">
              <Text id={headerTextId} className="font-bold text-black" />
              <XIcon className="w-6 h-6 text-primary cursor-pointer" onClick={onClose} />
            </div>
          </Dialog.Title>
          {children}
        </div> */}
      </Modal>
    </>
  );
};

export default ConfirmModalTemplate;
