import { Form, Formik } from 'formik';
import { FormikInput, FormikInputDateField, FormikTextArea } from 'molecules';
import { Button } from 'molecules/Button';
import FormikSelect from 'molecules/FormikSelect';
import * as yup from 'yup';

import React, { useRef } from 'react';
import { useFormSubmitWithLoading } from 'utils/hooks/useFormSubmitWithLoading';
import { validateRequiredFirstName, validateRequiredLastName } from 'utils/validators';
import { Text } from 'molecules/Text';

const requestPatientInfoValidation = yup.object().shape({
  firstName: validateRequiredFirstName().required('required'),
  lastName: validateRequiredLastName().required('required'),
  dateOfBirth: yup.date().required('required').nullable(),
  gender: yup.string().required('required'),
  primarySymptom: yup.string().trim().required('required'),
});

function RequestPatientInfo({ setFormStep, initialValues, onSubmit }: any) {
  const { onSubmitHandler, loading } = useFormSubmitWithLoading((values) => {
    onSubmit(values);
    setFormStep(2);
  });
  const formRef = useRef<any>();
  return (
    <div className="">
      <div className="pb-4">
        <Text className="text-gray-800 font-bold" id="form.heading.1" />
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmitHandler}
        validationSchema={requestPatientInfoValidation}
        innerRef={formRef}
        // validateOnChange
        // validateOnMount
      >
        {({ isValid, dirty, setFieldValue, values, setFieldTouched, handleChange }) => (
          <Form className="flex flex-col gap-4 sm:grid  mt-2">
            <div className="flex flex-col gap-4 sm:grid sm:gap-10 sm:grid-cols-2 ">
              <FormikInput label="firstname" name="firstName" fieldRequired />
              <FormikInput label="lastname" name="lastName" fieldRequired />
            </div>

            <div className="flex flex-col gap-4 sm:grid sm:gap-10 sm:grid-cols-2 ">
              <FormikInputDateField
                maxDate={new Date()}
                label="dob"
                name="dateOfBirth"
                fieldRequired
              />
              <FormikSelect
                label="gender"
                name="gender"
                options={[
                  { label: 'Male', value: 'Male' },
                  { label: 'Female', value: 'Female' },
                  { label: 'Intersex', value: 'Intersex' },
                  { label: 'M-to-F Female', value: 'M-to-F Female' },
                  { label: 'F-to-M Male', value: 'F-to-M Male' },
                ]}
                fieldRequired
              />
            </div>
            <div className="flex flex-col gap-4 sm:grid sm:gap-10 sm:grid-cols-2 ">
              <FormikTextArea
                label="patient.primary.symptoms"
                name="primarySymptom"
                fieldRequired
              />
            </div>

            <div className="flex justify-start mt-2">
              <Button
                loading={loading}
                textid="continue"
                className="w-full px-0 justify-center sm:w-auto sm:px-16 sm:py-3"
                type="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RequestPatientInfo;
