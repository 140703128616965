export const appRoutes = {
  LOGIN_PAGE: '/login',
  SIGNUP_PAGE: '/signup',
  LOGOUT: '/logout',
  ADD_PAST_MEDICAL_HISTORY: '/add_past_medical_history',
  EDIT_PAST_MEDICAL_HISTORY: '/edit_past_medical_history',
  ADD_FAMILY_MEDICAL_HISTORY: '/add_family_medical_history',
  EDIT_FAMILY_MEDICAL_HISTORY: '/edit_family_medical_history',
  ADD_ALLERGIES_HISTORY: '/add_allergies_history',
  EDIT_ALLERGIES_HISTORY: '/edit_allergies_history',
  ADD_MEDICATIONS_HISTORY: '/add_medications_history',
  EDIT_MEDICATIONS_HISTORY: '/edit_medications_history',
  ADD_SURGICAL_HISTORY: '/add_surgical_history',
  EDIT_SURGICAL_HISTORY: '/edit_surgical_history',
  OTP_VERIFICATION: '/otp-verification',
  HOME: '/home',
  SELECT_PLAN: '/select_plan',
  APPOINTMENTS: '/appointments',
  APPOINTMENT_DETAILS: '/appointments/appointment_details',
  APPOINTMENTS_BOOK: '/appointments/book_appointment',
  APPOINTMENTS_UPCOMING: '/appointments/upcoming_appointments',
  APPOINTMENTS_COMPLETED: '/appointments/completed_appointments',
  CHARTS: '/chart',
  NOTIFICATIONS: '/notification',
  // CHARTS_RECORDS: '/charts/records',
  CHARTS_HISTORY: '/records/medical-history',
  CHARTS_REPORTS: '/records/reports',
  CHAT: '/chat',
  CHAT_PROVIDER: '/appointments/chat/provider',
  ADMIN: '/admin',
  ADMIN_PASSWORD: '/admin/password',
  ADMIN_PROFILE: '/admin/profile',
  ADMIN_PAYMENTS: '/admin/payments',

  SUPER_ADMIN: '/super-admin',
  SUPER_ADMIN_PROFILE: '/super-admin/profile',
  SUPER_ADMIN_PASSWORD: '/super-admin/password',
  SUPER_ADMIN_LOGS: '/super-admin/admin_logs',
  SUPER_ADMIN_Admin_List: '/super-admin/admin_list',
  SUPER_ADMIN_EXPORTS_Appointment: '/super-admin/export',
  SUPER_ADMIN_EXPORTS_Invoice: '/super-admin/export/invoices',
  SUPER_ADMIN_SALES_TAX: '/super-admin/sales_tax',
  SUPER_ADMIN_SALES_TAX_HISTORY: '/super-admin/sales_tax/history',

  FORGOT_PASSWORD: '/forgot-password',
  ADMIN_BML: '/admin/bml_request',
  ADMIN_BML_INVOICE: '/admin/bml_request_invoice',
  ADMIN_UNVERIFIED_PAYMENTS: '/admin/appointments/unVerified_payments',
  ADMIN_BML_REQUEST: '/admin/appointments/BML_request',
  ADMIN_CASH_REQUEST: '/admin/cashRequest',
  ADMIN_FORGOT_PASSWORD: '/admin/forgot-password',
  ADMIN_PROVIDERS: '/admin/providers',
  ADMIN_SERVICES: '/admin/services',
  ADMIN_SERVICES_INACTIVE: '/admin/services/inactive',
  ADMIN_PROVIDER_SERVICES: '/admin/provider_services',
  ADMIN_PROVIDER_SERVICES_INACTIVE: '/admin/provider_services/inactive',
  ADMIN_PROVIDER_SERVICES_DXCODES: '/admin/provider_services/dx_codes',
  ADMIN_INVITE: '/admin/providers/invitation',
  ADMIN_USERS: '/admin/users',
  ADMIN_PATIENTS: '/admin/patients',
  ADMIN_PATIENTS_NOTES_DETAIL: '/admin/patients/:id/notes/detail',
  ADMIN_INVOICES: '/admin/invoices',
  ADMIN_CONSENTS: '/admin/consents',
  ADMIN_NOTIFICATION: '/admin/notification',
  ADMIN_CHAT: '/admin/chat',
  ADMIN_APPOINTMENT: '/admin/appointments',
  ADMIN_WEB_APPOINTMENT: '/admin/web-appointments',
  ADMIN_SOAP_NOTE_TEMPLATES: '/admin/soap-note-templates',
  ADMIN_APPOINTMENT_CHAT_VIEW: '/admin/appointments/chat_view',
  ADMIN_INBOX: '/admin/inbox',
  ADMIN_PROMETHEUS: '/admin/predictive-360',
  ADMIN_MEMBERSHIP_FORM: '/admin/membership-form',


  PROFILE: '/profile',
  PATIENT_PROFILE: '/profile/personal',
  PATIENT_PLANS: '/profile/plans',
  PATIENT_SETTINGS: '/profile/settings',
  PATIENT_ABOUT: '/profile/about',
  Patient_Payment: 'paynow',
  Set_Password: 'set-password',
  Vital_Chart: 'vital-chart',
  Request_Appointment: 'request-appointment',
  Consent_Details: 'consent_details',

  PROMETHEUS: `/predictive360`,
};

export const API_URL = {
  ADMIN_LOGIN: '/auth/admin-login',
  USER_INFO: '/auth/me',
  PATIENT_LOGIN: '/auth/patient-login',
  PATIENT_SEND_OTP: '/auth/patient/email-otp',
  PATIENT_VERIFY_OTP: '/auth/patient/verify-otp',
  PATIENT_REGISTER: '/auth/register',
  ADMIN_PATIENT_REGISTER: '/users/create-patient',
  RESET_PASSWORD: '/auth/reset-password',
  CHANGE_PASSWORD: '/users/change-password',
  SEND_OTP: '/auth/send-OTP',
  VERIFY_OTP: '/auth/verify-OTP',
};

export const ROLES = {
  ADMIN: 'ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  PATIENT: 'PATIENT',
};

export const RCH_ABOUT = {
  CONSENT_URL:
    'https://dev-qhl.s3.amazonaws.com/uploads/files/4849e460-ea1f-11eb-b75f-eb934964ee6a.html',
  DISCLOSURE_URL:
    'https://dev-qhl.s3.amazonaws.com/uploads/files/6de03cb0-ea1f-11eb-b75f-eb934964ee6a.html',
  PNP_URL:
    'https://dev-qhl.s3.amazonaws.com/uploads/images/79a1eb20-3e16-11ec-9b62-d98fa6c44552.html',
  TNC_URL:
    'https://dev-qhl.s3.amazonaws.com/uploads/images/664d3020-3e16-11ec-9b62-d98fa6c44552.html',
  BOTOX_CONSENT_URL:
    'https://dev-qhl.s3.amazonaws.com/uploads/files/39adb680-388e-11ec-ae13-09cf088131b1.html',
};

export type RoleType = any;

export const AUTH_KEYS = {
  USER_DATA: 'user',
  AUTH_TOKEN_COOKIE: 'access_token',
  REFRESH_TOKEN_COOKIE: 'refresh_token',
};

export const PAGE_SIZE = 10;

export const CapitalizeValue = (value: string) => {
  return value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase();
};

export const DATE_FORMATS = {
  default: 'MM/DD/YYYY',
  date_fns_format: 'MM/dd/yyyy',
  time: 'hh:mm A',
  date: 'MM/DD/YYYY',
};

export const SplitTimeValue = (value: string) => {
  const dateStr = `01/01/2000, ${value}`;
  const m = dateStr.match(/(\d{1,2}:\d{2}):\d{2}\s+?(AM|PM)/i);
  if (m) {
    return `${m[1]} ${m[2].toUpperCase()}`;
  }
  return value;
};

export const isVideo = (url?: string) => {
  return !!url?.match(/^.+\.(mp4|mov|avi|flv|mpeg)$/i);
};

export const getFormattedName = (firstName?: string, lastName?: string) => {
  return `${CapitalizeValue(firstName || '')} ${
    (lastName || '')
      ?.slice(0, 1)
      ?.charAt(0)
      ?.toUpperCase()
      ?.concat(lastName ? '.' : '') || 'User'
  }`;
};

export const formattedDate = (dob: string) => {
  const d = new Date(dob);
  return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/');
};

export const firebaseConfig = {
  apiKey: 'AIzaSyDMAG3ONJtn0KXTvt0I7_fZ27WmTvYUEiQ',
  authDomain: 'lx-medical-notification.firebaseapp.com',
  projectId: 'lx-medical-notification',
  storageBucket: 'lx-medical-notification.appspot.com',
  messagingSenderId: '12013037332',
  appId: '1:12013037332:web:e744f143252e3c05f44401',
  measurementId: 'G-8EEYW0PYME',
};

export const AppointmentStatus = {
  PENDING_APPROVAL: 'PENDING APPROVAL',
  AWAITING_TIME: 'AWAITING TIME',
  SCHEDULED: 'SCHEDULED',
  ON_THE_WAY: 'ON THE WAY',
  AWAITING_PAYMENT: 'AWAITING PAYMENT',
  PAID: 'PAID',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  // REJECTED: 'REJECTED',
  PAYMENT_REQUESTED: 'PAYMENT REQUESTED',
};

export const WebAppointmentStatus = {
  CREATED: 'CREATED',
  CONVERTED: 'CONVERTED',
  CANCELLED: 'CANCELLED',
};
export const AppointmentPaymentMode = {
  CASH: 'CASH',
  POS: 'Square POS',
  BML: 'BML',
  ONLINE: 'ONLINE',
  CARD_ON_FILE: 'CARD ON FILE',
  // STRIPE: 'STRIPE',
};

export const AppointmentSoapNoteOptions = {
  SUBMITTED: 'SUBMITTED',
  NOT_SUBMITTED: 'NOT SUBMITTED',
};

export const UnverfiedPaymentMode = {
  CASH: 'CASH',
  POS: 'Square POS',
  // ONLINE: 'ONLINE',
  // CARD_ON_FILE: 'CARD ON FILE',
};
export const dropdownStyles = {
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),
  container: (provided: any, state: any) => ({
    ...provided,
    background: state?.isDisabled ? '#F3F4F6' : 'white',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: '1px solid #D1D5DB',
    borderRadius: '0.375rem;',
    background: state?.isDisabled ? '#F3F4F6' : 'white',
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    background: state?.isSelected ? '#3b4d75' : provided?.background,
    color: state?.isSelected ? 'white' : 'black',
  }),
};

export const dropdownStylesWithPlaceholder = {
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),

  container: (provided: any, state: any) => ({
    ...provided,
    background: state?.isDisabled ? '#F3F4F6' : 'white',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: '1px solid #D1D5DB',
    borderRadius: '0.375rem;',
    background: state?.isDisabled ? '#F3F4F6' : 'white',
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    background: state?.isSelected ? '#3b4d75' : provided?.background,
    color: state?.isSelected ? 'white' : '#F3F4F6',
  }),
};

export const selectStyleWithSearch = {
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),

  container: (provided: any, state: any) => ({
    ...provided,
    background: state?.isDisabled ? '#F3F4F6' : 'bg-gray-200',
  }),
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: '1px solid #D1D5DB',
    borderRadius: '0.375rem;',
    background: state?.isDisabled ? '#F3F4F6' : 'white',
  }),

  option: (provided: any, state: any) => ({
    ...provided,
    background: state?.isSelected ? '#3b4d75' : provided?.background,
    color: state?.isSelected ? 'white' : '#F3F4F6',
  }),
};

export const tagColor = (valname: string) => {
  let color = '';
  switch (valname) {
    case 'isHH':
      color = '#4770CC';
      break;
    case 'isAdult':
      color = '#250868';
      break;
    case 'isDependent':
      color = '#ff6640';
      break;
    case 'isLocked':
      color = '#9b0808';
      break;
    case 'noLocked':
      color = '#006416b9';
      break;

    default:
      color = '#54BAB2';
      break;
  }
  return color;
};

export enum PaymentType {
  APPOINTMENT = 'APPOINTMENT',
  // MEMBERSHIP = 'MEMBERSHIP',
  CUSTOM_INVOICE = 'CUSTOM INVOICE',
}

export enum PaymentStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  OPEN = 'OPEN',
}

export enum MembershipType {
  ONDEMAND = 'ON DEMAND',
  // BASIC = 'BASIC',
  // EXECUTIVE = 'EXECUTIVE',
  STANDARD = 'STANDARD',
  CYPRESS = 'CYPRESS',
  PREMIER = 'PREMIER',
  PREMIERPLUS = 'PREMIER PLUS',
  LXELITE = 'LX ELITE',
  // MIORA = 'MIORA',
}
export enum Status {
  DRAFT = 'DRAFT',
  BML = 'BML', // Bill me Later signifies services is being done payment will be done later
  CANCELLED = 'CANCELLED',
  PAID = 'PAID',
  PAYMENT_REQUESTED = 'PAYMENT_REQUESTED',
}

export enum DocumentCategory {
  REPORT = 'Report',
  SOAP_NOTE_IMAGE = 'Soap Note Image',
  SYMPTOM_IMAGES = 'Symptom Images',
}

export const itemsPerPageOptions = [10, 50, 100];

export const genderArray = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Intersex', value: 'Intersex' },
  { label: 'M-to-F Female', value: 'M-to-F Female' },
  { label: 'F-to-M Male', value: 'F-to-M Male' },
];
export const travelFeeValue = '150';

export enum Pi360Status {
  ALL = 'All',
  INCOMPLETE = 'Incomplete',
  COMPLETE = 'Complete',
}
