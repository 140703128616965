import React, { FC } from 'react';
import * as yup from 'yup';
import { FormikHelpers, Form, Formik } from 'formik';
import { FormikInput } from 'molecules/FormikInput';
import { useFormSubmitWithLoading } from 'utils/hooks/useFormSubmitWithLoading';
import { Button } from 'molecules/Button';
import { Text } from 'molecules/Text';
import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';

const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required('Password is required')
    .matches(/^(?:(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*)$/, 'password.validation.message'),
  confirmPassword: yup
    .string()
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: yup.string().oneOf([yup.ref('password')], 'Both passwords need to be the same'),
    })
    .required('Confirm Password is required'),
});

const defaultValues = {
  password: '',
  confirmPassword: '',
};

type ResetPasswordFormValues = {
  password: string;
  confirmPassword: string;
};

export type ResetPasswordFormProps = {
  onSubmit?: (
    values: ResetPasswordFormValues,
    formikHelpers?: FormikHelpers<ResetPasswordFormValues>,
  ) => void;
  onBackButton: () => void;
};

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ onSubmit, onBackButton }) => {
  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);
  return (
    <div>
      <div className="flex items-center mb-5">
        <ArrowNarrowLeftIcon
          className="text-primary mr-2 h-8 w-10 mb-2 cursor-pointer"
          onClick={onBackButton}
        />
        <Text className="text-gray-900 text-2xl font-black text-primary mb-2" id="createPassword" />
      </div>

      <Formik
        initialValues={defaultValues}
        onSubmit={onSubmitHandler}
        validationSchema={resetPasswordSchema}
      >
        {() => (
          <Form className="grid gap-5">
            <FormikInput label="password" name="password" type="password" />
            <FormikInput label="confirmPassword" name="confirmPassword" type="password" />

            <div className="mx-auto mt-5 w-full ">
              <Button loading={loading} textid="continue" className="w-full" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
