import { CheckCircleIcon } from '@heroicons/react/solid';
import { Button } from 'molecules/Button';
import React, { useState } from 'react';
type formProps = {
  setFormStep: (val: number) => void;
};
function AfterSubmission({ setFormStep }: formProps) {
  const [loading, setloading] = useState(false);
  const handleTimeout = (e: any) => {
    e.stopPropagation();
    setloading(true);
    setTimeout(() => {
      setloading(false);
      setFormStep(1);
    }, 400);
  };
  return (
    <div className="">
      <div className="flex flex-col gap-8 mt-8">
        <div className="flex flex-col gap-4">
          <div className="flex justify-center">
            <CheckCircleIcon className="h-16 w-16 text-green-600   " />
          </div>
          <div className="flex justify-center text-lg text-gray-700 font-semibold">
            Appointment has been booked successfully.
          </div>
        </div>
        <div className="flex justify-center items-center gap-x-3">
          <div className=" text-base text-gray-700 font-semibold">Go to</div>
          <Button
            type="button"
            loading={loading}
            onClick={handleTimeout}
            textid="request.appointment"
            className="w-full px-0 justify-center sm:w-auto sm:px-16 sm:py-3"
          />
        </div>
      </div>
    </div>
  );
}

export default AfterSubmission;
