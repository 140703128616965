import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import axiosService from 'utils/api/axios';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';

const initialState = {
  chatInboxList: {},
  chatInboxListLoading: false,
};

const actions = {
  getChatInboxList: createAsyncActions('Chat_Inbox_List'),
};

const chatInboxListReducer = createReducer({
  [`${actions.getChatInboxList.loading}`]: (state) => ({
    ...state,
    chatInboxListLoading: true,
  }),
  [`${actions.getChatInboxList.success}`]: (state, { payload }) => ({
    ...state,
    chatInboxList: payload,
    chatInboxListLoading: false,
  }),
  [`${actions.getChatInboxList.failure}`]: (state) => ({
    ...state,
    chatInboxListLoading: false,
  }),
});

export const { useContext: useChatInboxList, Provider: ChatInboxListProvider } = createContainer(
  () => {
    const [state, dispatch] = useReducer(chatInboxListReducer, initialState);

    const getChatInboxList = useCallback(async () => {
      try {
        dispatch(actions.getChatInboxList.loading());

        const { data } = await axiosService?.get(`/chat/get-all-last-messages`);
        dispatch(actions.getChatInboxList.success(data?.data?.chats));

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.getChatInboxList.failure());
      }
      return undefined;
    }, []);

    const clearData = () => {
      dispatch(actions.getChatInboxList.success({}));
    };

    return {
      state,
      actions: {
        getChatInboxList,
        clearData,
      },
    };
  },
);

export default useChatInboxList;
