import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';

const initialState = {
  invitedProvidersList: { data: [], meta: {} },
  invitedProvidersLoading: false,
  ProvidersList: { data: [], meta: {} },
  ProvidersLoading: false,
  AllProvidersList: { data: [] },
  AllProvidersLoading: false,
};
const actions = {
  updateInvitedProviderList: createAsyncActions('UPDATE_INVITED_PROVIDERS_LIST'),
  updateProvidersList: createAsyncActions('PROVIDERS_LIST'),
  AllProviderList: createAsyncActions('ALL_PROVIDERS_LIST'),
};

const providerReducer = createReducer({
  [`${actions.updateInvitedProviderList.loading}`]: (state) => ({
    ...state,
    invitedProvidersLoading: true,
  }),
  [`${actions.updateInvitedProviderList.success}`]: (state, { payload }) => ({
    ...state,
    invitedProvidersList: payload,
    invitedProvidersLoading: false,
  }),
  [`${actions.updateInvitedProviderList.failure}`]: (state) => ({
    ...state,
    invitedProvidersLoading: false,
  }),

  [`${actions.updateProvidersList.loading}`]: (state) => ({
    ...state,
    ProvidersLoading: true,
  }),
  [`${actions.updateProvidersList.success}`]: (state, { payload }) => ({
    ...state,
    ProvidersList: payload,
    ProvidersLoading: false,
  }),
  [`${actions.updateProvidersList.failure}`]: (state) => ({
    ...state,
    ProvidersLoading: false,
  }),

  [`${actions.AllProviderList.loading}`]: (state) => ({
    ...state,
    AllProvidersLoading: true,
  }),
  [`${actions.AllProviderList.success}`]: (state, { payload }) => ({
    ...state,
    AllProvidersList: payload,
    AllProvidersLoading: false,
  }),
  [`${actions.AllProviderList.failure}`]: (state) => ({
    ...state,
    AllProvidersLoading: false,
  }),
});

export const { useContext: useAdminProvider, Provider: AdminProviderProvider } = createContainer(
  () => {
    const [state, dispatch] = useReducer(providerReducer, initialState);

    const InviteProvider = async (formData: any) => {
      try {
        const { data } = await axiosService?.post(`/auth/send-verification-code`, formData);
        toast.success(data?.message);

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const getInvitedProvidersList = async (
      PageNum: string | number,
      take?: string | number,
      query?: string,
      status?: string,
    ) => {
      try {
        dispatch(actions.updateInvitedProviderList.loading());
        let api = `/users/providers/invite?page=${Number(PageNum)}&take=${take || 10}&query=${query}`;

        if (status)
          api = `/users/providers/invite?page=${Number(
            PageNum,
          )}&take=${take || 10}&query=${query}&status=${status}`;

        const { data } = await axiosService?.get(api);
        dispatch(actions.updateInvitedProviderList.success(data?.data));

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.updateInvitedProviderList.failure());
      }
      return undefined;
    };

    const getProvidersList = async (
      PageNum?: string | number,
      query?: string,
      take?: string | number,
      sortBy?: string,
      credentials?: string,
    ) => {
      try {
        dispatch(actions.updateProvidersList.loading());

        let api = `/users?role=PROVIDER&query=${query || ''}&page=${PageNum || 1}&take=${
          take || 10
        }&order=${sortBy || 'ASC'}`;

        if (credentials)
          api = `/users?role=PROVIDER&query=${query || ''}&page=${PageNum || 1}&take=${
            take || 10
          }&order=${sortBy || 'ASC'}&credentials=${credentials}`;

        const { data } = await axiosService?.get(api);
        dispatch(actions.updateProvidersList.success(data));

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.updateProvidersList.failure());
      }
      return undefined;
    };
    const getAllProvidersList = useCallback(async () => {
      try {
        dispatch(actions.AllProviderList.loading());

        const { data } = await axiosService?.get('/users/list?role=PROVIDER');
        dispatch(actions.AllProviderList.success(data));

        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.AllProviderList.failure());
      }
      return undefined;
    }, []);

    return {
      state,
      actions: {
        InviteProvider,
        getInvitedProvidersList,
        getAllProvidersList,
        getProvidersList,
      },
    };
  },
);

export default useAdminProvider;
