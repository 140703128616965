import React, { useEffect, useState } from 'react';
import Modal from 'molecules/ConfirmModalTemplate';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm_';
import Loader from 'atoms/Loader';
import { toast } from 'react-toastify';
import axios from 'axios';

const { REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

// @ts-ignore
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

type CheckoutModalProps = {
  onClose: () => any;
  onSuccessfulCheckout: () => any;
  open: boolean;
  appointmentId: string;
  totalAmount: string | number;
};

const CheckoutModal = ({
  open,
  onClose,
  appointmentId,
  totalAmount,
  onSuccessfulCheckout,
}: CheckoutModalProps) => {
  const [clientSecret, setClientSecret] = useState('');

  const [paymentIsProcessing, setPaymentIsProcessing] = useState(false);
  axios.defaults.baseURL = process.env.REACT_APP_API_HOST;
  const clientSecretFunc = async (val: string) => {
    try {
      const res = await axios.post(`${axios.defaults.baseURL}/payments/${val}/paymentIntent`, {});
      setClientSecret(res?.data?.data?.clientSecret);
    } catch (e) {
      toast.error(
        <div>
          <div>
            {
              // @ts-ignore
              e?.response?.data?.message
            }
          </div>
          <div>Try Again!</div>
        </div>,
      );
      onClose();
    }
  };

  useEffect(() => {
    if (appointmentId && open) clientSecretFunc(appointmentId);
    if (!open) setClientSecret('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal
      headerTextId="add.payment.details"
      doNot_Allow_Out_Side_Close={true}
      open={open}
      onClose={() => {
        if (paymentIsProcessing) return;
        onClose();
      }}
    >
      {clientSecret ? (
        <Elements options={{ clientSecret }} stripe={stripePromise}>
          <CheckoutForm
            appointmentId={appointmentId}
            totalAmount={totalAmount}
            onSuccessfulCheckout={onSuccessfulCheckout}
            clientSecret={clientSecret}
            setPaymentIsProcessing={setPaymentIsProcessing}
          />
        </Elements>
      ) : (
        <div className="h-44">
          <Loader />
        </div>
      )}
    </Modal>
  );
};

export default CheckoutModal;
