import React, { useState, useEffect } from 'react';
import { getToken } from '../../firebase';
import useAuth from 'contexts/AuthenticationContext';

const Notifications = (props) => {
  const [fcmToken, setToken] = useState('false');
  const {
    actions: { setFCMToken },
  } = useAuth();

  // To load once
  useEffect(() => {
    let data;

    async function tokenFunc() {
      data = await getToken(setToken);

      return data;
    }

    tokenFunc();
  }, [setToken]);

  useEffect(() => {
    setFCMToken(fcmToken);
    // eslint-disable-next-line
  }, [fcmToken]);

  return <></>;
};

Notifications.propTypes = {};

export default Notifications;
