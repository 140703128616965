import axios from 'axios';
import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';

const initialState = {
  servicesList: { data: [], meta: {} },
  servicesLoading: false,
  providerServicesList: { data: [], meta: {} },
  providerServicesLoading: false,
  webFormServiceList: { data: [], meta: {} },
  webFormServiceLoading: false,
};

const actions = {
  updateServicesList: createAsyncActions('UPDATE_SERVICES_LIST'),
  webServiceList: createAsyncActions('WEB_SERVICE_LIST'),
  updateProviderServicesList: createAsyncActions('UPDATE_PROVIDER_SERVICES_LIST'),
};
axios.defaults.baseURL = process.env.REACT_APP_API_HOST;

const servicesReducer = createReducer({
  [`${actions.updateServicesList.loading}`]: (state) => ({
    ...state,
    servicesLoading: true,
  }),
  [`${actions.updateServicesList.success}`]: (state, { payload }) => ({
    ...state,
    servicesList: payload,
    servicesLoading: false,
  }),
  [`${actions.updateServicesList.failure}`]: (state) => ({
    ...state,
    servicesLoading: false,
  }),

  [`${actions.webServiceList.loading}`]: (state) => ({
    ...state,
    webFormServiceLoading: true,
  }),
  [`${actions.webServiceList.success}`]: (state, { payload }) => ({
    ...state,
    webFormServiceList: payload,
    webFormServiceLoading: false,
  }),
  [`${actions.webServiceList.failure}`]: (state) => ({
    ...state,
    webFormServiceLoading: false,
  }),

  [`${actions.updateProviderServicesList.loading}`]: (state) => ({
    ...state,
    providerServicesLoading: true,
  }),
  [`${actions.updateProviderServicesList.success}`]: (state, { payload }) => ({
    ...state,
    providerServicesList: payload,
    providerServicesLoading: false,
  }),
  [`${actions.updateProviderServicesList.failure}`]: (state) => ({
    ...state,
    providerServicesLoading: false,
  }),
});

export const { useContext: useAdminServices, Provider: AdminServices } = createContainer(() => {
  const [state, dispatch] = useReducer(servicesReducer, initialState);
  //Patient
  const AddService = async (formData: any) => {
    try {
      const { data } = await axiosService?.post(`/list/add-service`, formData);
      toast.success(data.message);

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  };
  const UpdateService = async (formData: any, callback?: () => void) => {
    try {
      const { data } = await axiosService?.post(`/list/update-service`, formData);
      toast.success(data?.message);

      const temp_state = state?.servicesList?.data?.map((val: any) =>
        val?.id === formData?.id ? { ...val, ...formData } : val,
      );

      dispatch(
        actions.updateServicesList.success({ data: temp_state, meta: state?.servicesList?.meta }),
      );

      if (data && typeof callback === 'function') {
        callback();
      }

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  };
  const getServicesList = useCallback(
    async (PageNum: string | number, query?: string, category?: string, enable?: boolean, take?: string | number) => {
      try {
        dispatch(actions.updateServicesList.loading());
        let api = `/list?page=${Number(PageNum)}&take=${take || 10}&query=${query}&category=${category || ''}`;
        if (typeof enable === 'boolean') {
          api = api + `&enable=${enable}`;
        }
        const { data } = await axiosService?.get(api);

        dispatch(actions.updateServicesList.success(data?.data?.serviceList));
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.updateServicesList.failure());
      }
      return undefined;
    },
    [],
  );
  const getWebServiceList = useCallback(async (PageNum: string | number, query?: string) => {
    try {
      dispatch(actions.webServiceList.loading());
      let api = `/list/patient-services?page=${Number(PageNum)}&take=10&query=${query}`;

      const { data } = await axios.get(`${axios.defaults.baseURL}/${api}`);
      dispatch(actions.webServiceList.success(data?.data?.serviceList));
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.webServiceList.failure());
    }
    return undefined;
  }, []);
  const deletePatientService = useCallback(async (id: string, callback) => {
    try {
      const { data } = await axiosService?.delete(`/list/patient-service/${id}`, {});
      if (typeof callback === 'function') {
        callback();
      }
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const clearData = () => dispatch(actions.updateServicesList.success({ data: [], meta: {} }));

  //Provider
  const AddProviderService = async (formData: any) => {
    try {
      const { data } = await axiosService?.post(`/list/add-provider-service`, formData);
      toast.success(data.message);

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  };
  const UpdateProviderService = async (formData: any, callback?: () => void) => {
    try {
      const { data } = await axiosService?.post(`/list/update-provider-service`, formData);
      toast.success(data?.message);

      const temp_state = state?.providerServicesList?.data?.map((val: any) =>
        val?.id === formData?.id ? { ...val, ...formData } : val,
      );

      dispatch(
        actions.updateProviderServicesList.success({
          data: temp_state,
          meta: state?.providerServicesList?.meta,
        }),
      );

      if (data && typeof callback === 'function') {
        callback();
      }

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  };
  const getProviderServicesList = useCallback(
    async (
      PageNum: string | number,
      query?: string,
      category?: string,
      enable?: boolean | string,
      take?: number | string,
    ) => {
      try {
        dispatch(actions.updateProviderServicesList.loading());
        let api = `/list/provider-services?`;
        if (PageNum) {
          api = api + `&page=${Number(PageNum)}&take=${take ? Number(take) : 10}`;
        }
        if (query) {
          api = api + `&query=${query}`;
        }
        if (category) {
          api = api + `&category=${category}`;
        }

        if (typeof enable === 'boolean') {
          api = api + `&enable=${enable}`;
        }
        const { data } = await axiosService?.get(api);
        dispatch(actions.updateProviderServicesList.success(data?.data?.serviceList));

        return data?.data?.serviceList;
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.updateProviderServicesList.failure());
      }
      return undefined;
    },
    [],
  );
  const deleteProviderService = useCallback(async (id: string, callback) => {
    try {
      const { data } = await axiosService?.delete(`/list/provider-service/${id}`, {});
      if (typeof callback === 'function') {
        callback();
      }
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  const getdxandcategory = async () => {
    try {
      // dispatch(actions.appointmentsList.loading());

      const { data } = await axiosService?.get('/list/list-DxCodes');
      // dispatch(actions.appointmentsList.success(data?.data?.appointments));
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      // dispatch(actions.appointmentsList.failure());
    }
    return undefined;
  };

  //AppointmentDetails
  const getProviderServiceCategories = useCallback(async () => {
    try {
      const { data } = await axiosService?.get(`/list/provider-service-categories`);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);
  const getCPTCodeList = useCallback(async (category?: string) => {
    try {
      let  api = '';
      if(category) {
        api = `/list/CPTCodes?category=${category}`;
      } else {
        api = `/list/CPTCodes`;
      }
      const { data } = await axiosService?.get(api);
      // const { data } = await axiosService?.get(`/list/CPTCodes?category=${category}`);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);
  const getSymptomsList = useCallback(async () => {
    try {
      const { data } = await axiosService?.get(`/list/symptoms`);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);
  const getDXCodeList = useCallback(async (symptom: string) => {
    try {
      const { data } = await axiosService?.get(`/list/DxCodes?symptom=${symptom}`);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);
  const updateAppointmentServiceAvailed = useCallback(async (formData, callback) => {
    try {
      const { data } = await axiosService?.put(`/appointment/add-services-availed`, formData);
      if (typeof callback === 'function') {
        callback();
      }
      return data;
    } catch (e) {
      // console.log(e);
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  }, []);

  return {
    state,
    actions: {
      UpdateProviderService,
      getProviderServiceCategories,
      getProviderServicesList,
      AddProviderService,
      getServicesList,
      AddService,
      UpdateService,
      clearData,
      deletePatientService,
      deleteProviderService,
      getdxandcategory,
      getCPTCodeList,
      getSymptomsList,
      updateAppointmentServiceAvailed,
      getDXCodeList,
      getWebServiceList,
    },
  };
});

export default useAdminServices;
