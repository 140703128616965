import React, { FC, useState, useCallback, useEffect } from 'react';

import { ArrowNarrowLeftIcon } from '@heroicons/react/solid';
import { Image } from 'atoms/Image/Image';
import EmailIcon from 'assets/icons/EmailIcon.png';
import OtpInput from 'react-otp-input';

import { useFormSubmitWithLoading } from 'utils/hooks/useFormSubmitWithLoading';
import { Button } from 'molecules/Button';
import { Text } from 'molecules/Text';
import useAuth from 'contexts/AuthenticationContext';

export type OTPVerifyFormProps = {
  onBackButton?: () => void;
  onConfirm: (token: string) => void;
  emailToVerify: any;
  phoneText?: true;
  userId?: boolean;
};

export const OTPVerifyForm: FC<OTPVerifyFormProps> = ({
  onBackButton,
  onConfirm,
  emailToVerify,
  phoneText,
  userId,
}) => {
  const [OTP, setOTP] = useState('');
  const [error, showError] = useState(false);
  const [timer, setTimer] = useState(15);
  const {
    actions: { verifyUserOTP, sendVerificationCode },
  } = useAuth();

  const startTimer = () => {
    setTimer((val) => val - 1);
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(startTimer, 1000);
    }
  }, [timer]);

  const resendVerificationCode = useCallback(() => {
    if (emailToVerify) {
      const userIdData = { userId: emailToVerify };
      const emailIdData = { email: emailToVerify };
      sendVerificationCode(userId ? userIdData : emailIdData);
      setTimer(15);
    }
  }, [emailToVerify, sendVerificationCode, userId]);

  useEffect(() => {
    if (OTP.length === 6) {
      showError(false);
    }
  }, [OTP, showError]);

  const handleOTP = async () => {
    if (OTP.length === 6) {
      const userIdData = { OTP: OTP, userId: emailToVerify };
      const emailIdData = { OTP: OTP, email: emailToVerify };

      const data = await verifyUserOTP(userId ? userIdData : emailIdData);
      if (!data) {
        showError(true);
      } else {
        onConfirm(data?.data?.token);
      }
    } else {
      showError(true);
    }
  };
  const { onSubmitHandler, loading } = useFormSubmitWithLoading(handleOTP);

  return (
    <div>
      {onBackButton && (
        <div className="flex items-center">
          <ArrowNarrowLeftIcon
            className="text-primary mr-2 h-8 w-10 mb-2 cursor-pointer"
            onClick={onBackButton}
          />
          <Text className="text-xl font-bold text-primary mb-2" id="verify.email" />
        </div>
      )}
      {!onBackButton && (
        <div className="space-y-4">
          <Text className="text-3xl font-bold text-primary mb-4 border-b-2" id="setPassword" />
          <Text className="text-xl font-bold text-primary mb-2" id="verify.email" />
        </div>
      )}
      {/* <MailOpenIcon className="text-gray-300 h-20 w-20 mt-5 mb-2" /> */}
      <Image
        src={EmailIcon}
        alt="EmailIcon"
        height="73px"
        width="116px"
        objectfit="scale-down"
        className="mt-5 mb-2"
      />
      <Text
        className="text-gray-500 text-xs mb-2 font-bold"
        id={phoneText ? 'verify.email_phone.content' : 'verify.email.content'}
      />
      {onBackButton && (
        <Text className="text-gray-600 text-base bg-gray-200 w-max font-bold px-1 py-0.5 rounded">
          {emailToVerify}
        </Text>
      )}

      <div className="my-4">
        <div className="hidden md:block">
          <OtpInput
            containerStyle={{
              margin: '0 0 0 -0.5rem',
            }}
            focusStyle={{ borderColor: 'white' }}
            inputStyle={{
              width: '3rem',
              height: '3rem',
              margin: '.5rem',
              fontSize: '1rem',
              fontWeight: 'bold',
              border: '1px solid',
              borderColor: 'black',
            }}
            value={OTP}
            shouldAutoFocus={true}
            onChange={setOTP}
            numInputs={6}
            hasErrored={error}
            errorStyle={{ borderColor: 'red' }}
          />
        </div>

        <div className="block md:hidden">
          <OtpInput
            containerStyle={{
              margin: '0 0 0 -0.5rem',
            }}
            focusStyle={{ borderColor: 'white' }}
            inputStyle={{
              width: '2rem',
              height: '2rem',
              margin: '.5rem',
              fontSize: '1rem',
              fontWeight: 'bold',
              border: '1px solid',
              borderColor: 'black',
            }}
            value={OTP}
            shouldAutoFocus={true}
            onChange={setOTP}
            numInputs={6}
            hasErrored={error}
            errorStyle={{ borderColor: 'red' }}
          />
        </div>

        {error && <Text className="text-xs text-red-500" id="verify.email.error" />}

        <div className=" mt-3 text-base">
          <Text className="font-medium inline" id="resend.otp.info" />
          {timer <= 0 ? (
            <Text
              className="font-bold inline text-primary ml-1 cursor-pointer"
              id="resend.otp"
              onClick={resendVerificationCode}
            />
          ) : (
            <Text className="font-bold inline text-primary ml-3 text-sm">
              <span className="text-xs text-gray-500">Resend code in </span> {timer}sec
            </Text>
          )}
        </div>
        <div className="mt-5">
          <Button loading={loading} type="submit" onClick={onSubmitHandler} textid="submit" />
        </div>
      </div>
    </div>
  );
};
