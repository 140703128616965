import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';

type dataType = {
  medicalHistory: {
    allergies: string;
    currentMedication: string;
    familyHistory: { illness: null | string[]; others: null | string };
    id: string;
    pastMedicalHistory: { illness: null | string[]; others: null | string };
    surgicalHistory: string;
  } | null;

  Loading: boolean;

  reports: {
    data: {
      createdAt: string;
      id: string;
      reportName: string;
      reportUrl: string;
      updatedAt: string;
    }[];
    meta: any;
  };
};

const initialState: dataType = {
  medicalHistory: null,
  Loading: false,
  reports: { data: [], meta: {} },
};

const actions = {
  medicalHistory: createAsyncActions('MEDICAL_HISTORY'),
  reports: createAsyncActions('MEDICAL_REPORTS'),
};

const medicalHistoryReducer = createReducer<dataType>({
  [`${actions.medicalHistory.loading}`]: (state) => ({
    ...state,
    Loading: true,
  }),
  [`${actions.medicalHistory.success}`]: (state, { payload }) => ({
    ...state,
    medicalHistory: payload,
    Loading: false,
  }),
  [`${actions.medicalHistory.failure}`]: (state) => ({
    ...state,
    medicalHistory: null,
    Loading: false,
  }),

  [`${actions.reports.loading}`]: (state) => ({
    ...state,
    Loading: true,
  }),
  [`${actions.reports.success}`]: (state, { payload }) => ({
    ...state,
    reports: payload,
    Loading: false,
  }),
  [`${actions.reports.failure}`]: (state) => ({
    ...state,
    reports: { data: [], meta: {} },
    Loading: false,
  }),
});

export const { useContext: useMedicalHistory, Provider: MedicalHistoryProvider } = createContainer(
  () => {
    const [state, dispatch] = useReducer(medicalHistoryReducer, initialState);

    const getMedicalHistory = useCallback(async (id: string) => {
      try {
        dispatch(actions.medicalHistory.loading());

        const { data } = await axiosService?.get(`/patient/history/${id}`);

        dispatch(actions.medicalHistory.success(data?.data));
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.medicalHistory.failure());
      }
      return undefined;
    }, []);

    const getReports = async (
      id: string,
      PageNum: string | number,
      startDate?: string,
      endDate?: string,
    ) => {
      try {
        dispatch(actions.reports.loading());

        const { data } = await axiosService?.get(
          `/appointment/reports?patientId=${id}&page=${Number(PageNum)}&take=10&startDate=${
            startDate || ''
          }&endDate=${endDate || ''}`,
        );

        dispatch(actions.reports.success(data?.data?.reports));
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.reports.failure());
      }
      return undefined;
    };

    const AddMedicalHistory = async (formData: any) => {
      try {
        const { data } = await axiosService?.post(`/patient/add-history`, {
          ...formData,
        });

        toast.success(data.message);
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    const UpdateMedicalHistory = async (formData: any) => {
      try {
        const { data } = await axiosService?.post(`/patient/update-history`, {
          ...formData,
        });

        toast.success(data.message);
        return data;
      } catch (e) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    };

    return {
      state,
      actions: {
        getMedicalHistory,
        getReports,
        UpdateMedicalHistory,
        AddMedicalHistory,
      },
    };
  },
);

export default useMedicalHistory;
