export const stateHash = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};
export const stateAndCities = {
  Alabama: [
    'ABBEVILLE',
    'ADAMSVILLE',
    'ADDISON',
    'AKRON',
    'ALABASTER',
    'ALBERTVILLE',
    'ALEXANDER CITY',
    'ALEXANDRIA',
    'ALICEVILLE',
    'ALLGOOD',
    'ALTOONA',
    'ANDALUSIA',
    'ANDERSON',
    'ANNISTON',
    'ARAB',
    'ARDMORE',
    'ARGO',
    'ARITON',
    'ARLEY',
    'ASHFORD',
    'ASHLAND',
    'ASHVILLE',
    'ATHENS',
    'ATMORE',
    'ATTALLA',
    'AUBURN',
    'AUTAUGAVILLE',
    'AVON',
    'BABBIE',
    'BAILEYTON',
    'BANKS',
    'BAY MINETTE',
    'BAYOU LA BATRE',
    'BEAR CREEK',
    'BEATRICE',
    'BEAVERTON',
    'BELK',
    'BENTON',
    'BERRY',
    'BESSEMER',
    'BILLINGSLEY',
    'BIRMINGHAM',
    'BLACK',
    'BLOUNTSVILLE',
    'BLUE MOUNTAIN',
    'BLUE RIDGE ',
    'BLUE SPRINGS ',
    'BOAZ ',
    'BOLIGEE ',
    'BON AIR ',
    ' BRANCHVILLE ',
    'BRANTLEY ',
    ' BRENT ',
    ' BREWTON ',
    'BRIDGEPORT',
    'BRIGHTON',
    'BRILLIANT',
    'BROOKSIDE',
    'BROOKWOOD',
    'BRUNDIDGE',
    'BUTLER',
    'BYNUM',
    'CAHABA HEIGHTS',
    'CALERA',
    'CAMDEN',
    'CAMP HILL',
    'CARBON HILL',
    'CARDIFF',
    'CAROLINA',
    'CARROLLTON',
    'CASTLEBERRY',
    'CEDAR BLUFF',
    'CENTER POINT',
    'CENTRE',
    'CENTREVILLE',
    'CHALKVILLE',
    'CHATOM',
    'CHELSEA',
    'CHEROKEE',
    'CHICKASAW',
    'CHILDERSBURG',
    'CITRONELLE',
    'CLANTON',
    'CLAY',
    'CLAYHATCHEE',
    'CLAYTON',
    'CLEVELAND',
    'CLIO',
    'COALING',
    'COFFEE SPRINGS',
    'COFFEEVILLE',
    'COKER',
    'COLLINSVILLE',
    'COLONY',
    'COLUMBIA',
    'COLUMBIANA',
    'CONCORD',
    'COOSADA',
    'CORDOVA',
    'COTTONWOOD',
    'COUNTY LINE',
    'COURTLAND',
    'COWARTS',
    'CREOLA',
    'CROSSVILLE',
    'CUBA',
    'CULLMAN',
    'DADEVILLE',
    'DALEVILLE',
    'DAPHNE',
    'DAUPHIN ISLAND',
    'DAVISTON',
    'DAYTON',
    'DEATSVILLE',
    'DECATUR',
    'DEMOPOLIS',
    'DETROIT',
    'DODGE CITY',
    'DORA',
    'DOTHAN',
    'DOUBLE SPRINGS',
    'DOUGLAS',
    'DOZIER',
    'DUTTON',
    'EAST BREWTON',
    'ECLECTIC',
    'EDGEWATER',
    'EDWARDSVILLE',
    'ELBA',
    'ELBERTA',
    'ELDRIDGE',
    'ELKMONT',
    'ELMORE',
    'EMELLE',
    'ENTERPRISE',
    'EPES',
    'ETHELSVILLE',
    'EUFAULA',
    'EUNOLA',
    'EUTAW',
    'EVA',
    'EVERGREEN',
    'EXCEL',
    'FAIRFIELD',
    'FAIRHOPE',
    'FAIRVIEW',
    'FALKVILLE',
    'FAUNSDALE',
    'FAYETTE',
    'FIVE POINTS',
    'FLOMATON',
    'FLORALA',
    'FLORENCE',
    'FOLEY',
    'FORESTDALE',
    'FORKLAND',
    'FORT DEPOSIT',
    'FORT PAYNE',
    'FORT RUCKER',
    'FRANKLIN',
    'FRISCO CITY',
    'FRUITHURST',
    'FULTON',
    'FULTONDALE',
    'FYFFE',
    'GADSDEN',
    'GAINESVILLE',
    'GANTT',
    'GANTTS QUARRY',
    'GARDEN CITY',
    'GARDENDALE',
    'GAYLESVILLE',
    'GEIGER',
    'GENEVA',
    'GEORGIANA',
    'GERALDINE',
    'GILBERTOWN',
    'GLEN ALLEN',
    'GLENCOE',
    'GLENWOOD',
    'GOLDVILLE',
    'GOOD HOPE',
    'GOODWATER',
    'GORDO',
    'GORDON',
    'GORDONVILLE',
    'GOSHEN',
    'GRAND BAY',
    'GRANT',
    'GRAYSON VALLEY',
    'GRAYSVILLE',
    'GREENSBORO',
    'GREENVILLE',
    'GRIMES',
    'GROVE HILL',
    'GUIN',
    'GULF SHORES',
    'GUNTERSVILLE',
    'GURLEY',
    'GU-WIN',
    'HACKLEBURG',
    'HALEBURG',
    'HALEYVILLE',
    'HAMILTON',
    'HAMMONDVILLE',
    'HANCEVILLE',
    'HARPERSVILLE',
    'HARTFORD',
    'HARTSELLE',
    'HARVEST',
    'HAYDEN',
    'HAYNEVILLE',
    'HAZEL GREEN',
    'HEADLAND',
    'HEATH',
    'HEFLIN',
    'HELENA',
    'HENAGAR',
    'HIGHLAND LAKE',
    'HILLSBORO',
    'HOBSON CITY',
    'HODGES',
    'HOKES BLUFF',
    'HOLLY POND',
    'HOLLYWOOD',
    'HOLT',
    'HOMEWOOD',
    'HOOVER',
    'HORN HILL',
    'HUEYTOWN',
    'HUGULEY',
    'HUNTSVILLE',
    'HURTSBORO',
    'HYTOP',
    'IDER',
    'INDIAN SPRINGS VILLAGE',
    'IRONDALE',
    'JACKSON',
    'JACKSONS GAP',
    'JACKSONVILLE',
    'JASPER',
    'JEMISON',
    'KANSAS',
    'KENNEDY',
    'KILLEN',
    'KIMBERLY',
    'KINSEY',
    'KINSTON',
    'LADONIA',
    'LA FAYETTE',
    'LAKE PURDY',
    'LAKEVIEW',
    'LAKE VIEW',
    'LANETT',
    'LANGSTON',
    'LEEDS',
    'LEESBURG',
    'LEIGHTON',
    'LESTER',
    'LEVEL PLAINS',
    'LEXINGTON',
    'LIBERTYVILLE',
    'LINCOLN',
    'LINDEN',
    'LINEVILLE',
    'LIPSCOMB',
    'LISMAN',
    'LITTLEVILLE',
    'LIVINGSTON',
    'LOACHAPOKA',
    'LOCKHART',
    'LOCUST FORK',
    'LOUISVILLE',
    'LOWNDESBORO',
    'LOXLEY',
    'LUVERNE',
    'LYNN',
    'MCDONALD CHAPEL',
    'MACEDONIA',
    'MCINTOSH',
    'MCKENZIE',
    'MCMULLEN',
    'MADISON',
    'MADRID',
    'MALVERN',
    'MAPLESVILLE',
    'MARGARET',
    'MARION',
    'MAYTOWN',
    'MEADOWBROOK',
    'MEMPHIS',
    'MENTONE',
    'MERIDIANVILLE',
    'MIDFIELD',
    'MIDLAND CITY',
    'MIDWAY',
    'MIGNON',
    'MILLBROOK',
    'MILLPORT',
    'MILLRY',
    'MINOR',
    'MOBILE',
    'MONROEVILLE',
    'MONTEVALLO',
    'MONTGOMERY',
    'MOODY',
    'MOORES MILL',
    'MOORESVILLE',
    'MORRIS',
    'MOSSES',
    'MOULTON',
    'MOUNDVILLE',
    'MOUNTAINBORO',
    'MOUNTAIN BROOK',
    'MOUNT OLIVE',
    'MOUNT VERNON',
    'MULGA',
    'MUNFORD',
    'MUSCLE SHOALS',
    'MYRTLEWOOD',
    'NAPIER FIELD',
    'NATURAL BRIDGE',
    'NAUVOO',
    'NECTAR',
    'NEEDHAM',
    'NEWBERN',
    'NEW BROCKTON',
    'NEW HOPE',
    'NEW MARKET',
    'NEW SITE',
    'NEWTON',
    'NEWVILLE',
    'NORTH BIBB',
    'NORTH COURTLAND',
    'NORTH JOHNS',
    'NORTHPORT',
    'NOTASULGA',
    'OAK GROVE',
    'OAK HILL',
    'OAKMAN',
    'ODENVILLE',
    'OHATCHEE',
    'ONEONTA',
    'ONYCHA',
    'OPELIKA',
    'OPP',
    'ORANGE BEACH',
    'ORRVILLE',
    'OWENS CROSS ROADS',
    'OXFORD',
    'OZARK',
    'PAINT ROCK',
    'PARRISH',
    'PELHAM',
    'PELL CITY',
    'PENNINGTON',
    'PETREY',
    'PHENIX CITY',
    'PHIL CAMPBELL',
    'PICKENSVILLE',
    'PIEDMONT',
    'PIKE ROAD',
    'PINCKARD',
    'PINE APPLE',
    'PINE HILL',
    'PINE RIDGE',
    'PINSON',
    'PISGAH',
    'PLEASANT GROVE',
    'PLEASANT GROVES',
    'POINT CLEAR',
    'POLLARD',
    'POWELL',
    'PRATTVILLE',
    'PRICEVILLE',
    'PRICHARD',
    'PROVIDENCE',
    'RAGLAND',
    'RAINBOW CITY',
    'RAINSVILLE',
    'RANBURNE',
    'RED BAY',
    'RED LEVEL',
    'REDSTONE ARSENAL',
    'REECE CITY',
    'REFORM',
    'REHOBETH',
    'REPTON',
    'RIDGEVILLE',
    'RIVER FALLS',
    'RIVERSIDE',
    'RIVERVIEW',
    'ROANOKE',
    'ROBERTSDALE',
    'ROCK CREEK',
    'ROCKFORD',
    'ROCK MILLS',
    'ROGERSVILLE',
    'ROSA',
    'RUSSELLVILLE',
    'RUTLEDGE',
    'ST. FLORIAN',
    'SAKS',
    'SAMSON',
    'SAND ROCK',
    'SANFORD',
    'SARALAND',
    'SARDIS CITY',
    'SATSUMA',
    'SCOTTSBORO',
    'SECTION',
    'SELMA',
    'SELMONT-WEST SELMONT',
    'SHEFFIELD',
    'SHILOH',
    'SHORTER',
    'SILAS',
    'SILVERHILL',
    'SIPSEY',
    'SKYLINE',
    'SLOCOMB',
    'SMITHS',
    'SMOKE RISE',
    'SNEAD',
    'SOMERVILLE',
    'SOUTHSIDE',
    'SOUTH VINEMONT',
    'SPANISH FORT',
    'SPRINGVILLE',
    'STEELE',
    'STEVENSON',
    'SULLIGENT',
    'SUMITON',
    'SUMMERDALE',
    'SUSAN MOORE',
    'SWEET WATER',
    'SYLACAUGA',
    'SYLVANIA',
    'SYLVAN SPRINGS',
    'TALLADEGA',
    'TALLADEGA SPRINGS',
    'TALLASSEE',
    'TARRANT',
    'TAYLOR',
    'THEODORE',
    'THOMASTON',
    'THOMASVILLE',
    'THORSBY',
    'TILLMANS CORNER',
    'TOWN CREEK',
    'TOXEY',
    'TRAFFORD',
    'TRIANA',
    'TRINITY',
    'TROY',
    'TRUSSVILLE',
    'TUSCALOOSA',
    'TUSCUMBIA',
    'TUSKEGEE',
    'UNDERWOOD-PETERSVILLE',
    'UNION',
    'UNION GROVE',
    'UNION SPRINGS',
    'UNIONTOWN',
    'VALLEY',
    'VALLEY HEAD',
    'VANCE',
    'VERNON',
    'VESTAVIA HILLS',
    'VINA',
    'VINCENT',
    'VREDENBURGH',
    'WADLEY',
    'WALDO',
    'WALNUT GROVE',
    'WARRIOR',
    'WATERLOO',
    'WAVERLY',
    'WEAVER',
    'WEBB',
    'WEDOWEE',
    'WEST BLOCTON',
    'WEST END-COBB TOWN',
    'WEST JEFFERSON',
    'WEST POINT',
    'WETUMPKA',
    'WHITE HALL',
    'WILSONVILLE',
    'WILTON',
    'WINFIELD',
    'WOODLAND',
    'WOODVILLE',
    'YELLOW BLUFF',
    'YORK',
  ],
  Alaska: [
    'ADAK',
    'AKHIOK',
    'AKIACHAK',
    'AKIAK',
    'AKUTAN',
    'ALAKANUK',
    'ALATNA',
    'ALCAN BORDER',
    'ALEKNAGIK',
    'ALENEVA',
    'ALLAKAKET',
    'ALPINE',
    'AMBLER',
    'ANAKTUVUK PASS',
    'ANCHORAGE',
    'ANCHOR POINT',
    'ANDERSON',
    'ANGOON',
    'ANIAK',
    'ANVIK',
    'ARCTIC VILLAGE',
    'ATKA',
    'ATMAUTLUAK',
    'ATQASUK',
    'ATTU STATION',
    'BARROW',
    'BEAR CREEK',
    'BEAVER',
    'BELUGA',
    'BETHEL',
    'BETTLES',
    'BIG DELTA',
    'BIG LAKE',
    'BIRCH CREEK',
    'BREVIG MISSION',
    'BUCKLAND',
    'BUFFALO SOAPSTONE',
    'BUTTE',
    'CANTWELL',
    'CENTRAL',
    'CHALKYITSIK',
    'CHASE',
    'CHEFORNAK',
    'CHENEGA',
    'CHEVAK',
    'CHICKALOON',
    'CHICKEN',
    'CHIGNIK',
    'CHIGNIK LAGOON',
    'CHIGNIK LAKE',
    'CHINIAK',
    'CHISANA',
    'CHISTOCHINA',
    'CHITINA',
    'CHUATHBALUK',
    'CIRCLE',
    'CLAM GULCH',
    'CLARK S POINT',
    'COFFMAN COVE',
    'COHOE',
    'COLD BAY',
    'COLDFOOT',
    'COLLEGE',
    'COOPER LANDING',
    'COPPER CENTER',
    'COPPERVILLE',
    'CORDOVA',
    'COVENANT LIFE',
    'CRAIG',
    'CROOKED CREEK',
    'CROWN POINT',
    'CUBE COVE',
    'DEERING',
    'DELTA JUNCTION',
    'DELTANA',
    'DIAMOND RIDGE',
    'DILLINGHAM',
    'DIOMEDE',
    'DOT LAKE',
    'DOT LAKE VILLAGE',
    'DRY CREEK',
    'EAGLE',
    'EAGLE VILLAGE',
    'EDNA BAY',
    'EEK',
    'EGEGIK',
    'EIELSON AFB',
    'EKWOK',
    'ELFIN COVE',
    'ELIM',
    'EMMONAK',
    'ESTER',
    'EVANSVILLE',
    'EXCURSION INLET',
    'FAIRBANKS',
    'FALSE PASS',
    'FARM LOOP',
    'FERRY',
    'FISHHOOK',
    'FLAT',
    'FORT GREELY',
    'FORT YUKON',
    'FOUR MILE ROAD',
    'FOX',
    'FOX RIVER',
    'FRITZ CREEK',
    'FUNNY RIVER',
    'GAKONA',
    'GALENA',
    'GAMBELL',
    'GAME CREEK',
    'GATEWAY',
    'GLACIER VIEW',
    'GLENNALLEN',
    'GOLOVIN',
    'GOODNEWS BAY',
    'GRAYLING',
    'GULKANA',
    'GUSTAVUS',
    'HAINES',
    'HALIBUT COVE',
    'HAPPY VALLEY',
    'HARDING-BIRCH LAKES',
    'HEALY',
    'HEALY LAKE',
    'HOBART BAY',
    'HOLLIS',
    'HOLY CROSS',
    'HOMER',
    'HOONAH',
    'HOOPER BAY',
    'HOPE',
    'HOUSTON',
    'HUGHES',
    'HUSLIA',
    'HYDABURG',
    'HYDER',
    'IGIUGIG',
    'ILIAMNA',
    'IVANOF BAY',
    'JUNEAU CITY AND',
    'KACHEMAK',
    'KAKE',
    'KAKTOVIK',
    'KALIFORNSKY',
    'KALTAG',
    'KARLUK',
    'KASAAN',
    'KASIGLUK',
    'KASILOF',
    'KENAI',
    'KENNY LAKE',
    'KETCHIKAN',
    'KIANA',
    'KING COVE',
    'KING SALMON',
    'KIPNUK',
    'KIVALINA',
    'KLAWOCK',
    'KLUKWAN',
    'KNIK-FAIRVIEW',
    'KNIK RIVER',
    'KOBUK',
    'KODIAK',
    'KODIAK STATION',
    'KOKHANOK',
    'KOLIGANEK',
    'KONGIGANAK',
    'KOTLIK',
    'KOTZEBUE',
    'KOYUK',
    'KOYUKUK',
    'KUPREANOF',
    'KWETHLUK',
    'KWIGILLINGOK',
    'LAKE LOUISE',
    'LAKE MINCHUMINA',
    'LAKES',
    'LARSEN BAY',
    'LAZY MOUNTAIN',
    'LEVELOCK',
    'LIME VILLAGE',
    'LIVENGOOD',
    'LOWELL POINT',
    'LOWER KALSKAG',
    'LUTAK',
    'MCCARTHY',
    'MCGRATH',
    'MCKINLEY PARK',
    'MANLEY HOT SPRINGS',
    'MANOKOTAK',
    'MARSHALL',
    'MEADOW LAKES',
    'MEKORYUK',
    'MENDELTNA',
    'MENTASTA LAKE',
    'METLAKATLA',
    'MEYERS CHUCK',
    'MILLER LANDING',
    'MINTO',
    'MOOSE CREEK',
    'MOOSE PASS',
    'MOSQUITO LAKE',
    'MOUNTAIN VILLAGE',
    'MUD BAY',
    'NAKNEK',
    'NANWALEK',
    'NAPAKIAK',
    'NAPASKIAK',
    'NAUKATI BAY',
    'NELCHINA',
    'NELSON LAGOON',
    'NENANA',
    'NEW ALLAKAKET',
    'NEWHALEN',
    'NEW STUYAHOK',
    'NEWTOK',
    'NIGHTMUTE',
    'NIKISKI',
    'NIKOLAEVSK',
    'NIKOLAI',
    'NIKOLSKI',
    'NINILCHIK',
    'NOATAK',
    'NOME',
    'NONDALTON',
    'NOORVIK',
    'NORTH POLE',
    'NORTHWAY',
    'NORTHWAY JUNCTION',
    'NORTHWAY VILLAGE',
    'NUIQSUT',
    'NULATO',
    'NUNAPITCHUK',
    'OLD HARBOR',
    'OSCARVILLE',
    'OUZINKIE',
    'PALMER',
    'PAXSON',
    'PEDRO BAY',
    'PELICAN',
    'PERRYVILLE',
    'PETERSBURG',
    'PETERSVILLE',
    'PILOT POINT',
    'PILOT STATION',
    'PITKAS POINT',
    'PLATINUM',
    'PLEASANT VALLEY',
    'POINT BAKER',
    'POINT HOPE',
    'POINT LAY',
    'POINT MACKENZIE',
    'POPE-VANNOY LANDING',
    'PORTAGE CREEK',
    'PORT ALEXANDER',
    'PORT ALSWORTH',
    'PORT CLARENCE',
    'PORT GRAHAM',
    'PORT HEIDEN',
    'PORT LIONS',
    'PORT PROTECTION',
    'PRIMROSE',
    'PRUDHOE BAY',
    'QUINHAGAK',
    'RAMPART',
    'RED DEVIL',
    'RED DOG MINE',
    'RIDGEWAY',
    'RUBY',
    'RUSSIAN MISSION',
    'ST. GEORGE',
    'ST. MARYS',
    'ST. MICHAEL',
    'ST. PAUL',
    'SALAMATOF',
    'SALCHA',
    'SAND POINT',
    'SAVOONGA',
    'SAXMAN',
    'SCAMMON BAY',
    'SELAWIK',
    'SELDOVIA',
    'SELDOVIA VILLAGE',
    'SEWARD',
    'SHAGELUK',
    'SHAKTOOLIK',
    'SHELDON POINT (NUNAM IQUA)',
    'SHISHMAREF',
    'SHUNGNAK',
    'SILVER SPRINGS',
    'SITKA CITY AND',
    'SKAGWAY',
    'SKWENTNA',
    'SLANA',
    'SLEETMUTE',
    'SOLDOTNA',
    'SOUTH NAKNEK',
    'STEBBINS',
    'STERLING',
    'STEVENS VILLAGE',
    'STONY RIVER',
    'SUNRISE',
    'SUSITNA',
    'SUTTON-ALPINE',
    'TAKOTNA',
    'TALKEETNA',
    'TANACROSS',
    'TANAINA',
    'TANANA',
    'TATITLEK',
    'TAZLINA',
    'TELLER',
    'TENAKEE SPRINGS',
    'TETLIN',
    'THOMS PLACE',
    'THORNE BAY',
    'TOGIAK',
    'TOK',
    'TOKSOOK BAY',
    'TOLSONA',
    'TONSINA',
    'TRAPPER CREEK',
    'TULUKSAK',
    'TUNTUTULIAK',
    'TUNUNAK',
    'TWIN HILLS',
    'TWO RIVERS',
    'TYONEK',
    'UGASHIK',
    'UNALAKLEET',
    'UNALASKA',
    'UPPER KALSKAG',
    'VALDEZ',
    'VENETIE',
    'WAINWRIGHT',
    'WALES',
    'WASILLA',
    'WHALE PASS',
    'WHITE MOUNTAIN',
    'WHITESTONE LOGGING CAMP',
    'WHITTIER',
    'WILLOW',
    'WILLOW CREEK',
    'WISEMAN',
    'WOMENS BAY',
    'WRANGELL',
    'Y',
    'YAKUTAT',
  ],
  'American Samoa': ['American Samoa'],
  Arizona: [
    'AJO',
    'AK-CHIN VILLAGE',
    'AMADO',
    'APACHE JUNCTION',
    'ARI',
    'ARI',
    'ASH FORK',
    'AVONDALE',
    'AVRA VALLEY',
    'BAGDAD',
    'BENSON',
    'BIG PARK',
    'BISBEE',
    'BITTER SPRINGS',
    'BLACK CANYON CITY',
    'BLACKWATER',
    'BLUEWATER',
    'BOUSE',
    'BUCKEYE',
    'BULLHEAD CITY',
    'BURNSIDE',
    'CAMERON',
    'CAMP VERDE',
    'CANYON DAY',
    'CAREFREE',
    'CASA GRANDE',
    'CASAS ADOBES',
    'CATALINA',
    'CATALINA FOOTHILLS',
    'CAVE CREEK',
    'CENTRAL HEIGHTS-MIDLAND CITY',
    'CHANDLER',
    'CHILCHINBITO',
    'CHINLE',
    'CHINO VALLEY',
    'CHUICHU',
    'CIBECUE',
    'CIBOLA',
    'CLARKDALE',
    'CLAYPOOL',
    'CLIFTON',
    'COLORADO CITY',
    'CONGRESS',
    'COOLIDGE',
    'CORDES LAKES',
    'CORNVILLE',
    'CORONA DE TUCSON',
    'COTTONWOOD',
    'COTTONWOOD-VERDE VILLAGE',
    'DENNEHOTSO',
    'DESERT HILLS',
    'DEWEY-HUMBOLDT',
    'DILKON',
    'DOLAN SPRINGS',
    'DOUGLAS',
    'DREXEL-ALVERNON',
    'DREXEL HEIGHTS',
    'DUDLEYVILLE',
    'DUNCAN',
    'EAGAR',
    'EAST FORK',
    'EAST SAHUARITA',
    'EHRENBERG',
    'ELGIN',
    'EL MIRAGE',
    'ELOY',
    'FIRST MESA',
    'FLAGSTAFF',
    'FLORENCE',
    'FLOWING WELLS',
    'FORT DEFIANCE',
    'FORTUNA FOOTHILLS',
    'FOUNTAIN HILLS',
    'FREDONIA',
    'GADSDEN',
    'GANADO',
    'GILA BEND',
    'GILBERT',
    'GISELA',
    'GLENDALE',
    'GLOBE',
    'GOLD CAMP',
    'GOLDEN VALLEY',
    'GOODYEAR',
    'GRAND CANYON VILLAGE',
    'GREASEWOOD',
    'GREEN VALLEY',
    'GUADALUPE',
    'HAYDEN',
    'HEBER-OVERGAARD',
    'HOLBROOK',
    'HOTEVILLA-BACAVI',
    'HOUCK',
    'HUACHUCA CITY',
    'JEDDITO',
    'JEROME',
    'KACHINA VILLAGE',
    'KAIBAB',
    'KAIBITO',
    'KAYENTA',
    'KEAMS CANYON',
    'KEARNY',
    'KINGMAN',
    'KYKOTSMOVI VILLAGE',
    'LAKE HAVASU CITY',
    'LAKE MONTEZUMA',
    'LECHEE',
    'LEUPP',
    'LITCHFIELD PARK',
    'LITTLETOWN',
    'LUKACHUKAI',
    'MCNARY',
    'MAMMOTH',
    'MANY FARMS',
    'MARANA',
    'MARICOPA',
    'MAYER',
    'MESA',
    'MESQUITE CREEK',
    'MIAMI',
    'MOENKOPI',
    'MOHAVE VALLEY',
    'MOJAVE RANCH ESTATES',
    'MORENCI',
    'MOUNTAINAIRE',
    'MUNDS PARK',
    'NACO',
    'NAZLINI',
    'NEW KINGMAN-BUTLER',
    'NEW RIVER',
    'NOGALES',
    'OLJATO-MONUMENT VALLEY',
    'ORACLE',
    'ORO VALLEY',
    'PAGE',
    'PARADISE VALLEY',
    'PARKER',
    'PARKER STRIP',
    'PARKS',
    'PATAGONIA',
    'PAULDEN',
    'PAYSON',
    'PEACH SPRINGS',
    'PEEPLES VALLEY',
    'PEORIA',
    'PERIDOT',
    'PHOENIX',
    'PICTURE ROCKS',
    'PIMA',
    'PINE',
    'PINETOP-LAKESIDE',
    'PINON',
    'PIRTLEVILLE',
    'PISINEMO',
    'POSTON',
    'PRESCOTT',
    'PRESCOTT VALLEY',
    'QUARTZSITE',
    'QUEEN CREEK',
    'QUEEN VALLEY',
    'RED MESA',
    'RIO RICO NORTHEAST',
    'RIO RICO NORTHWEST',
    'RIO RICO SOUTHEAST',
    'RIO RICO SOUTHWEST',
    'RIO VERDE',
    'ROCK POINT',
    'ROUGH ROCK',
    'ROUND ROCK',
    'SACATON',
    'SAFFORD',
    'SAHUARITA',
    'ST. DAVID',
    'ST. JOHNS',
    'ST. MICHAELS',
    'SALOME',
    'SAN CARLOS',
    'SAN LUIS',
    'SAN MANUEL',
    'SANTAN',
    'SANTA ROSA',
    'SAWMILL',
    'SCOTTSDALE',
    'SECOND MESA',
    'SEDONA',
    'SELIGMAN',
    'SELLS',
    'SHONGOPOVI',
    'SHONTO',
    'SHOW LOW',
    'SIERRA VISTA',
    'SIERRA VISTA SOUTHEAST',
    'SNOWFLAKE',
    'SOMERTON',
    'SONOITA',
    'SOUTH TUCSON',
    'SPRINGERVILLE',
    'SPRING VALLEY',
    'STANFIELD',
    'STEAMBOAT',
    'STRAWBERRY',
    'SUMMIT',
    'SUN CITY',
    'SUN CITY WEST',
    'SUN LAKES',
    'SUN VALLEY',
    'SUPAI',
    'SUPERIOR',
    'SURPRISE',
    'SWIFT TRAIL JUNCTION',
    'TACNA',
    'TANQUE VERDE',
    'TAYLOR',
    'TEEC NOS POS',
    'TEMPE',
    'THATCHER',
    'THREE POINTS',
    'TOLLESON',
    'TOMBSTONE',
    'TONALEA',
    'TONTO BASIN',
    'TOP-OF-THE-WORLD',
    'TORTOLITA',
    'TSAILE',
    'TUBAC',
    'TUBA CITY',
    'TUCSON',
    'TUCSON ESTATES',
    'TUMACACORI-CARMEN',
    'TUSAYAN',
    'VAIL',
    'VALENCIA WEST',
    'WELLTON',
    'WENDEN',
    'WHETSTONE',
    'WHITERIVER',
    'WICKENBURG',
    'WILHOIT',
    'WILLCOX',
    'WILLIAMS',
    'WILLIAMSON',
    'WILLOW VALLEY',
    'WINDOW ROCK',
    'WINKELMAN',
    'WINSLOW',
    'WINSLOW WEST',
    'YARNELL',
    'YOUNG',
    'YOUNGTOWN',
    'YUMA',
  ],
  Arkansas: [
    'ADONA',
    'ALEXANDER',
    'ALICIA',
    'ALLPORT',
    'ALMA',
    'ALMYRA',
    'ALPENA',
    'ALTHEIMER',
    'ALTUS',
    'AMAGON',
    'AMITY',
    'ANTHONYVILLE',
    'ANTOINE',
    'ARKADELPHIA',
    'ARKANSAS CITY',
    'ASHDOWN',
    'ASH FLAT',
    'ATKINS',
    'AUBREY',
    'AUGUSTA',
    'AUSTIN',
    'AVOCA',
    'BALD KNOB',
    'BANKS',
    'BARLING',
    'BASSETT',
    'BATESVILLE',
    'BAUXITE',
    'BAY',
    'BEARDEN',
    'BEAVER',
    'BEEBE',
    'BEEDEVILLE',
    'BELLA VISTA',
    'BELLEFONTE',
    'BELLEVILLE',
    'BEN LOMOND',
    'BENTON',
    'BENTONVILLE',
    'BERGMAN',
    'BERRYVILLE',
    'BETHEL HEIGHTS',
    'BIGELOW',
    'BIG FLAT',
    'BIGGERS',
    'BIRDSONG',
    'BLACK OAK',
    'BLACK ROCK',
    'BLACK SPRINGS',
    'BLEVINS',
    'BLUE EYE',
    'BLUE MOUNTAIN',
    'BLUFF CITY',
    'BLYTHEVILLE',
    'BODCAW',
    'BONANZA',
    'BONO',
    'BOONEVILLE',
    'BRADFORD',
    'BRADLEY',
    'BRANCH',
    'BRIARCLIFF',
    'BRINKLEY',
    'BROOKLAND',
    'BRYANT',
    'BUCKNER',
    'BULL SHOALS',
    'BURDETTE',
    'CABOT',
    'CADDO VALLEY',
    'CALDWELL',
    'CALE',
    'CALICO ROCK',
    'CALION',
    'CAMDEN',
    'CAMMACK VILLAGE',
    'CAMPBELL STATION',
    'CARAWAY',
    'CARLISLE',
    'CARTHAGE',
    'CASA',
    'CASH',
    'CAULKSVILLE',
    'CAVE CITY',
    'CAVE SPRINGS',
    'CEDARVILLE',
    'CENTERTON',
    'CENTRAL CITY',
    'CHARLESTON',
    'CHEROKEE VILLAGE',
    'CHERRY VALLEY',
    'CHESTER',
    'CHIDESTER',
    'CLARENDON',
    'CLARKSVILLE',
    'CLINTON',
    'COAL HILL',
    'COLLEGE CITY',
    'COLLEGE STATION',
    'COLT',
    'CONCORD',
    'CONWAY',
    'CORINTH',
    'CORNING',
    'COTTER',
    'COTTON PLANT',
    'COVE',
    'COY',
    'CRAWFORDSVILLE',
    'CROSSETT',
    'CUSHMAN',
    'DAISY',
    'DAMASCUS',
    'DANVILLE',
    'DARDANELLE',
    'DATTO',
    'DECATUR',
    'DELAPLAINE',
    'DELIGHT',
    'DELL',
    'DENNING',
    'DE QUEEN',
    'DERMOTT',
    'DES ARC',
    'DE VALLS BLUFF',
    'DE WITT',
    'DIAMOND CITY',
    'DIAZ',
    'DIERKS',
    'DONALDSON',
    'DOVER',
    'DUMAS',
    'DYER',
    'DYESS',
    'EARLE',
    'EAST CAMDEN',
    'EAST END',
    'EDMONDSON',
    'EGYPT',
    'ELAINE',
    'EL DORADO',
    'ELKINS',
    'ELM SPRINGS',
    'EMERSON',
    'EMMET',
    'ENGLAND',
    'ENOLA',
    'ETOWAH',
    'EUDORA',
    'EUREKA SPRINGS',
    'EVENING SHADE',
    'EVERTON',
    'FAIRFIELD BAY',
    'FARGO',
    'FARMINGTON',
    'FAYETTEVILLE',
    'FELSENTHAL',
    'FIFTY-SIX',
    'FISHER',
    'FLIPPIN',
    'FORDYCE',
    'FOREMAN',
    'FORREST CITY',
    'FORT SMITH',
    'FOUKE',
    'FOUNTAIN HILL',
    'FOUNTAIN LAKE',
    'FOURCHE',
    'FRANKLIN',
    'FREDONIA (BISCOE)',
    'FRIENDSHIP',
    'FULTON',
    'GARFIELD',
    'GARLAND',
    'GARNER',
    'GASSVILLE',
    'GATEWAY',
    'GENTRY',
    'GEORGETOWN',
    'GIBSON',
    'GILBERT',
    'GILLETT',
    'GILLHAM',
    'GILMORE',
    'GLENWOOD',
    'GOSHEN',
    'GOSNELL',
    'GOULD',
    'GRADY',
    'GRANNIS',
    'GRAVEL RIDGE',
    'GRAVETTE',
    'GREENBRIER',
    'GREEN FOREST',
    'GREENLAND',
    'GREENWAY',
    'GREENWOOD',
    'GREERS FERRY',
    'GRIFFITHVILLE',
    'GRUBBS',
    'GUION',
    'GUM SPRINGS',
    'GURDON',
    'GUY',
    'HACKETT',
    'HAMBURG',
    'HAMPTON',
    'HARDY',
    'HARRELL',
    'HARRISBURG',
    'HARRISON',
    'HARTFORD',
    'HARTMAN',
    'HASKELL',
    'HATFIELD',
    'HAVANA',
    'HAYNES',
    'HAZEN',
    'HEBER SPRINGS',
    'HECTOR',
    'HELENA',
    'HENSLEY',
    'HERMITAGE',
    'HICKORY RIDGE',
    'HIGDEN',
    'HIGGINSON',
    'HIGHFILL',
    'HIGHLAND',
    'HINDSVILLE',
    'HOLLAND',
    'HOLLY GROVE',
    'HOPE',
    'HORATIO',
    'HORSESHOE BEND',
    'HORSESHOE LAKE',
    'HOT SPRINGS',
    'HOT SPRINGS VILLAGE',
    'HOUSTON',
    'HOXIE',
    'HUGHES',
    'HUMNOKE',
    'HUMPHREY',
    'HUNTER',
    'HUNTINGTON',
    'HUNTSVILLE',
    'HUTTIG',
    'IMBODEN',
    'JACKSONPORT',
    'JACKSONVILLE',
    'JASPER',
    'JENNETTE',
    'JERICHO',
    'JEROME',
    'JOHNSON',
    'JOINER',
    'JONESBORO',
    'JUDSONIA',
    'JUNCTION CITY',
    'KEISER',
    'KENSETT',
    'KEO',
    'KIBLER',
    'KINGSLAND',
    'KNOBEL',
    'KNOXVILLE',
    'LAFE',
    'LAGRANGE',
    'LAKE CITY',
    'LAKE HAMILTON',
    'LAKEVIEW',
    'LAKE VIEW',
    'LAKE VILLAGE',
    'LAMAR',
    'LAVACA',
    'LEACHVILLE',
    'LEAD HILL',
    'LEOLA',
    'LEPANTO',
    'LESLIE',
    'LETONA',
    'LEWISVILLE',
    'LEXA',
    'LINCOLN',
    'LITTLE FLOCK',
    'LITTLE ROCK',
    'LOCKESBURG',
    'LONDON',
    'LONOKE',
    'LONSDALE',
    'LOUANN',
    'LOWELL',
    'LUXORA',
    'LYNN',
    'MCALMONT',
    'MCCASKILL',
    'MCCRORY',
    'MCDOUGAL',
    'MCGEHEE',
    'MCNAB',
    'MCNEIL',
    'MCRAE',
    'MADISON',
    'MAGAZINE',
    'MAGNESS',
    'MAGNOLIA',
    'MALVERN',
    'MAMMOTH SPRING',
    'MANILA',
    'MANSFIELD',
    'MARIANNA',
    'MARIE',
    'MARION',
    'MARKED TREE',
    'MARMADUKE',
    'MARSHALL',
    'MARVELL',
    'MAUMELLE',
    'MAYFLOWER',
    'MAYNARD',
    'MELBOURNE',
    'MENA',
    'MENIFEE',
    'MIDLAND',
    'MINERAL SPRINGS',
    'MINTURN',
    'MITCHELLVILLE',
    'MONETTE',
    'MONTICELLO',
    'MONTROSE',
    'MOOREFIELD',
    'MORO',
    'MORRILTON',
    'MORRISON BLUFF',
    'MOUNTAINBURG',
    'MOUNTAIN HOME',
    'MOUNTAIN PINE',
    'MOUNTAIN VIEW',
    'MOUNT IDA',
    'MOUNT PLEASANT',
    'MOUNT VERNON',
    'MULBERRY',
    'MURFREESBORO',
    'NASHVILLE',
    'NEWARK',
    'NEWPORT',
    'NIMMONS',
    'NORFORK',
    'NORMAN',
    'NORPHLET',
    'NORTH CROSSETT',
    'NORTH LITTLE ROCK',
    'OAK GROVE',
    'OAK GROVE HEIGHTS',
    'OAKHAVEN',
    'ODEN',
    'OGDEN',
    'OIL TROUGH',
    'O KEAN',
    'OKOLONA',
    'OLA',
    'OMAHA',
    'OPPELO',
    'OSCEOLA',
    'OXFORD',
    'OZAN',
    'OZARK',
    'PALESTINE',
    'PANGBURN',
    'PARAGOULD',
    'PARIS',
    'PARKDALE',
    'PARKERS-IRON SPRINGS',
    'PARKIN',
    'PATMOS',
    'PATTERSON',
    'PEACH ORCHARD',
    'PEA RIDGE',
    'PERLA',
    'PERRY',
    'PERRYTOWN',
    'PERRYVILLE',
    'PIGGOTT',
    'PINDALL',
    'PINE BLUFF',
    'PINEVILLE',
    'PINEY',
    'PLAINVIEW',
    'PLEASANT PLAINS',
    'PLUMERVILLE',
    'POCAHONTAS',
    'POLLARD',
    'PORTIA',
    'PORTLAND',
    'POTTSVILLE',
    'POWHATAN',
    'POYEN',
    'PRAIRIE CREEK',
    'PRAIRIE GROVE',
    'PRATTSVILLE',
    'PRESCOTT',
    'PYATT',
    'QUITMAN',
    'RATCLIFF',
    'RAVENDEN',
    'RAVENDEN SPRINGS',
    'READER',
    'RECTOR',
    'REDFIELD',
    'REED',
    'REYNO',
    'RISON',
    'ROCKPORT',
    'ROCKWELL',
    'ROE',
    'ROGERS',
    'RONDO',
    'ROSE BUD',
    'ROSSTON',
    'RUDY',
    'RUSSELL',
    'RUSSELLVILLE',
    'ST. CHARLES',
    'ST. FRANCIS',
    'ST. JOE',
    'ST. PAUL',
    'SALEM',
    'SALEM',
    'SALESVILLE',
    'SCOTT',
    'SCRANTON',
    'SEARCY',
    'SEDGWICK',
    'SHANNON HILLS',
    'SHERIDAN',
    'SHERRILL',
    'SHERWOOD',
    'SHIRLEY',
    'SIDNEY',
    'SILOAM SPRINGS',
    'SMACKOVER',
    'SMITHVILLE',
    'SOUTH LEAD HILL',
    'SPARKMAN',
    'SPRINGDALE',
    'SPRINGTOWN',
    'STAMPS',
    'STAR CITY',
    'STEPHENS',
    'STRAWBERRY',
    'STRONG',
    'STUTTGART',
    'SUBIACO',
    'SUCCESS',
    'SULPHUR ROCK',
    'SULPHUR SPRINGS',
    'SUMMIT',
    'SUNSET',
    'SWEET HOME',
    'SWIFTON',
    'TAYLOR',
    'TEXARKANA',
    'THORNTON',
    'TILLAR',
    'TINSMAN',
    'TOLLETTE',
    'TONTITOWN',
    'TRASKWOOD',
    'TRUMANN',
    'TUCKERMAN',
    'TULL',
    'TUPELO',
    'TURRELL',
    'TWIN GROVES',
    'TYRONZA',
    'ULM',
    'VALLEY SPRINGS',
    'VAN BUREN',
    'VANDERVOORT',
    'VICTORIA',
    'VILONIA',
    'VIOLA',
    'WABBASEKA',
    'WALDENBURG',
    'WALDO',
    'WALDRON',
    'WALNUT RIDGE',
    'WARD',
    'WARREN',
    'WASHINGTON',
    'WATSON',
    'WEINER',
    'WELDON',
    'WEST CROSSETT',
    'WESTERN GROVE',
    'WEST FORK',
    'WEST HELENA',
    'WEST MEMPHIS',
    'WEST POINT',
    'WHEATLEY',
    'WHELEN SPRINGS',
    'WHITE HALL',
    'WICKES',
    'WIDENER',
    'WIEDERKEHR VILLAGE',
    'WILLIFORD',
    'WILLISVILLE',
    'WILMAR',
    'WILMOT',
    'WILSON',
    'WILTON',
    'WINCHESTER',
    'WINSLOW',
    'WINTHROP',
    'WOODSON',
    'WOOSTER',
    'WRIGHTSVILLE',
    'WYNNE',
    'YELLVILLE',
    'ZINC',
  ],
  California: [
    'ACTON',
    'ADELANTO',
    'AGOURA HILLS',
    'ALAMEDA',
    'ALAMO',
    'ALBANY',
    'ALHAMBRA',
    'ALISO VIEJO',
    'ALMANOR',
    'ALONDRA PARK',
    'ALPAUGH',
    'ALPINE',
    'ALPINE VILLAGE',
    'ALTADENA',
    'ALTA SIERRA',
    'ALTURAS',
    'ALUM ROCK',
    'AMADOR CITY',
    'AMERICAN CANYON',
    'AMESTI',
    'ANAHEIM',
    'ANDERSON',
    'ANGELS CITY',
    'ANGWIN',
    'ANTIOCH',
    'APPLE VALLEY',
    'APTOS',
    'APTOS HILLS-LARKIN VALLEY',
    'ARBUCKLE',
    'ARCADIA',
    'ARCATA',
    'ARDEN-ARCADE',
    'ARMONA',
    'ARNOLD',
    'AROMAS',
    'ARROYO GRANDE',
    'ARTESIA',
    'ARVIN',
    'ASHLAND',
    'ATASCADERO',
    'ATHERTON',
    'ATWATER',
    'AUBERRY',
    'AUBURN',
    'AUGUST',
    'AVALON',
    'AVENAL',
    'AVERY',
    'AVOCADO HEIGHTS',
    'AZUSA',
    'BAKERSFIELD',
    'BALDWIN PARK',
    'BANNING',
    'BARSTOW',
    'BAY POINT',
    'BAYVIEW',
    'BAYVIEW-MONTALVIN',
    'BAYWOOD-LOS OSOS',
    'BEALE AFB',
    'BEAR VALLEY',
    'BEAR VALLEY SPRINGS',
    'BEAUMONT',
    'BECKWOURTH',
    'BELDEN',
    'BELL',
    'BELLFLOWER',
    'BELL GARDENS',
    'BELMONT',
    'BELVEDERE',
    'BENICIA',
    'BEN LOMOND',
    'BERKELEY',
    'BERMUDA DUNES',
    'BERTSCH-OCEANVIEW',
    'BETHEL ISLAND',
    'BEVERLY HILLS',
    'BIG BEAR CITY',
    'BIG BEAR LAKE',
    'BIG BEND',
    'BIGGS',
    'BIG PINE',
    'BIG RIVER',
    'BIOLA',
    'BISHOP',
    'BLACKHAWK-CAMINO TASSAJARA',
    'BLACK POINT-GREEN POINT',
    'BLAIRSDEN',
    'BLOOMINGTON',
    'BLUE LAKE',
    'BLUEWATER',
    'BLYTHE',
    'BODEGA BAY',
    'BODFISH',
    'BOLINAS',
    'BOMBAY BEACH',
    'BONADELLE RANCHOS-MADERA RANCHOS',
    'BONITA',
    'BONSALL',
    'BOOTJACK',
    'BORON',
    'BORONDA',
    'BORREGO SPRINGS',
    'BOSTONIA',
    'BOULDER CREEK',
    'BOWLES',
    'BOYES HOT SPRINGS',
    'BRADBURY',
    'BRADLEY',
    'BRAWLEY',
    'BREA',
    'BRENTWOOD',
    'BRET HARTE',
    'BRISBANE',
    'BROADMOOR',
    'BUCKS LAKE',
    'BUELLTON',
    'BUENA PARK',
    'BUENA VISTA',
    'BURBANK',
    'BURLINGAME',
    'BURNEY',
    'BUTTONWILLOW',
    'BYRON',
    'BYSTROM',
    'CABAZON',
    'CALABASAS',
    'CALEXICO',
    'CALIFORNIA CITY',
    'CALIMESA',
    'CALIPATRIA',
    'CALISTOGA',
    'CALWA',
    'CAMARILLO',
    'CAMBRIA',
    'CAMBRIAN PARK',
    'CAMERON PARK',
    'CAMPBELL',
    'CAMP PENDLETON NORTH',
    'CAMP PENDLETON SOUTH',
    'CANTUA CREEK',
    'CANYONDAM',
    'CANYON LAKE',
    'CAPITOLA',
    'CARIBOU',
    'CARLSBAD',
    'CARMEL-BY-THE-SEA',
    'CARMEL VALLEY VILLAGE',
    'CARMICHAEL',
    'CARPINTERIA',
    'CARRICK',
    'CARSON',
    'CARTAGO',
    'CARUTHERS',
    'CASA CONEJO',
    'CASA DE ORO-MOUNT HELIX',
    'CASTRO VALLEY',
    'CASTROVILLE',
    'CATHEDRAL CITY',
    'CAYUCOS',
    'CERES',
    'CERRITOS',
    'CHALLENGE-BROWNSVILLE',
    'CHANNEL ISLANDS BEACH',
    'CHARTER OAK',
    'CHERRYLAND',
    'CHERRY VALLEY',
    'CHESTER',
    'CHICO',
    'CHILCOOT-VINTON',
    'CHINA LAKE ACRES',
    'CHINESE CAMP',
    'CHINO',
    'CHINO HILLS',
    'CHOWCHILLA',
    'CHUALAR',
    'CHULA VISTA',
    'CITRUS',
    'CITRUS HEIGHTS',
    'CLAREMONT',
    'CLAYTON',
    'CLEARLAKE',
    'CLEARLAKE OAKS',
    'CLIO',
    'CLOVERDALE',
    'CLOVIS',
    'CLYDE',
    'COACHELLA',
    'COALINGA',
    'COBB',
    'COLFAX',
    'COLMA',
    'COLTON',
    'COLUMBIA',
    'COLUSA',
    'COMMERCE',
    'COMPTON',
    'CONCORD',
    'CONCOW',
    'COPPEROPOLIS',
    'CORCORAN',
    'CORNING',
    'CORONA',
    'CORONADO',
    'CORRALITOS',
    'CORTE MADERA',
    'COSTA MESA',
    'COTATI',
    'COTO DE CAZA',
    'COTTONWOOD',
    'COUNTRY CLUB',
    'COVELO',
    'COVINA',
    'CRESCENT CITY',
    'CRESCENT CITY NORTH',
    'CRESCENT MILLS',
    'CREST',
    'CRESTLINE',
    'C-ROAD',
    'CROCKETT',
    'CROMBERG',
    'CUDAHY',
    'CULVER CITY',
    'CUPERTINO',
    'CUTLER',
    'CUTTEN',
    'CYPRESS',
    'DALY CITY',
    'DANA POINT',
    'DANVILLE',
    'DARWIN',
    'DAVIS',
    'DAY VALLEY',
    'DEER PARK',
    'DEL AIRE',
    'DELANO',
    'DELHI',
    'DELLEKER',
    'DEL MAR',
    'DEL MONTE FOREST',
    'DEL REY',
    'DEL REY OAKS',
    'DEL RIO',
    'DENAIR',
    'DERBY ACRES',
    'DESERT HOT SPRINGS',
    'DESERT SHORES',
    'DESERT VIEW HIGHLANDS',
    'DIABLO',
    'DIAMOND BAR',
    'DIAMOND SPRINGS',
    'DILLON BEACH',
    'DINUBA',
    'DISCOVERY BAY',
    'DIXON',
    'DIXON LANE-MEADOW CREEK',
    'DOLLAR POINT',
    'DORRINGTON',
    'DORRIS',
    'DOS PALOS',
    'DOWNEY',
    'DUARTE',
    'DUBLIN',
    'DUCOR',
    'DUNSMUIR',
    'DURHAM',
    'DUSTIN ACRES',
    'EARLIMART',
    'EAST BLYTHE',
    'EAST COMPTON',
    'EAST FOOTHILLS',
    'EAST HEMET',
    'EAST LA MIRADA',
    'EAST LOS ANGELES',
    'EAST OAKDALE',
    'EASTON',
    'EAST OROSI',
    'EAST PALO ALTO',
    'EAST PASADENA',
    'EAST PORTERVILLE',
    'EAST QUINCY',
    'EAST RICHMOND HEIGHTS',
    'EAST SAN GABRIEL',
    'EAST SHORE',
    'EAST SONORA',
    'EDGEWOOD',
    'EDWARDS AFB',
    'EL CAJON',
    'EL CENTRO',
    'EL CERRITO',
    'EL DORADO HILLS',
    'ELDRIDGE',
    'EL GRANADA',
    'ELK GROVE',
    'ELKHORN',
    'ELMIRA',
    'EL MONTE',
    'EL PASO DE ROBLES (PASO ROBLES)',
    'EL RIO',
    'EL SEGUNDO',
    'EL SOBRANTE',
    'EL VERANO',
    'EMERALD LAKE HILLS',
    'EMERYVILLE',
    'EMPIRE',
    'ENCINITAS',
    'ESCALON',
    'ESCONDIDO',
    'ESPARTO',
    'ETNA',
    'EUREKA',
    'EXETER',
    'FAIRBANKS RANCH',
    'FAIRFAX',
    'FAIRFIELD',
    'FAIR OAKS',
    'FAIRVIEW',
    'FALLBROOK',
    'FALL RIVER MILLS',
    'FARMERSVILLE',
    'FARMINGTON',
    'FELLOWS',
    'FELTON',
    'FERNDALE',
    'FETTERS HOT SPRINGS-AGUA CALIENTE',
    'FILLMORE',
    'FIREBAUGH',
    'FLORENCE-GRAHAM',
    'FLORIN',
    'FOLSOM',
    'FONTANA',
    'FOOTHILL FARMS',
    'FOOTHILL RANCH',
    'FORD CITY',
    'FORESTHILL',
    'FOREST MEADOWS',
    'FORESTVILLE',
    'FORT BRAGG',
    'FORT JONES',
    'FORTUNA',
    'FOSTER CITY',
    'FOUNTAIN VALLEY',
    'FOWLER',
    'FRAZIER PARK',
    'FREEDOM',
    'FREMONT',
    'FRENCH CAMP',
    'FRENCH GULCH',
    'FRESNO',
    'FRIANT',
    'FRUITDALE',
    'FULLERTON',
    'FURNACE CREEK',
    'GALT',
    'GARDENA',
    'GARDEN ACRES',
    'GARDEN GROVE',
    'GAZELLE',
    'GEORGETOWN',
    'GERBER-LAS FLORES',
    'GILROY',
    'GLEN AVON',
    'GLENDALE',
    'GLENDORA',
    'GLEN ELLEN',
    'GOLDEN HILLS',
    'GOLD RIVER',
    'GOLETA',
    'GONZALES',
    'GOSHEN',
    'GRAEAGLE',
    'GRAND TERRACE',
    'GRANITE BAY',
    'GRANITE HILLS',
    'GRASS VALLEY',
    'GRATON',
    'GRAYSON',
    'GREENFIELD',
    'GREENHORN',
    'GREEN VALLEY',
    'GREENVIEW',
    'GREENVILLE',
    'GRENADA',
    'GRIDLEY',
    'GROVELAND-BIG OAK FLAT',
    'GROVER BEACH',
    'GUADALUPE',
    'GUERNEVILLE',
    'GUSTINE',
    'HACIENDA HEIGHTS',
    'HALF MOON BAY',
    'HAMILTON BRANCH',
    'HAMILTON CITY',
    'HANFORD',
    'HARBISON CANYON',
    'HAWAIIAN GARDENS',
    'HAWTHORNE',
    'HAYFORK',
    'HAYWARD',
    'HEALDSBURG',
    'HEBER',
    'HEMET',
    'HERCULES',
    'HERMOSA BEACH',
    'HESPERIA',
    'HICKMAN',
    'HIDDEN HILLS',
    'HIDDEN MEADOWS',
    'HIDDEN VALLEY LAKE',
    'HIGHGROVE',
    'HIGHLAND',
    'HIGHLANDS-BAYWOOD PARK',
    'HILLSBOROUGH',
    'HILMAR-IRWIN',
    'HOLLISTER',
    'HOLTVILLE',
    'HOME GARDEN',
    'HOME GARDENS',
    'HOMELAND',
    'HOMEWOOD CANYON-VALLEY WELLS',
    'HORNBROOK',
    'HUGHSON',
    'HUMBOLDT HILL',
    'HUNTINGTON BEACH',
    'HUNTINGTON PARK',
    'HURON',
    'HYDESVILLE',
    'IDYLLWILD-PINE COVE',
    'IMPERIAL',
    'IMPERIAL BEACH',
    'INDEPENDENCE',
    'INDIAN FALLS',
    'INDIAN WELLS',
    'INDIO',
    'INDUSTRY',
    'INGLEWOOD',
    'INTERLAKEN',
    'INVERNESS',
    'INYOKERN',
    'IONE',
    'IRON HORSE',
    'IRVINE',
    'IRWINDALE',
    'ISLA VISTA',
    'ISLETON',
    'IVANHOE',
    'JACKSON',
    'JAMESTOWN',
    'JAMUL',
    'JOHANNESBURG',
    'JOHNSVILLE',
    'JOSHUA TREE',
    'JULIAN',
    'KEDDIE',
    'KEELER',
    'KEENE',
    'KELSEYVILLE',
    'KENNEDY',
    'KENSINGTON',
    'KENTFIELD',
    'KERMAN',
    'KERNVILLE',
    'KETTLEMAN CITY',
    'KEYES',
    'KING CITY',
    'KINGS BEACH',
    'KINGSBURG',
    'KIRKWOOD',
    'KLAMATH',
    'KNIGHTSEN',
    'LA CANADA FLINTRIDGE',
    'LA CRESCENTA-MONTROSE',
    'LADERA HEIGHTS',
    'LAFAYETTE',
    'LAGUNA',
    'LAGUNA BEACH',
    'LAGUNA HILLS',
    'LAGUNA NIGUEL',
    'LAGUNA WEST-LAKESIDE',
    'LAGUNA WOODS',
    'LAGUNITAS-FOREST KNOLLS',
    'LA HABRA',
    'LA HABRA HEIGHTS',
    'LAKE ALMANOR COUNTRY CLUB',
    'LAKE ALMANOR PENINSULA',
    'LAKE ALMANOR WEST',
    'LAKE ARROWHEAD',
    'LAKE DAVIS',
    'LAKE ELSINORE',
    'LAKE FOREST',
    'LAKEHEAD-LAKESHORE',
    'LAKE ISABELLA',
    'LAKELAND VILLAGE',
    'LAKE LOS ANGELES',
    'LAKE NACIMIENTO',
    'LAKE OF THE PINES',
    'LAKE OF THE WOODS',
    'LAKEPORT',
    'LAKE SAN MARCOS',
    'LAKESIDE',
    'LAKEVIEW',
    'LAKE WILDWOOD',
    'LAKEWOOD',
    'LA MESA',
    'LA MIRADA',
    'LAMONT',
    'LANARE',
    'LANCASTER',
    'LA PALMA',
    'LA PORTE',
    'LA PRESA',
    'LA PUENTE',
    'LA QUINTA',
    'LA RIVIERA',
    'LARKFIELD-WIKIUP',
    'LARKSPUR',
    'LAS FLORES',
    'LAS LOMAS',
    'LATHROP',
    'LATON',
    'LA VERNE',
    'LAWNDALE',
    'LAYTONVILLE',
    'LEBEC',
    'LE GRAND',
    'LEMON COVE',
    'LEMON GROVE',
    'LEMOORE',
    'LEMOORE STATION',
    'LENNOX',
    'LENWOOD',
    'LEWISTON',
    'LEXINGTON HILLS',
    'LINCOLN',
    'LINCOLN VILLAGE',
    'LINDA',
    'LINDEN',
    'LINDSAY',
    'LITTLE GRASS VALLEY',
    'LITTLEROCK',
    'LIVE OAK',
    'LIVERMORE',
    'LIVINGSTON',
    'LOCKEFORD',
    'LODI',
    'LOMA LINDA',
    'LOMA RICA',
    'LOMITA',
    'LOMPOC',
    'LONDON',
    'LONE PINE',
    'LONG BEACH',
    'LOOMIS',
    'LOS ALAMITOS',
    'LOS ALAMOS',
    'LOS ALTOS',
    'LOS ALTOS HILLS',
    'LOS ANGELES',
    'LOS BANOS',
    'LOS GATOS',
    'LOS MOLINOS',
    'LOST HILLS',
    'LOWER LAKE',
    'LOYALTON',
    'LOYOLA',
    'LUCAS VALLEY-MARINWOOD',
    'LUCERNE',
    'LYNWOOD',
    'MCARTHUR',
    'MCCLOUD',
    'MACDOEL',
    'MCFARLAND',
    'MCKINLEYVILLE',
    'MCKITTRICK',
    'MADERA',
    'MADERA ACRES',
    'MAGALIA',
    'MALIBU',
    'MAMMOTH LAKES',
    'MANHATTAN BEACH',
    'MANTECA',
    'MANTON',
    'MARCH AFB',
    'MARICOPA',
    'MARINA',
    'MARINA DEL REY',
    'MARIPOSA',
    'MARKLEEVILLE',
    'MARTINEZ',
    'MARYSVILLE',
    'MAYFLOWER VILLAGE',
    'MAYWOOD',
    'MEADOW VALLEY',
    'MEADOW VISTA',
    'MECCA',
    'MEINERS OAKS',
    'MENDOCINO',
    'MENDOTA',
    'MENLO PARK',
    'MENTONE',
    'MERCED',
    'MESA',
    'MESA VISTA',
    'METTLER',
    'MIDDLETOWN',
    'MILLBRAE',
    'MILL VALLEY',
    'MILLVILLE',
    'MILPITAS',
    'MINERAL',
    'MIRA LOMA',
    'MIRA MONTE',
    'MISSION CANYON',
    'MISSION HILLS',
    'MISSION VIEJO',
    'MI-WUK VILLAGE',
    'MODESTO',
    'MOHAWK VISTA',
    'MOJAVE',
    'MOKELUMNE HILL',
    'MONO VISTA',
    'MONROVIA',
    'MONTAGUE',
    'MONTARA',
    'MONTCLAIR',
    'MONTEBELLO',
    'MONTECITO',
    'MONTEREY',
    'MONTEREY PARK',
    'MONTE RIO',
    'MONTE SERENO',
    'MONTGOMERY CREEK',
    'MOORPARK',
    'MORADA',
    'MORAGA',
    'MORENO VALLEY',
    'MORGAN HILL',
    'MORONGO VALLEY',
    'MORRO BAY',
    'MOSS BEACH',
    'MOSS LANDING',
    'MOUNTAIN MESA',
    'MOUNTAIN RANCH',
    'MOUNTAIN VIEW',
    'MOUNTAIN VIEW ACRES',
    'MOUNT HEBRON',
    'MOUNT SHASTA',
    'MUIR BEACH',
    'MURPHYS',
    'MURRIETA',
    'MURRIETA HOT SPRINGS',
    'MUSCOY',
    'MYRTLETOWN',
    'NAPA',
    'NATIONAL CITY',
    'NEBO CENTER',
    'NEEDLES',
    'NEVADA CITY',
    'NEWARK',
    'NEWMAN',
    'NEWPORT BEACH',
    'NEWPORT COAST',
    'NICE',
    'NILAND',
    'NIPOMO',
    'NORCO',
    'NORTH AUBURN',
    'NORTH EDWARDS',
    'NORTH EL MONTE',
    'NORTH FAIR OAKS',
    'NORTH HIGHLANDS',
    'NORTH LAKEPORT',
    'NORTH WOODBRIDGE',
    'NORWALK',
    'NOVATO',
    'NUEVO',
    'OAKDALE',
    'OAKHURST',
    'OAKLAND',
    'OAKLEY',
    'OAK PARK',
    'OAK VIEW',
    'OCCIDENTAL',
    'OCEANO',
    'OCEANSIDE',
    'OCOTILLO',
    'OILDALE',
    'OJAI',
    'OLANCHA',
    'OLIVEHURST',
    'ONTARIO',
    'ONYX',
    'OPAL CLIFFS',
    'ORANGE',
    'ORANGE COVE',
    'ORANGEVALE',
    'ORCUTT',
    'ORINDA',
    'ORLAND',
    'OROSI',
    'OROVILLE',
    'OROVILLE EAST',
    'OXNARD',
    'PACHECO',
    'PACIFICA',
    'PACIFIC GROVE',
    'PAJARO',
    'PALERMO',
    'PALMDALE',
    'PALM DESERT',
    'PALM SPRINGS',
    'PALO ALTO',
    'PALO CEDRO',
    'PALOS VERDES ESTATES',
    'PALO VERDE',
    'PARADISE',
    'PARAMOUNT',
    'PARKSDALE',
    'PARKWAY-SOUTH SACRAMENTO',
    'PARKWOOD',
    'PARLIER',
    'PASADENA',
    'PATTERSON',
    'PAXTON',
    'PEARSONVILLE',
    'PEDLEY',
    'PENN VALLEY',
    'PERRIS',
    'PETALUMA',
    'PHOENIX LAKE-CEDAR RIDGE',
    'PICO RIVERA',
    'PIEDMONT',
    'PINE HILLS',
    'PINE MOUNTAIN CLUB',
    'PINE VALLEY',
    'PINOLE',
    'PIRU',
    'PISMO BEACH',
    'PITTSBURG',
    'PIXLEY',
    'PLACENTIA',
    'PLACERVILLE',
    'PLANADA',
    'PLEASANT HILL',
    'PLEASANTON',
    'PLUMAS EUREKA',
    'PLYMOUTH',
    'POINT ARENA',
    'POINT REYES STATION',
    'POLLOCK PINES',
    'POMONA',
    'POPLAR-COTTON CENTER',
    'PORT COSTA',
    'PORTERVILLE',
    'PORT HUENEME',
    'PORTOLA',
    'PORTOLA HILLS',
    'PORTOLA VALLEY',
    'POWAY',
    'PRATTVILLE',
    'PRUNEDALE',
    'QUAIL VALLEY',
    'QUARTZ HILL',
    'QUINCY',
    'RAIL ROAD FLAT',
    'RAINBOW',
    'RAISIN CITY',
    'RAMONA',
    'RANCHO CALAVERAS',
    'RANCHO CORDOVA',
    'RANCHO CUCAMONGA',
    'RANCHO MIRAGE',
    'RANCHO MURIETA',
    'RANCHO PALOS VERDES',
    'RANCHO SAN DIEGO',
    'RANCHO SANTA FE',
    'RANCHO SANTA MARGARITA',
    'RANCHO TEHAMA RESERVE',
    'RANDSBURG',
    'RED BLUFF',
    'REDDING',
    'REDLANDS',
    'REDONDO BEACH',
    'REDWAY',
    'REDWOOD CITY',
    'REEDLEY',
    'RIALTO',
    'RICHGROVE',
    'RICHMOND',
    'RIDGECREST',
    'RIDGEMARK',
    'RIO DELL',
    'RIO DEL MAR',
    'RIO LINDA',
    'RIO VISTA',
    'RIPON',
    'RIVERBANK',
    'RIVERDALE',
    'RIVERDALE PARK',
    'RIVERSIDE',
    'ROCKLIN',
    'RODEO',
    'ROHNERT PARK',
    'ROLLING HILLS',
    'ROLLING HILLS ESTATES',
    'ROLLINGWOOD',
    'ROMOLAND',
    'ROSAMOND',
    'ROSEDALE',
    'ROSELAND',
    'ROSEMEAD',
    'ROSEMONT',
    'ROSEVILLE',
    'ROSS',
    'ROSSMOOR',
    'ROUND MOUNTAIN',
    'ROUND VALLEY',
    'ROWLAND HEIGHTS',
    'RUBIDOUX',
    'RUNNING SPRINGS',
    'SACRAMENTO',
    'ST. HELENA',
    'SALIDA',
    'SALINAS',
    'SALTON CITY',
    'SALTON SEA BEACH',
    'SAN ANDREAS',
    'SAN ANSELMO',
    'SAN ANTONIO HEIGHTS',
    'SAN ARDO',
    'SAN BERNARDINO',
    'SAN BRUNO',
    'SAN BUENAVENTURA (VENTURA)',
    'SAN CARLOS',
    'SAN CLEMENTE',
    'SAND CITY',
    'SAN DIEGO',
    'SAN DIEGO COUNTRY ESTATES',
    'SAN DIMAS',
    'SAN FERNANDO',
    'SAN FRANCISCO',
    'SAN GABRIEL',
    'SANGER',
    'SAN GERONIMO',
    'SAN JACINTO',
    'SAN JOAQUIN',
    'SAN JOAQUIN HILLS',
    'SAN JOSE',
    'SAN JUAN BAUTISTA',
    'SAN JUAN CAPISTRANO',
    'SAN LEANDRO',
    'SAN LORENZO',
    'SAN LUCAS',
    'SAN LUIS OBISPO',
    'SAN MARCOS',
    'SAN MARINO',
    'SAN MARTIN',
    'SAN MATEO',
    'SAN MIGUEL',
    'SAN PABLO',
    'SAN RAFAEL',
    'SAN RAMON',
    'SANTA ANA',
    'SANTA BARBARA',
    'SANTA CLARA',
    'SANTA CLARITA',
    'SANTA CRUZ',
    'SANTA FE SPRINGS',
    'SANTA MARIA',
    'SANTA MONICA',
    'SANTA PAULA',
    'SANTA ROSA',
    'SANTA VENETIA',
    'SANTA YNEZ',
    'SANTEE',
    'SARATOGA',
    'SAUSALITO',
    'SCOTTS VALLEY',
    'SEAL BEACH',
    'SEARLES VALLEY',
    'SEASIDE',
    'SEBASTOPOL',
    'SEDCO HILLS',
    'SEELEY',
    'SELMA',
    'SEVEN TREES',
    'SHACKELFORD',
    'SHAFTER',
    'SHANDON',
    'SHASTA LAKE',
    'SHAVER LAKE',
    'SHINGLE SPRINGS',
    'SHINGLETOWN',
    'SHOSHONE',
    'SIERRA MADRE',
    'SIGNAL HILL',
    'SIMI VALLEY',
    'SOLANA BEACH',
    'SOLEDAD',
    'SOLVANG',
    'SONOMA',
    'SONORA',
    'SOQUEL',
    'SOULSBYVILLE',
    'SOUTH DOS PALOS',
    'SOUTH EL MONTE',
    'SOUTH GATE',
    'SOUTH LAKE TAHOE',
    'SOUTH OROVILLE',
    'SOUTH PASADENA',
    'SOUTH SAN FRANCISCO',
    'SOUTH SAN GABRIEL',
    'SOUTH SAN JOSE HILLS',
    'SOUTH TAFT',
    'SOUTH WHITTIER',
    'SOUTH WOODBRIDGE',
    'SOUTH YUBA CITY',
    'SPRECKELS',
    'SPRING GARDEN',
    'SPRING VALLEY',
    'SPRINGVILLE',
    'SQUAW VALLEY',
    'SQUIRREL MOUNTAIN VALLEY',
    'STALLION SPRINGS',
    'STANFORD',
    'STANTON',
    'STINSON BEACH',
    'STOCKTON',
    'STORRIE',
    'STRATFORD',
    'STRATHMORE',
    'STRAWBERRY',
    'SUISUN CITY',
    'SUMMERLAND',
    'SUN CITY',
    'SUNNYSIDE-TAHOE CITY',
    'SUNNYSLOPE',
    'SUNNYVALE',
    'SUNOL',
    'SUNOL-MIDTOWN',
    'SUSANVILLE',
    'SUTTER',
    'SUTTER CREEK',
    'TAFT',
    'TAFT HEIGHTS',
    'TAFT MOSSWOOD',
    'TAHOE VISTA',
    'TALMAGE',
    'TAMALPAIS-HOMESTEAD VALLEY',
    'TARA HILLS',
    'TAYLORSVILLE',
    'TECOPA',
    'TEHACHAPI',
    'TEHAMA',
    'TEMECULA',
    'TEMELEC',
    'TEMPLE CITY',
    'TEMPLETON',
    'TENNANT',
    'TERRA BELLA',
    'THERMALITO',
    'THOUSAND OAKS',
    'THOUSAND PALMS',
    'THREE RIVERS',
    'TIBURON',
    'TIERRA BUENA',
    'TIPTON',
    'TOBIN',
    'TOMALES',
    'TORO CANYON',
    'TORRANCE',
    'TRACY',
    'TRANQUILLITY',
    'TRAVER',
    'TRINIDAD',
    'TRUCKEE',
    'TULARE',
    'TULELAKE',
    'TUOLUMNE CITY',
    'TUPMAN',
    'TURLOCK',
    'TUSTIN',
    'TUSTIN FOOTHILLS',
    'TWAIN',
    'TWAIN HARTE',
    'TWENTYNINE PALMS',
    'TWENTYNINE PALMS BASE',
    'TWIN LAKES',
    'UKIAH',
    'UNION CITY',
    'UPLAND',
    'UPPER LAKE',
    'VACAVILLE',
    'VALINDA',
    'VALLECITO',
    'VALLEJO',
    'VALLE VISTA',
    'VALLEY ACRES',
    'VALLEY CENTER',
    'VALLEY RANCH',
    'VALLEY SPRINGS',
    'VAL VERDE',
    'VANDENBERG AFB',
    'VANDENBERG VILLAGE',
    'VERNON',
    'VICTORVILLE',
    'VIEW PARK-WINDSOR HILLS',
    'VILLA PARK',
    'VINCENT',
    'VINE HILL',
    'VINEYARD',
    'VISALIA',
    'VISTA',
    'WALDON',
    'WALLACE',
    'WALNUT',
    'WALNUT CREEK',
    'WALNUT GROVE',
    'WALNUT PARK',
    'WASCO',
    'WATERFORD',
    'WATSONVILLE',
    'WEAVERVILLE',
    'WEED',
    'WEEDPATCH',
    'WELDON',
    'WEST ATHENS',
    'WEST BISHOP',
    'WEST CARSON',
    'WEST COMPTON',
    'WEST COVINA',
    'WESTHAVEN-MOONSTONE',
    'WEST HOLLYWOOD',
    'WESTLAKE VILLAGE',
    'WESTLEY',
    'WEST MENLO PARK',
    'WESTMINSTER',
    'WEST MODESTO',
    'WESTMONT',
    'WESTMORLAND',
    'WEST POINT',
    'WEST PUENTE VALLEY',
    'WEST SACRAMENTO',
    'WEST WHITTIER-LOS NIETOS',
    'WESTWOOD',
    'WHEATLAND',
    'WHITEHAWK',
    'WHITTIER',
    'WILDOMAR',
    'WILKERSON',
    'WILLIAMS',
    'WILLITS',
    'WILLOWBROOK',
    'WILLOW CREEK',
    'WILLOWS',
    'WILTON',
    'WINCHESTER',
    'WINDSOR',
    'WINTER GARDENS',
    'WINTERHAVEN',
    'WINTERS',
    'WINTON',
    'WOFFORD HEIGHTS',
    'WOODACRE',
    'WOODCREST',
    'WOODLAKE',
    'WOODLAND',
    'WOODSIDE',
    'WOODVILLE',
    'WRIGHTWOOD',
    'YORBA LINDA',
    'YOSEMITE LAKES',
    'YOSEMITE VALLEY',
    'YOUNTVILLE',
    'YREKA',
    'YUBA CITY',
    'YUCAIPA',
    'YUCCA VALLEY',
  ],
  Colorado: [
    'ACRES GREEN',
    'AGUILAR',
    'AIR FORCE ACADEMY',
    'AKRON',
    'ALAMOSA',
    'ALAMOSA EAST',
    'ALLENSPARK',
    'ALMA',
    'ANTONITO',
    'APPLEWOOD',
    'ARBOLES',
    'ARISTOCRAT RANCHETTES',
    'ARRIBA',
    'ARVADA',
    'ASPEN',
    'ASPEN PARK',
    'ATWOOD',
    'AULT',
    'AURORA',
    'AVON',
    'AVONDALE',
    'BASALT',
    'BATTLEMENT MESA',
    'BAYFIELD',
    'BENNETT',
    'BERKLEY',
    'BERTHOUD',
    'BETHUNE',
    'BEULAH VALLEY',
    'BLACK FOREST',
    'BLACK HAWK',
    'BLANCA',
    'BLUE RIVER',
    'BONANZA',
    'BOONE',
    'BOULDER',
    'BOW MAR',
    'BRANSON',
    'BRECKENRIDGE',
    'BRIGHTON',
    'BROOKSIDE',
    'BROOMFIELD',
    'BRUSH',
    'BUENA VISTA',
    'BURLINGTON',
    'BYERS',
    'CALHAN',
    'CAMPION',
    'CAMPO',
    'CANON CITY',
    'CARBONDALE',
    'CARRIAGE CLUB',
    'CASCADE-CHIPITA PARK',
    'CASTLE PINES',
    'CASTLE ROCK',
    'CASTLEWOOD',
    'CEDAREDGE',
    'CENTER',
    'CENTRAL CITY',
    'CHERAW',
    'CHERRY HILLS VILLAGE',
    'CHEYENNE WELLS',
    'CIMARRON HILLS',
    'CLIFTON',
    'COAL CREEK',
    'COAL CREEK',
    'COKEDALE',
    'COLLBRAN',
    'COLORADO CITY',
    'COLORADO SPRINGS',
    'COLUMBINE',
    'COLUMBINE VALLEY',
    'COMMERCE CITY',
    'CORTEZ',
    'COTTONWOOD',
    'CRAIG',
    'CRAWFORD',
    'CREEDE',
    'CRESTED BUTTE',
    'CRESTONE',
    'CRIPPLE CREEK',
    'CROOK',
    'CROWLEY',
    'DACONO',
    'DE BEQUE',
    'DEER TRAIL',
    'DEL NORTE',
    'DELTA',
    'DENVER',
    'DERBY',
    'DILLON',
    'DINOSAUR',
    'DOLORES',
    'DOVE CREEK',
    'DOWNIEVILLE-LAWSON-DUMONT',
    'DURANGO',
    'EADS',
    'EAGLE',
    'EAGLE-VAIL',
    'EAST PLEASANT VIEW',
    'EATON',
    'ECKLEY',
    'EDGEWATER',
    'EDWARDS',
    'ELDORA',
    'ELDORADO SPRINGS',
    'ELIZABETH',
    'EL JEBEL',
    'EMPIRE',
    'ENGLEWOOD',
    'ERIE',
    'ESTES PARK',
    'EVANS',
    'EVERGREEN',
    'FAIRPLAY',
    'FEDERAL HEIGHTS',
    'FIRESTONE',
    'FLAGLER',
    'FLEMING',
    'FLORENCE',
    'FORT CARSON',
    'FORT COLLINS',
    'FORT GARLAND',
    'FORT LUPTON',
    'FORT MORGAN',
    'FOUNTAIN',
    'FOWLER',
    'FOXFIELD',
    'FRANKTOWN',
    'FRASER',
    'FREDERICK',
    'FRISCO',
    'FRUITA',
    'FRUITVALE',
    'GARDEN CITY',
    'GENESEE',
    'GENOA',
    'GEORGETOWN',
    'GILCREST',
    'GLENDALE',
    'GLENEAGLE',
    'GLENWOOD SPRINGS',
    'GOLDEN',
    'GOLD HILL',
    'GRANADA',
    'GRANBY',
    'GRAND JUNCTION',
    'GRAND LAKE',
    'GRAND VIEW ESTATES',
    'GREELEY',
    'GREEN MOUNTAIN FALLS',
    'GREENWOOD VILLAGE',
    'GROVER',
    'GUNBARREL',
    'GUNNISON',
    'GYPSUM',
    'HARTMAN',
    'HASWELL',
    'HAXTUN',
    'HAYDEN',
    'HERITAGE HILLS',
    'HIGHLANDS RANCH',
    'HILLROSE',
    'HOLLY',
    'HOLYOKE',
    'HOOPER',
    'HOTCHKISS',
    'HOT SULPHUR SPRINGS',
    'HUDSON',
    'HUGO',
    'IDAHO SPRINGS',
    'IGNACIO',
    'ILIFF',
    'INDIAN HILLS',
    'JAMESTOWN',
    'JOHNSTOWN',
    'JULESBURG',
    'KEENESBURG',
    'KEN CARYL',
    'KERSEY',
    'KEYSTONE',
    'KIM',
    'KIOWA',
    'KIT CARSON',
    'KITTREDGE',
    'KREMMLING',
    'LAFAYETTE',
    'LA JARA',
    'LA JUNTA',
    'LAKE CITY',
    'LAKESIDE',
    'LAKEWOOD',
    'LAMAR',
    'LAPORTE',
    'LARKSPUR',
    'LA SALLE',
    'LAS ANIMAS',
    'LA VETA',
    'LEADVILLE',
    'LEADVILLE NORTH',
    'LIMON',
    'LINCOLN PARK',
    'LITTLETON',
    'LOCHBUIE',
    'LOGHILL VILLAGE',
    'LOG LANE VILLAGE',
    'LONE TREE',
    'LONGMONT',
    'LOUISVILLE',
    'LOUVIERS',
    'LOVELAND',
    'LYONS',
    'MANASSA',
    'MANCOS',
    'MANITOU SPRINGS',
    'MANZANOLA',
    'MARBLE',
    'MEAD',
    'MEEKER',
    'MERIDIAN',
    'MERINO',
    'MILLIKEN',
    'MINTURN',
    'MOFFAT',
    'MONTE VISTA',
    'MONTEZUMA',
    'MONTROSE',
    'MONUMENT',
    'MORRISON',
    'MOUNTAIN VIEW',
    'MOUNTAIN VILLAGE',
    'MOUNT CRESTED BUTTE',
    'NATURITA',
    'NEDERLAND',
    'NEW CASTLE',
    'NIWOT',
    'NORTHGLENN',
    'NORTH WASHINGTON',
    'NORWOOD',
    'NUCLA',
    'NUNN',
    'OAK CREEK',
    'OLATHE',
    'OLNEY SPRINGS',
    'OPHIR',
    'ORCHARD CITY',
    'ORCHARD MESA',
    'ORDWAY',
    'OTIS',
    'OURAY',
    'OVID',
    'PADRONI',
    'PAGOSA SPRINGS',
    'PALISADE',
    'PALMER LAKE',
    'PAOLI',
    'PAONIA',
    'PARACHUTE',
    'PARKER',
    'PEETZ',
    'PENROSE',
    'PERRY PARK',
    'PIERCE',
    'PITKIN',
    'PLATTEVILLE',
    'PONCHA SPRINGS',
    'PONDEROSA PARK',
    'PRITCHETT',
    'PUEBLO',
    'PUEBLO WEST',
    'RAMAH',
    'RANGELY',
    'RAYMER',
    'RED CLIFF',
    'RED FEATHER LAKES',
    'REDLANDS',
    'RICO',
    'RIDGWAY',
    'RIFLE',
    'ROCKVALE',
    'ROCKY FORD',
    'ROMEO',
    'ROXBOROUGH PARK',
    'RYE',
    'SAGUACHE',
    'ST. MARYS',
    'SALIDA',
    'SALT CREEK',
    'SANFORD',
    'SAN LUIS',
    'SAWPIT',
    'SECURITY-WIDEFIELD',
    'SEDALIA',
    'SEDGWICK',
    'SEIBERT',
    'SEVERANCE',
    'SHERIDAN',
    'SHERIDAN LAKE',
    'SHERRELWOOD',
    'SILT',
    'SILVER CLIFF',
    'SILVER PLUME',
    'SILVERTHORNE',
    'SILVERTON',
    'SIMLA',
    'SNOWMASS VILLAGE',
    'SOUTH FORK',
    'SOUTHGLENN',
    'SPRINGFIELD',
    'STARKVILLE',
    'STEAMBOAT SPRINGS',
    'STERLING',
    'STONEGATE',
    'STRASBURG',
    'STRATMOOR',
    'STRATTON',
    'SUGAR CITY',
    'SUPERIOR',
    'SWINK',
    'TABERNASH',
    'TELLURIDE',
    'THE PINERY',
    'THORNTON',
    'TIMNATH',
    'TODD CREEK',
    'TOWAOC',
    'TRINIDAD',
    'TWIN LAKES',
    'TWO BUTTES',
    'VAIL',
    'VICTOR',
    'VILAS',
    'VONA',
    'WALDEN',
    'WALSENBURG',
    'WALSH',
    'WARD',
    'WELBY',
    'WELLINGTON',
    'WESTCLIFFE',
    'WESTCREEK',
    'WESTMINSTER',
    'WEST PLEASANT VIEW',
    'WHEAT RIDGE',
    'WIGGINS',
    'WILEY',
    'WILLIAMSBURG',
    'WINDSOR',
    'WINTER PARK',
    'WOODLAND PARK',
    'WOODMOOR',
    'WRAY',
    'YAMPA',
    'YUMA',
  ],
  Connecticut: [
    'ANSONIA',
    'BANTAM',
    'BETHEL',
    'BETHLEHEM VILLAGE',
    'BLUE HILLS',
    'BRANFORD CENTER',
    'BRIDGEPORT',
    'BRISTOL',
    'BROAD BROOK',
    'CANAAN',
    'CANTON VALLEY',
    'CENTRAL MANCHESTER',
    'CENTRAL SOMERS',
    'CENTRAL WATERFORD',
    'CHESHIRE VILLAGE',
    'CHESTER CENTER',
    'CLINTON',
    'COLLINSVILLE',
    'CONNING TOWERS-NAUTILUS PARK',
    'COVENTRY LAKE',
    'CRYSTAL LAKE',
    'DANBURY',
    'DANIELSON',
    'DARIEN',
    'DEEP RIVER CENTER',
    'DERBY',
    'DURHAM',
    'EAST BROOKLYN',
    'EAST HAMPTON',
    'EAST HARTFORD',
    'EAST HAVEN',
    'ESSEX VILLAGE',
    'FENWICK',
    'GEORGETOWN',
    'GLASTONBURY CENTER',
    'GROTON',
    'GROTON LONG POINT',
    'GUILFORD CENTER',
    'HARTFORD',
    'HAZARDVILLE',
    'HERITAGE VILLAGE',
    'HIGGANUM',
    'JEWETT CITY',
    'KENSINGTON',
    'LAKE POCOTOPAUG',
    'LITCHFIELD',
    'LONG HILL',
    'MADISON CENTER',
    'MANSFIELD CENTER',
    'MERIDEN',
    'MIDDLETOWN',
    'MILFORD CITY',
    'MOODUS',
    'MOOSUP',
    'MYSTIC',
    'NAUGATUCK',
    'NEW BRITAIN',
    'NEW HARTFORD CENTER',
    'NEW HAVEN',
    'NEWINGTON',
    'NEW LONDON',
    'NEW MILFORD',
    'NEW PRESTON',
    'NEWTOWN',
    'NIANTIC',
    'NOANK',
    'NORTH GRANBY',
    'NORTH GROSVENOR DALE',
    'NORTH HAVEN',
    'NORTHWEST HARWINTON',
    'NORWALK',
    'NORWICH',
    'OAKVILLE',
    'OLD MYSTIC',
    'OLD SAYBROOK CENTER',
    'ORANGE',
    'OXOBOXO RIVER',
    'PAWCATUCK',
    'PLAINFIELD VILLAGE',
    'POQUONOCK BRIDGE',
    'PORTLAND',
    'PUTNAM DISTRICT',
    'QUINEBAUG',
    'RIDGEFIELD',
    'ROCKVILLE',
    'SALMON BROOK',
    'SAYBROOK MANOR',
    'SHELTON',
    'SHERWOOD MANOR',
    'SIMSBURY CENTER',
    'SOUTH COVENTRY',
    'SOUTH WINDHAM',
    'SOUTHWOOD ACRES',
    'SOUTH WOODSTOCK',
    'STAMFORD',
    'STONINGTON',
    'STORRS',
    'STRATFORD',
    'SUFFIELD DEPOT',
    'TARIFFVILLE',
    'TERRAMUGGUS',
    'TERRYVILLE',
    'THOMPSONVILLE',
    'TORRINGTON',
    'TRUMBULL',
    'WALLINGFORD CENTER',
    'WATERBURY',
    'WAUREGAN',
    'WEATOGUE',
    'WESTBROOK CENTER',
    'WEST HARTFORD',
    'WEST HAVEN',
    'WESTPORT',
    'WEST SIMSBURY',
    'WETHERSFIELD',
    'WILLIMANTIC',
    'WINDSOR LOCKS',
    'WINSTED',
    'WOODBURY CENTER',
    'WOODMONT',
  ],
  Delaware: [
    'ARDEN',
    'ARDENCROFT',
    'ARDENTOWN',
    'BEAR',
    'BELLEFONTE',
    'BETHANY',
    'BEACH',
    'BETHEL',
    'BLADES',
    'BOWERS',
    'BRIDGEVILLE',
    'BROOKSIDE',
    'CAMDEN',
    'CHESWOLD',
    'CLAYMONT',
    'CLAYTON',
    'DAGSBORO',
    'DELAWARE CITY',
    'DELMAR',
    'DEWEY BEACH',
    'DOVER',
    'DOVER BASE HOUSING',
    'EDGEMOOR',
    'ELLENDALE',
    'ELSMERE',
    'FARMINGTON',
    'FELTON',
    'FENWICK ISLAND',
    'FRANKFORD',
    'FREDERICA',
    'GEORGETOWN',
    'GLASGOW',
    'GREENVILLE',
    'GREENWOOD',
    'HARRINGTON',
    'HARTLY',
    'HENLOPEN ACRES',
    'HIGHLAND ACRES',
    'HOCKESSIN',
    'HOUSTON',
    'KENT ACRES',
    'KENTON',
    'LAUREL',
    'LEIPSIC',
    'LEWES',
    'LITTLE CREEK',
    'LONG NECK',
    'MAGNOLIA',
    'MIDDLETOWN',
    'MILFORD',
    'MILLSBORO',
    'MILLVILLE',
    'MILTON',
    'NEWARK',
    'NEW CASTLE',
    'NEWPORT',
    'NORTH STAR',
    'OCEAN VIEW',
    'ODESSA',
    'PIKE CREEK',
    'REHOBOTH BEACH',
    'RISING SUN-LEBANON',
    'RIVERVIEW',
    'RODNEY VILLAGE',
    'SEAFORD',
    'SELBYVILLE',
    'SLAUGHTER BEACH',
    'SMYRNA',
    'SOUTH BETHANY',
    'TOWNSEND',
    'VIOLA',
    'WILMINGTON',
    'WILMINGTON MANOR',
    'WOODSIDE',
    'WOODSIDE EAST',
    'WYOMING',
  ],
  'District Of Columbia': ['WASHINGTON'],
  'Federated States Of Micronesia': ['FSM'],
  Florida: [
    'ALACHUA',
    'ALFORD',
    'ALTAMONTE SPRINGS',
    'ALTHA',
    'ALTOONA',
    'ALVA',
    'ANDOVER',
    'ANDREWS',
    'ANNA MARIA',
    'APALACHICOLA',
    'APOLLO BEACH',
    'APOPKA',
    'ARCADIA',
    'ARCHER',
    'ASBURY LAKE',
    'ASTATULA',
    'ASTOR',
    'ATLANTIC BEACH',
    'ATLANTIS',
    'AUBURNDALE',
    'AVENTURA',
    'AVON PARK',
    'AZALEA PARK',
    'BABSON PARK',
    'BAGDAD',
    'BALDWIN',
    'BAL HARBOUR',
    'BARTOW',
    'BASCOM',
    'BAY HARBOR ISLANDS',
    'BAY HILL',
    'BAY LAKE',
    'BAYONET POINT',
    'BAY PINES',
    'BAYPORT',
    'BAYSHORE GARDENS',
    'BEACON SQUARE',
    'BEE RIDGE',
    'BELL',
    'BELLAIR-MEADOWBROOK TERRACE',
    'BELLEAIR',
    'BELLEAIR BEACH',
    'BELLEAIR BLUFFS',
    'BELLEAIR SHORE',
    'BELLE GLADE',
    'BELLE GLADE CAMP',
    'BELLE ISLE',
    'BELLEVIEW',
    'BELLVIEW',
    'BEVERLY BEACH',
    'BEVERLY HILLS',
    'BIG COPPITT KEY',
    'BIG PINE KEY',
    'BISCAYNE PARK',
    'BITHLO',
    'BLACK DIAMOND',
    'BLOOMINGDALE',
    'BLOUNTSTOWN',
    'BOCA DEL MAR',
    'BOCA POINTE',
    'BOCA RATON',
    'BOKEELIA',
    'BONIFAY',
    'BONITA SPRINGS',
    'BONNIE LOCK-WOODSETTER NORTH',
    'BOULEVARD GARDENS',
    'BOWLING GREEN',
    'BOYETTE',
    'BOYNTON BEACH',
    'BRADENTON',
    'BRADENTON BEACH',
    'BRANDON',
    'BRANFORD',
    'BRENT',
    'BRINY BREEZES',
    'BRISTOL',
    'BROADVIEW PARK',
    'BROADVIEW-POMPANO PARK',
    'BRONSON',
    'BROOKER',
    'BROOKRIDGE',
    'BROOKSVILLE',
    'BROWARD ESTATES',
    'BROWNSVILLE',
    'BUCKHEAD RIDGE',
    'BUCKINGHAM',
    'BUNCHE PARK',
    'BUNNELL',
    'BURNT STORE MARINA',
    'BUSHNELL',
    'BUTLER BEACH',
    'CALLAHAN',
    'CALLAWAY',
    'CAMPBELL',
    'CAMPBELLTON',
    'CANAL POINT',
    'CAPE CANAVERAL',
    'CAPE CORAL',
    'CAPTIVA',
    'CAROL CITY',
    'CARRABELLE',
    'CARVER RANCHES',
    'CARYVILLE',
    'CASSELBERRY',
    'CEDAR GROVE',
    'CEDAR KEY',
    'CELEBRATION',
    'CENTER HILL',
    'CENTURY',
    'CENTURY VILLAGE',
    'CHAMBERS ESTATES',
    'CHARLESTON PARK',
    'CHARLOTTE HARBOR',
    'CHARLOTTE PARK',
    'CHATTAHOOCHEE',
    'CHEVAL',
    'CHIEFLAND',
    'CHIPLEY',
    'CHOKOLOSKEE',
    'CHRISTMAS',
    'CHULA VISTA',
    'CHULUOTA',
    'CINCO BAYOU',
    'CITRUS HILLS',
    'CITRUS PARK',
    'CITRUS RIDGE',
    'CITRUS SPRINGS',
    'CLEARWATER',
    'CLERMONT',
    'CLEVELAND',
    'CLEWISTON',
    'CLOUD LAKE',
    'COCOA',
    'COCOA BEACH',
    'COCOA WEST',
    'COCONUT CREEK',
    'COLEMAN',
    'COLLIER MANOR-CRESTHAVEN',
    'COMBEE SETTLEMENT',
    'CONWAY',
    'COOPER CITY',
    'CORAL GABLES',
    'CORAL SPRINGS',
    'CORAL TERRACE',
    'CORTEZ',
    'COTTONDALE',
    'COUNTRY CLUB',
    'COUNTRY ESTATES',
    'COUNTRY WALK',
    'CRESCENT BEACH',
    'CRESCENT CITY',
    'CRESTVIEW',
    'CROOKED LAKE PARK',
    'CROSS CITY',
    'CRYSTAL LAKE',
    'CRYSTAL LAKE',
    'CRYSTAL RIVER',
    'CRYSTAL SPRINGS',
    'CUDJOE KEY',
    'CUTLER',
    'CUTLER RIDGE',
    'CYPRESS GARDENS',
    'CYPRESS LAKE',
    'CYPRESS LAKES',
    'CYPRESS QUARTERS',
    'DADE CITY',
    'DADE CITY NORTH',
    'DANIA BEACH',
    'DAVENPORT',
    'DAVIE',
    'DAYTONA BEACH',
    'DAYTONA BEACH SHORES',
    'DE BARY',
    'DEERFIELD BEACH',
    'DE FUNIAK SPRINGS',
    'DE LAND',
    'DE LAND SOUTHWEST',
    'DE LEON SPRINGS',
    'DELRAY BEACH',
    'DELTONA',
    'DESOTO LAKES',
    'DESTIN',
    'DOCTOR PHILLIPS',
    'DORAL',
    'DOVER',
    'DUCK KEY',
    'DUNDEE',
    'DUNEDIN',
    'DUNES ROAD',
    'DUNNELLON',
    'EAGLE LAKE',
    'EAST BRONSON',
    'EAST DUNBAR',
    'EAST LAKE',
    'EAST LAKE-ORIENT PARK',
    'EAST PALATKA',
    'EAST PERRINE',
    'EASTPOINT',
    'EAST WILLISTON',
    'EATONVILLE',
    'EBRO',
    'EDGEWATER',
    'EDGEWATER',
    'EDGEWOOD',
    'EGLIN AFB',
    'EGYPT LAKE-LETO',
    'ELFERS',
    'ELLENTON',
    'EL PORTAL',
    'ENGLEWOOD',
    'ENSLEY',
    'ESTATES OF FORT LAUDERDALE',
    'ESTERO',
    'ESTO',
    'EUSTIS',
    'EVERGLADES',
    'FAIRVIEW SHORES',
    'FANNING SPRINGS',
    'FEATHER SOUND',
    'FELLSMERE',
    'FERNANDINA BEACH',
    'FERNDALE',
    'FERN PARK',
    'FERRY PASS',
    'FISHER ISLAND',
    'FISH HAWK',
    'FIVE POINTS',
    'FLAGLER BEACH',
    'FLORAL CITY',
    'FLORIDA CITY',
    'FLORIDA RIDGE',
    'FOREST CITY',
    'FORT LAUDERDALE',
    'FORT MEADE',
    'FORT MYERS',
    'FORT MYERS BEACH',
    'FORT MYERS SHORES',
    'FORT PIERCE',
    'FORT PIERCE NORTH',
    'FORT PIERCE SOUTH',
    'FORT WALTON BEACH',
    'FORT WHITE',
    'FOUNTAINBLEAU',
    'FRANKLIN PARK',
    'FREEPORT',
    'FREMD VILLAGE-PADGETT ISLAND',
    'FROSTPROOF',
    'FRUIT COVE',
    'FRUITLAND PARK',
    'FRUITVILLE',
    'FUSSELS CORNER',
    'GAINESVILLE',
    'GANDY',
    'GATEWAY',
    'GENEVA',
    'GIBSONIA',
    'GIBSONTON',
    'GIFFORD',
    'GLADEVIEW',
    'GLENCOE',
    'GLEN RIDGE',
    'GLEN ST. MARY',
    'GLENVAR HEIGHTS',
    'GODFREY ROAD',
    'GOLDEN BEACH',
    'GOLDEN GATE',
    'GOLDEN GLADES',
    'GOLDEN HEIGHTS',
    'GOLDEN LAKES',
    'GOLDENROD',
    'GOLF',
    'GONZALEZ',
    'GOODLAND',
    'GOTHA',
    'GOULDING',
    'GOULDS',
    'GRACEVILLE',
    'GRAND RIDGE',
    'GREATER CARROLLWOOD',
    'GREATER NORTHDALE',
    'GREATER SUN CENTER',
    'GREENACRES',
    'GREEN COVE SPRINGS',
    'GREEN MEADOW',
    'GREENSBORO',
    'GREENVILLE',
    'GREENWOOD',
    'GRETNA',
    'GROVE CITY',
    'GROVELAND',
    'GULF BREEZE',
    'GULF GATE ESTATES',
    'GULFPORT',
    'GULF STREAM',
    'GUN CLUB ESTATES',
    'HAINES CITY',
    'HALLANDALE',
    'HAMPTON',
    'HAMPTONS AT BOCA RATON',
    'HARBOR BLUFFS',
    'HARBOUR HEIGHTS',
    'HARLEM',
    'HARLEM HEIGHTS',
    'HASTINGS',
    'HAVANA',
    'HAVERHILL',
    'HAWTHORNE',
    'HEATHROW',
    'HERNANDO',
    'HERNANDO BEACH',
    'HIALEAH',
    'HIALEAH GARDENS',
    'HIGHLAND BEACH',
    'HIGHLAND CITY',
    'HIGHLAND PARK',
    'HIGH POINT',
    'HIGH POINT',
    'HIGH SPRINGS',
    'HILAND PARK',
    'HILLCREST HEIGHTS',
    'HILLIARD',
    'HILL N DALE',
    'HILLSBORO BEACH',
    'HILLSBORO PINES',
    'HILLSBORO RANCHES',
    'HOBE SOUND',
    'HOLDEN HEIGHTS',
    'HOLIDAY',
    'HOLLY HILL',
    'HOLLYWOOD',
    'HOLMES BEACH',
    'HOMESTEAD',
    'HOMESTEAD BASE',
    'HOMOSASSA',
    'HOMOSASSA SPRINGS',
    'HORSESHOE BEACH',
    'HOWEY-IN-THE-HILLS',
    'HUDSON',
    'HUNTERS CREEK',
    'HUTCHINSON ISLAND SOUTH',
    'HYPOLUXO',
    'IMMOKALEE',
    'INDIALANTIC',
    'INDIAN CREEK',
    'INDIAN HARBOUR BEACH',
    'INDIAN RIVER ESTATES',
    'INDIAN RIVER SHORES',
    'INDIAN ROCKS BEACH',
    'INDIAN SHORES',
    'INDIANTOWN',
    'INGLIS',
    'INTERLACHEN',
    'INVERNESS',
    'INVERNESS HIGHLANDS NORTH',
    'INVERNESS HIGHLANDS SOUTH',
    'INWOOD',
    'IONA',
    'ISLAMORADA',
    'ISLANDIA',
    'ISTACHATTA',
    'IVANHOE ESTATES',
    'IVES ESTATES',
    'JACKSONVILLE',
    'JACKSONVILLE BEACH',
    'JACOB CITY',
    'JAN PHYL VILLAGE',
    'JASMINE ESTATES',
    'JASPER',
    'JAY',
    'JENNINGS',
    'JENSEN BEACH',
    'JUNE PARK',
    'JUNO BEACH',
    'JUNO RIDGE',
    'JUPITER',
    'JUPITER INLET COLONY',
    'JUPITER ISLAND',
    'KATHLEEN',
    'KENDALE LAKES',
    'KENDALL',
    'KENDALL GREEN',
    'KENDALL WEST',
    'KENNETH CITY',
    'KENSINGTON PARK',
    'KEY BISCAYNE',
    'KEY COLONY BEACH',
    'KEY LARGO',
    'KEYSTONE',
    'KEYSTONE HEIGHTS',
    'KEY WEST',
    'KINGS POINT',
    'KISSIMMEE',
    'LABELLE',
    'LACOOCHEE',
    'LA CROSSE',
    'LADY LAKE',
    'LAGUNA BEACH',
    'LAKE ALFRED',
    'LAKE BELVEDERE ESTATES',
    'LAKE BUENA VISTA',
    'LAKE BUTLER',
    'LAKE BUTTER',
    'LAKE CITY',
    'LAKE CLARKE SHORES',
    'LAKE FOREST',
    'LAKE HAMILTON',
    'LAKE HARBOR',
    'LAKE HART',
    'LAKE HELEN',
    'LAKE KATHRYN',
    'LAKELAND',
    'LAKELAND HIGHLANDS',
    'LAKE LINDSEY',
    'LAKE LORRAINE',
    'LAKE LUCERNE',
    'LAKE MACK-FOREST HILLS',
    'LAKE MAGDALENE',
    'LAKE MARY',
    'LAKE PANASOFFKEE',
    'LAKE PARK',
    'LAKE PLACID',
    'LAKE SARASOTA',
    'LAKES BY THE BAY',
    'LAKESIDE',
    'LAKESIDE GREEN',
    'LAKE WALES',
    'LAKEWOOD PARK',
    'LAKE WORTH',
    'LAKE WORTH CORRIDOR',
    'LAND O LAKES',
    'LANTANA',
    'LARGO',
    'LAUDERDALE-BY-THE-SEA',
    'LAUDERDALE LAKES',
    'LAUDERHILL',
    'LAUREL',
    'LAUREL HILL',
    'LAWTEY',
    'LAYTON',
    'LAZY LAKE',
    'LECANTO',
    'LEE',
    'LEESBURG',
    'LEHIGH ACRES',
    'LEISURE CITY',
    'LEISUREVILLE',
    'LELY',
    'LELY RESORT',
    'LIGHTHOUSE POINT',
    'LIMESTONE CREEK',
    'LISBON',
    'LIVE OAK',
    'LOCH LOMOND',
    'LOCHMOOR WATERWAY ESTATES',
    'LOCKHART',
    'LONGBOAT KEY',
    'LONGWOOD',
    'LOUGHMAN',
    'LOWER GRAND LAGOON',
    'LUTZ',
    'LYNN HAVEN',
    'MACCLENNY',
    'MCGREGOR',
    'MCINTOSH',
    'MADEIRA BEACH',
    'MADISON',
    'MAITLAND',
    'MALABAR',
    'MALONE',
    'MANALAPAN',
    'MANASOTA KEY',
    'MANATTEE ROAD',
    'MANGO',
    'MANGONIA PARK',
    'MARATHON',
    'MARCO ISLAND',
    'MARGATE',
    'MARIANNA',
    'MARINELAND',
    'MARY ESTHER',
    'MASARYKTOWN',
    'MASCOTTE',
    'MATLACHA',
    'MATLACHA ISLES-MATLACHA SHORES',
    'MAYO',
    'MEADOW WOODS',
    'MEDLEY',
    'MEDULLA',
    'MELBOURNE',
    'MELBOURNE BEACH',
    'MELBOURNE VILLAGE',
    'MELROSE PARK',
    'MEMPHIS',
    'MERRITT ISLAND',
    'MEXICO BEACH',
    'MIAMI',
    'MIAMI BEACH',
    'MIAMI GARDENS',
    'MIAMI LAKES',
    'MIAMI SHORES',
    'MIAMI SPRINGS',
    'MICANOPY',
    'MICCO',
    'MIDDLEBURG',
    'MIDWAY',
    'MIDWAY',
    'MILTON',
    'MIMS',
    'MINNEOLA',
    'MIRAMAR',
    'MIRAMAR BEACH',
    'MISSION BAY',
    'MOLINO',
    'MONTICELLO',
    'MONTVERDE',
    'MOORE HAVEN',
    'MOUNT DORA',
    'MOUNT PLYMOUTH',
    'MULBERRY',
    'MYRTLE GROVE',
    'NAPLES',
    'NAPLES MANOR',
    'NAPLES PARK',
    'NARANJA',
    'NASSAU VILLAGE-RATLIFF',
    'NEPTUNE BEACH',
    'NEWBERRY',
    'NEW PORT RICHEY',
    'NEW PORT RICHEY EAST',
    'NEW SMYRNA BEACH',
    'NICEVILLE',
    'NOBLETON',
    'NOKOMIS',
    'NOMA',
    'NORLAND',
    'NORTH ANDREWS GARDENS',
    'NORTH BAY VILLAGE',
    'NORTH BEACH',
    'NORTH BROOKSVILLE',
    'NORTH DE LAND',
    'NORTH FORT MYERS',
    'NORTH KEY LARGO',
    'NORTH LAUDERDALE',
    'NORTH MIAMI',
    'NORTH MIAMI BEACH',
    'NORTH PALM BEACH',
    'NORTH PORT',
    'NORTH REDINGTON BEACH',
    'NORTH RIVER SHORES',
    'NORTH SARASOTA',
    'NORTH WEEKI WACHEE',
    'OAK HILL',
    'OAKLAND',
    'OAKLAND PARK',
    'OAK POINT',
    'OAK RIDGE',
    'OCALA',
    'OCEAN BREEZE PARK',
    'OCEAN CITY',
    'OCEAN RIDGE',
    'OCOEE',
    'ODESSA',
    'OJUS',
    'OKAHUMPKA',
    'OKEECHOBEE',
    'OLDSMAR',
    'OLGA',
    'OLYMPIA HEIGHTS',
    'OPA-LOCKA',
    'OPA-LOCKA NORTH',
    'ORANGE CITY',
    'ORANGE PARK',
    'ORANGETREE',
    'ORCHID',
    'ORLANDO',
    'ORLOVISTA',
    'ORMOND BEACH',
    'ORMOND-BY-THE-SEA',
    'OSPREY',
    'OTTER CREEK',
    'OVIEDO',
    'PACE',
    'PAGE PARK',
    'PAHOKEE',
    'PAISLEY',
    'PALATKA',
    'PALM AIRE',
    'PALM BAY',
    'PALM BEACH',
    'PALM BEACH GARDENS',
    'PALM BEACH SHORES',
    'PALM CITY',
    'PALM COAST',
    'PALMETTO',
    'PALMETTO ESTATES',
    'PALM HARBOR',
    'PALMONA PARK',
    'PALM RIVER-CLAIR MEL',
    'PALM SHORES',
    'PALM SPRINGS',
    'PALM SPRINGS NORTH',
    'PALM VALLEY',
    'PANAMA CITY',
    'PANAMA CITY BEACH',
    'PARADISE HEIGHTS',
    'PARKER',
    'PARKLAND',
    'PAXTON',
    'PEBBLE CREEK',
    'PELICAN BAY',
    'PEMBROKE PARK',
    'PEMBROKE PINES',
    'PENNEY FARMS',
    'PENSACOLA',
    'PERRY',
    'PIERSON',
    'PINE CASTLE',
    'PINECREST',
    'PINE HILLS',
    'PINE ISLAND',
    'PINE ISLAND CENTER',
    'PINE ISLAND RIDGE',
    'PINE LAKES',
    'PINELAND',
    'PINELLAS PARK',
    'PINE MANOR',
    'PINE RIDGE',
    'PINE RIDGE',
    'PINEWOOD',
    'PITTMAN',
    'PLACID LAKES',
    'PLANTATION',
    'PLANTATION',
    'PLANTATION ISLAND',
    'PLANTATION MOBILE HOME PARK',
    'PLANT CITY',
    'POINCIANA',
    'POLK CITY',
    'POMONA PARK',
    'POMPANO BEACH',
    'POMPANO BEACH HIGHLANDS',
    'POMPANO ESTATES',
    'PONCE DE LEON',
    'PONCE INLET',
    'PORT CHARLOTTE',
    'PORT LA BELLE',
    'PORT ORANGE',
    'PORT RICHEY',
    'PORT ST. JOE',
    'PORT ST. JOHN',
    'PORT ST. LUCIE',
    'PORT ST. LUCIE-RIVER PARK',
    'PORT SALERNO',
    'PRETTY BAYOU',
    'PRINCETON',
    'PROGRESS VILLAGE',
    'PUNTA GORDA',
    'PUNTA RASSA',
    'QUINCY',
    'RAIFORD',
    'RAMBLEWOOD EAST',
    'RAVENSWOOD ESTATES',
    'REDDICK',
    'REDINGTON BEACH',
    'REDINGTON SHORES',
    'RICHMOND HEIGHTS',
    'RICHMOND WEST',
    'RIDGECREST',
    'RIDGE MANOR',
    'RIDGE WOOD HEIGHTS',
    'RIO',
    'RIVERLAND VILLAGE',
    'RIVERVIEW',
    'RIVIERA BEACH',
    'ROCK ISLAND',
    'ROCKLEDGE',
    'ROLLING OAKS',
    'ROOSEVELT GARDENS',
    'ROSELAND',
    'ROTONDA',
    'ROYAL PALM BEACH',
    'ROYAL PALM ESTATES',
    'ROYAL PALM RANCHES',
    'RUSKIN',
    'SAFETY HARBOR',
    'ST. AUGUSTINE',
    'ST. AUGUSTINE BEACH',
    'ST. AUGUSTINE SHORES',
    'ST. AUGUSTINE SOUTH',
    'ST. CLOUD',
    'ST. GEORGE',
    'ST. JAMES CITY',
    'ST. LEO',
    'ST. LUCIE',
    'ST. MARKS',
    'ST. PETE BEACH',
    'ST. PETERSBURG',
    'SAMOSET',
    'SAMSULA-SPRUCE CREEK',
    'SAN ANTONIO',
    'SAN CARLOS PARK',
    'SANDALFOOT COVE',
    'SANFORD',
    'SANIBEL',
    'SARASOTA',
    'SARASOTA SPRINGS',
    'SATELLITE BEACH',
    'SAWGRASS',
    'SCHALL CIRCLE',
    'SCOTT LAKE',
    'SEA RANCH LAKES',
    'SEBASTIAN',
    'SEBRING',
    'SEFFNER',
    'SEMINOLE',
    'SEMINOLE MANOR',
    'SEWALL S POINT',
    'SHADY HILLS',
    'SHALIMAR',
    'SHARPES',
    'SIESTA KEY',
    'SILVER LAKE',
    'SILVER SPRINGS SHORES',
    'SKY LAKE',
    'SNEADS',
    'SOLANA',
    'SOPCHOPPY',
    'SORRENTO',
    'SOUTH APOPKA',
    'SOUTH BAY',
    'SOUTH BEACH',
    'SOUTH BRADENTON',
    'SOUTH BROOKSVILLE',
    'SOUTHCHASE',
    'SOUTH DAYTONA',
    'SOUTHEAST ARCADIA',
    'SOUTHGATE',
    'SOUTH GATE RIDGE',
    'SOUTH HIGHPOINT',
    'SOUTH MIAMI',
    'SOUTH MIAMI HEIGHTS',
    'SOUTH PALM BEACH',
    'SOUTH PASADENA',
    'SOUTH PATRICK SHORES',
    'SOUTH SARASOTA',
    'SOUTH VENICE',
    'SPRINGFIELD',
    'SPRING HILL',
    'SPRING LAKE',
    'STACEY STREET',
    'STARKE',
    'STOCK ISLAND',
    'STUART',
    'SUGARMILL WOODS',
    'SUNCOAST ESTATES',
    'SUNNY ISLES BEACH',
    'SUNRISE',
    'SUNSET',
    'SUNSHINE ACRES',
    'SUNSHINE RANCHES',
    'SURFSIDE',
    'SWEETWATER',
    'SYLVAN SHORES',
    'TAFT',
    'TALLAHASSEE',
    'TAMARAC',
    'TAMIAMI',
    'TAMPA',
    'TANGELO PARK',
    'TANGERINE',
    'TARPON SPRINGS',
    'TAVARES',
    'TAVERNIER',
    'TAYLOR CREEK',
    'TEDDER',
    'TEMPLE TERRACE',
    'TEQUESTA',
    'TERRA MAR',
    'THE CROSSINGS',
    'THE HAMMOCKS',
    'THE MEADOWS',
    'THE VILLAGES',
    'THONOTOSASSA',
    'THREE LAKES',
    'THREE OAKS',
    'TICE',
    'TIERRA VERDE',
    'TILDENVILLE',
    'TIMBER PINES',
    'TITUSVILLE',
    'TOWN N COUNTRY',
    'TREASURE ISLAND',
    'TRENTON',
    'TRINITY',
    'TWIN LAKES',
    'TYNDALL AFB',
    'UMATILLA',
    'UNION PARK',
    'UNIVERSITY',
    'UNIVERSITY PARK',
    'UPPER GRAND LAGOON',
    'UTOPIA',
    'VALPARAISO',
    'VALRICO',
    'VAMO',
    'VENICE',
    'VENICE GARDENS',
    'VERNON',
    'VERO BEACH',
    'VERO BEACH SOUTH',
    'VILLAGE PARK',
    'VILLAGES OF ORIOLE',
    'VILLANO BEACH',
    'VILLAS',
    'VINEYARDS',
    'VIRGINIA GARDENS',
    'WABASSO',
    'WABASSO BEACH',
    'WAHNETA',
    'WALDO',
    'WARM MINERAL SPRINGS',
    'WARRINGTON',
    'WASHINGTON PARK',
    'WATERTOWN',
    'WAUCHULA',
    'WAUSAU',
    'WAVERLY',
    'WEBSTER',
    'WEDGEFIELD',
    'WEEKI WACHEE',
    'WEEKI WACHEE GARDENS',
    'WEKIWA SPRINGS',
    'WELAKA',
    'WELLINGTON',
    'WESLEY CHAPEL',
    'WESLEY CHAPEL SOUTH',
    'WEST AND EAST LEALMAN',
    'WEST BRADENTON',
    'WESTCHASE',
    'WESTCHESTER',
    'WEST DE LAND',
    'WESTGATE-BELVEDERE HOMES',
    'WEST KEN-LARK',
    'WEST LITTLE RIVER',
    'WEST MELBOURNE',
    'WEST MIAMI',
    'WESTON',
    'WEST PALM BEACH',
    'WEST PENSACOLA',
    'WEST PERRINE',
    'WEST SAMOSET',
    'WEST VERO CORRIDOR',
    'WESTVIEW',
    'WESTVILLE',
    'WESTWOOD LAKES',
    'WEWAHITCHKA',
    'WHISKEY CREEK',
    'WHISPER WALK',
    'WHITE CITY',
    'WHITE SPRINGS',
    'WHITFIELD',
    'WILDWOOD',
    'WILLIAMSBURG',
    'WILLISTON',
    'WILLISTON HIGHLANDS',
    'WILLOW OAK',
    'WILTON MANORS',
    'WIMAUMA',
    'WINDERMERE',
    'WINSTON',
    'WINTER BEACH',
    'WINTER GARDEN',
    'WINTER HAVEN',
    'WINTER PARK',
    'WINTER SPRINGS',
    'WOODVILLE',
    'WORTHINGTON SPRINGS',
    'WRIGHT',
    'YALAHA',
    'YANKEETOWN',
    'YEEHAW JUNCTION',
    'YULEE',
    'ZELLWOOD',
    'ZEPHYRHILLS',
    'ZEPHYRHILLS NORTH',
    'ZEPHYRHILLS SOUTH',
    'ZEPHYRHILLS WEST',
    'ZOLFO SPRINGS',
  ],
  Georgia: [
    'ABBEVILLE',
    'ACWORTH',
    'ADAIRSVILLE',
    'ADEL',
    'ADRIAN',
    'AILEY',
    'ALAMO',
    'ALAPAHA',
    'ALBANY',
    'ALDORA',
    'ALLENHURST',
    'ALLENTOWN',
    'ALMA',
    'ALPHARETTA',
    'ALSTON',
    'ALTO',
    'AMBROSE',
    'AMERICUS',
    'ANDERSONVILLE',
    'ARABI',
    'ARAGON',
    'ARCADE',
    'ARGYLE',
    'ARLINGTON',
    'ARNOLDSVILLE',
    'ASHBURN',
    'ATHENS-CLARKE COUNTY',
    'ATLANTA',
    'ATTAPULGUS',
    'AUBURN',
    'AUGUSTA-RICHMOND COUNTY',
    'AUSTELL',
    'AVALON',
    'AVERA',
    'AVONDALE ESTATES',
    'BACONTON',
    'BAINBRIDGE',
    'BALDWIN',
    'BALL GROUND',
    'BARNESVILLE',
    'BARTOW',
    'BARWICK',
    'BAXLEY',
    'BELLVILLE',
    'BELVEDERE PARK',
    'BERKELEY LAKE',
    'BERLIN',
    'BETHLEHEM',
    'BETWEEN',
    'BIBB CITY',
    'BISHOP',
    'BLACKSHEAR',
    'BLACKSVILLE',
    'BLAIRSVILLE',
    'BLAKELY',
    'BLOOMINGDALE',
    'BLUE RIDGE',
    'BLUFFTON',
    'BLYTHE',
    'BOGART',
    'BONANZA',
    'BOSTON',
    'BOSTWICK',
    'BOWDON',
    'BOWERSVILLE',
    'BOWMAN',
    'BRASELTON',
    'BRASWELL',
    'BREMEN',
    'BRINSON',
    'BRONWOOD',
    'BROOKLET',
    'BROOKS',
    'BROXTON',
    'BRUNSWICK',
    'BUCHANAN',
    'BUCKHEAD',
    'BUENA VISTA',
    'BUFORD',
    'BUTLER',
    'BYROMVILLE',
    'BYRON',
    'CADWELL',
    'CAIRO',
    'CALHOUN',
    'CAMAK',
    'CAMILLA',
    'CANDLER-MCAFEE',
    'CANON',
    'CANTON',
    'CARL',
    'CARLTON',
    'CARNESVILLE',
    'CARROLLTON',
    'CARTERSVILLE',
    'CAVE SPRING',
    'CECIL',
    'CEDARTOWN',
    'CENTERVILLE',
    'CENTRALHATCHEE',
    'CHAMBLEE',
    'CHATSWORTH',
    'CHATTANOOGA VALLEY',
    'CHAUNCEY',
    'CHESTER',
    'CHICKAMAUGA',
    'CLARKESVILLE',
    'CLARKSTON',
    'CLAXTON',
    'CLAYTON',
    'CLERMONT',
    'CLEVELAND',
    'CLIMAX',
    'COBBTOWN',
    'COCHRAN',
    'COHUTTA',
    'COLBERT',
    'COLEMAN',
    'COLLEGE PARK',
    'COLLINS',
    'COLQUITT',
    'COLUMBUS CITY',
    'COMER',
    'COMMERCE',
    'CONCORD',
    'CONLEY',
    'CONYERS',
    'COOLIDGE',
    'CORDELE',
    'CORINTH',
    'CORNELIA',
    'COUNTRY CLUB ESTATES',
    'COVINGTON',
    'CRAWFORD',
    'CRAWFORDVILLE',
    'CULLODEN',
    'CUMMING',
    'CUSSETA',
    'CUTHBERT',
    'DACULA',
    'DAHLONEGA',
    'DAISY',
    'DALLAS',
    'DALTON',
    'DAMASCUS',
    'DANIELSVILLE',
    'DANVILLE',
    'DARIEN',
    'DASHER',
    'DAVISBORO',
    'DAWSON',
    'DAWSONVILLE',
    'DEARING',
    'DECATUR',
    'DEENWOOD',
    'DEEPSTEP',
    'DEMOREST',
    'DENTON',
    'DE SOTO',
    'DEXTER',
    'DILLARD',
    'DOCK JUNCTION',
    'DOERUN',
    'DONALSONVILLE',
    'DOOLING',
    'DORAVILLE',
    'DOUGLAS',
    'DOUGLASVILLE',
    'DRUID HILLS',
    'DUBLIN',
    'DUDLEY',
    'DULUTH',
    'DUNWOODY',
    'DU PONT',
    'EAST DUBLIN',
    'EAST ELLIJAY',
    'EAST GRIFFIN',
    'EASTMAN',
    'EAST NEWNAN',
    'EAST POINT',
    'EATONTON',
    'EDGE HILL',
    'EDISON',
    'ELBERTON',
    'ELLAVILLE',
    'ELLENTON',
    'ELLIJAY',
    'EMERSON',
    'ENIGMA',
    'EPHESUS',
    'ETON',
    'EUHARLEE',
    'EVANS',
    'EXPERIMENT',
    'FAIRBURN',
    'FAIRMOUNT',
    'FAIR OAKS',
    'FAIRVIEW',
    'FARGO',
    'FAYETTEVILLE',
    'FITZGERALD',
    'FLEMINGTON',
    'FLOVILLA',
    'FLOWERY BRANCH',
    'FOLKSTON',
    'FOREST PARK',
    'FORSYTH',
    'FORT BENNING SOUTH',
    'FORT GAINES',
    'FORT OGLETHORPE',
    'FORT STEWART',
    'FORT VALLEY',
    'FRANKLIN',
    'FRANKLIN SPRINGS',
    'FUNSTON',
    'GAINESVILLE',
    'GARDEN CITY',
    'GARFIELD',
    'GAY',
    'GENEVA',
    'GEORGETOWN',
    'GEORGETOWN',
    'GIBSON',
    'GILLSVILLE',
    'GIRARD',
    'GLENNVILLE',
    'GLENWOOD',
    'GOOD HOPE',
    'GORDON',
    'GRAHAM',
    'GRANTVILLE',
    'GRAY',
    'GRAYSON',
    'GREENSBORO',
    'GREENVILLE',
    'GRESHAM PARK',
    'GRIFFIN',
    'GROVETOWN',
    'GUMBRANCH',
    'GUMLOG',
    'GUYTON',
    'HAGAN',
    'HAHIRA',
    'HAMILTON',
    'HAMPTON',
    'HANNAHS MILL',
    'HAPEVILLE',
    'HARALSON',
    'HARLEM',
    'HARRISON',
    'HARTWELL',
    'HAWKINSVILLE',
    'HAZLEHURST',
    'HELEN',
    'HELENA',
    'HEPHZIBAH',
    'HIAWASSEE',
    'HIGGSTON',
    'HILLTOP',
    'HILTONIA',
    'HINESVILLE',
    'HIRAM',
    'HOBOKEN',
    'HOGANSVILLE',
    'HOLLY SPRINGS',
    'HOMELAND',
    'HOMER',
    'HOMERVILLE',
    'HOSCHTON',
    'HULL',
    'IDEAL',
    'ILA',
    'INDIAN SPRINGS',
    'IRON CITY',
    'IRONDALE',
    'IRWINTON',
    'ISLE OF HOPE',
    'IVEY',
    'JACKSON',
    'JACKSONVILLE',
    'JAKIN',
    'JASPER',
    'JEFFERSON',
    'JEFFERSONVILLE',
    'JENKINSBURG',
    'JERSEY',
    'JESUP',
    'JONESBORO',
    'JUNCTION CITY',
    'KENNESAW',
    'KEYSVILLE',
    'KINGS BAY BASE',
    'KINGSLAND',
    'KINGSTON',
    'KITE',
    'LA FAYETTE',
    'LAGRANGE',
    'LAKE CITY',
    'LAKELAND',
    'LAKE PARK',
    'LAKEVIEW',
    'LAKEVIEW ESTATES',
    'LAVONIA',
    'LAWRENCEVILLE',
    'LEARY',
    'LEESBURG',
    'LENOX',
    'LESLIE',
    'LEXINGTON',
    'LILBURN',
    'LILLY',
    'LINCOLN PARK',
    'LINCOLNTON',
    'LINDALE',
    'LITHIA SPRINGS',
    'LITHONIA',
    'LOCUST GROVE',
    'LOGANVILLE',
    'LONE OAK',
    'LOOKOUT MOUNTAIN',
    'LOUISVILLE',
    'LOVEJOY',
    'LUDOWICI',
    'LULA',
    'LUMBER CITY',
    'LUMPKIN',
    'LUTHERSVILLE',
    'LYERLY',
    'LYONS',
    'MABLETON',
    'MCCAYSVILLE',
    'MCDONOUGH',
    'MCINTYRE',
    'MACON',
    'MCRAE',
    'MADISON',
    'MANASSAS',
    'MANCHESTER',
    'MANSFIELD',
    'MARIETTA',
    'MARSHALLVILLE',
    'MARTIN',
    'MARTINEZ',
    'MAXEYS',
    'MAYSVILLE',
    'MEANSVILLE',
    'MEIGS',
    'MENLO',
    'METTER',
    'MIDVILLE',
    'MIDWAY',
    'MIDWAY-HARDWICK',
    'MILAN',
    'MILLEDGEVILLE',
    'MILLEN',
    'MILNER',
    'MITCHELL',
    'MOLENA',
    'MONROE',
    'MONTEZUMA',
    'MONTGOMERY',
    'MONTICELLO',
    'MONTROSE',
    'MOODY AFB',
    'MORELAND',
    'MORGAN',
    'MORGANTON',
    'MORROW',
    'MORVEN',
    'MOULTRIE',
    'MOUNTAIN CITY',
    'MOUNTAIN PARK',
    'MOUNTAIN PARK',
    'MOUNT AIRY',
    'MOUNT VERNON',
    'MOUNT ZION',
    'NAHUNTA',
    'NASHVILLE',
    'NELSON',
    'NEWBORN',
    'NEWINGTON',
    'NEWNAN',
    'NEWTON',
    'NICHOLLS',
    'NICHOLSON',
    'NORCROSS',
    'NORMAN PARK',
    'NORTH ATLANTA',
    'NORTH DECATUR',
    'NORTH DRUID HILLS',
    'NORTH HIGH SHOALS',
    'NORWOOD',
    'NUNEZ',
    'OAK PARK',
    'OAKWOOD',
    'OCHLOCKNEE',
    'OCILLA',
    'OCONEE',
    'ODUM',
    'OFFERMAN',
    'OGLETHORPE',
    'OLIVER',
    'OMEGA',
    'ORCHARD HILL',
    'OXFORD',
    'PALMETTO',
    'PANTHERSVILLE',
    'PARROTT',
    'PATTERSON',
    'PAVO',
    'PAYNE',
    'PEACHTREE CITY',
    'PEARSON',
    'PELHAM',
    'PEMBROKE',
    'PENDERGRASS',
    'PERRY',
    'PHILLIPSBURG',
    'PINEHURST',
    'PINE LAKE',
    'PINE MOUNTAIN',
    'PINEVIEW',
    'PITTS',
    'PLAINS',
    'PLAINVILLE',
    'POOLER',
    'PORTAL',
    'PORTERDALE',
    'PORT WENTWORTH',
    'POULAN',
    'POWDER SPRINGS',
    'PRESTON',
    'PULASKI',
    'PUTNEY',
    'QUITMAN',
    'RANGER',
    'RAOUL',
    'RAY CITY',
    'RAYLE',
    'REBECCA',
    'REDAN',
    'REED CREEK',
    'REGISTER',
    'REIDSVILLE',
    'REMERTON',
    'RENTZ',
    'RESACA',
    'REST HAVEN',
    'REYNOLDS',
    'RHINE',
    'RICEBORO',
    'RICHLAND',
    'RICHMOND HILL',
    'RIDDLEVILLE',
    'RINCON',
    'RINGGOLD',
    'RIVERDALE',
    'RIVERSIDE',
    'ROBERTA',
    'ROBINS AFB',
    'ROCHELLE',
    'ROCKMART',
    'ROCKY FORD',
    'ROME',
    'ROOPVILLE',
    'ROSSVILLE',
    'ROSWELL',
    'ROYSTON',
    'RUTLEDGE',
    'ST. MARYS',
    'ST. SIMONS',
    'SALE CITY',
    'SALEM',
    'SANDERSVILLE',
    'SANDY SPRINGS',
    'SANTA CLAUS',
    'SARDIS',
    'SASSER',
    'SAVANNAH',
    'SCOTLAND',
    'SCOTTDALE',
    'SCREVEN',
    'SENOIA',
    'SHADY DALE',
    'SHANNON',
    'SHARON',
    'SHARPSBURG',
    'SHELLMAN',
    'SHILOH',
    'SILOAM',
    'SKIDAWAY ISLAND',
    'SKY VALLEY',
    'SMITHVILLE',
    'SMYRNA',
    'SNELLVILLE',
    'SOCIAL CIRCLE',
    'SOPERTON',
    'SPARKS',
    'SPARTA',
    'SPRINGFIELD',
    'STAPLETON',
    'STATESBORO',
    'STATHAM',
    'STILLMORE',
    'STOCKBRIDGE',
    'STONE MOUNTAIN',
    'SUGAR HILL',
    'SUMMERTOWN',
    'SUMMERVILLE',
    'SUMNER',
    'SUNNY SIDE',
    'SUNNYSIDE',
    'SUNSET VILLAGE',
    'SURRENCY',
    'SUWANEE',
    'SWAINSBORO',
    'SYCAMORE',
    'SYLVANIA',
    'SYLVESTER',
    'TALBOTTON',
    'TALKING ROCK',
    'TALLAPOOSA',
    'TALLULAH FALLS',
    'TALMO',
    'TARRYTOWN',
    'TAYLORSVILLE',
    'TEMPLE',
    'TENNILLE',
    'THOMASTON',
    'THOMASVILLE',
    'THOMSON',
    'THUNDERBOLT',
    'TIFTON',
    'TIGER',
    'TIGNALL',
    'TOCCOA',
    'TOOMSBORO',
    'TRENTON',
    'TRION',
    'TUCKER',
    'TUNNEL HILL',
    'TURIN',
    'TWIN CITY',
    'TYBEE ISLAND',
    'TYRONE',
    'TY TY',
    'UNADILLA',
    'UNION CITY',
    'UNION POINT',
    'UNIONVILLE',
    'UVALDA',
    'VALDOSTA',
    'VARNELL',
    'VERNONBURG',
    'VIDALIA',
    'VIDETTE',
    'VIENNA',
    'VILLA RICA',
    'VININGS',
    'WACO',
    'WADLEY',
    'WALESKA',
    'WALNUT GROVE',
    'WALTHOURVILLE',
    'WARM SPRINGS',
    'WARNER ROBINS',
    'WARRENTON',
    'WARWICK',
    'WASHINGTON',
    'WATKINSVILLE',
    'WAVERLY HALL',
    'WAYCROSS',
    'WAYNESBORO',
    'WESTON',
    'WEST POINT',
    'WHIGHAM',
    'WHITE',
    'WHITEMARSH ISLAND',
    'WHITE PLAINS',
    'WHITESBURG',
    'WILLACOOCHEE',
    'WILLIAMSON',
    'WILMINGTON ISLAND',
    'WINDER',
    'WINTERVILLE',
    'WOODBINE',
    'WOODBURY',
    'WOODLAND',
    'WOODSTOCK',
    'WOODVILLE',
    'WOOLSEY',
    'WRENS',
    'WRIGHTSVILLE',
    'YATESVILLE',
    'YOUNG HARRIS',
    'ZEBULON',
  ],
  Guam: ['GUAM'],
  Hawaii: [
    'AHUIMANU',
    'AIEA',
    'AINALOA',
    'ANAHOLA',
    'BARBERS POINT HOUSING',
    'CAPTAIN COOK',
    'EDEN ROC',
    'ELEELE',
    'EWA BEACH',
    'EWA GENTRY',
    'EWA VILLAGES',
    'FERN ACRES',
    'FERN FOREST',
    'HAIKU-PAUWELA',
    'HALAULA',
    'HALAWA',
    'HALEIWA',
    'HALIIMAILE',
    'HANA',
    'HANALEI',
    'HANAMAULU',
    'HANAPEPE',
    'HAUULA',
    'HAWAIIAN ACRES',
    'HAWAIIAN BEACHES',
    'HAWAIIAN OCEAN VIEW',
    'HAWAIIAN PARADISE PARK',
    'HAWI',
    'HEEIA',
    'HICKAM HOUSING',
    'HILO',
    'HOLUALOA',
    'HONALO',
    'HONAUNAU-NAPOOPOO',
    'HONOKAA',
    'HONOLULU',
    'HONOMU',
    'IROQUOIS POINT',
    'KAAAWA',
    'KAANAPALI',
    'KAHALUU',
    'KAHALUU-KEAUHOU',
    'KAHUKU',
    'KAHULUI',
    'KAILUA',
    'KAILUA',
    'KALAHEO',
    'KALAOA',
    'KALIHIWAI',
    'KANEOHE',
    'KANEOHE STATION',
    'KAPAA',
    'KAPAAU',
    'KAPALUA',
    'KAUMAKANI',
    'KAUNAKAKAI',
    'KAWELA BAY',
    'KEAAU',
    'KEALAKEKUA',
    'KEKAHA',
    'KIHEI',
    'KILAUEA',
    'KOLOA',
    'KUALAPUU',
    'KUKUIHAELE',
    'KURTISTOWN',
    'LAHAINA',
    'LAIE',
    'LANAI CITY',
    'LAUPAHOEHOE',
    'LAWAI',
    'LEILANI ESTATES',
    'LIHUE',
    'MAALAEA',
    'MAILI',
    'MAKAHA',
    'MAKAHA VALLEY',
    'MAKAKILO CITY',
    'MAKAWAO',
    'MAUNALOA',
    'MAUNAWILI',
    'MILILANI TOWN',
    'MOKULEIA',
    'MOUNTAIN VIEW',
    'NAALEHU',
    'NANAKULI',
    'NANAWALE ESTATES',
    'NAPILI-HONOKOWAI',
    'OMAO',
    'ORCHIDLANDS ESTATES',
    'PAAUILO',
    'PAHALA',
    'PAHOA',
    'PAIA',
    'PAKALA VILLAGE',
    'PAPAIKOU',
    'PAUKAA',
    'PEARL CITY',
    'PEPEEKEO',
    'POIPU',
    'PRINCEVILLE',
    'PUAKO',
    'PUHI',
    'PUKALANI',
    'PUNALUU',
    'PUPUKEA',
    'SCHOFIELD BARRACKS',
    'VILLAGE PARK',
    'VOLCANO',
    'WAHIAWA',
    'WAIALUA',
    'WAIANAE',
    'WAIHEE-WAIEHU',
    'WAIKANE',
    'WAIKAPU',
    'WAIKOLOA VILLAGE',
    'WAILEA-MAKENA',
    'WAILUA',
    'WAILUA HOMESTEADS',
    'WAILUKU',
    'WAIMALU',
    'WAIMANALO',
    'WAIMANALO BEACH',
    'WAIMEA',
    'WAIMEA',
    'WAINAKU',
    'WAIPAHU',
    'WAIPIO',
    'WAIPIO ACRES',
    'WHEELER AFB',
    'WHITMORE VILLAGE',
  ],
  Idaho: [
    'ABERDEEN',
    'ACEQUIA',
    'ALBION',
    'AMERICAN FALLS',
    'AMMON',
    'ARBON VALLEY',
    'ARCO',
    'ARIMO',
    'ASHTON',
    'ATHOL',
    'ATOMIC CITY',
    'BANCROFT',
    'BASALT',
    'BELLEVUE',
    'BLACKFOOT',
    'BLISS',
    'BLOOMINGTON',
    'BOISE CITY',
    'BONNERS FERRY',
    'BOVILL',
    'BUHL',
    'BURLEY',
    'BUTTE CITY',
    'CALDWELL',
    'CAMBRIDGE',
    'CAREY',
    'CASCADE',
    'CASTLEFORD',
    'CHALLIS',
    'CHUBBUCK',
    'CLARK FORK',
    'CLAYTON',
    'CLIFTON',
    'COEUR D ALENE',
    'COTTONWOOD',
    'COUNCIL',
    'CRAIGMONT',
    'CROUCH',
    'CULDESAC',
    'DALTON GARDENS',
    'DAYTON',
    'DEARY',
    'DECLO',
    'DIETRICH',
    'DONNELLY',
    'DOVER',
    'DOWNEY',
    'DRIGGS',
    'DRUMMOND',
    'DUBOIS',
    'EAGLE',
    'EAST HOPE',
    'EDEN',
    'ELK RIVER',
    'EMMETT',
    'FAIRFIELD',
    'FERDINAND',
    'FERNAN LAKE VILLAGE',
    'FILER',
    'FIRTH',
    'FORT HALL',
    'FRANKLIN',
    'FRUITLAND',
    'GARDEN CITY',
    'GENESEE',
    'GEORGETOWN',
    'GLENNS FERRY',
    'GOODING',
    'GRACE',
    'GRAND VIEW',
    'GRANGEVILLE',
    'GREENLEAF',
    'HAGERMAN',
    'HAILEY',
    'HAMER',
    'HANSEN',
    'HARRISON',
    'HAUSER',
    'HAYDEN',
    'HAYDEN LAKE',
    'HAZELTON',
    'HEYBURN',
    'HOLLISTER',
    'HOMEDALE',
    'HOPE',
    'HORSESHOE BEND',
    'HUETTER',
    'IDAHO CITY',
    'IDAHO FALLS',
    'INKOM',
    'IONA',
    'IRWIN',
    'ISLAND PARK',
    'JEROME',
    'JULIAETTA',
    'KAMIAH',
    'KELLOGG',
    'KENDRICK',
    'KETCHUM',
    'KIMBERLY',
    'KOOSKIA',
    'KOOTENAI',
    'KUNA',
    'LAPWAI',
    'LAVA HOT SPRINGS',
    'LEADORE',
    'LEWISTON',
    'LEWISVILLE',
    'LOST RIVER',
    'MCCALL',
    'MCCAMMON',
    'MACKAY',
    'MALAD CITY',
    'MALTA',
    'MARSING',
    'MELBA',
    'MENAN',
    'MERIDIAN',
    'MIDDLETON',
    'MIDVALE',
    'MINIDOKA',
    'MONTPELIER',
    'MOORE',
    'MOSCOW',
    'MOUNTAIN HOME',
    'MOUNTAIN HOME AFB',
    'MOYIE SPRINGS',
    'MUD LAKE',
    'MULLAN',
    'MURTAUGH',
    'NAMPA',
    'NEWDALE',
    'NEW MEADOWS',
    'NEW PLYMOUTH',
    'NEZPERCE',
    'NOTUS',
    'OAKLEY',
    'OLDTOWN',
    'ONAWAY',
    'OROFINO',
    'OSBURN',
    'OXFORD',
    'PARIS',
    'PARKER',
    'PARKLINE',
    'PARMA',
    'PAUL',
    'PAYETTE',
    'PECK',
    'PIERCE',
    'PINEHURST',
    'PLACERVILLE',
    'PLUMMER',
    'POCATELLO',
    'PONDERAY',
    'POST FALLS',
    'POTLATCH',
    'PRESTON',
    'PRIEST RIVER',
    'RATHDRUM',
    'REUBENS',
    'REXBURG',
    'RICHFIELD',
    'RIGBY',
    'RIGGINS',
    'RIRIE',
    'ROBERTS',
    'ROCKLAND',
    'RUPERT',
    'ST. ANTHONY',
    'ST. CHARLES',
    'ST. MARIES',
    'SALMON',
    'SANDPOINT',
    'SHELLEY',
    'SHOSHONE',
    'SMELTERVILLE',
    'SODA SPRINGS',
    'SPENCER',
    'SPIRIT LAKE',
    'STANLEY',
    'STAR',
    'STATE LINE',
    'STITES',
    'SUGAR CITY',
    'SUN VALLEY',
    'SWAN VALLEY',
    'TENSED',
    'TETON',
    'TETONIA',
    'TROY',
    'TWIN FALLS',
    'UCON',
    'VICTOR',
    'WALLACE',
    'WARDNER',
    'WARM RIVER',
    'WEIPPE',
    'WEISER',
    'WENDELL',
    'WESTON',
    'WHITE BIRD',
    'WILDER',
    'WINCHESTER',
    'WORLEY',
  ],
  Illinois: [
    'ABINGDON',
    'ADDIEVILLE',
    'ADDISON',
    'ADELINE',
    'ALBANY',
    'ALBERS',
    'ALBION',
    'ALEDO',
    'ALEXIS',
    'ALGONQUIN',
    'ALHAMBRA',
    'ALLENDALE',
    'ALLENVILLE',
    'ALLERTON',
    'ALMA',
    'ALORTON',
    'ALPHA',
    'ALSEY',
    'ALSIP',
    'ALTAMONT',
    'ALTON',
    'ALTONA',
    'ALTO PASS',
    'ALVIN',
    'AMBOY',
    'ANCHOR',
    'ANDALUSIA',
    'ANDOVER',
    'ANNA',
    'ANNAWAN',
    'ANTIOCH',
    'APPLE RIVER',
    'ARCOLA',
    'ARENZVILLE',
    'ARGENTA',
    'ARLINGTON',
    'ARLINGTON HEIGHTS',
    'ARMINGTON',
    'AROMA PARK',
    'ARROWSMITH',
    'ARTHUR',
    'ASHKUM',
    'ASHLAND',
    'ASHLEY',
    'ASHMORE',
    'ASHTON',
    'ASSUMPTION',
    'ASTORIA',
    'ATHENS',
    'ATKINSON',
    'ATLANTA',
    'ATWOOD',
    'AUBURN',
    'AUGUSTA',
    'AURORA',
    'AVA',
    'AVISTON',
    'AVON',
    'BALDWIN',
    'BANNER',
    'BANNOCKBURN',
    'BARDOLPH',
    'BARRINGTON',
    'BARRINGTON HILLS',
    'BARRY',
    'BARTELSO',
    'BARTLETT',
    'BARTONVILLE',
    'BASCO',
    'BATAVIA',
    'BATCHTOWN',
    'BATH',
    'BAYLIS',
    'BAY VIEW GARDENS',
    'BEACH PARK',
    'BEARDSTOWN',
    'BEAVERVILLE',
    'BECKEMEYER',
    'BEDFORD PARK',
    'BEECHER',
    'BEECHER CITY',
    'BELGIUM',
    'BELKNAP',
    'BELLE PRAIRIE CITY',
    'BELLE RIVE',
    'BELLEVILLE',
    'BELLEVUE',
    'BELLFLOWER',
    'BELLMONT',
    'BELLWOOD',
    'BELVIDERE',
    'BEMENT',
    'BENLD',
    'BENSENVILLE',
    'BENSON',
    'BENTLEY',
    'BENTON',
    'BERKELEY',
    'BERLIN',
    'BERWYN',
    'BETHALTO',
    'BETHANY',
    'BIGGSVILLE',
    'BINGHAM',
    'BIRDS',
    'BISHOP HILL',
    'BISMARCK',
    'BLANDINSVILLE',
    'BLOOMINGDALE',
    'BLOOMINGTON',
    'BLUE ISLAND',
    'BLUE MOUND',
    'BLUFFS',
    'BLUFORD',
    'BOLINGBROOK',
    'BONDVILLE',
    'BONE GAP',
    'BONFIELD',
    'BONNIE',
    'BOULDER HILL',
    'BOURBONNAIS',
    'BOWEN',
    'BRACEVILLE',
    'BRADFORD',
    'BRADLEY',
    'BRAIDWOOD',
    'BREESE',
    'BRIDGEPORT',
    'BRIDGEVIEW',
    'BRIGHTON',
    'BRIMFIELD',
    'BROADLANDS',
    'BROADVIEW',
    'BROADWELL',
    'BROCTON',
    'BROOKFIELD',
    'BROOKLYN',
    'BROOKPORT',
    'BROUGHTON',
    'BROWNING',
    'BROWNS',
    'BROWNSTOWN',
    'BRUSSELS',
    'BRYANT',
    'BUCKINGHAM',
    'BUCKLEY',
    'BUCKNER',
    'BUDA',
    'BUFFALO',
    'BUFFALO GROVE',
    'BULL VALLEY',
    'BULPITT',
    'BUNCOMBE',
    'BUNKER HILL',
    'BURBANK',
    'BUREAU JUNCTION',
    'BURLINGTON',
    'BURNHAM',
    'BURNT PRAIRIE',
    'BURR RIDGE',
    'BUSH',
    'BUSHNELL',
    'BUTLER',
    'BYRON',
    'CABERY',
    'CAHOKIA',
    'CAIRO',
    'CALEDONIA',
    'CALHOUN',
    'CALUMET CITY',
    'CALUMET PARK',
    'CAMARGO',
    'CAMBRIA',
    'CAMBRIDGE',
    'CAMDEN',
    'CAMPBELL HILL',
    'CAMP POINT',
    'CAMPUS',
    'CANTON',
    'CANTRALL',
    'CAPRON',
    'CARBON CLIFF',
    'CARBONDALE',
    'CARBON HILL',
    'CARLINVILLE',
    'CARLOCK',
    'CARLYLE',
    'CARMI',
    'CAROL STREAM',
    'CARPENTERSVILLE',
    'CARRIER MILLS',
    'CARROLLTON',
    'CARTERVILLE',
    'CARTHAGE',
    'CARY',
    'CASEY',
    'CASEYVILLE',
    'CATLIN',
    'CAVE-IN-ROCK',
    'CEDAR POINT',
    'CEDARVILLE',
    'CENTRAL CITY',
    'CENTRALIA',
    'CENTREVILLE',
    'CERRO GORDO',
    'CHADWICK',
    'CHAMPAIGN',
    'CHANDLERVILLE',
    'CHANNAHON',
    'CHANNEL LAKE',
    'CHAPIN',
    'CHARLESTON',
    'CHATHAM',
    'CHATSWORTH',
    'CHEBANSE',
    'CHENOA',
    'CHERRY',
    'CHERRY VALLEY',
    'CHESTER',
    'CHESTERFIELD',
    'CHICAGO',
    'CHICAGO HEIGHTS',
    'CHICAGO RIDGE',
    'CHILLICOTHE',
    'CHRISMAN',
    'CHRISTOPHER',
    'CICERO',
    'CISCO',
    'CISNE',
    'CISSNA PARK',
    'CLAREMONT',
    'CLARENDON HILLS',
    'CLAY CITY',
    'CLAYTON',
    'CLEAR LAKE',
    'CLEVELAND',
    'CLIFTON',
    'CLINTON',
    'COAL CITY',
    'COALTON',
    'COAL VALLEY',
    'COATSBURG',
    'COBDEN',
    'COFFEEN',
    'COLCHESTER',
    'COLETA',
    'COLFAX',
    'COLLINSVILLE',
    'COLONA',
    'COLP',
    'COLUMBIA',
    'COLUMBUS',
    'COMPTON',
    'CONCORD',
    'CONGERVILLE',
    'COOKSVILLE',
    'CORDOVA',
    'CORNELL',
    'CORTLAND',
    'COULTERVILLE',
    'COUNTRY CLUB HILLS',
    'COUNTRYSIDE',
    'COWDEN',
    'COYNE CENTER',
    'CRAINVILLE',
    'CREAL SPRINGS',
    'CRESCENT CITY',
    'CREST HILL',
    'CRESTON',
    'CRESTWOOD',
    'CRETE',
    'CREVE COEUR',
    'CROSSVILLE',
    'CRYSTAL LAKE',
    'CRYSTAL LAWNS',
    'CUBA',
    'CULLOM',
    'CUTLER',
    'CYPRESS',
    'DAHLGREN',
    'DAKOTA',
    'DALLAS CITY',
    'DALTON CITY',
    'DALZELL',
    'DAMIANSVILLE',
    'DANA',
    'DANFORTH',
    'DANVERS',
    'DANVILLE',
    'DARIEN',
    'DAVIS',
    'DAVIS JUNCTION',
    'DAWSON',
    'DECATUR',
    'DEER CREEK',
    'DEERFIELD',
    'DEER GROVE',
    'DEER PARK',
    'DEKALB',
    'DE LAND',
    'DELAVAN',
    'DE PUE',
    'DE SOTO',
    'DES PLAINES',
    'DETROIT',
    'DE WITT',
    'DIAMOND',
    'DIETERICH',
    'DIVERNON',
    'DIX',
    'DIXMOOR',
    'DIXON',
    'DOLTON',
    'DONGOLA',
    'DONNELLSON',
    'DONOVAN',
    'DORCHESTER',
    'DOVER',
    'DOWELL',
    'DOWNERS GROVE',
    'DOWNS',
    'DU BOIS',
    'DUNFERMLINE',
    'DUNLAP',
    'DUPO',
    'DU QUOIN',
    'DURAND',
    'DWIGHT',
    'EAGARVILLE',
    'EARLVILLE',
    'EAST ALTON',
    'EAST BROOKLYN',
    'EAST CAPE GIRARDEAU',
    'EAST CARONDELET',
    'EAST DUBUQUE',
    'EAST DUNDEE',
    'EAST GALESBURG',
    'EAST GILLESPIE',
    'EAST HAZEL CREST',
    'EAST MOLINE',
    'EASTON',
    'EAST PEORIA',
    'EAST ST. LOUIS',
    'EDDYVILLE',
    'EDGEWOOD',
    'EDINBURG',
    'EDWARDSVILLE',
    'EFFINGHAM',
    'ELBURN',
    'EL DARA',
    'ELDORADO',
    'ELDRED',
    'ELGIN',
    'ELIZABETH',
    'ELIZABETHTOWN',
    'ELK GROVE VILLAGE',
    'ELKHART',
    'ELKVILLE',
    'ELLIOTT',
    'ELLIS GROVE',
    'ELLISVILLE',
    'ELLSWORTH',
    'ELMHURST',
    'ELMWOOD',
    'ELMWOOD PARK',
    'EL PASO',
    'ELSAH',
    'ELVASTON',
    'ELWOOD',
    'EMDEN',
    'EMINGTON',
    'ENERGY',
    'ENFIELD',
    'EQUALITY',
    'ERIE',
    'ESSEX',
    'EUREKA',
    'EVANSTON',
    'EVANSVILLE',
    'EVERGREEN PARK',
    'EWING',
    'EXETER',
    'FAIRBURY',
    'FAIRFIELD',
    'FAIRMONT',
    'FAIRMONT CITY',
    'FAIRMOUNT',
    'FAIRVIEW',
    'FAIRVIEW HEIGHTS',
    'FARINA',
    'FARMER CITY',
    'FARMERSVILLE',
    'FARMINGTON',
    'FAYETTEVILLE',
    'FERRIS',
    'FIDELITY',
    'FIELDON',
    'FILLMORE',
    'FINDLAY',
    'FISHER',
    'FITHIAN',
    'FLANAGAN',
    'FLAT ROCK',
    'FLORA',
    'FLORENCE',
    'FLOSSMOOR',
    'FOOSLAND',
    'FORD HEIGHTS',
    'FOREST CITY',
    'FOREST LAKE',
    'FOREST PARK',
    'FOREST VIEW',
    'FORREST',
    'FORRESTON',
    'FORSYTH',
    'FOX LAKE',
    'FOX LAKE HILLS',
    'FOX RIVER GROVE',
    'FOX RIVER VALLEY GARDENS',
    'FRANKFORT',
    'FRANKFORT SQUARE',
    'FRANKLIN',
    'FRANKLIN GROVE',
    'FRANKLIN PARK',
    'FREEBURG',
    'FREEMAN SPUR',
    'FREEPORT',
    'FULTON',
    'FULTS',
    'GAGES LAKE',
    'GALATIA',
    'GALENA',
    'GALESBURG',
    'GALVA',
    'GARDNER',
    'GARRETT',
    'GAYS',
    'GENESEO',
    'GENEVA',
    'GENOA',
    'GEORGETOWN',
    'GERMANTOWN',
    'GERMANTOWN HILLS',
    'GERMAN VALLEY',
    'GIBSON',
    'GIFFORD',
    'GILBERTS',
    'GILLESPIE',
    'GILMAN',
    'GIRARD',
    'GLADSTONE',
    'GLASFORD',
    'GLASGOW',
    'GLEN CARBON',
    'GLENCOE',
    'GLENDALE HEIGHTS',
    'GLEN ELLYN',
    'GLENVIEW',
    'GLENWOOD',
    'GODFREY',
    'GODLEY',
    'GOLCONDA',
    'GOLDEN',
    'GOLDEN GATE',
    'GOLF',
    'GOODFIELD',
    'GOOD HOPE',
    'GOODINGS GROVE',
    'GOREVILLE',
    'GORHAM',
    'GRAFTON',
    'GRAND RIDGE',
    'GRAND TOWER',
    'GRANDVIEW',
    'GRANDWOOD PARK',
    'GRANITE CITY',
    'GRANTFORK',
    'GRANT PARK',
    'GRANVILLE',
    'GRAYSLAKE',
    'GRAYVILLE',
    'GREENFIELD',
    'GREEN OAKS',
    'GREENUP',
    'GREEN VALLEY',
    'GREENVIEW',
    'GREENVILLE',
    'GREENWOOD',
    'GRIDLEY',
    'GRIGGSVILLE',
    'GULF PORT',
    'GURNEE',
    'HAINESVILLE',
    'HAMBURG',
    'HAMEL',
    'HAMILTON',
    'HAMMOND',
    'HAMPSHIRE',
    'HAMPTON',
    'HANAFORD',
    'HANNA CITY',
    'HANOVER',
    'HANOVER PARK',
    'HARDIN',
    'HARMON',
    'HARRISBURG',
    'HARRISTOWN',
    'HARTFORD',
    'HARTSBURG',
    'HARVARD',
    'HARVEL',
    'HARVEY',
    'HARWOOD HEIGHTS',
    'HAVANA',
    'HAWTHORN WOODS',
    'HAZEL CREST',
    'HEBRON',
    'HECKER',
    'HENDERSON',
    'HENNEPIN',
    'HENNING',
    'HENRY',
    'HERRICK',
    'HERRIN',
    'HERSCHER',
    'HETTICK',
    'HEYWORTH',
    'HICKORY HILLS',
    'HIDALGO',
    'HIGHLAND',
    'HIGHLAND PARK',
    'HIGHWOOD',
    'HILLCREST',
    'HILLSBORO',
    'HILLSDALE',
    'HILLSIDE',
    'HILLVIEW',
    'HINCKLEY',
    'HINDSBORO',
    'HINSDALE',
    'HODGKINS',
    'HOFFMAN',
    'HOFFMAN ESTATES',
    'HOLIDAY HILLS',
    'HOLLOWAYVILLE',
    'HOMER',
    'HOMETOWN',
    'HOMEWOOD',
    'HOOPESTON',
    'HOOPPOLE',
    'HOPEDALE',
    'HOPEWELL',
    'HOPKINS PARK',
    'HOYLETON',
    'HUDSON',
    'HUEY',
    'HULL',
    'HUMBOLDT',
    'HUME',
    'HUNTLEY',
    'HURST',
    'HUTSONVILLE',
    'ILLIOPOLIS',
    'INA',
    'INDIAN CREEK',
    'INDIAN HEAD PARK',
    'INDIANOLA',
    'INDUSTRY',
    'INGALLS PARK',
    'INVERNESS',
    'IOLA',
    'IPAVA',
    'IROQUOIS',
    'IRVING',
    'IRVINGTON',
    'IRWIN',
    'ISLAND LAKE',
    'ITASCA',
    'IUKA',
    'IVESDALE',
    'JACKSONVILLE',
    'JEFFERSONVILLE',
    'JEISYVILLE',
    'JEROME',
    'JERSEYVILLE',
    'JEWETT',
    'JOHNSBURG',
    'JOHNSONVILLE',
    'JOHNSTON CITY',
    'JOLIET',
    'JONESBORO',
    'JOPPA',
    'JOY',
    'JUNCTION',
    'JUNCTION CITY',
    'JUSTICE',
    'KAMPSVILLE',
    'KANE',
    'KANGLEY',
    'KANKAKEE',
    'KANSAS',
    'KAPPA',
    'KARNAK',
    'KASKASKIA',
    'KEENES',
    'KEENSBURG',
    'KEITHSBURG',
    'KELL',
    'KEMPTON',
    'KENILWORTH',
    'KENNEY',
    'KEWANEE',
    'KEYESPORT',
    'KILBOURNE',
    'KILDEER',
    'KINCAID',
    'KINDERHOOK',
    'KINGSTON',
    'KINGSTON MINES',
    'KINMUNDY',
    'KINSMAN',
    'KIRKLAND',
    'KIRKWOOD',
    'KNOXVILLE',
    'LACON',
    'LADD',
    'LA FAYETTE',
    'LA GRANGE',
    'LA GRANGE PARK',
    'LA HARPE',
    'LAKE BARRINGTON',
    'LAKE BLUFF',
    'LAKE CATHERINE',
    'LAKE FOREST',
    'LAKE IN THE HILLS',
    'LAKEMOOR',
    'LAKE OF THE WOODS',
    'LAKE SUMMERSET',
    'LAKE VILLA',
    'LAKEWOOD',
    'LAKEWOOD SHORES',
    'LAKE ZURICH',
    'LA MOILLE',
    'LANARK',
    'LANSING',
    'LA PRAIRIE',
    'LA ROSE',
    'LA SALLE',
    'LATHAM',
    'LAWRENCEVILLE',
    'LEAF RIVER',
    'LEBANON',
    'LEE',
    'LELAND',
    'LELAND GROVE',
    'LEMONT',
    'LENA',
    'LENZBURG',
    'LEONORE',
    'LERNA',
    'LE ROY',
    'LEWISTOWN',
    'LEXINGTON',
    'LIBERTY',
    'LIBERTYVILLE',
    'LILY LAKE',
    'LIMA',
    'LINCOLN',
    'LINCOLNSHIRE',
    'LINCOLNWOOD',
    'LINDENHURST',
    'LISBON',
    'LISLE',
    'LITCHFIELD',
    'LITTLETON',
    'LITTLE YORK',
    'LIVERPOOL',
    'LIVINGSTON',
    'LOAMI',
    'LOCKPORT',
    'LODA',
    'LOMAX',
    'LOMBARD',
    'LONDON MILLS',
    'LONG CREEK',
    'LONG GROVE',
    'LONG LAKE',
    'LONG POINT',
    'LONGVIEW',
    'LORAINE',
    'LOSTANT',
    'LOUISVILLE',
    'LOVES PARK',
    'LOVINGTON',
    'LUDLOW',
    'LYNDON',
    'LYNNVILLE',
    'LYNWOOD',
    'LYONS',
    'MCCOOK',
    'MCCULLOM LAKE',
    'MACEDONIA',
    'MCHENRY',
    'MACHESNEY PARK',
    'MACKINAW',
    'MCLEAN',
    'MCLEANSBORO',
    'MCNABB',
    'MACOMB',
    'MACON',
    'MADISON',
    'MAEYSTOWN',
    'MAGNOLIA',
    'MAHOMET',
    'MAKANDA',
    'MALDEN',
    'MALTA',
    'MANCHESTER',
    'MANHATTAN',
    'MANITO',
    'MANLIUS',
    'MANSFIELD',
    'MANTENO',
    'MAPLE PARK',
    'MAPLETON',
    'MAQUON',
    'MARENGO',
    'MARIETTA',
    'MARINE',
    'MARION',
    'MARISSA',
    'MARK',
    'MARKHAM',
    'MAROA',
    'MARQUETTE HEIGHTS',
    'MARSEILLES',
    'MARSHALL',
    'MARTINSVILLE',
    'MARTINTON',
    'MARYVILLE',
    'MASCOUTAH',
    'MASON',
    'MASON CITY',
    'MATHERVILLE',
    'MATTESON',
    'MATTOON',
    'MAUNIE',
    'MAYWOOD',
    'MAZON',
    'MECHANICSBURG',
    'MEDIA',
    'MEDORA',
    'MELROSE PARK',
    'MELVIN',
    'MENDON',
    'MENDOTA',
    'MENOMINEE',
    'MEREDOSIA',
    'MERRIONETTE PARK',
    'METAMORA',
    'METCALF',
    'METROPOLIS',
    'METTAWA',
    'MIDDLETOWN',
    'MIDLOTHIAN',
    'MILAN',
    'MILFORD',
    'MILL CREEK',
    'MILLEDGEVILLE',
    'MILLINGTON',
    'MILL SHOALS',
    'MILLSTADT',
    'MILTON',
    'MINERAL',
    'MINIER',
    'MINONK',
    'MINOOKA',
    'MODESTO',
    'MOKENA',
    'MOLINE',
    'MOMENCE',
    'MONEE',
    'MONMOUTH',
    'MONTGOMERY',
    'MONTICELLO',
    'MONTROSE',
    'MORRIS',
    'MORRISON',
    'MORRISONVILLE',
    'MORTON',
    'MORTON GROVE',
    'MOUND CITY',
    'MOUNDS',
    'MOUND STATION',
    'MOUNT AUBURN',
    'MOUNT CARMEL',
    'MOUNT CARROLL',
    'MOUNT CLARE',
    'MOUNT ERIE',
    'MOUNT MORRIS',
    'MOUNT OLIVE',
    'MOUNT PROSPECT',
    'MOUNT PULASKI',
    'MOUNT STERLING',
    'MOUNT VERNON',
    'MOUNT ZION',
    'MOWEAQUA',
    'MUDDY',
    'MULBERRY GROVE',
    'MUNCIE',
    'MUNDELEIN',
    'MURPHYSBORO',
    'MURRAYVILLE',
    'NAPERVILLE',
    'NAPLATE',
    'NAPLES',
    'NASHVILLE',
    'NASON',
    'NAUVOO',
    'NEBO',
    'NELSON',
    'NEOGA',
    'NEPONSET',
    'NEWARK',
    'NEW ATHENS',
    'NEW BADEN',
    'NEW BEDFORD',
    'NEW BERLIN',
    'NEW BOSTON',
    'NEW BURNSIDE',
    'NEW CANTON',
    'NEW DOUGLAS',
    'NEW GRAND CHAIN',
    'NEW HAVEN',
    'NEW HOLLAND',
    'NEW LENOX',
    'NEWMAN',
    'NEW MILLFORD',
    'NEW MINDEN',
    'NEW SALEM',
    'NEWTON',
    'NIANTIC',
    'NILES',
    'NILWOOD',
    'NOBLE',
    'NOKOMIS',
    'NORA',
    'NORMAL',
    'NORRIDGE',
    'NORRIS',
    'NORRIS CITY',
    'NORTH AURORA',
    'NORTH BARRINGTON',
    'NORTHBROOK',
    'NORTH CHICAGO',
    'NORTH CITY',
    'NORTHFIELD',
    'NORTH HENDERSON',
    'NORTHLAKE',
    'NORTH PEKIN',
    'NORTH RIVERSIDE',
    'NORTH UTICA',
    'NORWOOD',
    'OAK BROOK',
    'OAKBROOK TERRACE',
    'OAKDALE',
    'OAKFORD',
    'OAK FOREST',
    'OAK GROVE',
    'OAKLAND',
    'OAK LAWN',
    'OAK PARK',
    'OAKWOOD',
    'OAKWOOD HILLS',
    'OBLONG',
    'OCONEE',
    'ODELL',
    'ODIN',
    'O FALLON',
    'OGDEN',
    'OGLESBY',
    'OHIO',
    'OHLMAN',
    'OKAWVILLE',
    'OLD MILL CREEK',
    'OLD RIPLEY',
    'OLD SHAWNEETOWN',
    'OLMSTED',
    'OLNEY',
    'OLYMPIA FIELDS',
    'OMAHA',
    'ONARGA',
    'ONEIDA',
    'OQUAWKA',
    'ORANGEVILLE',
    'OREANA',
    'OREGON',
    'ORIENT',
    'ORION',
    'ORLAND HILLS',
    'ORLAND PARK',
    'OSWEGO',
    'OTTAWA',
    'OTTERVILLE',
    'OWANECO',
    'PALATINE',
    'PALESTINE',
    'PALMER',
    'PALMYRA',
    'PALOS HEIGHTS',
    'PALOS HILLS',
    'PALOS PARK',
    'PANA',
    'PANAMA',
    'PANOLA',
    'PAPINEAU',
    'PARIS',
    'PARK CITY',
    'PARKERSBURG',
    'PARK FOREST',
    'PARK RIDGE',
    'PATOKA',
    'PAWNEE',
    'PAW PAW',
    'PAXTON',
    'PAYSON',
    'PEARL',
    'PEARL CITY',
    'PECATONICA',
    'PEKIN',
    'PEORIA',
    'PEORIA HEIGHTS',
    'PEOTONE',
    'PERCY',
    'PERRY',
    'PERU',
    'PESOTUM',
    'PETERSBURG',
    'PHILLIPSTOWN',
    'PHILO',
    'PHOENIX',
    'PIERRON',
    'PINCKNEYVILLE',
    'PINGREE GROVE',
    'PIPER CITY',
    'PISTAKEE HIGHLANDS',
    'PITTSBURG',
    'PITTSFIELD',
    'PLAINFIELD',
    'PLAINVILLE',
    'PLANO',
    'PLEASANT HILL',
    'PLEASANT PLAINS',
    'PLYMOUTH',
    'POCAHONTAS',
    'POLO',
    'PONTIAC',
    'PONTOON BEACH',
    'PONTOOSUC',
    'POPLAR GROVE',
    'PORT BYRON',
    'POSEN',
    'POTOMAC',
    'PRAIRIE CITY',
    'PRAIRIE DU ROCHER',
    'PRAIRIE GROVE',
    'PRESTON HEIGHTS',
    'PRINCETON',
    'PRINCEVILLE',
    'PROPHETSTOWN',
    'PROSPECT HEIGHTS',
    'PULASKI',
    'QUINCY',
    'RADOM',
    'RALEIGH',
    'RAMSEY',
    'RANKIN',
    'RANSOM',
    'RANTOUL',
    'RAPIDS CITY',
    'RARITAN',
    'RAYMOND',
    'RED BUD',
    'REDDICK',
    'REDMON',
    'REYNOLDS',
    'RICHMOND',
    'RICHTON PARK',
    'RICHVIEW',
    'RIDGE FARM',
    'RIDGWAY',
    'RIDOTT',
    'RINGWOOD',
    'RIO',
    'RIPLEY',
    'RIVERDALE',
    'RIVER FOREST',
    'RIVER GROVE',
    'RIVERSIDE',
    'RIVERTON',
    'RIVERWOODS',
    'ROANOKE',
    'ROBBINS',
    'ROBERTS',
    'ROBINSON',
    'ROCHELLE',
    'ROCHESTER',
    'ROCKBRIDGE',
    'ROCK CITY',
    'ROCKDALE',
    'ROCK FALLS',
    'ROCKFORD',
    'ROCK ISLAND',
    'ROCK ISLAND ARSENAL',
    'ROCKTON',
    'ROCKWOOD',
    'ROLLING MEADOWS',
    'ROME',
    'ROMEOVILLE',
    'ROODHOUSE',
    'ROSCOE',
    'ROSE HILL',
    'ROSELLE',
    'ROSEMONT',
    'ROSEVILLE',
    'ROSEWOOD HEIGHTS',
    'ROSICLARE',
    'ROSSVILLE',
    'ROUND LAKE',
    'ROUND LAKE BEACH',
    'ROUND LAKE HEIGHTS',
    'ROUND LAKE PARK',
    'ROXANA',
    'ROYAL',
    'ROYAL LAKES',
    'ROYALTON',
    'RUMA',
    'RUSHVILLE',
    'RUSSELLVILLE',
    'RUTLAND',
    'SADORUS',
    'SAILOR SPRINGS',
    'ST. ANNE',
    'ST. AUGUSTINE',
    'ST. CHARLES',
    'ST. DAVID',
    'ST. ELMO',
    'STE. MARIE',
    'ST. FRANCISVILLE',
    'ST. JACOB',
    'ST. JOHNS',
    'ST. JOSEPH',
    'ST. LIBORY',
    'ST. PETER',
    'SALEM',
    'SANDOVAL',
    'SANDWICH',
    'SAN JOSE',
    'SAUGET',
    'SAUK VILLAGE',
    'SAUNEMIN',
    'SAVANNA',
    'SAVOY',
    'SAWYERVILLE',
    'SAYBROOK',
    'SCALES MOUND',
    'SCHAUMBURG',
    'SCHILLER PARK',
    'SCHRAM CITY',
    'SCIOTA',
    'SCOTT AFB',
    'SCOTTVILLE',
    'SEATON',
    'SEATONVILLE',
    'SECOR',
    'SENECA',
    'SESSER',
    'SHABBONA',
    'SHANNON',
    'SHAWNEETOWN',
    'SHEFFIELD',
    'SHELBYVILLE',
    'SHELDON',
    'SHERIDAN',
    'SHERMAN',
    'SHERRARD',
    'SHILOH',
    'SHIPMAN',
    'SHOREWOOD',
    'SHUMWAY',
    'SIBLEY',
    'SIDELL',
    'SIDNEY',
    'SIGEL',
    'SILVIS',
    'SIMPSON',
    'SIMS',
    'SKOKIE',
    'SLEEPY HOLLOW',
    'SMITHBORO',
    'SMITHFIELD',
    'SMITHTON',
    'SOMONAUK',
    'SORENTO',
    'SOUTH BARRINGTON',
    'SOUTH BELOIT',
    'SOUTH CHICAGO HEIGHTS',
    'SOUTH ELGIN',
    'SOUTHERN VIEW',
    'SOUTH HOLLAND',
    'SOUTH JACKSONVILLE',
    'SOUTH PEKIN',
    'SOUTH ROXANA',
    'SOUTH WILMINGTON',
    'SPARLAND',
    'SPARTA',
    'SPAULDING',
    'SPILLERTOWN',
    'SPRING BAY',
    'SPRINGERTON',
    'SPRINGFIELD',
    'SPRING GROVE',
    'SPRING VALLEY',
    'STANDARD',
    'STANDARD CITY',
    'STANFORD',
    'STAUNTON',
    'STEELEVILLE',
    'STEGER',
    'STERLING',
    'STEWARD',
    'STEWARDSON',
    'STICKNEY',
    'STILLMAN VALLEY',
    'STOCKTON',
    'STONEFORT',
    'STONE PARK',
    'STONINGTON',
    'STOY',
    'STRASBURG',
    'STRAWN',
    'STREAMWOOD',
    'STREATOR',
    'STRONGHURST',
    'SUBLETTE',
    'SUGAR GROVE',
    'SULLIVAN',
    'SUMMERFIELD',
    'SUMMIT',
    'SUMNER',
    'SUN RIVER TERRACE',
    'SWANSEA',
    'SYCAMORE',
    'SYMERTON',
    'TABLE GROVE',
    'TALLULA',
    'TAMAROA',
    'TAMMS',
    'TAMPICO',
    'TAYLOR SPRINGS',
    'TAYLORVILLE',
    'TENNESSEE',
    'TEUTOPOLIS',
    'THAWVILLE',
    'THAYER',
    'THEBES',
    'THIRD LAKE',
    'THOMASBORO',
    'THOMPSONVILLE',
    'THOMSON',
    'THORNTON',
    'TILDEN',
    'TILTON',
    'TIMBERLANE',
    'TIME',
    'TINLEY PARK',
    'TISKILWA',
    'TOLEDO',
    'TOLONO',
    'TOLUCA',
    'TONICA',
    'TOPEKA',
    'TOULON',
    'TOVEY',
    'TOWANDA',
    'TOWER HILL',
    'TOWER LAKES',
    'TREMONT',
    'TRENTON',
    'TROUT VALLEY',
    'TROY',
    'TROY GROVE',
    'TUSCOLA',
    'ULLIN',
    'UNION',
    'UNION HILL',
    'UNIVERSITY PARK',
    'URBANA',
    'URSA',
    'VALIER',
    'VALLEY CITY',
    'VALMEYER',
    'VANDALIA',
    'VARNA',
    'VENEDY',
    'VENETIAN VILLAGE',
    'VENICE',
    'VERGENNES',
    'VERMILION',
    'VERMONT',
    'VERNON',
    'VERNON HILLS',
    'VERONA',
    'VERSAILLES',
    'VICTORIA',
    'VIENNA',
    'VILLA GROVE',
    'VILLA PARK',
    'VIOLA',
    'VIRDEN',
    'VIRGIL',
    'VIRGINIA',
    'VOLO',
    'WADSWORTH',
    'WAGGONER',
    'WALNUT',
    'WALNUT HILL',
    'WALSHVILLE',
    'WALTONVILLE',
    'WAMAC',
    'WAPELLA',
    'WARREN',
    'WARRENSBURG',
    'WARRENVILLE',
    'WARSAW',
    'WASHBURN',
    'WASHINGTON',
    'WASHINGTON PARK',
    'WATAGA',
    'WATERLOO',
    'WATERMAN',
    'WATSEKA',
    'WATSON',
    'WAUCONDA',
    'WAUKEGAN',
    'WAVERLY',
    'WAYNE',
    'WAYNE CITY',
    'WAYNESVILLE',
    'WELDON',
    'WELLINGTON',
    'WENONA',
    'WENONAH',
    'WEST BROOKLYN',
    'WESTCHESTER',
    'WEST CHICAGO',
    'WEST CITY',
    'WEST DUNDEE',
    'WESTERN SPRINGS',
    'WESTFIELD',
    'WEST FRANKFORT',
    'WESTMONT',
    'WEST PEORIA',
    'WEST POINT',
    'WEST SALEM',
    'WESTVILLE',
    'WHEATON',
    'WHEELER',
    'WHEELING',
    'WHITEASH',
    'WHITE CITY',
    'WHITE HALL',
    'WILLIAMSFIELD',
    'WILLIAMSON',
    'WILLIAMSVILLE',
    'WILLISVILLE',
    'WILLOWBROOK',
    'WILLOWBROOK',
    'WILLOW HILL',
    'WILLOW SPRINGS',
    'WILMETTE',
    'WILMINGTON',
    'WILMINGTON',
    'WILSONVILLE',
    'WINCHESTER',
    'WINDSOR',
    'WINDSOR',
    'WINFIELD',
    'WINNEBAGO',
    'WINNETKA',
    'WINSLOW',
    'WINTHROP HARBOR',
    'WITT',
    'WONDER LAKE',
    'WONDER LAKE',
    'WOOD DALE',
    'WOODHULL',
    'WOODLAND',
    'WOODLAWN',
    'WOODRIDGE',
    'WOOD RIVER',
    'WOODSON',
    'WOODSTOCK',
    'WORDEN',
    'WORTH',
    'WYANET',
    'WYOMING',
    'XENIA',
    'YALE',
    'YATES CITY',
    'YORKVILLE',
    'ZEIGLER',
    'ZION',
  ],
  Indiana: [
    'ADVANCE',
    'AKRON',
    'ALAMO',
    'ALBANY',
    'ALBION',
    'ALEXANDRIA',
    'ALFORDSVILLE',
    'ALTON',
    'ALTONA',
    'AMBIA',
    'AMBOY',
    'AMO',
    'ANDERSON',
    'ANDREWS',
    'ANGOLA',
    'ARCADIA',
    'ARGOS',
    'ASHLEY',
    'ATLANTA',
    'ATTICA',
    'AUBURN',
    'AURORA',
    'AUSTIN',
    'AVILLA',
    'AVON',
    'BAINBRIDGE',
    'BARGERSVILLE',
    'BASS LAKE',
    'BATESVILLE',
    'BATTLE GROUND',
    'BEDFORD',
    'BEECH GROVE',
    'BERNE',
    'BETHANY',
    'BEVERLY SHORES',
    'BICKNELL',
    'BIRDSEYE',
    'BLOOMFIELD',
    'BLOOMINGDALE',
    'BLOOMINGTON',
    'BLOUNTSVILLE',
    'BLUFFTON',
    'BOONVILLE',
    'BORDEN',
    'BOSTON',
    'BOSWELL',
    'BOURBON',
    'BRAZIL',
    'BREMEN',
    'BRIGHT',
    'BRISTOL',
    'BROOK',
    'BROOKLYN',
    'BROOKSBURG',
    'BROOKSTON',
    'BROOKVILLE',
    'BROWNSBURG',
    'BROWNSTOWN',
    'BRUCEVILLE',
    'BRYANT',
    'BUFFALO',
    'BUNKER HILL',
    'BURKET',
    'BURLINGTON',
    'BURNETTSVILLE',
    'BURNS HARBOR',
    'BUTLER',
    'CADIZ',
    'CAMBRIDGE CITY',
    'CAMDEN',
    'CAMPBELLSBURG',
    'CANNELBURG',
    'CANNELTON',
    'CARBON',
    'CARLISLE',
    'CARMEL',
    'CARTHAGE',
    'CAYUGA',
    'CEDAR GROVE',
    'CEDAR LAKE',
    'CENTER POINT',
    'CENTERVILLE',
    'CHALMERS',
    'CHANDLER',
    'CHARLESTOWN',
    'CHESTERFIELD',
    'CHESTERTON',
    'CHRISNEY',
    'CHURUBUSCO',
    'CICERO',
    'CLARKS HILL',
    'CLARKSVILLE',
    'CLAY CITY',
    'CLAYPOOL',
    'CLAYTON',
    'CLEAR LAKE',
    'CLERMONT',
    'CLIFFORD',
    'CLINTON',
    'CLOVERDALE',
    'COATESVILLE',
    'COLFAX',
    'COLLEGEVILLE',
    'COLUMBIA CITY',
    'COLUMBUS',
    'CONNERSVILLE',
    'CONVERSE',
    'CORUNNA',
    'CORYDON',
    'COUNTRY CLUB HEIGHTS',
    'COVINGTON',
    'CRANDALL',
    'CRANE',
    'CRAWFORDSVILLE',
    'CROMWELL',
    'CROTHERSVILLE',
    'CROWN POINT',
    'CROWS NEST',
    'CULVER',
    'CUMBERLAND',
    'CYNTHIANA',
    'DALE',
    'DALEVILLE',
    'DANA',
    'DANVILLE',
    'DARLINGTON',
    'DARMSTADT',
    'DAYTON',
    'DECATUR',
    'DECKER',
    'DELPHI',
    'DE MOTTE',
    'DENVER',
    'DILLSBORO',
    'DUBLIN',
    'DUGGER',
    'DUNE ACRES',
    'DUNKIRK',
    'DUNLAP',
    'DUNREITH',
    'DUPONT',
    'DYER',
    'EARL PARK',
    'EAST CHICAGO',
    'EAST GERMANTOWN',
    'EATON',
    'ECONOMY',
    'EDGEWOOD',
    'EDINBURGH',
    'EDWARDSPORT',
    'ELBERFELD',
    'ELIZABETH',
    'ELIZABETHTOWN',
    'ELKHART',
    'ELLETTSVILLE',
    'ELNORA',
    'ELWOOD',
    'ENGLISH',
    'ETNA GREEN',
    'EVANSVILLE',
    'FAIRLAND',
    'FAIRMOUNT',
    'FAIRVIEW PARK',
    'FARMERSBURG',
    'FARMLAND',
    'FERDINAND',
    'FILLMORE',
    'FISHERS',
    'FLORA',
    'FORT BRANCH',
    'FORTVILLE',
    'FORT WAYNE',
    'FOUNTAIN CITY',
    'FOWLER',
    'FOWLERTON',
    'FRANCESVILLE',
    'FRANCISCO',
    'FRANKFORT',
    'FRANKLIN',
    'FRANKTON',
    'FREDERICKSBURG',
    'FREMONT',
    'FRENCH LICK',
    'FULTON',
    'GALENA',
    'GALVESTON',
    'GARRETT',
    'GARY',
    'GAS CITY',
    'GASTON',
    'GENEVA',
    'GENTRYVILLE',
    'GEORGETOWN',
    'GEORGETOWN',
    'GLENWOOD',
    'GOODLAND',
    'GOSHEN',
    'GOSPORT',
    'GRABILL',
    'GRANDVIEW',
    'GRANGER',
    'GREENCASTLE',
    'GREENDALE',
    'GREENFIELD',
    'GREENSBORO',
    'GREENSBURG',
    'GREENS FORK',
    'GREENTOWN',
    'GREENVILLE',
    'GREENWOOD',
    'GRIFFIN',
    'GRIFFITH',
    'GRISSOM AFB',
    'GULIVOIRE PARK',
    'HAGERSTOWN',
    'HAMILTON',
    'HAMLET',
    'HAMMOND',
    'HANOVER',
    'HARDINSBURG',
    'HARMONY',
    'HARTFORD CITY',
    'HARTSVILLE',
    'HAUBSTADT',
    'HAZLETON',
    'HEBRON',
    'HENRYVILLE',
    'HIDDEN VALLEY',
    'HIGHLAND',
    'HIGHLAND',
    'HILLSBORO',
    'HOBART',
    'HOLLAND',
    'HOLTON',
    'HOMECROFT',
    'HOPE',
    'HUDSON',
    'HUNTERTOWN',
    'HUNTINGBURG',
    'HUNTINGTON',
    'HYMERA',
    'INDIANAPOLIS CITY',
    'INDIAN HEIGHTS',
    'INDIAN VILLAGE',
    'INGALLS',
    'JAMESTOWN',
    'JASONVILLE',
    'JASPER',
    'JEFFERSONVILLE',
    'JONESBORO',
    'JONESVILLE',
    'KEMPTON',
    'KENDALLVILLE',
    'KENNARD',
    'KENTLAND',
    'KEWANNA',
    'KINGMAN',
    'KINGSBURY',
    'KINGSFORD HEIGHTS',
    'KIRKLIN',
    'KNIGHTSTOWN',
    'KNIGHTSVILLE',
    'KNOX',
    'KOKOMO',
    'KOONTZ LAKE',
    'KOUTS',
    'LACONIA',
    'LA CROSSE',
    'LADOGA',
    'LAFAYETTE',
    'LA FONTAINE',
    'LAGRANGE',
    'LAGRO',
    'LAKE DALECARLIA',
    'LAKES OF THE FOUR SEASONS',
    'LAKE STATION',
    'LAKE VILLAGE',
    'LAKEVILLE',
    'LANESVILLE',
    'LA PAZ',
    'LAPEL',
    'LA PORTE',
    'LARWILL',
    'LAUREL',
    'LAWRENCE',
    'LAWRENCEBURG',
    'LEAVENWORTH',
    'LEBANON',
    'LEESBURG',
    'LEO-CEDARVILLE',
    'LEWISVILLE',
    'LIBERTY',
    'LIGONIER',
    'LINDEN',
    'LINTON',
    'LITTLE YORK',
    'LIVONIA',
    'LIZTON',
    'LOGANSPORT',
    'LONG BEACH',
    'LOOGOOTEE',
    'LOSANTVILLE',
    'LOWELL',
    'LYNN',
    'LYNNVILLE',
    'LYONS',
    'MCCORDSVILLE',
    'MACKEY',
    'MACY',
    'MADISON',
    'MARENGO',
    'MARION',
    'MARKLE',
    'MARKLEVILLE',
    'MARSHALL',
    'MARTINSVILLE',
    'MATTHEWS',
    'MAUCKPORT',
    'MECCA',
    'MEDARYVILLE',
    'MEDORA',
    'MELLOTT',
    'MELODY HILL',
    'MEMPHIS',
    'MENTONE',
    'MERIDIAN HILLS',
    'MEROM',
    'MERRILLVILLE',
    'MEXICO',
    'MICHIANA SHORES',
    'MICHIGAN CITY',
    'MICHIGANTOWN',
    'MIDDLEBURY',
    'MIDDLETOWN',
    'MILAN',
    'MILFORD',
    'MILFORD',
    'MILLERSBURG',
    'MILLHOUSEN',
    'MILLTOWN',
    'MILTON',
    'MISHAWAKA',
    'MITCHELL',
    'MODOC',
    'MONON',
    'MONROE',
    'MONROE CITY',
    'MONROEVILLE',
    'MONROVIA',
    'MONTEREY',
    'MONTEZUMA',
    'MONTGOMERY',
    'MONTICELLO',
    'MONTPELIER',
    'MOORELAND',
    'MOORES HILL',
    'MOORESVILLE',
    'MORGANTOWN',
    'MOROCCO',
    'MORRISTOWN',
    'MOUNT AUBURN',
    'MOUNT AYR',
    'MOUNT CARMEL',
    'MOUNT ETNA',
    'MOUNT SUMMIT',
    'MOUNT VERNON',
    'MULBERRY',
    'MUNCIE',
    'MUNSTER',
    'NAPOLEON',
    'NAPPANEE',
    'NASHVILLE',
    'NEW ALBANY',
    'NEW AMSTERDAM',
    'NEWBERRY',
    'NEWBURGH',
    'NEW CARLISLE',
    'NEW CASTLE',
    'NEW CHICAGO',
    'NEW HARMONY',
    'NEW HAVEN',
    'NEW MARKET',
    'NEW MIDDLETOWN',
    'NEW PALESTINE',
    'NEW PARIS',
    'NEW PEKIN',
    'NEWPOINT',
    'NEWPORT',
    'NEW RICHMOND',
    'NEW ROSS',
    'NEWTOWN',
    'NEW WASHINGTON',
    'NEW WHITELAND',
    'NOBLESVILLE',
    'NORTH CROWS NEST',
    'NORTH JUDSON',
    'NORTH LIBERTY',
    'NORTH MANCHESTER',
    'NORTH SALEM',
    'NORTH TERRE HAUTE',
    'NORTH VERNON',
    'NORTH WEBSTER',
    'NORWAY',
    'OAKLAND CITY',
    'OAK PARK',
    'OAKTOWN',
    'ODON',
    'OGDEN DUNES',
    'OLDENBURG',
    'ONWARD',
    'OOLITIC',
    'ORESTES',
    'ORLAND',
    'ORLEANS',
    'OSCEOLA',
    'OSGOOD',
    'OSSIAN',
    'OTTERBEIN',
    'OWENSVILLE',
    'OXFORD',
    'PALMYRA',
    'PAOLI',
    'PARAGON',
    'PARKER CITY',
    'PATOKA',
    'PATRIOT',
    'PENDLETON',
    'PENNVILLE',
    'PERRYSVILLE',
    'PERU',
    'PETERSBURG',
    'PIERCETON',
    'PINE VILLAGE',
    'PITTSBORO',
    'PLAINFIELD',
    'PLAINVILLE',
    'PLYMOUTH',
    'PONETO',
    'PORTAGE',
    'PORTER',
    'PORTLAND',
    'POSEYVILLE',
    'POTTAWATTAMIE PARK',
    'PRINCES LAKES',
    'PRINCETON',
    'REDKEY',
    'REMINGTON',
    'RENSSELAER',
    'REYNOLDS',
    'RICHMOND',
    'RIDGEVILLE',
    'RILEY',
    'RISING SUN',
    'RIVER FOREST',
    'ROACHDALE',
    'ROANN',
    'ROANOKE',
    'ROCHESTER',
    'ROCKPORT',
    'ROCKVILLE',
    'ROCKY RIPPLE',
    'ROME CITY',
    'ROSEDALE',
    'ROSELAND',
    'ROSELAWN',
    'ROSSVILLE',
    'ROYAL CENTER',
    'RUSHVILLE',
    'RUSSELLVILLE',
    'RUSSIAVILLE',
    'ST. JOE',
    'ST. JOHN',
    'ST. LEON',
    'ST. PAUL',
    'SALAMONIA',
    'SALEM',
    'SALTILLO',
    'SANDBORN',
    'SAN PIERRE',
    'SANTA CLAUS',
    'SARATOGA',
    'SCHERERVILLE',
    'SCHNEIDER',
    'SCOTTSBURG',
    'SEELYVILLE',
    'SELLERSBURG',
    'SELMA',
    'SEYMOUR',
    'SHADELAND',
    'SHAMROCK LAKES',
    'SHARPSVILLE',
    'SHELBURN',
    'SHELBYVILLE',
    'SHERIDAN',
    'SHIPSHEWANA',
    'SHIRLEY',
    'SHOALS',
    'SIDNEY',
    'SILVER LAKE',
    'SIMONTON LAKE',
    'SOMERVILLE',
    'SOUTH BEND',
    'SOUTH HAVEN',
    'SOUTHPORT',
    'SOUTH WHITLEY',
    'SPEEDWAY',
    'SPENCER',
    'SPICELAND',
    'SPRING GROVE',
    'SPRING HILL',
    'SPRING LAKE',
    'SPRINGPORT',
    'SPURGEON',
    'STAR CITY',
    'STATE LINE CITY',
    'STAUNTON',
    'STILESVILLE',
    'STINESVILLE',
    'STRAUGHN',
    'SULLIVAN',
    'SULPHUR SPRINGS',
    'SUMMITVILLE',
    'SUNMAN',
    'SWAYZEE',
    'SWEETSER',
    'SWITZ CITY',
    'SYRACUSE',
    'TAYLORSVILLE',
    'TELL CITY',
    'TENNYSON',
    'TERRE HAUTE',
    'THORNTOWN',
    'TIPTON',
    'TOPEKA',
    'TOWN OF PINES',
    'TRAFALGAR',
    'TRAIL CREEK',
    'TRI-LAKES',
    'TROY',
    'ULEN',
    'UNION CITY',
    'UNIONDALE',
    'UNIVERSAL',
    'UPLAND',
    'UTICA',
    'VALPARAISO',
    'VAN BUREN',
    'VEEDERSBURG',
    'VERA CRUZ',
    'VERNON',
    'VERSAILLES',
    'VEVAY',
    'VINCENNES',
    'WABASH',
    'WAKARUSA',
    'WALKERTON',
    'WALLACE',
    'WALTON',
    'WANATAH',
    'WARREN',
    'WARREN PARK',
    'WARSAW',
    'WASHINGTON',
    'WATERLOO',
    'WAVELAND',
    'WAYNETOWN',
    'WEST BADEN SPRINGS',
    'WEST COLLEGE CORNER',
    'WESTFIELD',
    'WEST HARRISON',
    'WEST LAFAYETTE',
    'WEST LEBANON',
    'WESTPORT',
    'WEST TERRE HAUTE',
    'WESTVILLE',
    'WHEATFIELD',
    'WHEATLAND',
    'WHITELAND',
    'WHITESTOWN',
    'WHITEWATER',
    'WHITING',
    'WILKINSON',
    'WILLIAMS CREEK',
    'WILLIAMSPORT',
    'WINAMAC',
    'WINCHESTER',
    'WINDFALL CITY',
    'WINFIELD',
    'WINGATE',
    'WINONA LAKE',
    'WINSLOW',
    'WOLCOTT',
    'WOLCOTTVILLE',
    'WOODBURN',
    'WOODLAWN HEIGHTS',
    'WORTHINGTON',
    'WYNNEDALE',
    'YEOMAN',
    'YORKTOWN',
    'ZANESVILLE',
    'ZIONSVILLE',
  ],
  Iowa: [
    'ACKLEY',
    'ACKWORTH',
    'ADAIR',
    'ADEL',
    'AFTON',
    'AGENCY',
    'AINSWORTH',
    'AKRON',
    'ALBERT CITY',
    'ALBIA',
    'ALBION',
    'ALBURNETT',
    'ALDEN',
    'ALEXANDER',
    'ALGONA',
    'ALLEMAN',
    'ALLERTON',
    'ALLISON',
    'ALTA',
    'ALTA VISTA',
    'ALTON',
    'ALTOONA',
    'ALVORD',
    'AMES',
    'ANAMOSA',
    'ANDOVER',
    'ANDREW',
    'ANITA',
    'ANKENY',
    'ANTHON',
    'APLINGTON',
    'ARCADIA',
    'ARCHER',
    'AREDALE',
    'ARION',
    'ARISPE',
    'ARLINGTON',
    'ARMSTRONG',
    'ARNOLDS PARK',
    'ARTHUR',
    'ASBURY',
    'ASHTON',
    'ASPINWALL',
    'ATALISSA',
    'ATHELSTAN',
    'ATKINS',
    'ATLANTIC',
    'AUBURN',
    'AUDUBON',
    'AURELIA',
    'AURORA',
    'AVOCA',
    'AYRSHIRE',
    'BADGER',
    'BAGLEY',
    'BALDWIN',
    'BALLTOWN',
    'BANCROFT',
    'BANKSTON',
    'BARNES CITY',
    'BARNUM',
    'BASSETT',
    'BATAVIA',
    'BATTLE CREEK',
    'BAXTER',
    'BAYARD',
    'BEACON',
    'BEACONSFIELD',
    'BEAMAN',
    'BEAVER',
    'BEDFORD',
    'BELLE PLAINE',
    'BELLEVUE',
    'BELMOND',
    'BENNETT',
    'BENTON',
    'BERKLEY',
    'BERNARD',
    'BERTRAM',
    'BETTENDORF',
    'BEVINGTON',
    'BIRMINGHAM',
    'BLAIRSBURG',
    'BLAIRSTOWN',
    'BLAKESBURG',
    'BLANCHARD',
    'BLENCOE',
    'BLOCKTON',
    'BLOOMFIELD',
    'BLUE GRASS',
    'BODE',
    'BONAPARTE',
    'BONDURANT',
    'BOONE',
    'BOUTON',
    'BOXHOLM',
    'BOYDEN',
    'BRADDYVILLE',
    'BRADGATE',
    'BRANDON',
    'BRAYTON',
    'BREDA',
    'BRIDGEWATER',
    'BRIGHTON',
    'BRISTOW',
    'BRITT',
    'BRONSON',
    'BROOKLYN',
    'BRUNSVILLE',
    'BUCKEYE',
    'BUCK GROVE',
    'BUFFALO',
    'BUFFALO CENTER',
    'BURLINGTON',
    'BURT',
    'BUSSEY',
    'CALAMUS',
    'CALLENDER',
    'CALMAR',
    'CALUMET',
    'CAMANCHE',
    'CAMBRIDGE',
    'CANTRIL',
    'CARBON',
    'CARLISLE',
    'CARPENTER',
    'CARROLL',
    'CARSON',
    'CARTER LAKE',
    'CASCADE',
    'CASEY',
    'CASTALIA',
    'CASTANA',
    'CEDAR FALLS',
    'CEDAR RAPIDS',
    'CENTER JUNCTION',
    'CENTER POINT',
    'CENTERVILLE',
    'CENTRAL CITY',
    'CENTRALIA',
    'CHARITON',
    'CHARLES CITY',
    'CHARLOTTE',
    'CHARTER OAK',
    'CHATSWORTH',
    'CHELSEA',
    'CHEROKEE',
    'CHESTER',
    'CHILLICOTHE',
    'CHURDAN',
    'CINCINNATI',
    'CLARE',
    'CLARENCE',
    'CLARINDA',
    'CLARION',
    'CLARKSVILLE',
    'CLAYTON',
    'CLEARFIELD',
    'CLEAR LAKE',
    'CLEGHORN',
    'CLEMONS',
    'CLERMONT',
    'CLINTON',
    'CLIO',
    'CLIVE',
    'CLUTIER',
    'COALVILLE',
    'COBURG',
    'COGGON',
    'COIN',
    'COLESBURG',
    'COLFAX',
    'COLLEGE SPRINGS',
    'COLLINS',
    'COLO',
    'COLUMBUS CITY',
    'COLUMBUS JUNCTION',
    'COLWELL',
    'CONESVILLE',
    'CONRAD',
    'CONWAY',
    'COON RAPIDS',
    'COPPOCK',
    'CORALVILLE',
    'CORNING',
    'CORRECTIONVILLE',
    'CORWITH',
    'CORYDON',
    'COTTER',
    'COULTER',
    'COUNCIL BLUFFS',
    'CRAIG',
    'CRAWFORDSVILLE',
    'CRESCENT',
    'CRESCO',
    'CRESTON',
    'CROMWELL',
    'CRYSTAL LAKE',
    'CUMBERLAND',
    'CUMMING',
    'CURLEW',
    'CUSHING',
    'CYLINDER',
    'DAKOTA CITY',
    'DALLAS CENTER',
    'DANA',
    'DANBURY',
    'DANVILLE',
    'DAVENPORT',
    'DAVIS CITY',
    'DAWSON',
    'DAYTON',
    'DECATUR CITY',
    'DECORAH',
    'DEDHAM',
    'DEEP RIVER',
    'DEFIANCE',
    'DELAWARE',
    'DELHI',
    'DELMAR',
    'DELOIT',
    'DELPHOS',
    'DELTA',
    'DENISON',
    'DENVER',
    'DERBY',
    'DES MOINES',
    'DE SOTO',
    'DE WITT',
    'DEXTER',
    'DIAGONAL',
    'DICKENS',
    'DIKE',
    'DIXON',
    'DOLLIVER',
    'DONAHUE',
    'DONNELLSON',
    'DOON',
    'DOUDS',
    'DOUGHERTY',
    'DOW CITY',
    'DOWS',
    'DRAKESVILLE',
    'DUBUQUE',
    'DUMONT',
    'DUNCOMBE',
    'DUNDEE',
    'DUNKERTON',
    'DUNLAP',
    'DURANGO',
    'DURANT',
    'DYERSVILLE',
    'DYSART',
    'EAGLE GROVE',
    'EARLHAM',
    'EARLING',
    'EARLVILLE',
    'EARLY',
    'EAST PERU',
    'EDDYVILLE',
    'EDGEWOOD',
    'ELBERON',
    'ELDON',
    'ELDORA',
    'ELDRIDGE',
    'ELGIN',
    'ELKADER',
    'ELKHART',
    'ELK HORN',
    'ELKPORT',
    'ELK RUN HEIGHTS',
    'ELLIOTT',
    'ELLSTON',
    'ELLSWORTH',
    'ELMA',
    'ELY',
    'EMERSON',
    'EMMETSBURG',
    'EPWORTH',
    'ESSEX',
    'ESTHERVILLE',
    'EVANSDALE',
    'EVERLY',
    'EXIRA',
    'EXLINE',
    'FAIRBANK',
    'FAIRFAX',
    'FAIRFIELD',
    'FARLEY',
    'FARMERSBURG',
    'FARMINGTON',
    'FARNHAMVILLE',
    'FARRAGUT',
    'FAYETTE',
    'FENTON',
    'FERGUSON',
    'FERTILE',
    'FLORIS',
    'FLOYD',
    'FONDA',
    'FONTANELLE',
    'FOREST CITY',
    'FORT ATKINSON',
    'FORT DODGE',
    'FORT MADISON',
    'FOSTORIA',
    'FRANKLIN',
    'FRASER',
    'FREDERICKSBURG',
    'FREDERIKA',
    'FREDONIA',
    'FREMONT',
    'FRUITLAND',
    'GALT',
    'GALVA',
    'GARBER',
    'GARDEN GROVE',
    'GARNAVILLO',
    'GARNER',
    'GARRISON',
    'GARWIN',
    'GENEVA',
    'GEORGE',
    'GIBSON',
    'GILBERT',
    'GILBERTVILLE',
    'GILLETT GROVE',
    'GILMAN',
    'GILMORE CITY',
    'GLADBROOK',
    'GLENWOOD',
    'GLIDDEN',
    'GOLDFIELD',
    'GOODELL',
    'GOOSE LAKE',
    'GOWRIE',
    'GRAETTINGER',
    'GRAF',
    'GRAFTON',
    'GRAND JUNCTION',
    'GRAND MOUND',
    'GRAND RIVER',
    'GRANDVIEW',
    'GRANGER',
    'GRANT',
    'GRANVILLE',
    'GRAVITY',
    'GRAY',
    'GREELEY',
    'GREENE',
    'GREENFIELD',
    'GREENVILLE',
    'GRIMES',
    'GRINNELL',
    'GRISWOLD',
    'GRUNDY CENTER',
    'GRUVER',
    'GUERNSEY',
    'GUTHRIE CENTER',
    'GUTTENBERG',
    'HALBUR',
    'HAMBURG',
    'HAMILTON',
    'HAMPTON',
    'HANCOCK',
    'HANLONTOWN',
    'HANSELL',
    'HARCOURT',
    'HARDY',
    'HARLAN',
    'HARPER',
    'HARPERS FERRY',
    'HARRIS',
    'HARTFORD',
    'HARTLEY',
    'HARTWICK',
    'HARVEY',
    'HASTINGS',
    'HAVELOCK',
    'HAVERHILL',
    'HAWARDEN',
    'HAWKEYE',
    'HAYESVILLE',
    'HAZLETON',
    'HEDRICK',
    'HENDERSON',
    'HEPBURN',
    'HIAWATHA',
    'HILLS',
    'HILLSBORO',
    'HINTON',
    'HOLLAND',
    'HOLSTEIN',
    'HOLY CROSS',
    'HOPKINTON',
    'HORNICK',
    'HOSPERS',
    'HOUGHTON',
    'HUBBARD',
    'HUDSON',
    'HULL',
    'HUMBOLDT',
    'HUMESTON',
    'HUXLEY',
    'IDA GROVE',
    'IMOGENE',
    'INDEPENDENCE',
    'INDIANOLA',
    'INWOOD',
    'IONIA',
    'IOWA CITY',
    'IOWA FALLS',
    'IRETON',
    'IRWIN',
    'JACKSON JUNCTION',
    'JAMAICA',
    'JANESVILLE',
    'JEFFERSON',
    'JESUP',
    'JEWELL JUNCTION',
    'JOHNSTON',
    'JOICE',
    'JOLLEY',
    'KALONA',
    'KAMRAR',
    'KANAWHA',
    'KELLERTON',
    'KELLEY',
    'KELLOGG',
    'KENSETT',
    'KENT',
    'KEOKUK',
    'KEOMAH VILLAGE',
    'KEOSAUQUA',
    'KEOTA',
    'KESWICK',
    'KEYSTONE',
    'KIMBALLTON',
    'KINGSLEY',
    'KINROSS',
    'KIRKMAN',
    'KIRKVILLE',
    'KIRON',
    'KLEMME',
    'KNIERIM',
    'KNOXVILLE',
    'LACONA',
    'LADORA',
    'LAKE CITY',
    'LAKE MILLS',
    'LAKE PARK',
    'LAKESIDE',
    'LAKE VIEW',
    'LAKOTA',
    'LAMBS GROVE',
    'LAMONI',
    'LAMONT',
    'LA MOTTE',
    'LANESBORO',
    'LANSING',
    'LA PORTE CITY',
    'LARCHWOOD',
    'LARRABEE',
    'LATIMER',
    'LAUREL',
    'LAURENS',
    'LAWLER',
    'LAWTON',
    'LEANDO',
    'LE CLAIRE',
    'LEDYARD',
    'LE GRAND',
    'LEHIGH',
    'LEIGHTON',
    'LELAND',
    'LE MARS',
    'LENOX',
    'LEON',
    'LE ROY',
    'LESTER',
    'LETTS',
    'LEWIS',
    'LIBERTYVILLE',
    'LIDDERDALE',
    'LIME SPRINGS',
    'LINCOLN',
    'LINDEN',
    'LINEVILLE',
    'LINN GROVE',
    'LISBON',
    'LISCOMB',
    'LITTLEPORT',
    'LITTLE ROCK',
    'LITTLE SIOUX',
    'LIVERMORE',
    'LOCKRIDGE',
    'LOGAN',
    'LOHRVILLE',
    'LONE ROCK',
    'LONE TREE',
    'LONG GROVE',
    'LORIMOR',
    'LOST NATION',
    'LOVILIA',
    'LOWDEN',
    'LOW MOOR',
    'LUANA',
    'LUCAS',
    'LUTHER',
    'LU VERNE',
    'LUXEMBURG',
    'LUZERNE',
    'LYNNVILLE',
    'LYTTON',
    'MCCALLSBURG',
    'MCCAUSLAND',
    'MCCLELLAND',
    'MACEDONIA',
    'MCGREGOR',
    'MCINTIRE',
    'MACKSBURG',
    'MADRID',
    'MAGNOLIA',
    'MALCOM',
    'MALLARD',
    'MALOY',
    'MALVERN',
    'MANCHESTER',
    'MANILLA',
    'MANLY',
    'MANNING',
    'MANSON',
    'MAPLETON',
    'MAQUOKETA',
    'MARATHON',
    'MARBLE ROCK',
    'MARCUS',
    'MARENGO',
    'MARION',
    'MARNE',
    'MARQUETTE',
    'MARSHALLTOWN',
    'MARTELLE',
    'MARTENSDALE',
    'MARTINSBURG',
    'MARYSVILLE',
    'MASON CITY',
    'MASONVILLE',
    'MASSENA',
    'MATLOCK',
    'MAURICE',
    'MAXWELL',
    'MAYNARD',
    'MAYSVILLE',
    'MECHANICSVILLE',
    'MEDIAPOLIS',
    'MELBOURNE',
    'MELCHER-DALLAS',
    'MELROSE',
    'MELVIN',
    'MENLO',
    'MERIDEN',
    'MERRILL',
    'MESERVEY',
    'MIDDLETOWN',
    'MILES',
    'MILFORD',
    'MILLERSBURG',
    'MILLERTON',
    'MILLVILLE',
    'MILO',
    'MILTON',
    'MINBURN',
    'MINDEN',
    'MINGO',
    'MISSOURI VALLEY',
    'MITCHELL',
    'MITCHELLVILLE',
    'MODALE',
    'MONDAMIN',
    'MONMOUTH',
    'MONONA',
    'MONROE',
    'MONTEZUMA',
    'MONTICELLO',
    'MONTOUR',
    'MONTROSE',
    'MOORHEAD',
    'MOORLAND',
    'MORAVIA',
    'MORLEY',
    'MORNING SUN',
    'MORRISON',
    'MOULTON',
    'MOUNT AUBURN',
    'MOUNT AYR',
    'MOUNT PLEASANT',
    'MOUNT STERLING',
    'MOUNT UNION',
    'MOUNT VERNON',
    'MOVILLE',
    'MURRAY',
    'MUSCATINE',
    'MYSTIC',
    'NASHUA',
    'NEMAHA',
    'NEOLA',
    'NEVADA',
    'NEW ALBIN',
    'NEWELL',
    'NEWHALL',
    'NEW HAMPTON',
    'NEW HARTFORD',
    'NEW LIBERTY',
    'NEW LONDON',
    'NEW MARKET',
    'NEW PROVIDENCE',
    'NEW SHARON',
    'NEWTON',
    'NEW VIENNA',
    'NEW VIRGINIA',
    'NICHOLS',
    'NODAWAY',
    'NORA SPRINGS',
    'NORTHBORO',
    'NORTH BUENA VISTA',
    'NORTH ENGLISH',
    'NORTH LIBERTY',
    'NORTH WASHINGTON',
    'NORTHWOOD',
    'NORWALK',
    'NORWAY',
    'NUMA',
    'OAKLAND',
    'OAKLAND ACRES',
    'OAKVILLE',
    'OCHEYEDAN',
    'ODEBOLT',
    'OELWEIN',
    'OGDEN',
    'OKOBOJI',
    'OLDS',
    'OLIN',
    'OLLIE',
    'ONAWA',
    'ONSLOW',
    'ORANGE CITY',
    'ORCHARD',
    'ORIENT',
    'ORLEANS',
    'OSAGE',
    'OSCEOLA',
    'OSKALOOSA',
    'OSSIAN',
    'OSTERDOCK',
    'OTHO',
    'OTO',
    'OTTOSEN',
    'OTTUMWA',
    'OWASA',
    'OXFORD',
    'OXFORD JUNCTION',
    'OYENS',
    'PACIFIC JUNCTION',
    'PACKWOOD',
    'PALMER',
    'PALO',
    'PANAMA',
    'PANORA',
    'PANORAMA PARK',
    'PARKERSBURG',
    'PARK VIEW',
    'PARNELL',
    'PATON',
    'PATTERSON',
    'PAULLINA',
    'PELLA',
    'PEOSTA',
    'PERRY',
    'PERSIA',
    'PETERSON',
    'PIERSON',
    'PILOT MOUND',
    'PIONEER',
    'PISGAH',
    'PLAINFIELD',
    'PLANO',
    'PLEASANT HILL',
    'PLEASANTON',
    'PLEASANT PLAIN',
    'PLEASANTVILLE',
    'PLOVER',
    'PLYMOUTH',
    'POCAHONTAS',
    'POLK CITY',
    'POMEROY',
    'POPEJOY',
    'PORTSMOUTH',
    'POSTVILLE',
    'PRAIRIEBURG',
    'PRAIRIE CITY',
    'PRESCOTT',
    'PRESTON',
    'PRIMGHAR',
    'PRINCETON',
    'PROMISE CITY',
    'PROTIVIN',
    'PULASKI',
    'QUASQUETON',
    'QUIMBY',
    'RADCLIFFE',
    'RAKE',
    'RALSTON',
    'RANDALIA',
    'RANDALL',
    'RANDOLPH',
    'RATHBUN',
    'RAYMOND',
    'READLYN',
    'REASNOR',
    'REDDING',
    'REDFIELD',
    'RED OAK',
    'REINBECK',
    'REMBRANDT',
    'REMSEN',
    'RENWICK',
    'RHODES',
    'RICEVILLE',
    'RICHLAND',
    'RICKARDSVILLE',
    'RICKETTS',
    'RIDGEWAY',
    'RINARD',
    'RINGSTED',
    'RIPPEY',
    'RIVERDALE',
    'RIVERSIDE',
    'RIVERTON',
    'ROBINS',
    'ROCK FALLS',
    'ROCKFORD',
    'ROCK RAPIDS',
    'ROCK VALLEY',
    'ROCKWELL',
    'ROCKWELL CITY',
    'RODMAN',
    'RODNEY',
    'ROLAND',
    'ROLFE',
    'ROME',
    'ROSE HILL',
    'ROSSIE',
    'ROWAN',
    'ROWLEY',
    'ROYAL',
    'RUDD',
    'RUNNELLS',
    'RUSSELL',
    'RUTHVEN',
    'RUTLAND',
    'RYAN',
    'SABULA',
    'SAC CITY',
    'SAGEVILLE',
    'ST. ANSGAR',
    'ST. ANTHONY',
    'ST. CHARLES',
    'ST. DONATUS',
    'ST. LUCAS',
    'ST. MARYS',
    'ST. OLAF',
    'ST. PAUL',
    'SALEM',
    'SALIX',
    'SANBORN',
    'SANDYVILLE',
    'SAYLORVILLE',
    'SCARVILLE',
    'SCHALLER',
    'SCHLESWIG',
    'SCRANTON',
    'SEARSBORO',
    'SERGEANT BLUFF',
    'SEYMOUR',
    'SHAMBAUGH',
    'SHANNON CITY',
    'SHARPSBURG',
    'SHEFFIELD',
    'SHELBY',
    'SHELDAHL',
    'SHELDON',
    'SHELL ROCK',
    'SHELLSBURG',
    'SHENANDOAH',
    'SHERRILL',
    'SHUEYVILLE',
    'SIBLEY',
    'SIDNEY',
    'SIGOURNEY',
    'SILVER CITY',
    'SIOUX CENTER',
    'SIOUX CITY',
    'SIOUX RAPIDS',
    'SLATER',
    'SLOAN',
    'SMITHLAND',
    'SOLDIER',
    'SOLON',
    'SOMERS',
    'SOUTH ENGLISH',
    'SPENCER',
    'SPILLVILLE',
    'SPIRIT LAKE',
    'SPRAGUEVILLE',
    'SPRINGBROOK',
    'SPRING HILL',
    'SPRINGVILLE',
    'STACYVILLE',
    'STANHOPE',
    'STANLEY',
    'STANTON',
    'STANWOOD',
    'STATE CENTER',
    'STEAMBOAT ROCK',
    'STOCKPORT',
    'STOCKTON',
    'STORM LAKE',
    'STORY CITY',
    'STOUT',
    'STRATFORD',
    'STRAWBERRY POINT',
    'STRUBLE',
    'STUART',
    'SULLY',
    'SUMNER',
    'SUPERIOR',
    'SUTHERLAND',
    'SWALEDALE',
    'SWAN',
    'SWEA CITY',
    'SWISHER',
    'TABOR',
    'TAMA',
    'TEMPLETON',
    'TENNANT',
    'TERRIL',
    'THAYER',
    'THOMPSON',
    'THOR',
    'THORNBURG',
    'THORNTON',
    'THURMAN',
    'TIFFIN',
    'TINGLEY',
    'TIPTON',
    'TITONKA',
    'TOLEDO',
    'TORONTO',
    'TRAER',
    'TREYNOR',
    'TRIPOLI',
    'TRUESDALE',
    'TRURO',
    'TURIN',
    'UDELL',
    'UNDERWOOD',
    'UNION',
    'UNIONVILLE',
    'UNIVERSITY HEIGHTS',
    'UNIVERSITY PARK',
    'URBANA',
    'URBANDALE',
    'UTE',
    'VAIL',
    'VALERIA',
    'VAN HORNE',
    'VAN METER',
    'VAN WERT',
    'VARINA',
    'VENTURA',
    'VICTOR',
    'VILLISCA',
    'VINCENT',
    'VINING',
    'VINTON',
    'VOLGA',
    'WADENA',
    'WAHPETON',
    'WALCOTT',
    'WALFORD',
    'WALKER',
    'WALLINGFORD',
    'WALL LAKE',
    'WALNUT',
    'WAPELLO',
    'WASHINGTON',
    'WASHTA',
    'WATERLOO',
    'WATERVILLE',
    'WAUCOMA',
    'WAUKEE',
    'WAUKON',
    'WAVERLY',
    'WAYLAND',
    'WEBB',
    'WEBSTER',
    'WEBSTER CITY',
    'WELDON',
    'WELLMAN',
    'WELLSBURG',
    'WELTON',
    'WESLEY',
    'WEST BEND',
    'WEST BRANCH',
    'WEST BURLINGTON',
    'WEST CHESTER',
    'WEST DES MOINES',
    'WESTFIELD',
    'WESTGATE',
    'WEST LIBERTY',
    'WEST OKOBOJI',
    'WESTPHALIA',
    'WEST POINT',
    'WESTSIDE',
    'WEST UNION',
    'WESTWOOD',
    'WHAT CHEER',
    'WHEATLAND',
    'WHITING',
    'WHITTEMORE',
    'WHITTEN',
    'WILLEY',
    'WILLIAMS',
    'WILLIAMSBURG',
    'WILLIAMSON',
    'WILTON',
    'WINDSOR HEIGHTS',
    'WINFIELD',
    'WINTERSET',
    'WINTHROP',
    'WIOTA',
    'WODEN',
    'WOODBINE',
    'WOODBURN',
    'WOODWARD',
    'WOOLSTOCK',
    'WORTHINGTON',
    'WYOMING',
    'YALE',
    'YETTER',
    'YORKTOWN',
    'ZEARING',
    'ZWINGLE',
  ],
  Kansas: [
    'ABBYVILLE',
    'ABILENE',
    'ADMIRE',
    'AGENDA',
    'AGRA',
    'ALBERT',
    'ALDEN',
    'ALEXANDER',
    'ALLEN',
    'ALMA',
    'ALMENA',
    'ALTAMONT',
    'ALTA VISTA',
    'ALTON',
    'ALTOONA',
    'AMERICUS',
    'ANDALE',
    'ANDOVER',
    'ANTHONY',
    'ARCADIA',
    'ARGONIA',
    'ARKANSAS CITY',
    'ARLINGTON',
    'ARMA',
    'ASHLAND',
    'ASSARIA',
    'ATCHISON',
    'ATHOL',
    'ATLANTA',
    'ATTICA',
    'ATWOOD',
    'AUBURN',
    'AUGUSTA',
    'AURORA',
    'AXTELL',
    'BALDWIN CITY',
    'BARNARD',
    'BARNES',
    'BARTLETT',
    'BASEHOR',
    'BASSETT',
    'BAXTER SPRINGS',
    'BAZINE',
    'BEATTIE',
    'BEL AIRE',
    'BELLE PLAINE',
    'BELLEVILLE',
    'BELOIT',
    'BELPRE',
    'BELVUE',
    'BENEDICT',
    'BENNINGTON',
    'BENTLEY',
    'BENTON',
    'BERN',
    'BEVERLY',
    'BIRD CITY',
    'BISON',
    'BLUE MOUND',
    'BLUE RAPIDS',
    'BLUFF CITY',
    'BOGUE',
    'BONNER SPRINGS',
    'BREWSTER',
    'BRONSON',
    'BROOKVILLE',
    'BROWNELL',
    'BUCKLIN',
    'BUFFALO',
    'BUHLER',
    'BUNKER HILL',
    'BURDEN',
    'BURDETT',
    'BURLINGAME',
    'BURLINGTON',
    'BURNS',
    'BURR OAK',
    'BURRTON',
    'BUSHONG',
    'BUSHTON',
    'BYERS',
    'CALDWELL',
    'CAMBRIDGE',
    'CANEY',
    'CANTON',
    'CARBONDALE',
    'CARLTON',
    'CASSODAY',
    'CAWKER CITY',
    'CEDAR',
    'CEDAR POINT',
    'CEDAR VALE',
    'CENTRALIA',
    'CHANUTE',
    'CHAPMAN',
    'CHASE',
    'CHAUTAUQUA',
    'CHENEY',
    'CHEROKEE',
    'CHERRYVALE',
    'CHETOPA',
    'CIMARRON',
    'CIRCLEVILLE',
    'CLAFLIN',
    'CLAY CENTER',
    'CLAYTON',
    'CLEARWATER',
    'CLIFTON',
    'CLIMAX',
    'CLYDE',
    'COATS',
    'COFFEYVILLE',
    'COLBY',
    'COLDWATER',
    'COLLYER',
    'COLONY',
    'COLUMBUS',
    'COLWICH',
    'CONCORDIA',
    'CONWAY SPRINGS',
    'COOLIDGE',
    'COPELAND',
    'CORNING',
    'COTTONWOOD FALLS',
    'COUNCIL GROVE',
    'COUNTRYSIDE',
    'COURTLAND',
    'COYVILLE',
    'CUBA',
    'CULLISON',
    'CULVER',
    'CUNNINGHAM',
    'DAMAR',
    'DANVILLE',
    'DEARING',
    'DEERFIELD',
    'DELIA',
    'DELPHOS',
    'DENISON',
    'DENTON',
    'DERBY',
    'DE SOTO',
    'DEXTER',
    'DIGHTON',
    'DODGE CITY',
    'DORRANCE',
    'DOUGLASS',
    'DOWNS',
    'DRESDEN',
    'DUNLAP',
    'DURHAM',
    'DWIGHT',
    'EARLTON',
    'EASTBOROUGH',
    'EASTON',
    'EDGERTON',
    'EDMOND',
    'EDNA',
    'EDWARDSVILLE',
    'EFFINGHAM',
    'ELBING',
    'EL DORADO',
    'ELGIN',
    'ELK CITY',
    'ELK FALLS',
    'ELKHART',
    'ELLINWOOD',
    'ELLIS',
    'ELLSWORTH',
    'ELMDALE',
    'ELSMORE',
    'ELWOOD',
    'EMMETT',
    'EMPORIA',
    'ENGLEWOOD',
    'ENSIGN',
    'ENTERPRISE',
    'ERIE',
    'ESBON',
    'ESKRIDGE',
    'EUDORA',
    'EUREKA',
    'EVEREST',
    'FAIRVIEW',
    'FAIRWAY',
    'FALL RIVER',
    'FLORENCE',
    'FONTANA',
    'FORD',
    'FORMOSO',
    'FORT RILEY-CAMP WHITESIDE',
    'FORT RILEY NORTH',
    'FORT SCOTT',
    'FOWLER',
    'FRANKFORT',
    'FREDERICK',
    'FREDONIA',
    'FREEPORT',
    'FRONTENAC',
    'FULTON',
    'GALATIA',
    'GALENA',
    'GALESBURG',
    'GALVA',
    'GARDEN CITY',
    'GARDEN PLAIN',
    'GARDNER',
    'GARFIELD',
    'GARNETT',
    'GAS',
    'GAYLORD',
    'GEM',
    'GENESEO',
    'GEUDA SPRINGS',
    'GIRARD',
    'GLADE',
    'GLASCO',
    'GLEN ELDER',
    'GODDARD',
    'GOESSEL',
    'GOFF',
    'GOODLAND',
    'GORHAM',
    'GOVE CITY',
    'GRAINFIELD',
    'GRANDVIEW PLAZA',
    'GREAT BEND',
    'GREELEY',
    'GREEN',
    'GREENLEAF',
    'GREENSBURG',
    'GRENOLA',
    'GRIDLEY',
    'GRINNELL',
    'GYPSUM',
    'HADDAM',
    'HALSTEAD',
    'HAMILTON',
    'HAMLIN',
    'HANOVER',
    'HANSTON',
    'HARDTNER',
    'HARPER',
    'HARRIS',
    'HARTFORD',
    'HARVEYVILLE',
    'HAVANA',
    'HAVEN',
    'HAVENSVILLE',
    'HAVILAND',
    'HAYS',
    'HAYSVILLE',
    'HAZELTON',
    'HEPLER',
    'HERINGTON',
    'HERNDON',
    'HESSTON',
    'HIAWATHA',
    'HIGHLAND',
    'HILL CITY',
    'HILLSBORO',
    'HOISINGTON',
    'HOLCOMB',
    'HOLLENBERG',
    'HOLTON',
    'HOLYROOD',
    'HOPE',
    'HORACE',
    'HORTON',
    'HOWARD',
    'HOXIE',
    'HOYT',
    'HUDSON',
    'HUGOTON',
    'HUMBOLDT',
    'HUNNEWELL',
    'HUNTER',
    'HURON',
    'HUTCHINSON',
    'INDEPENDENCE',
    'INGALLS',
    'INMAN',
    'IOLA',
    'ISABEL',
    'IUKA',
    'JAMESTOWN',
    'JENNINGS',
    'JETMORE',
    'JEWELL',
    'JOHNSON CITY',
    'JUNCTION CITY',
    'KANOPOLIS',
    'KANORADO',
    'KANSAS CITY',
    'KECHI',
    'KENSINGTON',
    'KINCAID',
    'KINGMAN',
    'KINSLEY',
    'KIOWA',
    'KIRWIN',
    'KISMET',
    'LABETTE',
    'LA CROSSE',
    'LA CYGNE',
    'LA HARPE',
    'LAKE QUIVIRA',
    'LAKIN',
    'LANCASTER',
    'LANE',
    'LANGDON',
    'LANSING',
    'LARNED',
    'LATHAM',
    'LATIMER',
    'LAWRENCE',
    'LEAVENWORTH',
    'LEAWOOD',
    'LEBANON',
    'LEBO',
    'LECOMPTON',
    'LEHIGH',
    'LENEXA',
    'LENORA',
    'LEON',
    'LEONA',
    'LEONARDVILLE',
    'LEOTI',
    'LE ROY',
    'LEWIS',
    'LIBERAL',
    'LIBERTY',
    'LIEBENTHAL',
    'LINCOLN CENTER',
    'LINCOLNVILLE',
    'LINDSBORG',
    'LINN',
    'LINN VALLEY',
    'LINWOOD',
    'LITTLE RIVER',
    'LOGAN',
    'LONE ELM',
    'LONGFORD',
    'LONG ISLAND',
    'LONGTON',
    'LORRAINE',
    'LOST SPRINGS',
    'LOUISBURG',
    'LOUISVILLE',
    'LUCAS',
    'LURAY',
    'LYNDON',
    'LYONS',
    'MCCRACKEN',
    'MCCUNE',
    'MCDONALD',
    'MCFARLAND',
    'MACKSVILLE',
    'MCLOUTH',
    'MCPHERSON',
    'MADISON',
    'MAHASKA',
    'MAIZE',
    'MANCHESTER',
    'MANHATTAN',
    'MANKATO',
    'MANTER',
    'MAPLE HILL',
    'MAPLETON',
    'MARION',
    'MARQUETTE',
    'MARYSVILLE',
    'MATFIELD GREEN',
    'MAYETTA',
    'MAYFIELD',
    'MEADE',
    'MEDICINE LODGE',
    'MELVERN',
    'MENLO',
    'MERIDEN',
    'MERRIAM',
    'MILAN',
    'MILDRED',
    'MILFORD',
    'MILTONVALE',
    'MINNEAPOLIS',
    'MINNEOLA',
    'MISSION',
    'MISSION HILLS',
    'MISSION WOODS',
    'MOLINE',
    'MONTEZUMA',
    'MORAN',
    'MORGANVILLE',
    'MORLAND',
    'MORRILL',
    'MORROWVILLE',
    'MOSCOW',
    'MOUND CITY',
    'MOUNDRIDGE',
    'MOUND VALLEY',
    'MOUNT HOPE',
    'MULBERRY',
    'MULLINVILLE',
    'MULVANE',
    'MUNDEN',
    'MUSCOTAH',
    'NARKA',
    'NASHVILLE',
    'NATOMA',
    'NEODESHA',
    'NEOSHO FALLS',
    'NEOSHO RAPIDS',
    'NESS CITY',
    'NETAWAKA',
    'NEW ALBANY',
    'NEW CAMBRIA',
    'NEW STRAWN',
    'NEWTON',
    'NICKERSON',
    'NIOTAZE',
    'NORCATUR',
    'NORTH NEWTON',
    'NORTON',
    'NORTONVILLE',
    'NORWICH',
    'OAK HILL',
    'OAKLAWN-SUNVIEW',
    'OAKLEY',
    'OBERLIN',
    'OFFERLE',
    'OGDEN',
    'OKETO',
    'OLATHE',
    'OLIVET',
    'OLMITZ',
    'OLPE',
    'OLSBURG',
    'ONAGA',
    'ONEIDA',
    'OSAGE CITY',
    'OSAWATOMIE',
    'OSBORNE',
    'OSKALOOSA',
    'OSWEGO',
    'OTIS',
    'OTTAWA',
    'OVERBROOK',
    'OVERLAND PARK',
    'OXFORD',
    'OZAWKIE',
    'PALCO',
    'PALMER',
    'PAOLA',
    'PARADISE',
    'PARK',
    'PARK CITY',
    'PARKER',
    'PARKERVILLE',
    'PARSONS',
    'PARTRIDGE',
    'PAWNEE ROCK',
    'PAXICO',
    'PEABODY',
    'PENALOSA',
    'PERRY',
    'PERU',
    'PHILLIPSBURG',
    'PITTSBURG',
    'PLAINS',
    'PLAINVILLE',
    'PLEASANTON',
    'PLEVNA',
    'POMONA',
    'PORTIS',
    'POTWIN',
    'POWHATTAN',
    'PRAIRIE VIEW',
    'PRAIRIE VILLAGE',
    'PRATT',
    'PRESCOTT',
    'PRESTON',
    'PRETTY PRAIRIE',
    'PRINCETON',
    'PROTECTION',
    'QUENEMO',
    'QUINTER',
    'RADIUM',
    'RAMONA',
    'RANDALL',
    'RANDOLPH',
    'RANSOM',
    'RANTOUL',
    'RAYMOND',
    'READING',
    'REDFIELD',
    'REPUBLIC',
    'RESERVE',
    'REXFORD',
    'RICHFIELD',
    'RICHMOND',
    'RILEY',
    'ROBINSON',
    'ROELAND PARK',
    'ROLLA',
    'ROSE HILL',
    'ROSELAND',
    'ROSSVILLE',
    'ROZEL',
    'RUSH CENTER',
    'RUSSELL',
    'RUSSELL SPRINGS',
    'SABETHA',
    'ST. FRANCIS',
    'ST. GEORGE',
    'ST. JOHN',
    'ST. MARYS',
    'ST. PAUL',
    'SALINA',
    'SATANTA',
    'SAVONBURG',
    'SAWYER',
    'SCAMMON',
    'SCANDIA',
    'SCHOENCHEN',
    'SCOTT CITY',
    'SCOTTSVILLE',
    'SCRANTON',
    'SEDAN',
    'SEDGWICK',
    'SELDEN',
    'SENECA',
    'SEVERANCE',
    'SEVERY',
    'SEWARD',
    'SHARON',
    'SHARON SPRINGS',
    'SHAWNEE',
    'SILVER LAKE',
    'SIMPSON',
    'SMITH CENTER',
    'SMOLAN',
    'SOLDIER',
    'SOLOMON',
    'SOUTH HAVEN',
    'SOUTH HUTCHINSON',
    'SPEARVILLE',
    'SPEED',
    'SPIVEY',
    'SPRING HILL',
    'STAFFORD',
    'STARK',
    'STERLING',
    'STOCKTON',
    'STRONG CITY',
    'SUBLETTE',
    'SUMMERFIELD',
    'SUN CITY',
    'SUSANK',
    'SYLVAN GROVE',
    'SYLVIA',
    'SYRACUSE',
    'TAMPA',
    'TESCOTT',
    'THAYER',
    'TIMKEN',
    'TIPTON',
    'TONGANOXIE',
    'TOPEKA',
    'TORONTO',
    'TOWANDA',
    'TREECE',
    'TRIBUNE',
    'TROY',
    'TURON',
    'TYRO',
    'UDALL',
    'ULYSSES',
    'UNIONTOWN',
    'UTICA',
    'VALLEY CENTER',
    'VALLEY FALLS',
    'VERMILLION',
    'VICTORIA',
    'VINING',
    'VIOLA',
    'VIRGIL',
    'WAKEENEY',
    'WAKEFIELD',
    'WALDO',
    'WALDRON',
    'WALLACE',
    'WALNUT',
    'WALTON',
    'WAMEGO',
    'WASHINGTON',
    'WATERVILLE',
    'WATHENA',
    'WAVERLY',
    'WEBBER',
    'WEIR',
    'WELLINGTON',
    'WELLSVILLE',
    'WEST MINERAL',
    'WESTMORELAND',
    'WESTPHALIA',
    'WESTWOOD',
    'WESTWOOD HILLS',
    'WETMORE',
    'WHEATON',
    'WHITE CITY',
    'WHITE CLOUD',
    'WHITEWATER',
    'WHITING',
    'WICHITA',
    'WILLARD',
    'WILLIAMSBURG',
    'WILLIS',
    'WILLOWBROOK',
    'WILMORE',
    'WILSEY',
    'WILSON',
    'WINCHESTER',
    'WINDOM',
    'WINFIELD',
    'WINONA',
    'WOODBINE',
    'WOODSTON',
    'YATES CENTER',
    'ZENDA',
    'ZURICH',
  ],
  Kentucky: [
    'ADAIRVILLE',
    'ALBANY',
    'ALEXANDRIA',
    'ALLEN',
    'ALLENSVILLE',
    'ANCHORAGE',
    'ANNVILLE',
    'ARLINGTON',
    'ASHLAND',
    'AUBURN',
    'AUDUBON PARK',
    'AUGUSTA',
    'BANCROFT',
    'BARBOURMEADE',
    'BARBOURVILLE',
    'BARDSTOWN',
    'BARDWELL',
    'BARLOW',
    'BEATTYVILLE',
    'BEAVER DAM',
    'BEDFORD',
    'BEECHWOOD VILLAGE',
    'BELLEFONTE',
    'BELLEMEADE',
    'BELLEVUE',
    'BELLEWOOD',
    'BENHAM',
    'BENTON',
    'BEREA',
    'BERRY',
    'BLACKEY',
    'BLAINE',
    'BLANDVILLE',
    'BLOOMFIELD',
    'BLUE RIDGE MANOR',
    'BONNIEVILLE',
    'BOONEVILLE',
    'BOWLING GREEN',
    'BRADFORDSVILLE',
    'BRANDENBURG',
    'BRECKINRIDGE CENTER',
    'BREMEN',
    'BRIARWOOD',
    'BROAD FIELDS',
    'BRODHEAD',
    'BROECK POINTE',
    'BROMLEY',
    'BROOKS',
    'BROOKSVILLE',
    'BROWNSBORO FARM',
    'BROWNSBORO VILLAGE',
    'BROWNSVILLE',
    'BUCKHORN',
    'BUCKNER',
    'BUECHEL',
    'BURGIN',
    'BURKESVILLE',
    'BURLINGTON',
    'BURNSIDE',
    'BUTLER',
    'CADIZ',
    'CALHOUN',
    'CALIFORNIA',
    'CALVERT CITY',
    'CAMARGO',
    'CAMBRIDGE',
    'CAMPBELLSBURG',
    'CAMPBELLSVILLE',
    'CAMPTON',
    'CANEYVILLE',
    'CARLISLE',
    'CARROLLTON',
    'CARRSVILLE',
    'CATLETTSBURG',
    'CAVE CITY',
    'CEDARVILLE',
    'CENTERTOWN',
    'CENTRAL CITY',
    'CHERRYWOOD VILLAGE',
    'CLARKSON',
    'CLARYVILLE',
    'CLAY',
    'CLAY CITY',
    'CLINTON',
    'CLOVERPORT',
    'COAL RUN VILLAGE',
    'COLD SPRING',
    'COLDSTREAM',
    'COLUMBIA',
    'COLUMBUS',
    'CONCORD',
    'CORBIN',
    'CORINTH',
    'CORYDON',
    'COVINGTON',
    'CRAB ORCHARD',
    'CREEKSIDE',
    'CRESCENT SPRINGS',
    'CRESTVIEW',
    'CRESTVIEW HILLS',
    'CRESTWOOD',
    'CRITTENDEN',
    'CROFTON',
    'CROSSGATE',
    'CUMBERLAND',
    'CYNTHIANA',
    'DANVILLE',
    'DAWSON SPRINGS',
    'DAYTON',
    'DIXON',
    'DOUGLASS HILLS',
    'DOVER',
    'DRAKESBORO',
    'DRUID HILLS',
    'DRY RIDGE',
    'DYCUSBURG',
    'EARLINGTON',
    'EAST BERNSTADT',
    'EDDYVILLE',
    'EDGEWOOD',
    'EDMONTON',
    'EKRON',
    'ELIZABETHTOWN',
    'ELKHORN CITY',
    'ELKTON',
    'ELSMERE',
    'EMINENCE',
    'ERLANGER',
    'EUBANK',
    'EVARTS',
    'EWING',
    'FAIRDALE',
    'FAIRFIELD',
    'FAIRMEADE',
    'FAIRVIEW',
    'FALMOUTH',
    'FERGUSON',
    'FERN CREEK',
    'FINCASTLE',
    'FLATWOODS',
    'FLEMING-NEON',
    'FLEMINGSBURG',
    'FLORENCE',
    'FORDSVILLE',
    'FOREST HILLS',
    'FORT CAMPBELL NORTH',
    'FORT KNOX',
    'FORT MITCHELL',
    'FORT THOMAS',
    'FORT WRIGHT',
    'FOUNTAIN RUN',
    'FOX CHASE',
    'FRANKFORT',
    'FRANKLIN',
    'FREDONIA',
    'FRENCHBURG',
    'FULTON',
    'GAMALIEL',
    'GEORGETOWN',
    'GERMANTOWN',
    'GHENT',
    'GLASGOW',
    'GLENCOE',
    'GLENVIEW',
    'GLENVIEW HILLS',
    'GLENVIEW MANOR',
    'GOOSE CREEK',
    'GOSHEN',
    'GRAND RIVERS',
    'GRATZ',
    'GRAYMOOR-DEVONDALE',
    'GRAYSON',
    'GREENSBURG',
    'GREEN SPRING',
    'GREENUP',
    'GREENVILLE',
    'GUTHRIE',
    'HANSON',
    'HARDIN',
    'HARDINSBURG',
    'HARLAN',
    'HARRODSBURG',
    'HARTFORD',
    'HAWESVILLE',
    'HAZARD',
    'HAZEL',
    'HEBRON ESTATES',
    'HENDERSON',
    'HENDRON',
    'HICKMAN',
    'HICKORY HILL',
    'HIGHLAND HEIGHTS',
    'HIGHVIEW',
    'HILLS AND DALES',
    'HILLVIEW',
    'HINDMAN',
    'HISEVILLE',
    'HODGENVILLE',
    'HOLLOW CREEK',
    'HOLLYVILLA',
    'HOPKINSVILLE',
    'HORSE CAVE',
    'HOUSTON ACRES',
    'HUNTERS HOLLOW',
    'HURSTBOURNE',
    'HURSTBOURNE ACRES',
    'HUSTONVILLE',
    'HYDEN',
    'INDEPENDENCE',
    'INDIAN HILLS',
    'INEZ',
    'IRVINE',
    'IRVINGTON',
    'ISLAND',
    'JACKSON',
    'JAMESTOWN',
    'JEFFERSONTOWN',
    'JEFFERSONVILLE',
    'JENKINS',
    'JUNCTION CITY',
    'KEENELAND',
    'KENTON VALE',
    'KEVIL',
    'KINGSLEY',
    'KUTTAWA',
    'LA CENTER',
    'LAFAYETTE',
    'LA GRANGE',
    'LAKESIDE PARK',
    'LAKEVIEW HEIGHTS',
    'LANCASTER',
    'LANGDON PLACE',
    'LATONIA LAKES',
    'LAWRENCEBURG',
    'LEBANON',
    'LEBANON JUNCTION',
    'LEDBETTER',
    'LEITCHFIELD',
    'LEWISBURG',
    'LEWISPORT',
    '',
    'LIBERTY',
    'LINCOLNSHIRE',
    'LIVERMORE',
    'LIVINGSTON',
    'LONDON',
    'LONE OAK',
    'LORETTO',
    'LOUISA',
    'LOUISVILLE',
    'LOYALL',
    'LUDLOW',
    'LYNCH',
    'LYNDON',
    'LYNNVIEW',
    'MCHENRY',
    'MCKEE',
    'MACKVILLE',
    'MCROBERTS',
    'MADISONVILLE',
    'MANCHESTER',
    'MANOR CREEK',
    'MARION',
    'MARTIN',
    'MARYHILL ESTATES',
    'MASONVILLE',
    'MASSAC',
    'MAYFIELD',
    'MAYSVILLE',
    'MEADOWBROOK FARM',
    'MEADOW VALE',
    'MEADOWVIEW ESTATES',
    'MELBOURNE',
    'MENTOR',
    'MIDDLESBOROUGH',
    'MIDDLETOWN',
    'MIDWAY',
    'MILLERSBURG',
    'MILTON',
    'MINOR LANE HEIGHTS',
    'MOCKINGBIRD VALLEY',
    'MONTEREY',
    'MONTICELLO',
    'MOORLAND',
    'MOREHEAD',
    'MORGANFIELD',
    'MORGANTOWN',
    'MORTONS GAP',
    'MOUNT OLIVET',
    'MOUNT STERLING',
    'MOUNT VERNON',
    'MOUNT WASHINGTON',
    'MULDRAUGH',
    'MUNFORDVILLE',
    'MURRAY',
    'MURRAY HILL',
    'NEBO',
    'NEWBURG',
    'NEW CASTLE',
    'NEW HAVEN',
    'NEWPORT',
    'NICHOLASVILLE',
    'NORBOURNE ESTATES',
    'NORTH CORBIN',
    'NORTHFIELD',
    'NORTH MIDDLETOWN',
    'NORTONVILLE',
    'NORWOOD',
    'OAKBROOK',
    'OAK GROVE',
    'OAKLAND',
    'OKOLONA',
    'OLD BROWNSBORO PLACE',
    'OLIVE HILL',
    'ORCHARD GRASS HILLS',
    'OWENSBORO',
    'OWENTON',
    'OWINGSVILLE',
    'PADUCAH',
    'PAINTSVILLE',
    'PARIS',
    'PARK CITY',
    'PARK HILLS',
    'PARK LAKE',
    'PARKWAY VILLAGE',
    'PEMBROKE',
    'PERRYVILLE',
    'PEWEE VALLEY',
    'PHELPS',
    'PIKEVILLE',
    'PINE KNOT',
    'PINEVILLE',
    'PIONEER VILLAGE',
    'PIPPA PASSES',
    'PLANTATION',
    'PLEASURE RIDGE PARK',
    'PLEASUREVILLE',
    'PLUM SPRINGS',
    'PLYMOUTH VILLAGE',
    'POPLAR HILLS',
    'POWDERLY',
    'PRESTONSBURG',
    'PRESTONVILLE',
    'PRINCETON',
    'PROSPECT',
    'PROVIDENCE',
    'RACELAND',
    'RADCLIFF',
    'RAVENNA',
    'RAYWICK',
    'REIDLAND',
    'RICHLAWN',
    'RICHMOND',
    'RIVER BLUFF',
    'RIVERWOOD',
    'ROBARDS',
    'ROCHESTER',
    'ROCKPORT',
    'ROLLING FIELDS',
    'ROLLING HILLS',
    'RUSSELL',
    'RUSSELL SPRINGS',
    'RUSSELLVILLE',
    'RYLAND HEIGHTS',
    'SACRAMENTO',
    'SADIEVILLE',
    'ST. CHARLES',
    'ST. DENNIS',
    'ST. MATTHEWS',
    'ST. REGIS PARK',
    'SALEM',
    'SALT LICK',
    'SALYERSVILLE',
    'SANDERS',
    'SANDY HOOK',
    'SARDIS',
    'SCIENCE HILL',
    'SCOTTSVILLE',
    'SEBREE',
    'SENECA GARDENS',
    'SHARPSBURG',
    'SHELBYVILLE',
    'SHEPHERDSVILLE',
    'SHIVELY',
    'SILVER GROVE',
    'SIMPSONVILLE',
    'SLAUGHTERS',
    'SMITHFIELD',
    'SMITHLAND',
    'SMITHS GROVE',
    'SOMERSET',
    'SONORA',
    'SOUTH CARROLLTON',
    'SOUTHGATE',
    'SOUTH PARK VIEW',
    'SOUTH SHORE',
    'SOUTH WALLINS',
    'SPARTA',
    'SPRINGFIELD',
    'SPRINGLEE',
    'SPRING MILL',
    'SPRING VALLEY',
    'STAMPING GROUND',
    'STANFORD',
    'STANTON',
    'STEARNS',
    'STRATHMOOR MANOR',
    'STRATHMOOR VILLAGE',
    'STURGIS',
    'SYCAMORE',
    'TAYLOR MILL',
    'TAYLORSVILLE',
    'TEN BROECK',
    'THORNHILL',
    'TOMPKINSVILLE',
    'TRENTON',
    'UNION',
    'UNIONTOWN',
    'UPTON',
    'VALLEY STATION',
    'VANCEBURG',
    'VERSAILLES',
    'VICCO',
    'VILLA HILLS',
    'VINE GROVE',
    'VISALIA',
    'WALLINS CREEK',
    'WALTON',
    'WARFIELD',
    'WARSAW',
    'WATER VALLEY',
    'WATTERSON PARK',
    'WAVERLY',
    'WAYLAND',
    'WELLINGTON',
    'WEST BUECHEL',
    'WEST LIBERTY',
    'WEST POINT',
    'WESTWOOD',
    'WESTWOOD',
    'WHEATCROFT',
    'WHEELWRIGHT',
    'WHIPPS MILLGATE',
    'WHITE PLAINS',
    'WHITESBURG',
    'WHITESVILLE',
    'WHITLEY CITY',
    'WICKLIFFE',
    'WILDER',
    'WILDWOOD',
    'WILLIAMSBURG',
    'WILLIAMSTOWN',
    'WILLISBURG',
    'WILMORE',
    'WINCHESTER',
    'WINDY HILLS',
    'WINGO',
    'WOODBURN',
    'WOODBURY',
    'WOODLAND HILLS',
    'WOODLAWN',
    'WOODLAWN-OAKDALE',
    'WOODLAWN PARK',
    'WORTHINGTON',
    'WORTHINGTON HILLS',
    'WORTHVILLE',
    'WURTLAND',
  ],
  Louisiana: [
    'ABBEVILLE',
    'ABITA SPRINGS',
    'ADDIS',
    'ALBANY',
    'ALEXANDRIA',
    'AMA',
    'AMELIA',
    'AMITE CITY',
    'ANACOCO',
    'ANGIE',
    'ARABI',
    'ARCADIA',
    'ARNAUDVILLE',
    'ASHLAND',
    'ATHENS',
    'ATLANTA',
    'AVONDALE',
    'BAKER',
    'BALDWIN',
    'BALL',
    'BARATARIA',
    'BASILE',
    'BASKIN',
    'BASTROP',
    'BATON ROUGE',
    'BAYOU CANE',
    'BAYOU GAUCHE',
    'BAYOU VISTA',
    'BELCHER',
    'BELLE CHASSE',
    'BELLE ROSE',
    'BENTON',
    'BERNICE',
    'BERWICK',
    'BIENVILLE',
    'BLANCHARD',
    'BOGALUSA',
    'BONITA',
    'BOOTHVILLE-VENICE',
    'BOSSIER CITY',
    'BOUTTE',
    'BOYCE',
    'BREAUX BRIDGE',
    'BRIDGE CITY',
    'BROUSSARD',
    'BROWNFIELDS',
    'BROWNSVILLE-BAWCOMVILLE',
    'BRUSLY',
    'BRYCELAND',
    'BUNKIE',
    'BURAS-TRIUMPH',
    'CALVIN',
    'CAMERON',
    'CAMPTI',
    'CANKTON',
    'CARENCRO',
    'CARLYSS',
    'CASTOR',
    'CECILIA',
    'CHACKBAY',
    'CHALMETTE',
    'CHARENTON',
    'CHATAIGNIER',
    'CHATHAM',
    'CHAUVIN',
    'CHENEYVILLE',
    'CHOUDRANT',
    'CHURCH POINT',
    'CLAIBORNE',
    'CLARENCE',
    'CLARKS',
    'CLAYTON',
    'CLINTON',
    'COLFAX',
    'COLLINSTON',
    'COLUMBIA',
    'CONVERSE',
    'COTTONPORT',
    'COTTON VALLEY',
    'COUSHATTA',
    'COVINGTON',
    'CROWLEY',
    'CULLEN',
    'CUT OFF',
    'DELCAMBRE',
    'DELHI',
    'DELTA',
    'DENHAM SPRINGS',
    'DEQUINCY',
    'DE RIDDER',
    'DES ALLEMANDS',
    'DESTREHAN',
    'DEVILLE',
    'DIXIE INN',
    'DODSON',
    'DONALDSONVILLE',
    'DOWNSVILLE',
    'DOYLINE',
    'DRY PRONG',
    'DUBACH',
    'DUBBERLY',
    'DULAC',
    'DUSON',
    'EAST HODGE',
    'EASTWOOD',
    'EDEN ISLE',
    'EDGARD',
    'EDGEFIELD',
    'ELIZABETH',
    'ELMWOOD',
    'ELTON',
    'EMPIRE',
    'EPPS',
    'ERATH',
    'EROS',
    'ESTELLE',
    'ESTHERWOOD',
    'EUNICE',
    'EVERGREEN',
    'FARMERVILLE',
    'FENTON',
    'FERRIDAY',
    'FISHER',
    'FLORIEN',
    'FOLSOM',
    'FORDOCHE',
    'FOREST',
    'FOREST HILL',
    'FORT POLK NORTH',
    'FORT POLK SOUTH',
    'FRANKLIN',
    'FRANKLINTON',
    'FRENCH SETTLEMENT',
    'GALLIANO',
    'GARDERE',
    'GARYVILLE',
    'GEORGETOWN',
    'GIBSLAND',
    'GILBERT',
    'GILLIAM',
    'GLENMORA',
    'GOLDEN MEADOW',
    'GOLDONNA',
    'GONZALES',
    'GRAMBLING',
    'GRAMERCY',
    'GRAND CANE',
    'GRAND COTEAU',
    'GRAND ISLE',
    'GRAY',
    'GRAYSON',
    'GREENSBURG',
    'GREENWOOD',
    'GRETNA',
    'GROSSE TETE',
    'GUEYDAN',
    'HACKBERRY',
    'HAHNVILLE',
    'HALL SUMMIT',
    'HAMMOND',
    'HARAHAN',
    'HARRISONBURG',
    'HARVEY',
    'HAUGHTON',
    'HAYNESVILLE',
    'HEFLIN',
    'HENDERSON',
    'HESSMER',
    'HODGE',
    'HOMER',
    'HORNBECK',
    'HOSSTON',
    'HOUMA',
    'IDA',
    'INDEPENDENCE',
    'INNISWOLD',
    'IOTA',
    'IOWA',
    'JACKSON',
    'JAMESTOWN',
    'JEANERETTE',
    'JEAN LAFITTE',
    'JEFFERSON',
    'JENA',
    'JENNINGS',
    'JONESBORO',
    'JONESVILLE',
    'JUNCTION CITY',
    'KAPLAN',
    'KEACHI',
    'KENNER',
    'KENTWOOD',
    'KILBOURNE',
    'KILLIAN',
    'KILLONA',
    'KINDER',
    'KROTZ SPRINGS',
    'LABADIEVILLE',
    'LACOMBE',
    'LAFAYETTE',
    'LAFITTE',
    'LAKE ARTHUR',
    'LAKE CHARLES',
    'LAKE PROVIDENCE',
    'LAPLACE',
    'LAROSE',
    'LECOMPTE',
    'LEESVILLE',
    'LEONVILLE',
    'LILLIE',
    'LISBON',
    'LIVINGSTON',
    'LIVONIA',
    'LOCKPORT',
    'LOGANSPORT',
    'LONGSTREET',
    'LOREAUVILLE',
    'LUCKY',
    'LULING',
    'LUTCHER',
    'LYDIA',
    'MCNARY',
    'MADISONVILLE',
    'MAMOU',
    'MANDEVILLE',
    'MANGHAM',
    'MANSFIELD',
    'MANSURA',
    'MANY',
    'MARINGOUIN',
    'MARION',
    'MARKSVILLE',
    'MARRERO',
    'MARTIN',
    'MATHEWS',
    'MAURICE',
    'MELVILLE',
    'MERAUX',
    'MERMENTAU',
    'MER ROUGE',
    'MERRYDALE',
    'MERRYVILLE',
    'METAIRIE',
    'MIDWAY',
    'MINDEN',
    'MONROE',
    'MONTEGUT',
    'MONTGOMERY',
    'MONTICELLO',
    'MONTPELIER',
    'MONTZ',
    'MOORINGSPORT',
    'MOREAUVILLE',
    'MORGAN CITY',
    'MORGANZA',
    'MORSE',
    'MOSS BLUFF',
    'MOUND',
    'MOUNT LEBANON',
    'NAPOLEONVILLE',
    'NATALBANY',
    'NATCHEZ',
    'NATCHITOCHES',
    'NEWELLTON',
    'NEW IBERIA',
    'NEW LLANO',
    'NEW ORLEANS',
    'NEW ROADS',
    'NEW SARPY',
    'NOBLE',
    'NORCO',
    'NORTH HODGE',
    'NORTH VACHERIE',
    'NORWOOD',
    'OAKDALE',
    'OAK GROVE',
    'OAK HILLS PLACE',
    'OAK RIDGE',
    'OBERLIN',
    'OIL CITY',
    'OLD JEFFERSON',
    'OLLA',
    'OPELOUSAS',
    'PAINCOURTVILLE',
    'PALMETTO',
    'PARADIS',
    'PARKS',
    'PATTERSON',
    'PEARL RIVER',
    'PIERRE PART',
    'PINE PRAIRIE',
    'PINEVILLE',
    'PIONEER',
    'PLAIN DEALING',
    'PLAQUEMINE',
    'PLAUCHEVILLE',
    'PLEASANT HILL',
    'POLLOCK',
    'PONCHATOULA',
    'PORT ALLEN',
    'PORT BARRE',
    'PORT SULPHUR',
    'PORT VINCENT',
    'POWHATAN',
    'POYDRAS',
    'PRIEN',
    'PROVENCAL',
    'QUITMAN',
    'RACELAND',
    'RAYNE',
    'RAYVILLE',
    'RED CHUTE',
    'REEVES',
    'RESERVE',
    'RICHMOND',
    'RICHWOOD',
    'RIDGECREST',
    'RINGGOLD',
    'RIVER RIDGE',
    'ROBELINE',
    'RODESSA',
    'ROSEDALE',
    'ROSELAND',
    'ROSEPINE',
    'RUSTON',
    'ST. FRANCISVILLE',
    'ST. GABRIEL',
    'ST. JOSEPH',
    'ST. MARTINVILLE',
    'ST. ROSE',
    'SALINE',
    'SAREPTA',
    'SCHRIEVER',
    'SCOTT',
    'SHENANDOAH',
    'SHONGALOO',
    'SHREVEPORT',
    'SIBLEY',
    'SICILY ISLAND',
    'SIKES',
    'SIMMESPORT',
    'SIMPSON',
    'SIMSBORO',
    'SLAUGHTER',
    'SLIDELL',
    'SORRENTO',
    'SOUTH MANSFIELD',
    'SOUTH VACHERIE',
    'SPEARSVILLE',
    'SPRINGFIELD',
    'SPRINGHILL',
    'STANLEY',
    'STERLINGTON',
    'STONEWALL',
    'SULPHUR',
    'SUN',
    'SUNSET',
    'SUPREME',
    'SWARTZ',
    'TAFT',
    'TALLULAH',
    'TANGIPAHOA',
    'TERRYTOWN',
    'THIBODAUX',
    'TICKFAW',
    'TIMBERLANE',
    'TULLOS',
    'TURKEY CREEK',
    'URANIA',
    'VARNADO',
    'VIDALIA',
    'VIENNA',
    'VILLAGE ST. GEORGE',
    'VILLE PLATTE',
    'VINTON',
    'VIOLET',
    'VIVIAN',
    'WAGGAMAN',
    'WALKER',
    'WALLACE',
    'WASHINGTON',
    'WATERPROOF',
    'WELSH',
    'WEST FERRIDAY',
    'WESTLAKE',
    'WESTMINSTER',
    'WEST MONROE',
    'WESTWEGO',
    'WHITE CASTLE',
    'WILSON',
    'WINNFIELD',
    'WINNSBORO',
    'WISNER',
    'WOODMERE',
    'WOODWORTH',
    'YOUNGSVILLE',
    'ZACHARY',
    'ZWOLLE',
  ],
  Maine: [
    'ANSON',
    'AUBURN',
    'AUGUSTA',
    'BANGOR',
    'BAR HARBOR',
    'BATH',
    'BELFAST',
    'BERWICK',
    'BIDDEFORD',
    'BINGHAM',
    'BOOTHBAY HARBOR',
    'BREWER',
    'BRIDGTON',
    'BRUNSWICK',
    'BRUNSWICK STATION',
    'BUCKSPORT',
    'CALAIS',
    'CAMDEN',
    'CAPE NEDDICK',
    'CARIBOU',
    'CHISHOLM',
    'CLINTON',
    'CUMBERLAND CENTER',
    'DAMARISCOTTA-NEWCASTLE',
    'DEXTER',
    'DIXFIELD',
    'DOVER-FOXCROFT',
    'EAST MILLINOCKET',
    'EASTPORT',
    'ELLSWORTH',
    'FAIRFIELD',
    'FALMOUTH FORESIDE',
    'FARMINGDALE',
    'FARMINGTON',
    'FORT FAIRFIELD',
    'FORT KENT',
    'FREEPORT',
    'FRYEBURG',
    'GARDINER',
    'GORHAM',
    'GREENVILLE',
    'GUILFORD',
    'HALLOWELL',
    'HAMPDEN',
    'HARTLAND',
    'HOULTON',
    'HOWLAND',
    'KENNEBUNK',
    'KENNEBUNKPORT',
    'KITTERY',
    'KITTERY POINT',
    'LAKE ARROWHEAD',
    'LEWISTON',
    'LIMESTONE',
    'LINCOLN',
    'LISBON FALLS',
    'LITTLE FALLS-SOUTH WINDHAM',
    'LIVERMORE FALLS',
    'LORING AFB',
    'MACHIAS',
    'MADAWASKA',
    'MADISON',
    'MARS HILL-BLAINE',
    'MECHANIC FALLS',
    'MEXICO',
    'MILFORD',
    'MILLINOCKET',
    'MILO',
    'NEWPORT',
    'NORRIDGEWOCK',
    'NORTH BERWICK',
    'NORTH WINDHAM',
    'NORWAY',
    'OAKLAND',
    'OLD ORCHARD BEACH',
    'OLD TOWN',
    'ORONO',
    'OXFORD',
    'PITTSFIELD',
    'PORTLAND',
    'PRESQUE ISLE',
    'RANDOLPH',
    'RICHMOND',
    'ROCKLAND',
    'RUMFORD',
    'SACO',
    'SANFORD',
    'SCARBOROUGH',
    'SEARSPORT',
    'SKOWHEGAN',
    'SOUTH ELIOT',
    'SOUTH PARIS',
    'SOUTH PORTLAND',
    'SOUTH SANFORD',
    'SPRINGVALE',
    'THOMASTON',
    'TOPSHAM',
    'UNITY',
    'VAN BUREN',
    'WALDOBORO',
    'WATERVILLE',
    'WESTBROOK',
    'WEST KENNEBUNK',
    'WILTON',
    'WINSLOW',
    'WINTERPORT',
    'WINTHROP',
    'WISCASSET',
    'WOODLAND',
    'YARMOUTH',
    'YORK HARBOR',
  ],
  'Marshall Islands': ['MARSHALL ISLANDS'],
  Maryland: [
    'ABERDEEN',
    'ABERDEEN PROVING GROUND',
    'ACCIDENT',
    'ACCOKEEK',
    'ADELPHI',
    'ALGONQUIN',
    'ANDREWS AFB',
    'ANNAPOLIS',
    'ARBUTUS',
    'ARDEN-ON-THE-SEVERN',
    'ARNOLD',
    'ASHTON-SANDY SPRING',
    'ASPEN HILL',
    'BALLENGER CREEK',
    'BALTIMORE',
    'BARCLAY',
    'BARNESVILLE',
    'BARTON',
    'BEL AIR',
    'BEL AIR NORTH',
    'BEL AIR SOUTH',
    'BELTSVILLE',
    'BENNSVILLE',
    'BERLIN',
    'BERWYN HEIGHTS',
    'BETHESDA',
    'BETTERTON',
    'BLADENSBURG',
    'BOONSBORO',
    'BOWIE',
    'BOWLEYS QUARTERS',
    'BRADDOCK HEIGHTS',
    'BRANDYWINE',
    'BRENTWOOD',
    'BROOKEVILLE',
    'BROOKLYN PARK',
    'BROOKMONT',
    'BROOKVIEW',
    'BRUNSWICK',
    'BRYANS ROAD',
    'BURKITTSVILLE',
    'BURTONSVILLE',
    'CABIN JOHN',
    'CALIFORNIA',
    'CALVERT BEACH-LONG BEACH',
    'CALVERTON',
    'CAMBRIDGE',
    'CAMP SPRINGS',
    'CAPE ST. CLAIRE',
    'CAPITOL HEIGHTS',
    'CARMODY HILLS-PEPPER MILL VILLAGE',
    'CARNEY',
    'CATONSVILLE',
    'CAVETOWN',
    'CECILTON',
    'CENTREVILLE',
    'CHANCE',
    'CHARLESTOWN',
    'CHARLOTTE HALL',
    'CHESAPEAKE BEACH',
    'CHESAPEAKE CITY',
    'CHESAPEAKE RANCH ESTATES-DRUM POINT',
    'CHESTER',
    'CHESTERTOWN',
    'CHEVERLY',
    'CHEVY CHASE',
    'CHEVY CHASE',
    'CHEVY CHASE SECTION FIVE',
    'CHEVY CHASE SECTION THREE',
    'CHEVY CHASE VIEW',
    'CHEVY CHASE VILLAGE',
    'CHEWSVILLE',
    'CHILLUM',
    'CHURCH CREEK',
    'CHURCH HILL',
    'CLARKSBURG',
    'CLEAR SPRING',
    'CLINTON',
    'CLOVER HILL',
    'CLOVERLY',
    'COCKEYSVILLE',
    'COLESVILLE',
    'COLLEGE PARK',
    'COLMAR MANOR',
    'COLUMBIA',
    'CORAL HILLS',
    'CORDOVA',
    'COTTAGE CITY',
    'CRESAPTOWN-BEL AIR',
    'CRISFIELD',
    'CROFTON',
    'CROWNSVILLE',
    'CUMBERLAND',
    'DAMASCUS',
    'DAMES QUARTER',
    'DARNESTOWN',
    'DEALE',
    'DEAL ISLAND',
    'DEER PARK',
    'DELMAR',
    'DENTON',
    'DISCOVERY-SPRING GARDEN',
    'DISTRICT HEIGHTS',
    'DUNDALK',
    'DUNKIRK',
    'EAGLE HARBOR',
    'EAST NEW MARKET',
    'EASTON',
    'EAST RIVERDALE',
    'EDEN',
    'EDGEMERE',
    'EDGEWOOD',
    'EDMONSTON',
    'ELDERSBURG',
    'ELDORADO',
    'ELKRIDGE',
    'ELKTON',
    'ELLICOTT CITY',
    'EMMITSBURG',
    'ESSEX',
    'FAIRLAND',
    'FAIRMOUNT',
    'FAIRMOUNT HEIGHTS',
    'FALLSTON',
    'FEDERALSBURG',
    'FERNDALE',
    'FOREST GLEN',
    'FOREST HEIGHTS',
    'FORESTVILLE',
    'FORT MEADE',
    'FORT RITCHIE',
    'FORT WASHINGTON',
    'FOUNTAINHEAD-ORCHARD HILLS',
    'FREDERICK',
    'FRENCHTOWN-RUMBLY',
    'FRIENDLY',
    'FRIENDSHIP VILLAGE',
    'FRIENDSVILLE',
    'FROSTBURG',
    'FRUITLAND',
    'FUNKSTOWN',
    'GAITHERSBURG',
    'GALENA',
    'GALESTOWN',
    'GARRETT PARK',
    'GARRISON',
    'GERMANTOWN',
    'GIRDLETREE',
    'GLENARDEN',
    'GLEN BURNIE',
    'GLEN ECHO',
    'GLENN DALE',
    'GODDARD',
    'GOLDEN BEACH',
    'GOLDSBORO',
    'GRANTSVILLE',
    'GRASONVILLE',
    'GREATER LANDOVER',
    'GREATER UPPER MARLBORO',
    'GREENBELT',
    'GREEN HAVEN',
    'GREENSBORO',
    'GREEN VALLEY',
    'HAGERSTOWN',
    'HALFWAY',
    'HAMPSTEAD',
    'HAMPTON',
    'HANCOCK',
    'HAVRE DE GRACE',
    'HEBRON',
    'HENDERSON',
    'HERALD HARBOR',
    'HIGHFIELD-CASCADE',
    'HIGHLAND BEACH',
    'HILLANDALE',
    'HILLCREST HEIGHTS',
    'HILLSBORO',
    'HILLSMERE SHORES',
    'HUGHESVILLE',
    'HUNTINGTOWN',
    'HURLOCK',
    'HYATTSVILLE',
    'INDIAN HEAD',
    'JARRETTSVILLE',
    'JESSUP',
    'JOPPATOWNE',
    'KEEDYSVILLE',
    'KEMP MILL',
    'KENSINGTON',
    'KENT NARROWS',
    'KETTERING',
    'KINGSTOWN',
    'KINGSVILLE',
    'KITZMILLER',
    'LAKE ARBOR',
    'LAKE SHORE',
    'LANDOVER HILLS',
    'LANGLEY PARK',
    'LANHAM-SEABROOK',
    'LANSDOWNE-BALTIMORE HIGHLANDS',
    'LA PLATA',
    'LARGO',
    'LAUREL',
    'LA VALE',
    'LAYTONSVILLE',
    'LEITERSBURG',
    'LEONARDTOWN',
    'LEXINGTON PARK',
    'LINGANORE-BARTONSVILLE',
    'LINTHICUM',
    'LOCHEARN',
    'LOCH LYNN HEIGHTS',
    'LONACONING',
    'LONDONTOWNE',
    'LUKE',
    'LUSBY',
    'LUTHERVILLE-TIMONIUM',
    'MANCHESTER',
    'MARDELA SPRINGS',
    'MARLOW HEIGHTS',
    'MARLTON',
    'MARTIN S ADDITIONS',
    'MARYDEL',
    'MARYLAND CITY',
    'MAUGANSVILLE',
    'MAYO',
    'MAYS CHAPEL',
    'MIDDLE RIVER',
    'MIDDLETOWN',
    'MIDLAND',
    'MILFORD MILL',
    'MILLINGTON',
    'MITCHELLVILLE',
    'MONTGOMERY VILLAGE',
    'MORNINGSIDE',
    'MOUNT AETNA',
    'MOUNTAIN LAKE PARK',
    'MOUNT AIRY',
    'MOUNT LENA',
    'MOUNT RAINIER',
    'MOUNT VERNON',
    'MYERSVILLE',
    'NAVAL ACADEMY',
    'NEWARK',
    'NEW CARROLLTON',
    'NEW MARKET',
    'NEW WINDSOR',
    'NORTH BEACH',
    'NORTH BETHESDA',
    'NORTH BRENTWOOD',
    'NORTH CHEVY CHASE',
    'NORTH EAST',
    'NORTH KENSINGTON',
    'NORTH LAUREL',
    'NORTH POTOMAC',
    'OAKLAND',
    'OCEAN CITY',
    'OCEAN PINES',
    'ODENTON',
    'OLNEY',
    'OVERLEA',
    'OWINGS',
    'OWINGS MILLS',
    'OXFORD',
    'OXON HILL-GLASSMANOR',
    'PARAMOUNT-LONG MEADOW',
    'PARKVILLE',
    'PAROLE',
    'PASADENA',
    'PERRY HALL',
    'PERRYMAN',
    'PERRYVILLE',
    'PIKESVILLE',
    'PITTSVILLE',
    'PLEASANT HILLS',
    'POCOMOKE CITY',
    'POOLESVILLE',
    'PORT DEPOSIT',
    'PORT TOBACCO VILLAGE',
    'POTOMAC',
    'POTOMAC HEIGHTS',
    'PRESTON',
    'PRINCE FREDERICK',
    'PRINCESS ANNE',
    'PUMPHREY',
    'QUEEN ANNE',
    'QUEENSTOWN',
    'RANDALLSTOWN',
    'REDLAND',
    'REISTERSTOWN',
    'RIDGELY',
    'RISING SUN',
    'RIVA',
    'RIVERDALE PARK',
    'RIVERSIDE',
    'RIVIERA BEACH',
    'ROBINWOOD',
    'ROCK HALL',
    'ROCKVILLE',
    'ROHRERSVILLE',
    'ROSARYVILLE',
    'ROSEDALE',
    'ROSEMONT',
    'ROSSMOOR',
    'ROSSVILLE',
    'ST. CHARLES',
    'ST. JAMES',
    'ST. LEONARD',
    'ST. MICHAELS',
    'SALISBURY',
    'SAN MAR',
    'SAVAGE-GUILFORD',
    'SEAT PLEASANT',
    'SECRETARY',
    'SELBY-ON-THE-BAY',
    'SEVERN',
    'SEVERNA PARK',
    'SHADY SIDE',
    'SHARPSBURG',
    'SHARPTOWN',
    'SILVER SPRING',
    'SMITH ISLAND',
    'SMITHSBURG',
    'SNOW HILL',
    'SOLOMONS',
    'SOMERSET',
    'SOUTH GATE',
    'SOUTH KENSINGTON',
    'SOUTH LAUREL',
    'SPRINGDALE',
    'STEVENSVILLE',
    'STOCKTON',
    'SUDLERSVILLE',
    'SUITLAND-SILVER HILL',
    'SYKESVILLE',
    'TAKOMA PARK',
    'TANEYTOWN',
    'TEMPLE HILLS',
    'TEMPLEVILLE',
    'THURMONT',
    'TILGHMAN ISLAND',
    'TOWSON',
    'TRAPPE',
    'TRAVILAH',
    'UNION BRIDGE',
    'UNIVERSITY PARK',
    'UPPER MARLBORO',
    'VIENNA',
    'WALDORF',
    'WALKER MILL',
    'WALKERSVILLE',
    'WASHINGTON GROVE',
    'WESTERNPORT',
    'WEST LAUREL',
    'WESTMINSTER',
    'WEST OCEAN CITY',
    'WEST POCOMOKE',
    'WHALEYVILLE',
    'WHEATON-GLENMONT',
    'WHITE MARSH',
    'WHITE OAK',
    'WILLARDS',
    'WILLIAMSPORT',
    'WILSON-CONOCOCHEAGUE',
    'WOODLAWN',
    'WOODLAWN',
    'WOODMORE',
    'WOODSBORO',
  ],
  Massachusetts: [
    'ABINGTON',
    'ACUSHNET CENTER',
    'ADAMS',
    'AGAWAM',
    'AMESBURY',
    'AMHERST CENTER',
    'ANDOVER',
    'ARLINGTON',
    'ATHOL',
    'ATTLEBORO',
    'AYER',
    'BALDWINVILLE',
    'BARNSTABLE TOWN',
    'BARRE',
    'BELCHERTOWN',
    'BELLINGHAM',
    'BELMONT',
    'BEVERLY',
    'BLISS CORNER',
    'BONDSVILLE',
    'BOSTON',
    'BOURNE',
    'BOXFORD',
    'BRAINTREE',
    'BREWSTER',
    'BRIDGEWATER',
    'BROCKTON',
    'BROOKLINE',
    'BURLINGTON',
    'BUZZARDS BAY',
    'CAMBRIDGE',
    'CHATHAM',
    'CHELSEA',
    'CHICOPEE',
    'CLINTON',
    'COCHITUATE',
    'CORDAVILLE',
    'DANVERS',
    'DEDHAM',
    'DENNIS',
    'DENNIS PORT',
    'DOVER',
    'DUXBURY',
    'EAST BROOKFIELD',
    'EAST DENNIS',
    'EAST DOUGLAS',
    'EAST FALMOUTH',
    'EASTHAMPTON',
    'EAST HARWICH',
    'EAST PEPPERELL',
    'EAST SANDWICH',
    'ESSEX',
    'EVERETT',
    'FALL RIVER',
    'FALMOUTH',
    'FISKDALE',
    'FITCHBURG',
    'FORESTDALE',
    'FORT DEVENS',
    'FOXBOROUGH',
    'FRAMINGHAM',
    'FRANKLIN',
    'GARDNER',
    'GLOUCESTER',
    'GRANBY',
    'GREAT BARRINGTON',
    'GREENFIELD',
    'GREEN HARBOR-CEDAR CREST',
    'GROTON',
    'HANSON',
    'HARWICH CENTER',
    'HARWICH PORT',
    'HATFIELD',
    'HAVERHILL',
    'HINGHAM',
    'HOLBROOK',
    'HOLLAND',
    'HOLYOKE',
    'HOPEDALE',
    'HOPKINTON',
    'HOUSATONIC',
    'HUDSON',
    'HULL',
    'IPSWICH',
    'KINGSTON',
    'LAWRENCE',
    'LEE',
    'LENOX',
    'LEOMINSTER',
    'LEXINGTON',
    'LITTLETON COMMON',
    'LONGMEADOW',
    'LOWELL',
    'LUNENBURG',
    'LYNN',
    'LYNNFIELD',
    'MALDEN',
    'MANSFIELD CENTER',
    'MARBLEHEAD',
    'MARION CENTER',
    'MARLBOROUGH',
    'MARSHFIELD',
    'MARSHFIELD HILLS',
    'MASHPEE NECK',
    'MATTAPOISETT CENTER',
    'MAYNARD',
    'MEDFIELD',
    'MEDFORD',
    'MELROSE',
    'METHUEN',
    'MIDDLEBOROUGH CENTER',
    'MILFORD',
    'MILLERS FALLS',
    'MILLIS-CLICQUOT',
    'MILTON',
    'MONOMOSCOY ISLAND',
    'MONSON CENTER',
    'MONUMENT BEACH',
    'NAHANT',
    'NANTUCKET',
    'NEEDHAM',
    'NEW BEDFORD',
    'NEWBURYPORT',
    'NEW SEABURY',
    'NEWTON',
    'NORTH ADAMS',
    'NORTH AMHERST',
    'NORTHAMPTON',
    'NORTH ATTLEBOROUGH CENTER',
    'NORTHBOROUGH',
    'NORTH BROOKFIELD',
    'NORTH EASTHAM',
    'NORTH FALMOUTH',
    'NORTHFIELD',
    'NORTH LAKEVILLE',
    'NORTH PEMBROKE',
    'NORTH PLYMOUTH',
    'NORTH SCITUATE',
    'NORTH SEEKONK',
    'NORTHWEST HARWICH',
    'NORTH WESTPORT',
    'NORTON CENTER',
    'NORWOOD',
    'OCEAN BLUFF-BRANT ROCK',
    'OCEAN GROVE',
    'ONSET',
    'ORANGE',
    'ORLEANS',
    'OXFORD',
    'PALMER',
    'PEABODY',
    'PEPPERELL',
    'PINEHURST',
    'PITTSFIELD',
    'PLYMOUTH',
    'POCASSET',
    'POPPONESSET',
    'POPPONESSET ISLAND',
    'PROVINCETOWN',
    'QUINCY',
    'RANDOLPH',
    'RAYNHAM CENTER',
    'READING',
    'REVERE',
    'ROCKPORT',
    'ROWLEY',
    'RUTLAND',
    'SAGAMORE',
    'SALEM',
    'SALISBURY',
    'SANDWICH',
    'SAUGUS',
    'SCITUATE',
    'SEABROOK',
    'SECONSETT ISLAND',
    'SHARON',
    'SHELBURNE FALLS',
    'SHIRLEY',
    'SMITH MILLS',
    'SOMERSET',
    'SOMERVILLE',
    'SOUTH AMHERST',
    'SOUTH ASHBURNHAM',
    'SOUTHBRIDGE',
    'SOUTH DEERFIELD',
    'SOUTH DENNIS',
    'SOUTH DUXBURY',
    'SOUTH LANCASTER',
    'SOUTH YARMOUTH',
    'SPENCER',
    'SPRINGFIELD',
    'STONEHAM',
    'STURBRIDGE',
    'SWAMPSCOTT',
    'TAUNTON',
    'TEATICKET',
    'THREE RIVERS',
    'TOPSFIELD',
    'TOWNSEND',
    'TURNERS FALLS',
    'UPTON-WEST UPTON',
    'VINEYARD HAVEN',
    'WAKEFIELD',
    'WALPOLE',
    'WALTHAM',
    'WARE',
    'WAREHAM CENTER',
    'WARREN',
    'WATERTOWN',
    'WEBSTER',
    'WELLESLEY',
    'WESTBOROUGH',
    'WEST BROOKFIELD',
    'WEST CHATHAM',
    'WEST CONCORD',
    'WEST DENNIS',
    'WEST FALMOUTH',
    'WESTFIELD',
    'WEST SPRINGFIELD',
    'WEST WAREHAM',
    'WEST YARMOUTH',
    'WEWEANTIC',
    'WEYMOUTH',
    'WHITE ISLAND SHORES',
    'WHITINSVILLE',
    'WILBRAHAM',
    'WILLIAMSTOWN',
    'WILMINGTON',
    'WINCHENDON',
    'WINCHESTER',
    'WINTHROP',
    'WOBURN',
    'WOODS HOLE',
    'WORCESTER',
    'YARMOUTH PORT',
  ],
  Michigan: [
    'ADDISON',
    'ADRIAN',
    'AHMEEK',
    'AKRON',
    'ALANSON',
    'ALBION',
    'ALGONAC',
    'ALLEGAN',
    'ALLEN',
    'ALLENDALE',
    'ALLEN PARK',
    'ALMA',
    'ALMONT',
    'ALPENA',
    'ALPHA',
    'ANN ARBOR',
    'APPLEGATE',
    'ARGENTINE',
    'ARMADA',
    'ASHLEY',
    'ATHENS',
    'ATLANTA',
    'AUBURN',
    'AUBURN HILLS',
    'AU GRES',
    'AUGUSTA',
    'AU SABLE',
    'BAD AXE',
    'BALDWIN',
    'BANCROFT',
    'BANGOR',
    'BARAGA',
    'BARNES LAKE-MILLERS LAKE',
    'BARODA',
    'BARRYTON',
    'BARTON HILLS',
    'BATTLE CREEK',
    'BAY CITY',
    'BEAL CITY',
    'BEAR LAKE',
    'BEAVERTON',
    'BEECHER',
    'BEECHWOOD',
    'BELDING',
    'BELLAIRE',
    'BELLEVILLE',
    'BELLEVUE',
    'BENTON HARBOR',
    'BENTON HEIGHTS',
    'BENZONIA',
    'BERKLEY',
    'BERRIEN SPRINGS',
    'BESSEMER',
    'BEULAH',
    'BEVERLY HILLS',
    'BIG BAY',
    'BIG RAPIDS',
    'BINGHAM FARMS',
    'BIRCH RUN',
    'BIRMINGHAM',
    'BLISSFIELD',
    'BLOOMFIELD HILLS',
    'BLOOMFIELD TOWNSHIP',
    'BLOOMINGDALE',
    'BOYNE CITY',
    'BOYNE FALLS',
    'BRECKENRIDGE',
    'BREEDSVILLE',
    'BRIDGEPORT',
    'BRIDGMAN',
    'BRIGHTON',
    'BRITTON',
    'BRONSON',
    'BROOKLYN',
    'BROWN CITY',
    'BROWNLEE PARK',
    'BUCHANAN',
    'BUCKLEY',
    'BUENA VISTA',
    'BURLINGTON',
    'BURR OAK',
    'BURT',
    'BURTON',
    'BYRON',
    'BYRON CENTER',
    'CADILLAC',
    'CALEDONIA',
    'CALUMET',
    'CAMDEN',
    'CANADA CREEK RANCH',
    'CANADIAN LAKES',
    'CANTON',
    'CAPAC',
    'CARLETON',
    'CARNEY',
    'CARO',
    'CARROLLTON',
    'CARSON CITY',
    'CARSONVILLE',
    'CASEVILLE',
    'CASNOVIA',
    'CASPIAN',
    'CASS CITY',
    'CASSOPOLIS',
    'CEDAR SPRINGS',
    'CEMENT CITY',
    'CENTER LINE',
    'CENTRAL LAKE',
    'CENTREVILLE',
    'CHARLEVOIX',
    'CHARLOTTE',
    'CHATHAM',
    'CHEBOYGAN',
    'CHELSEA',
    'CHESANING',
    'CLARE',
    'CLARKSVILLE',
    'CLAWSON',
    'CLAYTON',
    'CLIFFORD',
    'CLIMAX',
    'CLINTON',
    'CLINTON',
    'CLIO',
    'COLDWATER',
    'COLEMAN',
    'COLOMA',
    'COLON',
    'COLUMBIAVILLE',
    'COMSTOCK NORTHWEST',
    'COMSTOCK PARK',
    'CONCORD',
    'CONSTANTINE',
    'COOPERSVILLE',
    'COPEMISH',
    'COPPER CITY',
    'CORUNNA',
    'CROSWELL',
    'CRYSTAL FALLS',
    'CUSTER',
    'CUTLERVILLE',
    'DAGGETT',
    'DANSVILLE',
    'DAVISON',
    'DEARBORN',
    'DEARBORN HEIGHTS',
    'DECATUR',
    'DECKERVILLE',
    'DEERFIELD',
    'DE TOUR VILLAGE',
    'DETROIT',
    'DETROIT BEACH',
    'DE WITT',
    'DEXTER',
    'DIMONDALE',
    'DOUGLAS',
    'DOWAGIAC',
    'DRYDEN',
    'DUNDEE',
    'DURAND',
    'EAGLE',
    'EAST GRAND RAPIDS',
    'EAST JORDAN',
    'EASTLAKE',
    'EAST LANSING',
    'EASTPOINTE',
    'EAST TAWAS',
    'EASTWOOD',
    'EATON RAPIDS',
    'EAU CLAIRE',
    'ECORSE',
    'EDGEMONT PARK',
    'EDMORE',
    'EDWARDSBURG',
    'ELBERTA',
    'ELK RAPIDS',
    'ELKTON',
    'ELLSWORTH',
    'ELSIE',
    'EMMETT',
    'EMPIRE',
    'ESCANABA',
    'ESSEXVILLE',
    'ESTRAL BEACH',
    'EVART',
    'FAIRGROVE',
    'FAIR PLAIN',
    'FARMINGTON',
    'FARMINGTON HILLS',
    'FARWELL',
    'FENNVILLE',
    'FENTON',
    'FERNDALE',
    'FERRYSBURG',
    'FIFE LAKE',
    'FLAT ROCK',
    'FLINT',
    'FLUSHING',
    'FOREST HILLS',
    'FORESTVILLE',
    'FOUNTAIN',
    'FOWLER',
    'FOWLERVILLE',
    'FRANKENMUTH',
    'FRANKFORT',
    'FRANKLIN',
    'FRASER',
    'FREELAND',
    'FREEPORT',
    'FREE SOIL',
    'FREMONT',
    'FRUITPORT',
    'GAASTRA',
    'GAGETOWN',
    'GAINES',
    'GALESBURG',
    'GALIEN',
    'GARDEN',
    'GARDEN CITY',
    'GAYLORD',
    'GIBRALTAR',
    'GLADSTONE',
    'GLADWIN',
    'GOBLES',
    'GOODRICH',
    'GRAND BEACH',
    'GRAND BLANC',
    'GRAND HAVEN',
    'GRAND LEDGE',
    'GRAND RAPIDS',
    'GRANDVILLE',
    'GRANT',
    'GRASS LAKE',
    'GRAYLING',
    'GREATER GALESBURG',
    'GREENVILLE',
    'GREILICKVILLE',
    'GROSSE ILE',
    'GROSSE POINTE',
    'GROSSE POINTE FARMS',
    'GROSSE POINTE PARK',
    'GROSSE POINTE SHORES',
    'GROSSE POINTE WOODS',
    'GWINN',
    'HAMTRAMCK',
    'HANCOCK',
    'HANOVER',
    'HARBOR BEACH',
    'HARBOR SPRINGS',
    'HARPER WOODS',
    'HARRIETTA',
    'HARRISON',
    'HARRISON',
    'HARRISVILLE',
    'HART',
    'HARTFORD',
    'HARVEY',
    'HASLETT',
    'HASTINGS',
    'HAZEL PARK',
    'HEMLOCK',
    'HERSEY',
    'HESPERIA',
    'HIGHLAND PARK',
    'HILLMAN',
    'HILLSDALE',
    'HOLLAND',
    'HOLLY',
    'HOLT',
    'HOMER',
    'HONOR',
    'HOPKINS',
    'HOUGHTON',
    'HOUGHTON LAKE',
    'HOWARD CITY',
    'HOWELL',
    'HUBBARD LAKE',
    'HUBBARDSTON',
    'HUBBELL',
    'HUDSON',
    'HUDSONVILLE',
    'HUNTINGTON WOODS',
    'IMLAY CITY',
    'INDIAN RIVER',
    'INKSTER',
    'IONIA',
    'IRON MOUNTAIN',
    'IRON RIVER',
    'IRONWOOD',
    'ISHPEMING',
    'ITHACA',
    'JACKSON',
    'JENISON',
    'JONESVILLE',
    'KALAMAZOO',
    'KALEVA',
    'KALKASKA',
    'KEEGO HARBOR',
    'KENT CITY',
    'KENTWOOD',
    'KINDE',
    'KINGSFORD',
    'KINGSLEY',
    'KINGSTON',
    'K. I. SAWYER AFB',
    'LAINGSBURG',
    'LAKE ANGELUS',
    'LAKE ANN',
    'LAKE CITY',
    'LAKE FENTON',
    'LAKE LINDEN',
    'LAKE MICHIGAN BEACH',
    'LAKE ODESSA',
    'LAKE ORION',
    'LAKEVIEW',
    'LAKEWOOD CLUB',
    'LAMBERTVILLE',
    'L ANSE',
    'LANSING',
    'LAPEER',
    'LATHRUP VILLAGE',
    'LAURIUM',
    'LAWRENCE',
    'LAWTON',
    'LENNON',
    'LEONARD',
    'LE ROY',
    'LESLIE',
    'LEVEL PARK-OAK PARK',
    'LEWISTON',
    'LEXINGTON',
    'LINCOLN',
    'LINCOLN PARK',
    'LINDEN',
    'LITCHFIELD',
    'LIVONIA',
    'LOST LAKE WOODS',
    'LOWELL',
    'LUDINGTON',
    'LUNA PIER',
    'LUTHER',
    'LYONS',
    'MCBAIN',
    'MCBRIDE',
    'MACKINAC ISLAND',
    'MACKINAW CITY',
    'MADISON HEIGHTS',
    'MANCELONA',
    'MANCHESTER',
    'MANISTEE',
    'MANISTIQUE',
    'MANITOU BEACH-DEVILS LAKE',
    'MANTON',
    'MAPLE RAPIDS',
    'MARCELLUS',
    'MARINE CITY',
    'MARION',
    'MARLETTE',
    'MARQUETTE',
    'MARSHALL',
    'MARTIN',
    'MARYSVILLE',
    'MASON',
    'MATTAWAN',
    'MAYBEE',
    'MAYVILLE',
    'MECOSTA',
    'MELVIN',
    'MELVINDALE',
    'MEMPHIS',
    'MENDON',
    'MENOMINEE',
    'MERRILL',
    'MESICK',
    'METAMORA',
    'MICHIANA',
    'MICHIGAMME',
    'MICHIGAN CENTER',
    'MIDDLETOWN',
    'MIDDLEVILLE',
    'MIDLAND',
    'MILAN',
    'MILFORD',
    'MILLERSBURG',
    'MILLINGTON',
    'MINDEN CITY',
    'MINERAL HILLS',
    'MIO',
    'MONROE',
    'MONTAGUE',
    'MONTGOMERY',
    'MONTROSE',
    'MORENCI',
    'MORLEY',
    'MORRICE',
    'MOUNT CLEMENS',
    'MOUNT MORRIS',
    'MOUNT PLEASANT',
    'MUIR',
    'MULLIKEN',
    'MUNISING',
    'MUSKEGON',
    'MUSKEGON HEIGHTS',
    'NAPOLEON',
    'NASHVILLE',
    'NEGAUNEE',
    'NEWAYGO',
    'NEW BALTIMORE',
    'NEWBERRY',
    'NEW BUFFALO',
    'NEW ERA',
    'NEW HAVEN',
    'NEW LOTHROP',
    'NILES',
    'NORTH ADAMS',
    'NORTH BRANCH',
    'NORTH MUSKEGON',
    'NORTHPORT',
    'NORTHVIEW',
    'NORTHVILLE',
    'NORTON SHORES',
    'NORWAY',
    'NOVI',
    'OAKLEY',
    'OAK PARK',
    'OKEMOS',
    'OLIVET',
    'OMER',
    'ONAWAY',
    'ONEKAMA',
    'ONSTED',
    'ONTONAGON',
    'ORCHARD LAKE VILLAGE',
    'ORTONVILLE',
    'OSCODA',
    'OSSINEKE',
    'OTISVILLE',
    'OTSEGO',
    'OTTER LAKE',
    'OVID',
    'OWENDALE',
    'OWOSSO',
    'OXFORD',
    'PALMER',
    'PARCHMENT',
    'PARMA',
    'PAW PAW',
    'PAW PAW LAKE',
    'PEARL BEACH',
    'PECK',
    'PELLSTON',
    'PENTWATER',
    'PERRINTON',
    'PERRY',
    'PETERSBURG',
    'PETOSKEY',
    'PEWAMO',
    'PIERSON',
    'PIGEON',
    'PINCKNEY',
    'PINCONNING',
    'PLAINWELL',
    'PLEASANT RIDGE',
    'PLYMOUTH',
    'PLYMOUTH TOWNSHIP',
    'PONTIAC',
    'PORTAGE',
    'PORT AUSTIN',
    'PORT HOPE',
    'PORT HURON',
    'PORTLAND',
    'PORT SANILAC',
    'POSEN',
    'POTTERVILLE',
    'POWERS',
    'PRESCOTT',
    'PRUDENVILLE',
    'QUINCY',
    'QUINNESEC',
    'RAVENNA',
    'READING',
    'REDFORD',
    'REED CITY',
    'REESE',
    'REPUBLIC',
    'RICHLAND',
    'RICHMOND',
    'RIVER ROUGE',
    'RIVERVIEW',
    'ROBIN GLEN-INDIANTOWN',
    'ROCHESTER',
    'ROCHESTER HILLS',
    'ROCKFORD',
    'ROCKWOOD',
    'ROGERS CITY',
    'ROMEO',
    'ROMULUS',
    'ROOSEVELT PARK',
    'ROSCOMMON',
    'ROSEBUSH',
    'ROSE CITY',
    'ROSEVILLE',
    'ROTHBURY',
    'ROYAL OAK',
    'SAGINAW',
    'SAGINAW TOWNSHIP NORTH',
    'SAGINAW TOWNSHIP SOUTH',
    'ST. CHARLES',
    'ST. CLAIR',
    'ST. CLAIR SHORES',
    'ST. HELEN',
    'ST. IGNACE',
    'ST. JOHNS',
    'ST. JOSEPH',
    'ST. LOUIS',
    'SALINE',
    'SAND LAKE',
    'SANDUSKY',
    'SANFORD',
    'SARANAC',
    'SAUGATUCK',
    'SAULT STE. MARIE',
    'SCHOOLCRAFT',
    'SCOTTVILLE',
    'SEBEWAING',
    'SHELBY',
    'SHELBY',
    'SHEPHERD',
    'SHERIDAN',
    'SHERWOOD',
    'SHIELDS',
    'SHOREHAM',
    'SHOREWOOD-TOWER HILLS-HARBERT',
    'SKIDWAY LAKE',
    'SOUTHFIELD',
    'SOUTHGATE',
    'SOUTH GULL LAKE',
    'SOUTH HAVEN',
    'SOUTH LYON',
    'SOUTH MONROE',
    'SOUTH RANGE',
    'SOUTH ROCKWOOD',
    'SPARTA',
    'SPRING ARBOR',
    'SPRINGFIELD',
    'SPRING LAKE',
    'SPRINGPORT',
    'STAMBAUGH',
    'STANDISH',
    'STANTON',
    'STANWOOD',
    'STEPHENSON',
    'STERLING',
    'STERLING HEIGHTS',
    'STEVENSVILLE',
    'STOCKBRIDGE',
    'STONY POINT',
    'STURGIS',
    'SUNFIELD',
    'SUTTONS BAY',
    'SWARTZ CREEK',
    'SYLVAN LAKE',
    'TAWAS CITY',
    'TAYLOR',
    'TECUMSEH',
    'TEKONSHA',
    'TEMPERANCE',
    'THOMPSONVILLE',
    'THREE OAKS',
    'THREE RIVERS',
    'TRAVERSE CITY',
    'TRENTON',
    'TROWBRIDGE PARK',
    'TROY',
    'TURNER',
    'TUSTIN',
    'TWINING',
    'TWIN LAKE',
    'UBLY',
    'UNION CITY',
    'UNIONVILLE',
    'UTICA',
    'VANDALIA',
    'VANDERBILT',
    'VANDERCOOK LAKE',
    'VASSAR',
    'VERMONTVILLE',
    'VERNON',
    'VICKSBURG',
    'VILLAGE OF CLARKSTON',
    'VILLAGE OF LAKE ISABELLA',
    'WAKEFIELD',
    'WALDRON',
    'WALKER',
    'WALKERVILLE',
    'WALLED LAKE',
    'WARREN',
    'WATERFORD',
    'WATERVLIET',
    'WAVERLY',
    'WAYLAND',
    'WAYNE',
    'WEBBERVILLE',
    'WEIDMAN',
    'WEST BLOOMFIELD TOWNSHIP',
    'WEST BRANCH',
    'WEST ISHPEMING',
    'WESTLAND',
    'WEST MONROE',
    'WESTPHALIA',
    'WESTWOOD',
    'WHITE CLOUD',
    'WHITEHALL',
    'WHITE PIGEON',
    'WHITMORE LAKE',
    'WHITTEMORE',
    'WILLIAMSTON',
    'WIXOM',
    'WOLF LAKE',
    'WOLVERINE',
    'WOLVERINE LAKE',
    'WOODHAVEN',
    'WOODLAND',
    'WOODLAND BEACH',
    'WYANDOTTE',
    'WYOMING',
    'YALE',
    'YPSILANTI',
    'ZEELAND',
    'ZILWAUKEE',
  ],
  Minnesota: [
    'ADA',
    'ADAMS',
    'ADRIAN',
    'AFTON',
    'AITKIN',
    'AKELEY',
    'ALBANY',
    'ALBERTA',
    'ALBERT LEA',
    'ALBERTVILLE',
    'ALDEN',
    'ALDRICH',
    'ALEXANDRIA',
    'ALPHA',
    'ALTURA',
    'ALVARADO',
    'AMBOY',
    'ANDOVER',
    'ANNANDALE',
    'ANOKA',
    'APPLETON',
    'APPLE VALLEY',
    'ARCO',
    'ARDEN HILLS',
    'ARGYLE',
    'ARLINGTON',
    'ARNOLD',
    'ASHBY',
    'ASKOV',
    'ATWATER',
    'AUDUBON',
    'AURORA',
    'AUSTIN',
    'AVOCA',
    'AVON',
    'BABBITT',
    'BACKUS',
    'BADGER',
    'BAGLEY',
    'BALATON',
    'BARNESVILLE',
    'BARNUM',
    'BARRETT',
    'BARRY',
    'BATTLE LAKE',
    'BAUDETTE',
    'BAXTER',
    'BAYPORT',
    'BEARDSLEY',
    'BEAVER BAY',
    'BEAVER CREEK',
    'BECKER',
    'BEJOU',
    'BELGRADE',
    'BELLECHESTER',
    'BELLE PLAINE',
    'BELLINGHAM',
    'BELTRAMI',
    'BELVIEW',
    'BEMIDJI',
    'BENA',
    'BENSON',
    'BERTHA',
    'BETHEL',
    'BIGELOW',
    'BIG FALLS',
    'BIGFORK',
    'BIG LAKE',
    'BINGHAM LAKE',
    'BIRCHWOOD VILLAGE',
    'BIRD ISLAND',
    'BISCAY',
    'BIWABIK',
    'BLACKDUCK',
    'BLAINE',
    'BLOMKEST',
    'BLOOMING PRAIRIE',
    'BLOOMINGTON',
    'BLUE EARTH',
    'BLUFFTON',
    'BOCK',
    'BORUP',
    'BOVEY',
    'BOWLUS',
    'BOYD',
    'BOY RIVER',
    'BRAHAM',
    'BRAINERD',
    'BRANDON',
    'BRECKENRIDGE',
    'BREEZY POINT',
    'BREWSTER',
    'BRICELYN',
    'BROOKLYN CENTER',
    'BROOKLYN PARK',
    'BROOK PARK',
    'BROOKS',
    'BROOKSTON',
    'BROOTEN',
    'BROWERVILLE',
    'BROWNSDALE',
    'BROWNS VALLEY',
    'BROWNSVILLE',
    'BROWNTON',
    'BRUNO',
    'BUCKMAN',
    'BUFFALO',
    'BUFFALO LAKE',
    'BUHL',
    'BURNSVILLE',
    'BURTRUM',
    'BUTTERFIELD',
    'BYRON',
    'CALEDONIA',
    'CALLAWAY',
    'CALUMET',
    'CAMBRIDGE',
    'CAMPBELL',
    'CANBY',
    'CANNON FALLS',
    'CANTON',
    'CARLOS',
    'CARLTON',
    'CARVER',
    'CASS LAKE',
    'CEDAR MILLS',
    'CENTER CITY',
    'CENTERVILLE',
    'CEYLON',
    'CHAMPLIN',
    'CHANDLER',
    'CHANHASSEN',
    'CHASKA',
    'CHATFIELD',
    'CHICKAMAW BEACH',
    'CHISAGO CITY',
    'CHISHOLM',
    'CHOKIO',
    'CIRCLE PINES',
    'CLARA CITY',
    'CLAREMONT',
    'CLARISSA',
    'CLARKFIELD',
    'CLARKS GROVE',
    'CLEARBROOK',
    'CLEAR LAKE',
    'CLEARWATER',
    'CLEMENTS',
    'CLEVELAND',
    'CLIMAX',
    'CLINTON',
    'CLITHERALL',
    'CLONTARF',
    'CLOQUET',
    'COATES',
    'COBDEN',
    'COHASSET',
    'COKATO',
    'COLD SPRING',
    'COLERAINE',
    'COLOGNE',
    'COLUMBIA HEIGHTS',
    'COMFREY',
    'COMSTOCK',
    'CONGER',
    'COOK',
    'COON RAPIDS',
    'CORCORAN',
    'CORRELL',
    'COSMOS',
    'COTTAGE GROVE',
    'COTTONWOOD',
    'COURTLAND',
    'CROMWELL',
    'CROOKSTON',
    'CROSBY',
    'CROSSLAKE',
    'CRYSTAL',
    'CURRIE',
    'CUYUNA',
    'CYRUS',
    'DAKOTA',
    'DALTON',
    'DANUBE',
    'DANVERS',
    'DARFUR',
    'DARWIN',
    'DASSEL',
    'DAWSON',
    'DAYTON',
    'DEEPHAVEN',
    'DEER CREEK',
    'DEER RIVER',
    'DEERWOOD',
    'DE GRAFF',
    'DELANO',
    'DELAVAN',
    'DELHI',
    'DELLWOOD',
    'DENHAM',
    'DENNISON',
    'DENT',
    'DETROIT LAKES',
    'DEXTER',
    'DILWORTH',
    'DODGE CENTER',
    'DONALDSON',
    'DONNELLY',
    'DORAN',
    'DOVER',
    'DOVRAY',
    'DULUTH',
    'DUMONT',
    'DUNDAS',
    'DUNDEE',
    'DUNNELL',
    'EAGAN',
    'EAGLE BEND',
    'EAGLE LAKE',
    'EAST BETHEL',
    'EAST GRAND FORKS',
    'EAST GULL LAKE',
    'EASTON',
    'ECHO',
    'EDEN PRAIRIE',
    'EDEN VALLEY',
    'EDGERTON',
    'EDINA',
    'EFFIE',
    'EITZEN',
    'ELBA',
    'ELBOW LAKE',
    'ELBOW LAKE',
    'ELGIN',
    'ELIZABETH',
    'ELKO',
    'ELK RIVER',
    'ELKTON',
    'ELLENDALE',
    'ELLSWORTH',
    'ELMDALE',
    'ELMORE',
    'ELROSA',
    'ELY',
    'ELYSIAN',
    'EMILY',
    'EMMONS',
    'ERHARD',
    'ERSKINE',
    'EVAN',
    'EVANSVILLE',
    'EVELETH',
    'EXCELSIOR',
    'EYOTA',
    'FAIRFAX',
    'FAIRMONT',
    'FALCON HEIGHTS',
    'FARIBAULT',
    'FARMINGTON',
    'FARWELL',
    'FEDERAL DAM',
    'FELTON',
    'FERGUS FALLS',
    'FERTILE',
    'FIFTY LAKES',
    'FINLAYSON',
    'FISHER',
    'FLENSBURG',
    'FLOODWOOD',
    'FLORENCE',
    'FOLEY',
    'FORADA',
    'FOREST LAKE',
    'FORESTON',
    'FORT RIPLEY',
    'FOSSTON',
    'FOUNTAIN',
    'FOXHOME',
    'FRANKLIN',
    'FRAZEE',
    'FREEBORN',
    'FREEPORT',
    'FRIDLEY',
    'FROST',
    'FULDA',
    'FUNKLEY',
    'GARFIELD',
    'GARRISON',
    'GARVIN',
    'GARY',
    'GAYLORD',
    'GEM LAKE',
    'GENEVA',
    'GENOLA',
    'GEORGETOWN',
    'GHENT',
    'GIBBON',
    'GILBERT',
    'GILMAN',
    'GLENCOE',
    'GLENVILLE',
    'GLENWOOD',
    'GLYNDON',
    'GOLDEN VALLEY',
    'GONVICK',
    'GOODHUE',
    'GOODRIDGE',
    'GOOD THUNDER',
    'GOODVIEW',
    'GRACEVILLE',
    'GRANADA',
    'GRAND MARAIS',
    'GRAND MEADOW',
    'GRAND RAPIDS',
    'GRANITE FALLS',
    'GRANT',
    'GRASSTON',
    'GREENBUSH',
    'GREENFIELD',
    'GREEN ISLE',
    'GREENWALD',
    'GREENWOOD',
    'GREY EAGLE',
    'GROVE CITY',
    'GRYGLA',
    'GULLY',
    'HACKENSACK',
    'HADLEY',
    'HALLOCK',
    'HALMA',
    'HALSTAD',
    'HAMBURG',
    'HAM LAKE',
    'HAMMOND',
    'HAMPTON',
    'HANCOCK',
    'HANLEY FALLS',
    'HANOVER',
    'HANSKA',
    'HARDING',
    'HARDWICK',
    'HARMONY',
    'HARRIS',
    'HARTLAND',
    'HASTINGS',
    'HATFIELD',
    'HAWLEY',
    'HAYFIELD',
    'HAYWARD',
    'HAZEL RUN',
    'HECTOR',
    'HEIDELBERG',
    'HENDERSON',
    'HENDRICKS',
    'HENDRUM',
    'HENNING',
    'HENRIETTE',
    'HERMAN',
    'HERMANTOWN',
    'HERON LAKE',
    'HEWITT',
    'HIBBING',
    'HILL CITY',
    'HILLMAN',
    'HILLS',
    'HILLTOP',
    'HINCKLEY',
    'HITTERDAL',
    'HOFFMAN',
    'HOKAH',
    'HOLDINGFORD',
    'HOLLAND',
    'HOLLANDALE',
    'HOLLOWAY',
    'HOLT',
    'HOPKINS',
    'HOUSTON',
    'HOWARD LAKE',
    'HOYT LAKES',
    'HUGO',
    'HUMBOLDT',
    'HUTCHINSON',
    'IHLEN',
    'INDEPENDENCE',
    'INTERNATIONAL FALLS',
    'INVER GROVE HEIGHTS',
    'IONA',
    'IRON JUNCTION',
    'IRONTON',
    'ISANTI',
    'ISLE',
    'IVANHOE',
    'JACKSON',
    'JANESVILLE',
    'JASPER',
    'JEFFERS',
    'JENKINS',
    'JOHNSON',
    'JORDAN',
    'KANDIYOHI',
    'KARLSTAD',
    'KASOTA',
    'KASSON',
    'KEEWATIN',
    'KELLIHER',
    'KELLOGG',
    'KENNEDY',
    'KENNETH',
    'KENSINGTON',
    'KENT',
    'KENYON',
    'KERKHOVEN',
    'KERRICK',
    'KETTLE RIVER',
    'KIESTER',
    'KILKENNY',
    'KIMBALL',
    'KINBRAE',
    'KINGSTON',
    'KINNEY',
    'LA CRESCENT',
    'LAFAYETTE',
    'LAKE BENTON',
    'LAKE BRONSON',
    'LAKE CITY',
    'LAKE CRYSTAL',
    'LAKE ELMO',
    'LAKEFIELD',
    'LAKE HENRY',
    'LAKELAND',
    'LAKELAND SHORES',
    'LAKE LILLIAN',
    'LAKE PARK',
    'LAKE ST. CROIX BEACH',
    'LAKE SHORE',
    'LAKEVILLE',
    'LAKE WILSON',
    'LAMBERTON',
    'LANCASTER',
    'LANDFALL',
    'LANESBORO',
    'LAPORTE',
    'LA PRAIRIE',
    'LA SALLE',
    'LASTRUP',
    'LAUDERDALE',
    'LE CENTER',
    'LENGBY',
    'LEONARD',
    'LEONIDAS',
    'LEOTA',
    'LE ROY',
    'LESTER PRAIRIE',
    'LE SUEUR',
    'LEWISTON',
    'LEWISVILLE',
    'LEXINGTON',
    'LILYDALE',
    'LINDSTROM',
    'LINO LAKES',
    'LISMORE',
    'LITCHFIELD',
    'LITTLE CANADA',
    'LITTLE FALLS',
    'LITTLEFORK',
    'LITTLE ROCK',
    'LONG BEACH',
    'LONG LAKE',
    'LONG PRAIRIE',
    'LONGVILLE',
    'LONSDALE',
    'LORETTO',
    'LOUISBURG',
    'LOWRY',
    'LUCAN',
    'LUVERNE',
    'LYLE',
    'LYND',
    'MABEL',
    'MCGRATH',
    'MCGREGOR',
    'MCINTOSH',
    'MCKINLEY',
    'MADELIA',
    'MADISON',
    'MADISON LAKE',
    'MAGNOLIA',
    'MAHNOMEN',
    'MAHTOMEDI',
    'MANCHESTER',
    'MANHATTAN BEACH',
    'MANKATO',
    'MANTORVILLE',
    'MAPLE GROVE',
    'MAPLE LAKE',
    'MAPLE PLAIN',
    'MAPLETON',
    'MAPLEVIEW',
    'MAPLEWOOD',
    'MARBLE',
    'MARIETTA',
    'MARINE ON ST. CROIX',
    'MARSHALL',
    'MAYER',
    'MAYNARD',
    'MAZEPPA',
    'MEADOWLANDS',
    'MEDFORD',
    'MEDICINE LAKE',
    'MEDINA',
    'MEIRE GROVE',
    'MELROSE',
    'MENAHGA',
    'MENDOTA',
    'MENDOTA HEIGHTS',
    'MENTOR',
    'MIDDLE RIVER',
    'MIESVILLE',
    'MILACA',
    'MILAN',
    'MILLERVILLE',
    'MILLVILLE',
    'MILROY',
    'MILTONA',
    'MINNEAPOLIS',
    'MINNEISKA',
    'MINNEOTA',
    'MINNESOTA CITY',
    'MINNESOTA LAKE',
    'MINNETONKA',
    'MINNETONKA BEACH',
    'MINNETRISTA',
    'MIZPAH',
    'MONTEVIDEO',
    'MONTGOMERY',
    'MONTICELLO',
    'MONTROSE',
    'MOORHEAD',
    'MOOSE LAKE',
    'MORA',
    'MORGAN',
    'MORRIS',
    'MORRISTOWN',
    'MORTON',
    'MOTLEY',
    'MOUND',
    'MOUNDS VIEW',
    'MOUNTAIN IRON',
    'MOUNTAIN LAKE',
    'MURDOCK',
    'MYRTLE',
    'NASHUA',
    'NASHWAUK',
    'NASSAU',
    'NAYTAHWAUSH',
    'NELSON',
    'NERSTRAND',
    'NEVIS',
    'NEW AUBURN',
    'NEW BRIGHTON',
    'NEWFOLDEN',
    'NEW GERMANY',
    'NEW HOPE',
    'NEW LONDON',
    'NEW MARKET',
    'NEW MUNICH',
    'NEWPORT',
    'NEW PRAGUE',
    'NEW RICHLAND',
    'NEW TRIER',
    'NEW ULM',
    'NEW YORK MILLS',
    'NICOLLET',
    'NIELSVILLE',
    'NIMROD',
    'NISSWA',
    'NORCROSS',
    'NORTH BRANCH',
    'NORTHFIELD',
    'NORTH MANKATO',
    'NORTH OAKS',
    'NORTHOME',
    'NORTHROP',
    'NORTH ST. PAUL',
    'NORWOOD YOUNG AMERICA',
    'OAKDALE',
    'OAK GROVE',
    'OAK PARK HEIGHTS',
    'OAKPORT',
    'ODESSA',
    'ODIN',
    'OGEMA',
    'OGILVIE',
    'OKABENA',
    'OKLEE',
    'OLIVIA',
    'ONAMIA',
    'ORMSBY',
    'ORONO',
    'ORONOCO',
    'ORR',
    'ORTONVILLE',
    'OSAKIS',
    'OSLO',
    'OSSEO',
    'OSTRANDER',
    'OTSEGO',
    'OTTERTAIL',
    'OWATONNA',
    'PALISADE',
    'PARKERS PRAIRIE',
    'PARK RAPIDS',
    'PAYNESVILLE',
    'PEASE',
    'PELICAN RAPIDS',
    'PEMBERTON',
    'PENNOCK',
    'PEQUOT LAKES',
    'PERHAM',
    'PERLEY',
    'PETERSON',
    'PIERZ',
    'PILLAGER',
    'PINE CITY',
    'PINE ISLAND',
    'PINE POINT',
    'PINE RIVER',
    'PINE SPRINGS',
    'PIPESTONE',
    'PLAINVIEW',
    'PLATO',
    'PLEASANT LAKE',
    'PLUMMER',
    'PLYMOUTH',
    'PONEMAH',
    'PORTER',
    'PRESTON',
    'PRINCETON',
    'PRINSBURG',
    'PRIOR LAKE',
    'PROCTOR',
    'QUAMBA',
    'RACINE',
    'RAMSEY',
    'RANDALL',
    'RANDOLPH',
    'RANIER',
    'RAYMOND',
    'REDBY',
    'RED LAKE',
    'RED LAKE FALLS',
    'RED WING',
    'REDWOOD FALLS',
    'REGAL',
    'REMER',
    'RENVILLE',
    'REVERE',
    'RICE',
    'RICE LAKE',
    'RICHFIELD',
    'RICHMOND',
    'RICHVILLE',
    'RIVERTON',
    'ROBBINSDALE',
    'ROCHESTER',
    'ROCK CREEK',
    'ROCKFORD',
    'ROCKVILLE',
    'ROGERS',
    'ROLLINGSTONE',
    'RONNEBY',
    'ROOSEVELT',
    'ROSCOE',
    'ROSEAU',
    'ROSE CREEK',
    'ROSEMOUNT',
    'ROSEVILLE',
    'ROTHSAY',
    'ROUND LAKE',
    'ROYALTON',
    'RUSH CITY',
    'RUSHFORD',
    'RUSHFORD VILLAGE',
    'RUSHMORE',
    'RUSSELL',
    'RUTHTON',
    'RUTLEDGE',
    'SABIN',
    'SACRED HEART',
    'ST. ANTHONY',
    'ST. ANTHONY',
    'ST. BONIFACIUS',
    'ST. CHARLES',
    'ST. CLAIR',
    'ST. CLOUD',
    'ST. FRANCIS',
    'ST. HILAIRE',
    'ST. JAMES',
    'ST. JOSEPH',
    'ST. LEO',
    'ST. LOUIS PARK',
    'ST. MARTIN',
    'ST. MARYS POINT',
    'ST. MICHAEL',
    'ST. PAUL',
    'ST. PAUL PARK',
    'ST. PETER',
    'ST. ROSA',
    'ST. STEPHEN',
    'ST. VINCENT',
    'SANBORN',
    'SANDSTONE',
    'SARGEANT',
    'SARTELL',
    'SAUK CENTRE',
    'SAUK RAPIDS',
    'SAVAGE',
    'SCANLON',
    'SEAFORTH',
    'SEBEKA',
    'SEDAN',
    'SHAFER',
    'SHAKOPEE',
    'SHELLY',
    'SHERBURN',
    'SHEVLIN',
    'SHOREVIEW',
    'SHOREWOOD',
    'SILVER BAY',
    'SILVER LAKE',
    'SKYLINE',
    'SLAYTON',
    'SLEEPY EYE',
    'SOBIESKI',
    'SOLWAY',
    'SOUTH HAVEN',
    'SOUTH ST. PAUL',
    'SPICER',
    'SPRINGFIELD',
    'SPRING GROVE',
    'SPRING HILL',
    'SPRING LAKE PARK',
    'SPRING PARK',
    'SPRING VALLEY',
    'SQUAW LAKE',
    'STACY',
    'STAPLES',
    'STARBUCK',
    'STEEN',
    'STEPHEN',
    'STEWART',
    'STEWARTVILLE',
    'STILLWATER',
    'STOCKTON',
    'STORDEN',
    'STRANDQUIST',
    'STRATHCONA',
    'STURGEON LAKE',
    'SUNBURG',
    'SUNFISH LAKE',
    'SWANVILLE',
    'TACONITE',
    'TAMARACK',
    'TAOPI',
    'TAUNTON',
    'TAYLORS FALLS',
    'TENNEY',
    'TENSTRIKE',
    'THE LAKES',
    'THIEF RIVER FALLS',
    'THOMSON',
    'TINTAH',
    'TONKA BAY',
    'TOWER',
    'TRACY',
    'TRAIL',
    'TRIMONT',
    'TROMMALD',
    'TROSKY',
    'TRUMAN',
    'TURTLE RIVER',
    'TWIN LAKES',
    'TWIN VALLEY',
    'TWO HARBORS',
    'TYLER',
    'ULEN',
    'UNDERWOOD',
    'UPSALA',
    'URBANK',
    'UTICA',
    'VADNAIS HEIGHTS',
    'VERGAS',
    'VERMILLION',
    'VERNDALE',
    'VERNON CENTER',
    'VESTA',
    'VICTORIA',
    'VIKING',
    'VILLARD',
    'VINELAND',
    'VINING',
    'VIRGINIA',
    'WABASHA',
    'WABASSO',
    'WACONIA',
    'WADENA',
    'WAHKON',
    'WAITE PARK',
    'WALDORF',
    'WALKER',
    'WALNUT GROVE',
    'WALTERS',
    'WALTHAM',
    'WANAMINGO',
    'WANDA',
    'WARBA',
    'WARREN',
    'WARROAD',
    'WASECA',
    'WATERTOWN',
    'WATERVILLE',
    'WATKINS',
    'WATSON',
    'WAUBUN',
    'WAVERLY',
    'WAYZATA',
    'WELCOME',
    'WELLS',
    'WENDELL',
    'WESTBROOK',
    'WEST CONCORD',
    'WESTPORT',
    'WEST ST. PAUL',
    'WEST UNION',
    'WHALAN',
    'WHEATON',
    'WHITE BEAR LAKE',
    'WHITE EARTH',
    'WILDER',
    'WILLERNIE',
    'WILLIAMS',
    'WILLMAR',
    'WILLOW RIVER',
    'WILMONT',
    'WILTON',
    'WINDOM',
    'WINGER',
    'WINNEBAGO',
    'WINONA',
    'WINSTED',
    'WINTHROP',
    'WINTON',
    'WOLF LAKE',
    'WOLVERTON',
    'WOODBURY',
    'WOOD LAKE',
    'WOODLAND',
    'WOODSTOCK',
    'WORTHINGTON',
    'WRENSHALL',
    'WRIGHT',
    'WYKOFF',
    'WYOMING',
    'ZEMPLE',
    'ZIMMERMAN',
    'ZUMBRO FALLS',
    'ZUMBROTA',
  ],
  Mississippi: [
    'ABBEVILLE',
    'ABERDEEN',
    'ACKERMAN',
    'ALGOMA',
    'ALLIGATOR',
    'AMORY',
    'ANGUILLA',
    'ARCOLA',
    'ARTESIA',
    'ASHLAND',
    'BALDWYN',
    'BASSFIELD',
    'BATESVILLE',
    'BAY ST. LOUIS',
    'BAY SPRINGS',
    'BEAUMONT',
    'BEAUREGARD',
    'BELMONT',
    'BELZONI',
    'BENOIT',
    'BENTONIA',
    'BEULAH',
    'BIG CREEK',
    'BIG POINT',
    'BILOXI',
    'BLUE MOUNTAIN',
    'BLUE SPRINGS',
    'BOGUE CHITTO',
    'BOLTON',
    'BOONEVILLE',
    'BOYLE',
    'BRANDON',
    'BRAXTON',
    'BROOKHAVEN',
    'BROOKSVILLE',
    'BRUCE',
    'BUDE',
    'BURNSVILLE',
    'BYHALIA',
    'BYRAM',
    'CALEDONIA',
    'CALHOUN CITY',
    'CANTON',
    'CARROLLTON',
    'CARTHAGE',
    'CARY',
    'CENTREVILLE',
    'CHARLESTON',
    'CHUNKY',
    'CLARKSDALE',
    'CLEVELAND',
    'CLINTON',
    'COAHOMA',
    'COFFEEVILLE',
    'COLDWATER',
    'COLLINS',
    'COLLINSVILLE',
    'COLUMBIA',
    'COLUMBUS',
    'COLUMBUS AFB',
    'COMO',
    'CONEHATTA',
    'CORINTH',
    'COURTLAND',
    'CRAWFORD',
    'CRENSHAW',
    'CROSBY',
    'CROWDER',
    'CRUGER',
    'CRYSTAL SPRINGS',
    'DECATUR',
    'DE KALB',
    'DERMA',
    'DIAMONDHEAD',
    'D IBERVILLE',
    'D LO',
    'DODDSVILLE',
    'DREW',
    'DUCK HILL',
    'DUMAS',
    'DUNCAN',
    'DURANT',
    'ECRU',
    'EDEN',
    'EDWARDS',
    'ELLISVILLE',
    'ENTERPRISE',
    'ESCATAWPA',
    'ETHEL',
    'EUPORA',
    'FALCON',
    'FALKNER',
    'FARMINGTON',
    'FAYETTE',
    'FLORA',
    'FLORENCE',
    'FLOWOOD',
    'FOREST',
    'FRENCH CAMP',
    'FRIARS POINT',
    'FULTON',
    'GATTMAN',
    'GAUTIER',
    'GEORGETOWN',
    'GLEN',
    'GLENDORA',
    'GLOSTER',
    'GOLDEN',
    'GOODMAN',
    'GREENVILLE',
    'GREENWOOD',
    'GRENADA',
    'GULF HILLS',
    'GULF PARK ESTATES',
    'GULFPORT',
    'GUNNISON',
    'GUNTOWN',
    'HATLEY',
    'HATTIESBURG',
    'HAZLEHURST',
    'HEIDELBERG',
    'HELENA',
    'HERNANDO',
    'HICKORY',
    'HICKORY FLAT',
    'HICKORY HILLS',
    'HOLLANDALE',
    'HOLLY SPRINGS',
    'HORN LAKE',
    'HOUSTON',
    'HURLEY',
    'INDIANOLA',
    'INVERNESS',
    'ISOLA',
    'ITTA BENA',
    'IUKA',
    'JACKSON',
    'JONESTOWN',
    'JUMPERTOWN',
    'KILMICHAEL',
    'KILN',
    'KOSCIUSKO',
    'KOSSUTH',
    'LAKE',
    'LAMBERT',
    'LATIMER',
    'LAUREL',
    'LEAKESVILLE',
    'LEARNED',
    'LELAND',
    'LENA',
    'LEXINGTON',
    'LIBERTY',
    'LONG BEACH',
    'LOUIN',
    'LOUISE',
    'LOUISVILLE',
    'LUCEDALE',
    'LULA',
    'LUMBERTON',
    'LYMAN',
    'LYNCHBURG',
    'LYON',
    'MABEN',
    'MCCOMB',
    'MCCOOL',
    'MCLAIN',
    'MACON',
    'MADISON',
    'MAGEE',
    'MAGNOLIA',
    'MANTACHIE',
    'MANTEE',
    'MARIETTA',
    'MARION',
    'MARKS',
    'MATHISTON',
    'MAYERSVILLE',
    'MEADVILLE',
    'MEMPHIS',
    'MENDENHALL',
    'MERIDIAN',
    'MERIDIAN STATION',
    'MERIGOLD',
    'METCALFE',
    'MIZE',
    'MONTICELLO',
    'MONTROSE',
    'MOORHEAD',
    'MORGAN CITY',
    'MORTON',
    'MOSS POINT',
    'MOUND BAYOU',
    'MOUNT OLIVE',
    'MYRTLE',
    'NATCHEZ',
    'NELLIEBURG',
    'NETTLETON',
    'NEW ALBANY',
    'NEW AUGUSTA',
    'NEW HEBRON',
    'NEW HOPE',
    'NEW HOULKA',
    'NEWTON',
    'NORTH CARROLLTON',
    'NORTH TUNICA',
    'NOXAPATER',
    'OAKLAND',
    'OCEAN SPRINGS',
    'OKOLONA',
    'OLIVE BRANCH',
    'OSYKA',
    'OXFORD',
    'PACE',
    'PACHUTA',
    'PADEN',
    'PASCAGOULA',
    'PASS CHRISTIAN',
    'PEARL',
    'PEARLINGTON',
    'PEARL RIVER',
    'PELAHATCHIE',
    'PETAL',
    'PHILADELPHIA',
    'PICAYUNE',
    'PICKENS',
    'PITTSBORO',
    'PLANTERSVILLE',
    'POLKVILLE',
    'PONTOTOC',
    'POPE',
    'POPLARVILLE',
    'PORT GIBSON',
    'POTTS CAMP',
    'PRENTISS',
    'PUCKETT',
    'PURVIS',
    'QUITMAN',
    'RALEIGH',
    'RAYMOND',
    'REDWATER',
    'RENOVA',
    'RICHLAND',
    'RICHTON',
    'RIDGELAND',
    'RIENZI',
    'RIPLEY',
    'ROLLING FORK',
    'ROSEDALE',
    'ROXIE',
    'RULEVILLE',
    'ST. MARTIN',
    'SALLIS',
    'SALTILLO',
    'SANDERSVILLE',
    'SARDIS',
    'SATARTIA',
    'SAUCIER',
    'SCHLATER',
    'SCOOBA',
    'SEBASTOPOL',
    'SEMINARY',
    'SENATOBIA',
    'SHANNON',
    'SHAW',
    'SHELBY',
    'SHERMAN',
    'SHORELINE PARK',
    'SHUBUTA',
    'SHUQUALAK',
    'SIDON',
    'SILVER CITY',
    'SILVER CREEK',
    'SLATE SPRINGS',
    'SLEDGE',
    'SMITHVILLE',
    'SNOW LAKE SHORES',
    'SOSO',
    'SOUTHAVEN',
    'STANDING PINE',
    'STARKVILLE',
    'STATE LINE',
    'STONEWALL',
    'STURGIS',
    'SUMMIT',
    'SUMNER',
    'SUMRALL',
    'SUNFLOWER',
    'SYLVARENA',
    'TAYLOR',
    'TAYLORSVILLE',
    'TCHULA',
    'TERRY',
    'THAXTON',
    'TILLATOBA',
    'TISHOMINGO',
    'TOCCOPOLA',
    'TREMONT',
    'TUCKER',
    'TUNICA',
    'TUPELO',
    'TUTWILER',
    'TYLERTOWN',
    'UNION',
    'UTICA',
    'VAIDEN',
    'VANCLEAVE',
    'VARDAMAN',
    'VERONA',
    'VICKSBURG',
    'WADE',
    'WALNUT',
    'WALNUT GROVE',
    'WALTHALL',
    'WATER VALLEY',
    'WAVELAND',
    'WAYNESBORO',
    'WEBB',
    'WEIR',
    'WESSON',
    'WEST',
    'WEST HATTIESBURG',
    'WEST POINT',
    'WIGGINS',
    'WINONA',
    'WINSTONVILLE',
    'WOODLAND',
    'WOODVILLE',
    'YAZOO CITY',
  ],
  Missouri: [
    'ADRIAN',
    'ADVANCE',
    'AFFTON',
    'AGENCY',
    'AIRPORT DRIVE',
    'ALBA',
    'ALBANY',
    'ALDRICH',
    'ALEXANDRIA',
    'ALLENDALE',
    'ALLENVILLE',
    'ALMA',
    'ALTAMONT',
    'ALTENBURG',
    'ALTON',
    'AMAZONIA',
    'AMITY',
    'AMORET',
    'AMSTERDAM',
    'ANDERSON',
    'ANNADA',
    'ANNAPOLIS',
    'ANNISTON',
    'APPLETON CITY',
    'ARBELA',
    'ARBYRD',
    'ARCADIA',
    'ARCHIE',
    'ARCOLA',
    'ARGYLE',
    'ARKOE',
    'ARMSTRONG',
    'ARNOLD',
    'ARROW POINT',
    'ARROW ROCK',
    'ASBURY',
    'ASHBURN',
    'ASH GROVE',
    'ASHLAND',
    'ATLANTA',
    'AUGUSTA',
    'AULLVILLE',
    'AURORA',
    'AUXVASSE',
    'AVA',
    'AVILLA',
    'AVONDALE',
    'BAGNELL',
    'BAKER',
    'BAKERSFIELD',
    'BALDWIN PARK',
    'BALLWIN',
    'BARING',
    'BARNARD',
    'BARNETT',
    'BARNHART',
    'BATES CITY',
    'BATTLEFIELD',
    'BELLA VILLA',
    'BELL CITY',
    'BELLE',
    'BELLEFONTAINE NEIGHBORS',
    'BELLERIVE',
    'BELLFLOWER',
    'BEL-NOR',
    'BEL-RIDGE',
    'BELTON',
    'BENTON',
    'BENTON CITY',
    'BERGER',
    'BERKELEY',
    'BERNIE',
    'BERTRAND',
    'BETHANY',
    'BETHEL',
    'BEVERLY HILLS',
    'BEVIER',
    'BIEHLE',
    'BIGELOW',
    'BIG LAKE',
    'BILLINGS',
    'BIRCH TREE',
    'BIRMINGHAM',
    'BISMARCK',
    'BLACKBURN',
    'BLACK JACK',
    'BLACKWATER',
    'BLAIRSTOWN',
    'BLAND',
    'BLODGETT',
    'BLOOMFIELD',
    'BLOOMSDALE',
    'BLUE EYE',
    'BLUE SPRINGS',
    'BLYTHEDALE',
    'BOGARD',
    'BOLCKOW',
    'BOLIVAR',
    'BONNE TERRE',
    'BOONVILLE',
    'BOSWORTH',
    'BOURBON',
    'BOWLING GREEN',
    'BRAGG CITY',
    'BRANDSVILLE',
    'BRANSON',
    'BRANSON WEST',
    'BRASHEAR',
    'BRAYMER',
    'BRECKENRIDGE',
    'BRECKENRIDGE HILLS',
    'BRENTWOOD',
    'BRIDGETON',
    'BRIMSON',
    'BRONAUGH',
    'BROOKFIELD',
    'BROOKLINE',
    'BROOKLYN HEIGHTS',
    'BROWNING',
    'BROWNINGTON',
    'BRUMLEY',
    'BRUNSWICK',
    'BUCKLIN',
    'BUCKNER',
    'BUFFALO',
    'BULL CREEK',
    'BUNCETON',
    'BUNKER',
    'BURGESS',
    'BURLINGTON JUNCTION',
    'BUTLER',
    'BUTTERFIELD',
    'BYRNES MILL',
    'CABOOL',
    'CAINSVILLE',
    'CAIRO',
    'CALEDONIA',
    'CALHOUN',
    'CALIFORNIA',
    'CALLAO',
    'CALVERTON PARK',
    'CAMDEN',
    'CAMDEN POINT',
    'CAMDENTON',
    'CAMERON',
    'CAMPBELL',
    'CANALOU',
    'CANTON',
    'CAPE GIRARDEAU',
    'CARDWELL',
    'CARL JUNCTION',
    'CARROLLTON',
    'CARTERVILLE',
    'CARTHAGE',
    'CARUTHERSVILLE',
    'CARYTOWN',
    'CASSVILLE',
    'CASTLE POINT',
    'CATRON',
    'CAVE',
    'CEDAR HILL',
    'CEDAR HILL LAKES',
    'CENTER',
    'CENTERTOWN',
    'CENTERVIEW',
    'CENTERVILLE',
    'CENTRALIA',
    'CHAFFEE',
    'CHAIN OF ROCKS',
    'CHAIN-O-LAKES',
    'CHAMOIS',
    'CHAMP',
    'CHARLACK',
    'CHARLESTON',
    'CHESTERFIELD',
    'CHILHOWEE',
    'CHILLICOTHE',
    'CHULA',
    'CLARENCE',
    'CLARK',
    'CLARKSBURG',
    'CLARKSDALE',
    'CLARKSON VALLEY',
    'CLARKSVILLE',
    'CLARKTON',
    'CLAYCOMO',
    'CLAYTON',
    'CLEARMONT',
    'CLEVELAND',
    'CLEVER',
    'CLIFF VILLAGE',
    'CLIFTON HILL',
    'CLIMAX SPRINGS',
    'CLINTON',
    'CLYDE',
    'COBALT',
    'COFFEY',
    'COLE CAMP',
    'COLLINS',
    'COLUMBIA',
    'COMMERCE',
    'CONCEPTION JUNCTION',
    'CONCORD',
    'CONCORDIA',
    'CONEY ISLAND',
    'CONWAY',
    'COOL VALLEY',
    'COOTER',
    'CORDER',
    'CORNING',
    'COSBY',
    'COTTLEVILLE',
    'COUNTRY CLUB',
    'COUNTRY CLUB HILLS',
    'COUNTRY LIFE ACRES',
    'COWGILL',
    'CRAIG',
    'CRANE',
    'CREIGHTON',
    'CRESTWOOD',
    'CREVE COEUR',
    'CROCKER',
    'CROSS TIMBERS',
    'CRYSTAL CITY',
    'CRYSTAL LAKE PARK',
    'CRYSTAL LAKES',
    'CUBA',
    'CURRYVILLE',
    'DADEVILLE',
    'DALTON',
    'DARDENNE PRAIRIE',
    'DARLINGTON',
    'DEARBORN',
    'DEEPWATER',
    'DEERFIELD',
    'DE KALB',
    'DELLWOOD',
    'DELTA',
    'DENNIS ACRES',
    'DENVER',
    'DES ARC',
    'DESLOGE',
    'DE SOTO',
    'DES PERES',
    'DE WITT',
    'DEXTER',
    'DIAMOND',
    'DIEHLSTADT',
    'DIGGINS',
    'DIXON',
    'DONIPHAN',
    'DOOLITTLE',
    'DOVER',
    'DOWNING',
    'DREXEL',
    'DUDLEY',
    'DUENWEG',
    'DUQUESNE',
    'DUTCHTOWN',
    'EAGLEVILLE',
    'EAST LYNNE',
    'EASTON',
    'EAST PRAIRIE',
    'EDGAR SPRINGS',
    'EDGERTON',
    'EDINA',
    'EDMUNDSON',
    'ELDON',
    'EL DORADO SPRINGS',
    'ELLINGTON',
    'ELLISVILLE',
    'ELLSINORE',
    'ELMER',
    'ELMIRA',
    'ELMO',
    'ELSBERRY',
    'EMERALD BEACH',
    'EMINENCE',
    'EMMA',
    'EOLIA',
    'ESSEX',
    'ETHEL',
    'EUREKA',
    'EVERTON',
    'EWING',
    'EXCELSIOR ESTATES',
    'EXCELSIOR SPRINGS',
    'EXETER',
    'FAIRFAX',
    'FAIR GROVE',
    'FAIR PLAY',
    'FAIRVIEW',
    'FARBER',
    'FARLEY',
    'FARMINGTON',
    'FAYETTE',
    'FENTON',
    'FERGUSON',
    'FERRELVIEW',
    'FESTUS',
    'FIDELITY',
    'FILLMORE',
    'FISK',
    'FLEMING',
    'FLEMINGTON',
    'FLINT HILL',
    'FLORDELL HILLS',
    'FLORIDA',
    'FLORISSANT',
    'FOLEY',
    'FORDLAND',
    'FOREST CITY',
    'FORISTELL',
    'FORSYTH',
    'FORTESCUE',
    'FORT LEONARD WOOD',
    'FOSTER',
    'FOUNTAIN N LAKES',
    'FRANKFORD',
    'FRANKLIN',
    'FREDERICKTOWN',
    'FREEBURG',
    'FREEMAN',
    'FREISTATT',
    'FREMONT HILLS',
    'FROHNA',
    'FRONTENAC',
    'FULTON',
    'GAINESVILLE',
    'GALENA',
    'GALLATIN',
    'GALT',
    'GARDEN CITY',
    'GASCONADE',
    'GENTRY',
    'GERALD',
    'GERSTER',
    'GIBBS',
    'GIDEON',
    'GILLIAM',
    'GILMAN CITY',
    'GLADSTONE',
    'GLASGOW',
    'GLASGOW VILLAGE',
    'GLENAIRE',
    'GLEN ALLEN',
    'GLENDALE',
    'GLEN ECHO PARK',
    'GLENWOOD',
    'GOLDEN CITY',
    'GOODMAN',
    'GORDONVILLE',
    'GOWER',
    'GRAHAM',
    'GRAIN VALLEY',
    'GRANBY',
    'GRAND FALLS PLAZA',
    'GRANDIN',
    'GRAND PASS',
    'GRANDVIEW',
    'GRANGER',
    'GRANT CITY',
    'GRANTWOOD VILLAGE',
    'GRAVOIS MILLS',
    'GRAY SUMMIT',
    'GREENCASTLE',
    'GREEN CITY',
    'GREENDALE',
    'GREENFIELD',
    'GREEN PARK',
    'GREEN RIDGE',
    'GREENTOP',
    'GREENVILLE',
    'GREENWOOD',
    'GUILFORD',
    'GUNN CITY',
    'HALE',
    'HALFWAY',
    'HALLSVILLE',
    'HALLTOWN',
    'HAMILTON',
    'HANLEY HILLS',
    'HANNIBAL',
    'HARDIN',
    'HARRIS',
    'HARRISBURG',
    'HARRISONVILLE',
    'HARTSBURG',
    'HARTVILLE',
    'HARTWELL',
    'HARWOOD',
    'HAWK POINT',
    'HAYTI',
    'HAYTI HEIGHTS',
    'HAYWARD',
    'HAYWOOD CITY',
    'HAZELWOOD',
    'HENRIETTA',
    'HERCULANEUM',
    'HERMANN',
    'HERMITAGE',
    'HIGBEE',
    'HIGGINSVILLE',
    'HIGH HILL',
    'HIGHLANDVILLE',
    'HIGH RIDGE',
    'HILLSBORO',
    'HILLSDALE',
    'HOBERG',
    'HOLCOMB',
    'HOLDEN',
    'HOLLAND',
    'HOLLIDAY',
    'HOLLISTER',
    'HOLT',
    'HOLTS SUMMIT',
    'HOMESTEAD',
    'HOMESTOWN',
    'HOPKINS',
    'HORINE',
    'HORNERSVILLE',
    'HOUSTON',
    'HOUSTONIA',
    'HOUSTON LAKE',
    'HOWARDVILLE',
    'HUGHESVILLE',
    'HUMANSVILLE',
    'HUME',
    'HUMPHREYS',
    'HUNNEWELL',
    'HUNTLEIGH',
    'HUNTSVILLE',
    'HURDLAND',
    'HURLEY',
    'IATAN',
    'IBERIA',
    'IMPERIAL',
    'INDEPENDENCE',
    'INDIAN POINT',
    'INNSBROOK',
    'IONIA',
    'IRENA',
    'IRONDALE',
    'IRON MOUNTAIN LAKE',
    'IRONTON',
    'JACKSON',
    'JACKSONVILLE',
    'JAMESON',
    'JAMESPORT',
    'JAMESTOWN',
    'JASPER',
    'JEFFERSON CITY',
    'JENNINGS',
    'JERICO SPRINGS',
    'JONESBURG',
    'JOPLIN',
    'JOSEPHVILLE',
    'JUNCTION CITY',
    'KAHOKA',
    'KANSAS CITY',
    'KEARNEY',
    'KELSO',
    'KENNETT',
    'KEYTESVILLE',
    'KIDDER',
    'KIMBERLING CITY',
    'KIMMSWICK',
    'KING CITY',
    'KINGDOM CITY',
    'KINGSTON',
    'KINGSVILLE',
    'KINLOCH',
    'KIRKSVILLE',
    'KIRKWOOD',
    'KNOB NOSTER',
    'KNOX CITY',
    'KOSHKONONG',
    'LA BELLE',
    'LACLEDE',
    'LADDONIA',
    'LA DUE',
    'LADUE',
    'LA GRANGE',
    'LAKE ANNETTE',
    'LAKE LAFAYETTE',
    'LAKE LOTAWANA',
    'LAKE MYKEE TOWN',
    'LAKE OZARK',
    'LAKE ST. LOUIS',
    'LAKESHIRE',
    'LAKESIDE',
    'LAKE TAPAWINGO',
    'LAKE WAUKOMIS',
    'LAKE WINNEBAGO',
    'LAMAR',
    'LAMAR HEIGHTS',
    'LAMBERT',
    'LA MONTE',
    'LANAGAN',
    'LANCASTER',
    'LA PLATA',
    'LAREDO',
    'LA RUSSELL',
    'LATHROP',
    'LA TOUR',
    'LAURIE',
    'LAWSON',
    'LEADINGTON',
    'LEADWOOD',
    'LEASBURG',
    'LEAWOOD',
    'LEBANON',
    'LEE S SUMMIT',
    'LEETON',
    'LEMAY',
    'LEONARD',
    'LESLIE',
    'LEVASY',
    'LEWIS AND CLARK VILLAGE',
    'LEWISTOWN',
    'LEXINGTON',
    'LIBERAL',
    'LIBERTY',
    'LICKING',
    'LILBOURN',
    'LINCOLN',
    'LINN',
    'LINN CREEK',
    'LINNEUS',
    'LITHIUM',
    'LIVONIA',
    'LOCK SPRINGS',
    'LOCKWOOD',
    'LOHMAN',
    'LOMA LINDA',
    'LONE JACK',
    'LONGTOWN',
    'LOUISBURG',
    'LOUISIANA',
    'LOWRY CITY',
    'LUCERNE',
    'LUDLOW',
    'LUPUS',
    'LURAY',
    'MCBAINE',
    'MCCORD BEND',
    'MCFALL',
    'MACKENZIE',
    'MCKITTRICK',
    'MACKS CREEK',
    'MACON',
    'MADISON',
    'MAITLAND',
    'MALDEN',
    'MALTA BEND',
    'MANCHESTER',
    'MANSFIELD',
    'MAPLEWOOD',
    'MARBLE HILL',
    'MARCELINE',
    'MARIONVILLE',
    'MARLBOROUGH',
    'MARQUAND',
    'MARSHALL',
    'MARSHFIELD',
    'MARSTON',
    'MARTHASVILLE',
    'MARTINSBURG',
    'MARYLAND HEIGHTS',
    'MARYVILLE',
    'MATTHEWS',
    'MAYSVILLE',
    'MAYVIEW',
    'MEADVILLE',
    'MEHLVILLE',
    'MEMPHIS',
    'MENDON',
    'MERCER',
    'MERRIAM WOODS',
    'MERWIN',
    'META',
    'METZ',
    'MEXICO',
    'MIAMI',
    'MIDDLETOWN',
    'MILAN',
    'MILFORD',
    'MILLARD',
    'MILLER',
    'MILL SPRING',
    'MILO',
    'MINDENMINES',
    'MINER',
    'MINERAL POINT',
    'MIRAMIGUOA PARK',
    'MISSOURI CITY',
    'MOBERLY',
    'MOKANE',
    'MOLINE ACRES',
    'MONETT',
    'MONROE CITY',
    'MONTGOMERY CITY',
    'MONTICELLO',
    'MONTROSE',
    'MOORESVILLE',
    'MOREHOUSE',
    'MORLEY',
    'MORRISON',
    'MORRISVILLE',
    'MOSBY',
    'MOSCOW MILLS',
    'MOUND CITY',
    'MOUNDVILLE',
    'MOUNTAIN GROVE',
    'MOUNTAIN VIEW',
    'MOUNT LEONARD',
    'MOUNT MORIAH',
    'MOUNT VERNON',
    'MURPHY',
    'NAPOLEON',
    'NAYLOR',
    'NECK CITY',
    'NEELYVILLE',
    'NELSON',
    'NEOSHO',
    'NEVADA',
    'NEWARK',
    'NEW BLOOMFIELD',
    'NEWBURG',
    'NEW CAMBRIA',
    'NEW FLORENCE',
    'NEW FRANKLIN',
    'NEW HAMPTON',
    'NEW HAVEN',
    'NEW LONDON',
    'NEW MADRID',
    'NEW MELLE',
    'NEWTONIA',
    'NEWTOWN',
    'NIANGUA',
    'NIXA',
    'NOEL',
    'NORBORNE',
    'NORMANDY',
    'NORTH KANSAS CITY',
    'NORTH LILBOURN',
    'NORTHMOOR',
    'NORTH WARDELL',
    'NORTHWOODS',
    'NORWOOD',
    'NORWOOD COURT',
    'NOVELTY',
    'NOVINGER',
    'OAK GROVE',
    'OAK GROVE',
    'OAKLAND',
    'OAK RIDGE',
    'OAKS',
    'OAKVIEW',
    'OAKVILLE',
    'OAKWOOD',
    'OAKWOOD PARK',
    'ODESSA',
    'O FALLON',
    'OLD APPLETON',
    'OLD MONROE',
    'OLEAN',
    'OLIVETTE',
    'OLYMPIAN VILLAGE',
    'ORAN',
    'OREGON',
    'ORONOGO',
    'ORRICK',
    'OSAGE BEACH',
    'OSBORN',
    'OSCEOLA',
    'OSGOOD',
    'OTTERVILLE',
    'OVERLAND',
    'OWENSVILLE',
    'OZARK',
    'PACIFIC',
    'PAGEDALE',
    'PALMYRA',
    'PARIS',
    'PARKDALE',
    'PARK HILLS',
    'PARKVILLE',
    'PARKWAY',
    'PARMA',
    'PARNELL',
    'PASADENA HILLS',
    'PASADENA PARK',
    'PASCOLA',
    'PASSAIC',
    'PATTONSBURG',
    'PAYNESVILLE',
    'PECULIAR',
    'PENERMON',
    'PERRY',
    'PERRYVILLE',
    'PEVELY',
    'PHILLIPSBURG',
    'PICKERING',
    'PIEDMONT',
    'PIERCE CITY',
    'PILOT GROVE',
    'PILOT KNOB',
    'PINE LAWN',
    'PINEVILLE',
    'PINHOOK',
    'PLATTE CITY',
    'PLATTE WOODS',
    'PLATTSBURG',
    'PLEASANT HILL',
    'PLEASANT HOPE',
    'PLEASANT VALLEY',
    'POCAHONTAS',
    'POLLOCK',
    'POLO',
    'POPLAR BLUFF',
    'PORTAGE DES SIOUX',
    'PORTAGEVILLE',
    'POTOSI',
    'POWERSVILLE',
    'PRAIRIE HOME',
    'PRATHERSVILLE',
    'PRESTON',
    'PRINCETON',
    'PURCELL',
    'PURDIN',
    'PURDY',
    'PUXICO',
    'QUEEN CITY',
    'QUITMAN',
    'QULIN',
    'RANDOLPH',
    'RAVENWOOD',
    'RAYMONDVILLE',
    'RAYMORE',
    'RAYTOWN',
    'RAYVILLE',
    'REA',
    'REDINGS MILL',
    'REEDS',
    'REEDS SPRING',
    'RENICK',
    'RENSSELAER',
    'REPUBLIC',
    'REVERE',
    'RHINELAND',
    'RICHARDS',
    'RICH HILL',
    'RICHLAND',
    'RICHMOND',
    'RICHMOND HEIGHTS',
    'RIDGELY',
    'RIDGEWAY',
    'RISCO',
    'RITCHEY',
    'RIVER BEND',
    'RIVERSIDE',
    'RIVERVIEW',
    'RIVES',
    'ROCHEPORT',
    'ROCKAWAY BEACH',
    'ROCK HILL',
    'ROCK PORT',
    'ROCKVILLE',
    'ROGERSVILLE',
    'ROLLA',
    'ROSCOE',
    'ROSEBUD',
    'ROSENDALE',
    'ROTHVILLE',
    'RUSH HILL',
    'RUSHVILLE',
    'RUSSELLVILLE',
    'RUTLEDGE',
    'SAGINAW',
    'ST. ANN',
    'ST. CHARLES',
    'ST. CLAIR',
    'ST. CLOUD',
    'STE. GENEVIEVE',
    'ST. ELIZABETH',
    'ST. GEORGE',
    'ST. JAMES',
    'ST. JOHN',
    'ST. JOSEPH',
    'ST. LOUIS',
    'ST. MARTINS',
    'ST. MARY',
    'ST. PAUL',
    'ST. PETERS',
    'ST. ROBERT',
    'ST. THOMAS',
    'SALEM',
    'SALISBURY',
    'SAPPINGTON',
    'SARCOXIE',
    'SAVANNAH',
    'SCHELL CITY',
    'SCOTSDALE',
    'SCOTT CITY',
    'SEDALIA',
    'SEDGEWICKVILLE',
    'SELIGMAN',
    'SENATH',
    'SENECA',
    'SEYMOUR',
    'SHELBINA',
    'SHELBYVILLE',
    'SHELDON',
    'SHELL KNOB',
    'SHERIDAN',
    'SHOAL CREEK DRIVE',
    'SHOAL CREEK ESTATES',
    'SHREWSBURY',
    'SIBLEY',
    'SIKESTON',
    'SILEX',
    'SILVER CREEK',
    'SKIDMORE',
    'SLATER',
    'SMITHTON',
    'SMITHVILLE',
    'SOUTH GIFFORD',
    'SOUTH GORIN',
    'SOUTH GREENFIELD',
    'SOUTH LINEVILLE',
    'SOUTH WEST CITY',
    'SPANISH LAKE',
    'SPARTA',
    'SPICKARD',
    'SPOKANE',
    'SPRINGFIELD',
    'STANBERRY',
    'STARK CITY',
    'STEELE',
    'STEELVILLE',
    'STELLA',
    'STEWARTSVILLE',
    'STOCKTON',
    'STOTESBURY',
    'STOTTS CITY',
    'STOUTLAND',
    'STOUTSVILLE',
    'STOVER',
    'STRAFFORD',
    'STRASBURG',
    'STURGEON',
    'SUGAR CREEK',
    'SULLIVAN',
    'SUMMERSVILLE',
    'SUMNER',
    'SUNDOWN',
    'SUNRISE BEACH',
    'SUNSET HILLS',
    'SWEET SPRINGS',
    'SYCAMORE HILLS',
    'SYRACUSE',
    'TABLE ROCK',
    'TALLAPOOSA',
    'TANEYVILLE',
    'TAOS',
    'TARKIO',
    'TARRANTS',
    'THAYER',
    'THEODOSIA',
    'TIGHTWAD',
    'TINA',
    'TINDALL',
    'TIPTON',
    'TOWN AND COUNTRY',
    'TRACY',
    'TRENTON',
    'TRIMBLE',
    'TRIPLETT',
    'TROY',
    'TRUESDALE',
    'TRUXTON',
    'TURNEY',
    'TUSCUMBIA',
    'TWIN BRIDGES',
    'TWIN OAKS',
    'UMBER VIEW HEIGHTS',
    'UNION',
    'UNION STAR',
    'UNIONVILLE',
    'UNITY VILLAGE',
    'UNIVERSITY CITY',
    'UPLANDS PARK',
    'URBANA',
    'URICH',
    'UTICA',
    'VALLEY PARK',
    'VAN BUREN',
    'VANDALIA',
    'VANDIVER',
    'VANDUSER',
    'VELDA CITY',
    'VELDA VILLAGE HILLS',
    'VERONA',
    'VERSAILLES',
    'VIBURNUM',
    'VIENNA',
    'VILLAGE OF FOUR SEASONS',
    'VILLA RIDGE',
    'VINITA PARK',
    'VINITA TERRACE',
    'VISTA',
    'WACO',
    'WALKER',
    'WALNUT GROVE',
    'WARDELL',
    'WARDSVILLE',
    'WARRENSBURG',
    'WARRENTON',
    'WARSAW',
    'WARSON WOODS',
    'WASHBURN',
    'WASHINGTON',
    'WATSON',
    'WAVERLY',
    'WAYLAND',
    'WAYNESVILLE',
    'WEATHERBY',
    'WEATHERBY LAKE',
    'WEAUBLEAU',
    'WEBB CITY',
    'WEBSTER GROVES',
    'WELDON SPRING',
    'WELDON SPRING HEIGHTS',
    'WELLINGTON',
    'WELLSTON',
    'WELLSVILLE',
    'WENTWORTH',
    'WENTZVILLE',
    'WEST ALTON',
    'WESTBORO',
    'WEST LINE',
    'WESTON',
    'WESTPHALIA',
    'WEST PLAINS',
    'WESTWOOD',
    'WHEATLAND',
    'WHEATON',
    'WHEELING',
    'WHITEMAN AFB',
    'WHITESIDE',
    'WHITEWATER',
    'WILBUR PARK',
    'WILDWOOD',
    'WILLARD',
    'WILLIAMSVILLE',
    'WILLOW SPRINGS',
    'WILSON CITY',
    'WINCHESTER',
    'WINDSOR',
    'WINFIELD',
    'WINONA',
    'WINSTON',
    'WOODS HEIGHTS',
    'WOODSON TERRACE',
    'WOOLDRIDGE',
    'WORTH',
    'WORTHINGTON',
    'WRIGHT CITY',
    'WYACONDA',
    'WYATT',
    'ZALMA',
  ],
  Montana: [
    'ABSAROKEE',
    'AGENCY',
    'ALBERTON',
    'ALDER',
    'AMSTERDAM-CHURCHILL',
    'ANACONDA-DEER LODGE',
    'ANTELOPE',
    'ARLEE',
    'ASHLAND',
    'AUGUSTA',
    'AVON',
    'AZURE',
    'BAINVILLE',
    'BAKER',
    'BALLANTINE',
    'BASIN',
    'BEARCREEK',
    'BEAVER CREEK',
    'BELFRY',
    'BELGRADE',
    'BELT',
    'BIG ARM',
    'BIGFORK',
    'BIG SANDY',
    'BIG SKY',
    'BIG TIMBER',
    'BILLINGS',
    'BIRNEY',
    'BLACK EAGLE',
    'BONEAU',
    'BONNER-WEST RIVERSIDE',
    'BOULDER',
    'BOX ELDER',
    'BOZEMAN',
    'BRIDGER',
    'BROADUS',
    'BROADVIEW',
    'BROCKTON',
    'BROWNING',
    'BUSBY',
    'BUTTE-SILVER BOW',
    'CAMP THREE',
    'CARDWELL',
    'CARTER',
    'CASCADE',
    'CHARLO',
    'CHESTER',
    'CHINOOK',
    'CHOTEAU',
    'CIRCLE',
    'CLANCY',
    'CLINTON',
    'CLYDE PARK',
    'COLSTRIP',
    'COLUMBIA FALLS',
    'COLUMBUS',
    'CONRAD',
    'COOKE CITY-SILVER GATE',
    'CORAM',
    'CORVALLIS',
    'CROW AGENCY',
    'CULBERTSON',
    'CUSTER',
    'CUT BANK',
    'DARBY',
    'DAYTON',
    'DE BORGIA',
    'DEER LODGE',
    'DENTON',
    'DILLON',
    'DIXON',
    'DODSON',
    'DRUMMOND',
    'DUTTON',
    'EAST GLACIER PARK VILLAGE',
    'EAST HELENA',
    'EAST MISSOULA',
    'EKALAKA',
    'ELLISTON',
    'ELMO',
    'ENNIS',
    'EUREKA',
    'EVARO',
    'EVERGREEN',
    'FAIRFIELD',
    'FAIRVIEW',
    'FALLON',
    'FINLEY POINT',
    'FLAXVILLE',
    'FLORENCE',
    'FORSYTH',
    'FORT BELKNAP AGENCY',
    'FORT BENTON',
    'FORTINE',
    'FORT PECK',
    'FORT SHAW',
    'FORT SMITH',
    'FOUR CORNERS',
    'FOX LAKE',
    'FRAZER',
    'FRENCHTOWN',
    'FROID',
    'FROMBERG',
    'GARDINER',
    'GARRISON',
    'GERALDINE',
    'GILDFORD',
    'GLASGOW',
    'GLENDIVE',
    'GRASS RANGE',
    'GREAT FALLS',
    'GREYCLIFF',
    'HAMILTON',
    'HARDIN',
    'HARLEM',
    'HARLOWTON',
    'HARRISON',
    'HAVRE',
    'HAVRE NORTH',
    'HAYS',
    'HEART BUTTE',
    'HELENA',
    'HELENA VALLEY NORTHEAST',
    'HELENA VALLEY NORTHWEST',
    'HELENA VALLEY SOUTHEAST',
    'HELENA VALLEY WEST CENTRAL',
    'HELENA WEST SIDE',
    'HERON',
    'HERRON',
    'HIGHWOOD',
    'HINGHAM',
    'HOBSON',
    'HOT SPRINGS',
    'HUNGRY HORSE',
    'HUNTLEY',
    'HYSHAM',
    'INVERNESS',
    'ISMAY',
    'JEFFERSON CITY',
    'JETTE',
    'JOLIET',
    'JOPLIN',
    'JORDAN',
    'JUDITH GAP',
    'KALISPELL',
    'KERR',
    'KEVIN',
    'KICKING HORSE',
    'KINGS POINT',
    'KLEIN',
    'KNIFE RIVER',
    'KREMLIN',
    'LAKESIDE',
    'LAME DEER',
    'LAUREL',
    'LAVINA',
    'LEWISTOWN',
    'LEWISTOWN HEIGHTS',
    'LIBBY',
    'LIMA',
    'LINCOLN',
    'LIVINGSTON',
    'LOCKWOOD',
    'LODGE GRASS',
    'LODGE POLE',
    'LOLO',
    'LOMA',
    'LONEPINE',
    'MALMSTROM AFB',
    'MALTA',
    'MANHATTAN',
    'MARTIN CITY',
    'MEDICINE LAKE',
    'MELSTONE',
    'MILES CITY',
    'MISSOULA',
    'MONTANA CITY',
    'MOORE',
    'MUDDY',
    'MUSSELSHELL',
    'NASHUA',
    'NEIHART',
    'NIARADA',
    'NORTH BROWNING',
    'NOXON',
    'OLD AGENCY',
    'OPHEIM',
    'ORCHARD HOMES',
    'OUTLOOK',
    'OVANDO',
    'PABLO',
    'PARADISE',
    'PARK CITY',
    'PARKER SCHOOL',
    'PHILIPSBURG',
    'PINESDALE',
    'PLAINS',
    'PLENTYWOOD',
    'PLEVNA',
    'POLSON',
    'POPLAR',
    'POWER',
    'PRYOR',
    'RADERSBURG',
    'RAVALLI',
    'RED LODGE',
    'REED POINT',
    'RESERVE',
    'REXFORD',
    'RICHEY',
    'RIVERBEND',
    'ROCKY POINT',
    'ROLLINS',
    'RONAN',
    'ROUNDUP',
    'RUDYARD',
    'RYEGATE',
    'SACO',
    'SADDLE BUTTE',
    'ST. IGNATIUS',
    'ST. MARIE',
    'ST. PIERRE',
    'ST. REGIS',
    'ST. XAVIER',
    'SANGREY',
    'SCOBEY',
    'SEELEY LAKE',
    'SHELBY',
    'SHEPHERD',
    'SHERIDAN',
    'SIDNEY',
    'SIMMS',
    'SOMERS',
    'SOUTH BROWNING',
    'STANFORD',
    'STARR SCHOOL',
    'STEVENSVILLE',
    'SUNBURST',
    'SUN PRAIRIE',
    'SUN RIVER',
    'SUPERIOR',
    'TERRY',
    'THOMPSON FALLS',
    'THREE FORKS',
    'TOSTON',
    'TOWNSEND',
    'TROUT CREEK',
    'TROY',
    'TURTLE LAKE',
    'TWIN BRIDGES',
    'ULM',
    'VALIER',
    'VAUGHN',
    'VICTOR',
    'VIRGINIA CITY',
    'WALKERVILLE',
    'WESTBY',
    'WEST GLENDIVE',
    'WEST HAVRE',
    'WEST YELLOWSTONE',
    'WHITEFISH',
    'WHITEHALL',
    'WHITE SULPHUR SPRINGS',
    'WIBAUX',
    'WILLOW CREEK',
    'WILSALL',
    'WINIFRED',
    'WINNETT',
    'WINSTON',
    'WISDOM',
    'WOLF POINT',
    'WOODS BAY',
    'WORDEN',
    'WYE',
    'WYOLA',
  ],
  Nebraska: [
    'ABIE',
    'ADAMS',
    'AINSWORTH',
    'ALBION',
    'ALDA',
    'ALEXANDRIA',
    'ALLEN',
    'ALLIANCE',
    'ALMA',
    'ALVO',
    'AMHERST',
    'ANOKA',
    'ANSELMO',
    'ANSLEY',
    'ARAPAHOE',
    'ARCADIA',
    'ARLINGTON',
    'ARNOLD',
    'ARTHUR',
    'ASHLAND',
    'ASHTON',
    'ATKINSON',
    'ATLANTA',
    'AUBURN',
    'AURORA',
    'AVOCA',
    'AXTELL',
    'AYR',
    'BANCROFT',
    'BARADA',
    'BARNESTON',
    'BARTLETT',
    'BARTLEY',
    'BASSETT',
    'BATTLE CREEK',
    'BAYARD',
    'BAZILE MILLS',
    'BEATRICE',
    'BEAVER CITY',
    'BEAVER CROSSING',
    'BEE',
    'BEEMER',
    'BELDEN',
    'BELGRADE',
    'BELLEVUE',
    'BELLWOOD',
    'BELVIDERE',
    'BENEDICT',
    'BENKELMAN',
    'BENNET',
    'BENNINGTON',
    'BERTRAND',
    'BERWYN',
    'BIG SPRINGS',
    'BLADEN',
    'BLAIR',
    'BLOOMFIELD',
    'BLOOMINGTON',
    'BLUE HILL',
    'BLUE SPRINGS',
    'BOYS TOWN',
    'BRADSHAW',
    'BRADY',
    'BRAINARD',
    'BREWSTER',
    'BRIDGEPORT',
    'BRISTOW',
    'BROADWATER',
    'BROCK',
    'BROKEN BOW',
    'BROWNVILLE',
    'BRULE',
    'BRUNING',
    'BRUNO',
    'BRUNSWICK',
    'BURCHARD',
    'BURR',
    'BURTON',
    'BURWELL',
    'BUSHNELL',
    'BUTTE',
    'BYRON',
    'CAIRO',
    'CALLAWAY',
    'CAMBRIDGE',
    'CAMPBELL',
    'CARLETON',
    'CARROLL',
    'CEDAR BLUFFS',
    'CEDAR CREEK',
    'CEDAR RAPIDS',
    'CENTER',
    'CENTRAL CITY',
    'CERESCO',
    'CHADRON',
    'CHALCO',
    'CHAMBERS',
    'CHAPMAN',
    'CHAPPELL',
    'CHESTER',
    'CISCO',
    'CLARKS',
    'CLARKSON',
    'CLATONIA',
    'CLAY CENTER',
    'CLEARWATER',
    'CLINTON',
    'CODY',
    'COLERIDGE',
    'COLON',
    'COLUMBUS',
    'COMSTOCK',
    'CONCORD',
    'COOK',
    'CORDOVA',
    'CORNLEA',
    'CORTLAND',
    'COTESFIELD',
    'COWLES',
    'COZAD',
    'CRAB ORCHARD',
    'CRAIG',
    'CRAWFORD',
    'CREIGHTON',
    'CRESTON',
    'CRETE',
    'CROFTON',
    'CROOKSTON',
    'CULBERTSON',
    'CURTIS',
    'CUSHING',
    'DAKOTA CITY',
    'DALTON',
    'DANBURY',
    'DANNEBROG',
    'DAVENPORT',
    'DAVEY',
    'DAVID CITY',
    'DAWSON',
    'DAYKIN',
    'DECATUR',
    'DENTON',
    'DESHLER',
    'DEWEESE',
    'DE WITT',
    'DILLER',
    'DIX',
    'DIXON',
    'DODGE',
    'DONIPHAN',
    'DORCHESTER',
    'DOUGLAS',
    'DU BOIS',
    'DUNBAR',
    'DUNCAN',
    'DUNNING',
    'DWIGHT',
    'EAGLE',
    'EDDYVILLE',
    'EDGAR',
    'EDISON',
    'ELBA',
    'ELGIN',
    'ELK CREEK',
    'ELKHORN',
    'ELM CREEK',
    'ELMWOOD',
    'ELSIE',
    'ELWOOD',
    'ELYRIA',
    'EMERSON',
    'EMMET',
    'ENDICOTT',
    'ERICSON',
    'EUSTIS',
    'EWING',
    'EXETER',
    'FAIRBURY',
    'FAIRFIELD',
    'FAIRMONT',
    'FALLS CITY',
    'FARNAM',
    'FARWELL',
    'FILLEY',
    'FIRTH',
    'FORDYCE',
    'FORT CALHOUN',
    'FOSTER',
    'FRANKLIN',
    'FREMONT',
    'FRIEND',
    'FULLERTON',
    'FUNK',
    'GANDY',
    'GARLAND',
    'GARRISON',
    'GENEVA',
    'GENOA',
    'GERING',
    'GIBBON',
    'GILEAD',
    'GILTNER',
    'GLENVIL',
    'GOEHNER',
    'GORDON',
    'GOTHENBURG',
    'GRAFTON',
    'GRAND ISLAND',
    'GRANT',
    'GREELEY CENTER',
    'GREENWOOD',
    'GRESHAM',
    'GRETNA',
    'GROSS',
    'GUIDE ROCK',
    'GURLEY',
    'HADAR',
    'HAIGLER',
    'HALLAM',
    'HALSEY',
    'HAMLET',
    'HAMPTON',
    'HARBINE',
    'HARDY',
    'HARRISBURG',
    'HARRISON',
    'HARTINGTON',
    'HARVARD',
    'HASTINGS',
    'HAYES CENTER',
    'HAY SPRINGS',
    'HAZARD',
    'HEARTWELL',
    'HEBRON',
    'HEMINGFORD',
    'HENDERSON',
    'HENDLEY',
    'HENRY',
    'HERMAN',
    'HERSHEY',
    'HICKMAN',
    'HILDRETH',
    'HOLBROOK',
    'HOLDREGE',
    'HOLSTEIN',
    'HOMER',
    'HOOPER',
    'HORDVILLE',
    'HOSKINS',
    'HOWARD CITY',
    'HOWELLS',
    'HUBBARD',
    'HUBBELL',
    'HUMBOLDT',
    'HUMPHREY',
    'HUNTLEY',
    'HYANNIS',
    'IMPERIAL',
    'INDIANOLA',
    'INGLEWOOD',
    'INMAN',
    'ITHACA',
    'JACKSON',
    'JANSEN',
    'JOHNSON',
    'JOHNSTOWN',
    'JULIAN',
    'JUNIATA',
    'KEARNEY',
    'KENESAW',
    'KENNARD',
    'KILGORE',
    'KIMBALL',
    'LAMAR',
    'LAUREL',
    'LA VISTA',
    'LAWRENCE',
    'LEBANON',
    'LEIGH',
    'LESHARA',
    'LEWELLEN',
    'LEWISTON',
    'LEXINGTON',
    'LIBERTY',
    'LINCOLN',
    'LINDSAY',
    'LINWOOD',
    'LITCHFIELD',
    'LODGEPOLE',
    'LONG PINE',
    'LOOMIS',
    'LORTON',
    'LOUISVILLE',
    'LOUP CITY',
    'LUSHTON',
    'LYMAN',
    'LYNCH',
    'LYONS',
    'MCCOOK',
    'MCCOOL JUNCTION',
    'MCGREW',
    'MCLEAN',
    'MACY',
    'MADISON',
    'MADRID',
    'MAGNET',
    'MALCOLM',
    'MALMO',
    'MANLEY',
    'MARQUETTE',
    'MARTINSBURG',
    'MASKELL',
    'MASON CITY',
    'MAXWELL',
    'MAYWOOD',
    'MEAD',
    'MEADOW GROVE',
    'MELBETA',
    'MEMPHIS',
    'MERNA',
    'MERRIMAN',
    'MILFORD',
    'MILLER',
    'MILLIGAN',
    'MINATARE',
    'MINDEN',
    'MITCHELL',
    'MONOWI',
    'MONROE',
    'MOOREFIELD',
    'MORRILL',
    'MORSE BLUFF',
    'MULLEN',
    'MURDOCK',
    'MURRAY',
    'NAPER',
    'NAPONEE',
    'NEBRASKA CITY',
    'NEHAWKA',
    'NELIGH',
    'NELSON',
    'NEMAHA',
    'NENZEL',
    'NEWCASTLE',
    'NEWMAN GROVE',
    'NEWPORT',
    'NICKERSON',
    'NIOBRARA',
    'NORA',
    'NORFOLK',
    'NORMAN',
    'NORTH BEND',
    'NORTH LOUP',
    'NORTH PLATTE',
    'OAK',
    'OAKDALE',
    'OAKLAND',
    'OBERT',
    'OCONTO',
    'OCTAVIA',
    'ODELL',
    'OFFUTT AFB',
    'OGALLALA',
    'OHIOWA',
    'OMAHA',
    'O NEILL',
    'ONG',
    'ORCHARD',
    'ORD',
    'ORLEANS',
    'OSCEOLA',
    'OSHKOSH',
    'OSMOND',
    'OTOE',
    'OVERTON',
    'OXFORD',
    'PAGE',
    'PALISADE',
    'PALMER',
    'PALMYRA',
    'PANAMA',
    'PAPILLION',
    'PAWNEE CITY',
    'PAXTON',
    'PENDER',
    'PERU',
    'PETERSBURG',
    'PHILLIPS',
    'PICKRELL',
    'PIERCE',
    'PILGER',
    'PINE RIDGE',
    'PLAINVIEW',
    'PLATTE CENTER',
    'PLATTSMOUTH',
    'PLEASANT DALE',
    'PLEASANTON',
    'PLYMOUTH',
    'POLK',
    'PONCA',
    'POTTER',
    'PRAGUE',
    'PRESTON',
    'PRIMROSE',
    'PROSSER',
    'RAGAN',
    'RALSTON',
    'RANDOLPH',
    'RAVENNA',
    'RAYMOND',
    'RED CLOUD',
    'REPUBLICAN CITY',
    'REYNOLDS',
    'RICHLAND',
    'RISING CITY',
    'RIVERDALE',
    'RIVERTON',
    'ROCA',
    'ROCKVILLE',
    'ROGERS',
    'ROSALIE',
    'ROSELAND',
    'ROYAL',
    'RULO',
    'RUSHVILLE',
    'RUSKIN',
    'ST. EDWARD',
    'ST. HELENA',
    'ST. PAUL',
    'SALEM',
    'SANTEE',
    'SARGENT',
    'SARONVILLE',
    'SCHUYLER',
    'SCOTIA',
    'SCOTTSBLUFF',
    'SCRIBNER',
    'SENECA',
    'SEWARD',
    'SHELBY',
    'SHELTON',
    'SHICKLEY',
    'SHOLES',
    'SHUBERT',
    'SIDNEY',
    'SILVER CREEK',
    'SMITHFIELD',
    'SNYDER',
    'SOUTH BEND',
    'SOUTH SIOUX CITY',
    'SPALDING',
    'SPENCER',
    'SPRAGUE',
    'SPRINGFIELD',
    'SPRINGVIEW',
    'STAMFORD',
    'STANTON',
    'STAPLEHURST',
    'STAPLETON',
    'STEELE CITY',
    'STEINAUER',
    'STELLA',
    'STERLING',
    'STOCKHAM',
    'STOCKVILLE',
    'STRANG',
    'STRATTON',
    'STROMSBURG',
    'STUART',
    'SUMNER',
    'SUPERIOR',
    'SURPRISE',
    'SUTHERLAND',
    'SUTTON',
    'SWANTON',
    'SYRACUSE',
    'TABLE ROCK',
    'TALMAGE',
    'TARNOV',
    'TAYLOR',
    'TECUMSEH',
    'TEKAMAH',
    'TERRYTOWN',
    'THAYER',
    'THEDFORD',
    'THURSTON',
    'TILDEN',
    'TOBIAS',
    'TRENTON',
    'TRUMBULL',
    'UEHLING',
    'ULYSSES',
    'UNADILLA',
    'UNION',
    'UPLAND',
    'UTICA',
    'VALENTINE',
    'VALLEY',
    'VALPARAISO',
    'VENANGO',
    'VERDEL',
    'VERDIGRE',
    'VERDON',
    'VIRGINIA',
    'WACO',
    'WAHOO',
    'WAKEFIELD',
    'WALLACE',
    'WALTHILL',
    'WASHINGTON',
    'WATERBURY',
    'WATERLOO',
    'WAUNETA',
    'WAUSA',
    'WAVERLY',
    'WAYNE',
    'WEEPING WATER',
    'WELLFLEET',
    'WESTERN',
    'WESTON',
    'WEST POINT',
    'WHITNEY',
    'WILBER',
    'WILCOX',
    'WILSONVILLE',
    'WINNEBAGO',
    'WINNETOON',
    'WINSIDE',
    'WINSLOW',
    'WISNER',
    'WOLBACH',
    'WOOD LAKE',
    'WOOD RIVER',
    'WYMORE',
    'WYNOT',
    'YORK',
    'YUTAN',
  ],
  Nevada: [
    'BATTLE MOUNTAIN',
    'BEATTY',
    'BLUE DIAMOND',
    'BOULDER CITY',
    'BUNKERVILLE',
    'CALIENTE',
    'CAL-NEV-ARI',
    'CARLIN',
    'CARSON',
    'COLD SPRINGS',
    'DAYTON',
    'ELKO',
    'ELY',
    'ENTERPRISE',
    'FALLON',
    'FALLON STATION',
    'FERNLEY',
    'GABBS',
    'GARDNERVILLE',
    'GARDNERVILLE RANCHOS',
    'GERLACH-EMPIRE',
    'GOODSPRINGS',
    'HAWTHORNE',
    'HENDERSON',
    'INCLINE VILLAGE-CRYSTAL BAY',
    'INDIAN HILLS',
    'INDIAN SPRINGS',
    'JOHNSON LANE',
    'KINGSBURY',
    'LAS VEGAS',
    'LAUGHLIN',
    'LEMMON VALLEY-GOLDEN VALLEY',
    'LOVELOCK',
    'MCDERMITT',
    'MCGILL',
    'MESQUITE',
    'MINDEN',
    'MOAPA TOWN',
    'MOAPA VALLEY',
    'MOUNT CHARLESTON',
    'NELLIS AFB',
    'NIXON',
    'NORTH LAS VEGAS',
    'OWYHEE',
    'PAHRUMP',
    'PARADISE',
    'RENO',
    'SANDY VALLEY',
    'SCHURZ',
    'SEARCHLIGHT',
    'SILVER SPRINGS',
    'SMITH VALLEY',
    'SPANISH SPRINGS',
    'SPARKS',
    'SPRING CREEK',
    'SPRING VALLEY',
    'STATELINE',
    'SUMMERLIN SOUTH',
    'SUNRISE MANOR',
    'SUN VALLEY',
    'SUTCLIFFE',
    'TONOPAH',
    'VERDI-MOGUL',
    'WADSWORTH',
    'WELLS',
    'WEST WENDOVER',
    'WHITNEY',
    'WINCHESTER',
    'WINNEMUCCA',
    'YERINGTON',
    'ZEPHYR COVE-ROUND HILL VILLAGE',
  ],
  'New Hampshire': [
    'ANTRIM',
    'BERLIN',
    'BRISTOL',
    'CHARLESTOWN',
    'CLAREMONT',
    'CONCORD',
    'CONTOOCOOK',
    'CONWAY',
    'DERRY',
    'DOVER',
    'DURHAM',
    'EAST MERRIMACK',
    'ENFIELD',
    'EPPING',
    'EXETER',
    'FARMINGTON',
    'FRANKLIN',
    'GORHAM',
    'GREENVILLE',
    'GROVETON',
    'HAMPTON',
    'HANOVER',
    'HENNIKER',
    'HILLSBOROUGH',
    'HINSDALE',
    'HOOKSETT',
    'HUDSON',
    'JAFFREY',
    'KEENE',
    'LACONIA',
    'LANCASTER',
    'LEBANON',
    'LISBON',
    'LITTLETON',
    'LONDONDERRY',
    'MANCHESTER',
    'MARLBOROUGH',
    'MEREDITH',
    'MILFORD',
    'NASHUA',
    'NEWMARKET',
    'NEWPORT',
    'NORTH CONWAY',
    'PETERBOROUGH',
    'PINARDVILLE',
    'PITTSFIELD',
    'PLYMOUTH',
    'PORTSMOUTH',
    'RAYMOND',
    'ROCHESTER',
    'SOMERSWORTH',
    'SOUTH HOOKSETT',
    'SUNCOOK',
    'TILTON-NORTHFIELD',
    'WEST SWANZEY',
    'WHITEFIELD',
    'WILTON',
    'WINCHESTER',
    'WOLFEBORO',
    'WOODSVILLE',
  ],
  'New Jersey': [
    'ABSECON',
    'ALLAMUCHY-PANTHER VALLEY',
    'ALLENDALE',
    'ALLENHURST',
    'ALLENTOWN',
    'ALLENWOOD',
    'ALLOWAY',
    'ALPHA',
    'ALPINE',
    'ANDOVER',
    'ANNANDALE',
    'ASBURY PARK',
    'ASHLAND',
    'ATLANTIC CITY',
    'ATLANTIC HIGHLANDS',
    'AUDUBON',
    'AUDUBON PARK',
    'AVALON',
    'AVENEL',
    'AVON-BY-THE-SEA',
    'BARCLAY-KINGSTON',
    'BARNEGAT',
    'BARNEGAT LIGHT',
    'BARRINGTON',
    'BAY HEAD',
    'BAYONNE',
    'BEACH HAVEN',
    'BEACH HAVEN WEST',
    'BEACHWOOD',
    'BEATYESTOWN',
    'BECKETT',
    'BELFORD',
    'BELLEVILLE',
    'BELLMAWR',
    'BELMAR',
    'BELVIDERE',
    'BERGENFIELD',
    'BERKELEY HEIGHTS',
    'BERLIN',
    'BERNARDSVILLE',
    'BEVERLY',
    'BLACKWOOD',
    'BLOOMFIELD',
    'BLOOMINGDALE',
    'BLOOMSBURY',
    'BOGOTA',
    'BOONTON',
    'BORDENTOWN',
    'BOUND BROOK',
    'BRADLEY BEACH',
    'BRANCHVILLE',
    'BRASS CASTLE',
    'BRIDGETON',
    'BRIELLE',
    'BRIGANTINE',
    'BROOKLAWN',
    'BROWNS MILLS',
    'BROWNVILLE',
    'BUDD LAKE',
    'BUENA',
    'BURLINGTON',
    'BUTLER',
    'CALDWELL',
    'CALIFON',
    'CAMDEN',
    'CAPE MAY',
    'CAPE MAY COURT HOUSE',
    'CAPE MAY POINT',
    'CARLSTADT',
    'CARNEYS POINT',
    'CARTERET',
    'CEDAR GLEN LAKES',
    'CEDAR GLEN WEST',
    'CEDAR GROVE',
    'CEDARVILLE',
    'CHATHAM',
    'CHERRY HILL MALL',
    'CHESILHURST',
    'CHESTER',
    'CLARK',
    'CLAYTON',
    'CLEARBROOK PARK',
    'CLEMENTON',
    'CLIFFSIDE PARK',
    'CLIFFWOOD BEACH',
    'CLIFTON',
    'CLINTON',
    'CLOSTER',
    'COLLINGS LAKES',
    'COLLINGSWOOD',
    'COLONIA',
    'CONCORDIA',
    'CORBIN CITY',
    'COUNTRY LAKE ESTATES',
    'CRANBURY',
    'CRANDON LAKES',
    'CRANFORD',
    'CRESSKILL',
    'CRESTWOOD VILLAGE',
    'DAYTON',
    'DEAL',
    'DEMAREST',
    'DIAMOND BEACH',
    'DOVER',
    'DOVER BEACHES NORTH',
    'DOVER BEACHES SOUTH',
    'DUMONT',
    'DUNELLEN',
    'EAST BRUNSWICK',
    'EAST FREEHOLD',
    'EAST NEWARK',
    'EAST ORANGE',
    'EAST RUTHERFORD',
    'EATONTOWN',
    'ECHELON',
    'EDGEWATER',
    'EDISON',
    'EGG HARBOR CITY',
    'ELIZABETH',
    'ELMER',
    'ELMWOOD PARK',
    'ELWOOD-MAGNOLIA',
    'EMERSON',
    'ENGLEWOOD',
    'ENGLEWOOD CLIFFS',
    'ENGLISHTOWN',
    'ERLTON-ELLISBURG',
    'ERMA',
    'ESSEX FELLS',
    'ESTELL MANOR',
    'EWING',
    'FAIRFIELD',
    'FAIR HAVEN',
    'FAIR LAWN',
    'FAIRTON',
    'FAIRVIEW',
    'FAIRVIEW',
    'FANWOOD',
    'FAR HILLS',
    'FARMINGDALE',
    'FIELDSBORO',
    'FLEMINGTON',
    'FLORENCE-ROEBLING',
    'FLORHAM PARK',
    'FOLSOM',
    'FORDS',
    'FORKED RIVER',
    'FORT DIX',
    'FORT LEE',
    'FRANKLIN',
    'FRANKLIN LAKES',
    'FREEHOLD',
    'FRENCHTOWN',
    'GARFIELD',
    'GARWOOD',
    'GIBBSBORO',
    'GIBBSTOWN',
    'GLASSBORO',
    'GLENDORA',
    'GLEN GARDNER',
    'GLEN RIDGE',
    'GLEN ROCK',
    'GLOUCESTER CITY',
    'GOLDEN TRIANGLE',
    'GREAT MEADOWS-VIENNA',
    'GREENTREE',
    'GUTTENBERG',
    'HACKENSACK',
    'HACKETTSTOWN',
    'HADDONFIELD',
    'HADDON HEIGHTS',
    'HALEDON',
    'HAMBURG',
    'HAMMONTON',
    'HAMPTON',
    'HARRINGTON PARK',
    'HARRISON',
    'HARVEY CEDARS',
    'HASBROUCK HEIGHTS',
    'HAWORTH',
    'HAWTHORNE',
    'HEATHCOTE',
    'HELMETTA',
    'HIGH BRIDGE',
    'HIGHLAND LAKE',
    'HIGHLAND PARK',
    'HIGHLANDS',
    'HIGHTSTOWN',
    'HILLSDALE',
    'HILLSIDE',
    'HI-NELLA',
    'HOBOKEN',
    'HO-HO-KUS',
    'HOLIDAY CITY-BERKELEY',
    'HOLIDAY CITY SOUTH',
    'HOLIDAY HEIGHTS',
    'HOPATCONG',
    'HOPEWELL',
    'INTERLAKEN',
    'IRVINGTON',
    'ISELIN',
    'ISLAND HEIGHTS',
    'JAMESBURG',
    'JERSEY CITY',
    'KEANSBURG',
    'KEARNY',
    'KENDALL PARK',
    'KENILWORTH',
    'KEYPORT',
    'KINGSTON',
    'KINNELON',
    'LAKEHURST',
    'LAKE MOHAWK',
    'LAKE TELEMARK',
    'LAKEWOOD',
    'LAMBERTVILLE',
    'LAUREL LAKE',
    'LAUREL SPRINGS',
    'LAURENCE HARBOR',
    'LAVALLETTE',
    'LAWNSIDE',
    'LAWRENCEVILLE',
    'LEBANON',
    'LEISURE KNOLL',
    'LEISURETOWNE',
    'LEISURE VILLAGE',
    'LEISURE VILLAGE EAST',
    'LEISURE VILLAGE WEST-PINE LAKE PARK',
    'LEONARDO',
    'LEONIA',
    'LINCOLN PARK',
    'LINCROFT',
    'LINDEN',
    'LINDENWOLD',
    'LINWOOD',
    'LITTLE FALLS',
    'LITTLE FERRY',
    'LITTLE SILVER',
    'LIVINGSTON',
    'LOCH ARBOUR',
    'LODI',
    'LONG BRANCH',
    'LONGPORT',
    'LONG VALLEY',
    'LYNDHURST',
    'MCGUIRE AFB',
    'MADISON',
    'MADISON PARK',
    'MAGNOLIA',
    'MANAHAWKIN',
    'MANASQUAN',
    'MANTOLOKING',
    'MANVILLE',
    'MAPLEWOOD',
    'MARGATE CITY',
    'MARLTON',
    'MATAWAN',
    'MAYS LANDING',
    'MAYWOOD',
    'MEDFORD LAKES',
    'MENDHAM',
    'MERCERVILLE-HAMILTON SQUARE',
    'MERCHANTVILLE',
    'METUCHEN',
    'MIDDLESEX',
    'MIDLAND PARK',
    'MILFORD',
    'MILLBURN',
    'MILLSTONE',
    'MILLTOWN',
    'MILLVILLE',
    'MONMOUTH BEACH',
    'MONMOUTH JUNCTION',
    'MONTCLAIR',
    'MONTVALE',
    'MOONACHIE',
    'MOORESTOWN-LENOLA',
    'MORGANVILLE',
    'MORRIS PLAINS',
    'MORRISTOWN',
    'MOUNTAIN LAKES',
    'MOUNTAINSIDE',
    'MOUNT ARLINGTON',
    'MOUNT EPHRAIM',
    'MULLICA HILL',
    'MYSTIC ISLAND',
    'NATIONAL PARK',
    'NAVESINK',
    'NEPTUNE CITY',
    'NETCONG',
    'NEWARK',
    'NEW BRUNSWICK',
    'NEW EGYPT',
    'NEWFIELD',
    'NEW MILFORD',
    'NEW PROVIDENCE',
    'NEWTON',
    'NORTH ARLINGTON',
    'NORTH BEACH HAVEN',
    'NORTH BRUNSWICK TOWNSHIP',
    'NORTH CALDWELL',
    'NORTH CAPE MAY',
    'NORTHFIELD',
    'NORTH HALEDON',
    'NORTH MIDDLETOWN',
    'NORTH PLAINFIELD',
    'NORTHVALE',
    'NORTH WILDWOOD',
    'NORWOOD',
    'NUTLEY',
    'OAKHURST',
    'OAKLAND',
    'OAKLYN',
    'OAK VALLEY',
    'OCEAN ACRES',
    'OCEAN CITY',
    'OCEAN GATE',
    'OCEAN GROVE',
    'OCEANPORT',
    'OGDENSBURG',
    'OLD BRIDGE',
    'OLD TAPPAN',
    'OLIVET',
    'ORADELL',
    'ORANGE',
    'OXFORD',
    'PALISADES PARK',
    'PALMYRA',
    'PARAMUS',
    'PARK RIDGE',
    'PASSAIC',
    'PATERSON',
    'PAULSBORO',
    'PEAPACK AND GLADSTONE',
    'PEMBERTON',
    'PEMBERTON HEIGHTS',
    'PENNINGTON',
    'PENNSAUKEN',
    'PENNS GROVE',
    'PENNSVILLE',
    'PERTH AMBOY',
    'PHILLIPSBURG',
    'PINE BEACH',
    'PINE HILL',
    'PINE RIDGE AT CRESTWOOD',
    'PINE VALLEY',
    'PITMAN',
    'PLAINFIELD',
    'PLAINSBORO CENTER',
    'PLEASANTVILLE',
    'POINT PLEASANT',
    'POINT PLEASANT BEACH',
    'POMONA',
    'POMPTON LAKES',
    'PORT MONMOUTH',
    'PORT NORRIS',
    'PORT READING',
    'PORT REPUBLIC',
    'PRESIDENTIAL LAKES ESTATES',
    'PRINCETON',
    'PRINCETON JUNCTION',
    'PRINCETON MEADOWS',
    'PRINCETON NORTH',
    'PROSPECT PARK',
    'RAHWAY',
    'RAMBLEWOOD',
    'RAMSEY',
    'RAMTOWN',
    'RARITAN',
    'RED BANK',
    'RIDGEFIELD',
    'RIDGEFIELD PARK',
    'RIDGEWOOD',
    'RINGWOOD',
    'RIO GRANDE',
    'RIVERDALE',
    'RIVER EDGE',
    'RIVERTON',
    'RIVER VALE',
    'ROCHELLE PARK',
    'ROCKAWAY',
    'ROCKLEIGH',
    'ROCKY HILL',
    'ROOSEVELT',
    'ROSELAND',
    'ROSELLE',
    'ROSELLE PARK',
    'ROSENHAYN',
    'ROSSMOOR',
    'RUMSON',
    'RUNNEMEDE',
    'RUTHERFORD',
    'SADDLE BROOK',
    'SADDLE RIVER',
    'SALEM',
    'SAYREVILLE',
    'SCOTCH PLAINS',
    'SEA BRIGHT',
    'SEABROOK FARMS',
    'SEA GIRT',
    'SEA ISLE CITY',
    'SEASIDE HEIGHTS',
    'SEASIDE PARK',
    'SECAUCUS',
    'SEWAREN',
    'SHARK RIVER HILLS',
    'SHILOH',
    'SHIP BOTTOM',
    'SHREWSBURY',
    'SILVER RIDGE',
    'SOCIETY HILL',
    'SOMERDALE',
    'SOMERSET',
    'SOMERS POINT',
    'SOMERVILLE',
    'SOUTH AMBOY',
    'SOUTH BELMAR',
    'SOUTH BOUND BROOK',
    'SOUTH ORANGE',
    'SOUTH PLAINFIELD',
    'SOUTH RIVER',
    'SOUTH TOMS RIVER',
    'SPOTSWOOD',
    'SPRINGDALE',
    'SPRINGFIELD',
    'SPRING LAKE',
    'SPRING LAKE HEIGHTS',
    'STANHOPE',
    'STOCKTON',
    'STONE HARBOR',
    'STRATFORD',
    'STRATHMERE',
    'STRATHMORE',
    'SUCCASUNNA-KENVIL',
    'SUMMIT',
    'SURF CITY',
    'SUSSEX',
    'SWEDESBORO',
    'TAVISTOCK',
    'TEANECK',
    'TENAFLY',
    'TETERBORO',
    'TINTON FALLS',
    'TOMS RIVER',
    'TOTOWA',
    'TRENTON',
    'TUCKERTON',
    'TURNERSVILLE',
    'TWIN RIVERS',
    'UNION',
    'UNION BEACH',
    'UNION CITY',
    'UPPER SADDLE RIVER',
    'VENTNOR CITY',
    'VERNON VALLEY',
    'VERONA',
    'VICTORY GARDENS',
    'VICTORY LAKES',
    'VILLAS',
    'VINELAND',
    'VISTA CENTER',
    'WALDWICK',
    'WALLINGTON',
    'WANAMASSA',
    'WANAQUE',
    'WARETOWN',
    'WASHINGTON',
    'WASHINGTON TOWNSHIP',
    'WATCHUNG',
    'WAYNE',
    'WENONAH',
    'WEST BELMAR',
    'WEST CALDWELL',
    'WEST CAPE MAY',
    'WESTFIELD',
    'WEST FREEHOLD',
    'WEST LONG BRANCH',
    'WEST MILFORD',
    'WEST NEW YORK',
    'WEST ORANGE',
    'WEST PATERSON',
    'WESTVILLE',
    'WEST WILDWOOD',
    'WESTWOOD',
    'WHARTON',
    'WHITE HORSE',
    'WHITE HOUSE STATION',
    'WHITE MEADOW LAKE',
    'WHITESBORO-BURLEIGH',
    'WHITTINGHAM',
    'WILDWOOD',
    'WILDWOOD CREST',
    'WILLIAMSTOWN',
    'WOODBINE',
    'WOODBRIDGE',
    'WOODBURY',
    'WOODBURY HEIGHTS',
    'WOODCLIFF LAKE',
    'WOODLYNNE',
    'WOOD-RIDGE',
    'WOODSTOWN',
    'WRIGHTSTOWN',
    'WYCKOFF',
    'YARDVILLE-GROVEVILLE',
    'YORKETOWN',
  ],
  'New Mexico': [
    'ACOMITA LAKE',
    'AGUA FRIA',
    'ALAMO',
    'ALAMOGORDO',
    'ALBUQUERQUE',
    'ALCALDE',
    'ALGODONES',
    'ANGEL FIRE',
    'ANTHONY',
    'ARTESIA',
    'AZTEC',
    'BAYARD',
    'BECLABITO',
    'BELEN',
    'BERNALILLO',
    'BLACK ROCK',
    'BLOOMFIELD',
    'BOLES ACRES',
    'BOSQUE FARMS',
    'BRIMHALL NIZHONI',
    'CANADA DE LOS ALAMOS',
    'CANNON AFB',
    'CAPITAN',
    'CARLSBAD',
    'CARLSBAD NORTH',
    'CARNUEL',
    'CARRIZOZO',
    'CASA COLORADA',
    'CAUSEY',
    'CEDAR CREST',
    'CEDAR GROVE',
    'CHAMA',
    'CHAMISAL',
    'CHAPARRAL',
    'CHILILI',
    'CHIMAYO',
    'CHUPADERO',
    'CHURCH ROCK',
    'CIMARRON',
    'CLAYTON',
    'CLOUDCROFT',
    'CLOVIS',
    'COCHITI',
    'COLUMBUS',
    'CORONA',
    'CORRALES',
    'CROWNPOINT',
    'CRYSTAL',
    'CUARTELEZ',
    'CUBA',
    'CUNDIYO',
    'CUYAMUNGUE',
    'DEMING',
    'DES MOINES',
    'DEXTER',
    'DONA ANA',
    'DORA',
    'DULCE',
    'EAGLE NEST',
    'EDGEWOOD',
    'EL CERRO-MONTEREY PARK',
    'ELDORADO AT SANTA FE',
    'ELEPHANT BUTTE',
    'ELIDA',
    'EL RANCHO',
    'EL VALLE DE ARROYO SECO',
    'ENCINAL',
    'ENCINO',
    'ESPANOLA',
    'ESTANCIA',
    'EUNICE',
    'FARMINGTON',
    'FLORA VISTA',
    'FLOYD',
    'FOLSOM',
    'FORT SUMNER',
    'GALISTEO',
    'GALLUP',
    'GLORIETA',
    'GRADY',
    'GRANTS',
    'GRENVILLE',
    'HAGERMAN',
    'HATCH',
    'HOBBS',
    'HOLLOMAN AFB',
    'HOPE',
    'HOUSE',
    'HUERFANO',
    'HURLEY',
    'ISLETA VILLAGE PROPER',
    'JACONITA',
    'JAL',
    'JARALES',
    'JEMEZ PUEBLO',
    'JEMEZ SPRINGS',
    'KIRTLAND',
    'LA CIENEGA',
    'LAGUNA',
    'LA JARA',
    'LAKE ARTHUR',
    'LAKE SUMNER',
    'LA LUZ',
    'LAMY',
    'LA PUEBLA',
    'LAS CRUCES',
    'LAS VEGAS',
    'LOGAN',
    'LORDSBURG',
    'LOS ALAMOS',
    'LOS CERRILLOS',
    'LOS CHAVES',
    'LOS LUNAS',
    'LOS RANCHOS DE ALBUQUERQUE',
    'LOS TRUJILLOS-GABALDON',
    'LOVING',
    'LOVINGTON',
    'MADRID',
    'MAGDALENA',
    'MANZANO',
    'MAXWELL',
    'MEADOW LAKE',
    'MELROSE',
    'MESCALERO',
    'MESILLA',
    'MESITA',
    'MESQUITE',
    'MILAN',
    'MORIARTY',
    'MOSQUERO',
    'MOUNTAINAIR',
    'NAGEEZI',
    'NAKAIBITO',
    'NAPI HQ',
    'NASCHITTI',
    'NAVAJO',
    'NENAHNEZAD',
    'NEWCOMB',
    'NORTH ACOMITA VILLAGE',
    'NORTH VALLEY',
    'OJO AMARILLO',
    'PAGUATE',
    'PARAJE',
    'PECOS',
    'PENA BLANCA',
    'PENASCO',
    'PERALTA',
    'PICURIS PUEBLO',
    'PINEHILL',
    'PLACITAS',
    'POJOAQUE',
    'PONDEROSA',
    'PORTALES',
    'PUEBLO OF SANDIA VILLAGE',
    'PUEBLO PINTADO',
    'QUESTA',
    'RADIUM SPRINGS',
    'RAMAH',
    'RANCHOS DE TAOS',
    'RATON',
    'RED RIVER',
    'REGINA',
    'RESERVE',
    'RINCON',
    'RIO CHIQUITO',
    'RIO COMMUNITIES',
    'RIO COMMUNITIES NORTH',
    'RIO EN MEDIO',
    'RIO LUCIO',
    'RIO RANCHO',
    'ROCK SPRINGS',
    'ROSWELL',
    'ROY',
    'RUIDOSO',
    'RUIDOSO DOWNS',
    'SALEM',
    'SAN FELIPE PUEBLO',
    'SAN ILDEFONSO PUEBLO',
    'SAN JON',
    'SAN JUAN',
    'SANOSTEE',
    'SANTA ANA PUEBLO',
    'SANTA CLARA',
    'SANTA CLARA PUEBLO',
    'SANTA CRUZ',
    'SANTA FE',
    'SANTA ROSA',
    'SANTA TERESA',
    'SANTO DOMINGO PUEBLO',
    'SAN YSIDRO',
    'SEAMA',
    'SHEEP SPRINGS',
    'SHIPROCK',
    'SILVER CITY',
    'SKYLINE-GANIPA',
    'SOCORRO',
    'SOMBRILLO',
    'SOUTH VALLEY',
    'SPRINGER',
    'SUNLAND PARK',
    'TAJIQUE',
    'TAOS',
    'TAOS PUEBLO',
    'TAOS SKI VALLEY',
    'TATUM',
    'TESUQUE',
    'TEXICO',
    'THOREAU',
    'TIJERAS',
    'TIMBERON',
    'TOHATCHI',
    'TOME-ADELINO',
    'TORREON',
    'TORREON',
    'TRUTH OR CONSEQUENCES',
    'TSE BONITO',
    'TUCUMCARI',
    'TULAROSA',
    'TWIN LAKES',
    'UNIVERSITY PARK',
    'UPPER FRUITLAND',
    'VADITO',
    'VADO',
    'VALENCIA',
    'VAUGHN',
    'VIRDEN',
    'WAGON MOUND',
    'WHITE ROCK',
    'WHITE SANDS',
    'WILLARD',
    'WILLIAMSBURG',
    'YAH-TA-HEY',
    'ZIA PUEBLO',
    'ZUNI PUEBLO',
  ],
  'New York': [
    'ACCORD',
    'ADAMS',
    'ADAMS CENTER',
    'ADDISON',
    'AFTON',
    'AIRMONT',
    'AKRON',
    'ALBANY',
    'ALBERTSON',
    'ALBION',
    'ALDEN',
    'ALEXANDER',
    'ALEXANDRIA BAY',
    'ALFRED',
    'ALLEGANY',
    'ALMOND',
    'ALTAMONT',
    'ALTMAR',
    'ALTONA',
    'AMAGANSETT',
    'AMENIA',
    'AMES',
    'AMITYVILLE',
    'AMSTERDAM',
    'ANDES',
    'ANDOVER',
    'ANGELICA',
    'ANGOLA',
    'ANGOLA ON THE LAKE',
    'ANTWERP',
    'APALACHIN',
    'AQUEBOGUE',
    'ARCADE',
    'ARDSLEY',
    'ARGYLE',
    'ARKPORT',
    'ARLINGTON',
    'ARMONK',
    'ASHAROKEN',
    'ATHENS',
    'ATLANTIC BEACH',
    'ATTICA',
    'AUBURN',
    'AURORA',
    'AU SABLE FORKS',
    'AVERILL PARK',
    'AVOCA',
    'AVON',
    'BABYLON',
    'BAINBRIDGE',
    'BAITING HOLLOW',
    'BALDWIN',
    'BALDWIN HARBOR',
    'BALDWINSVILLE',
    'BALLSTON SPA',
    'BALMVILLE',
    'BARDONIA',
    'BARKER',
    'BARNEVELD',
    'BARNUM ISLAND',
    'BATAVIA',
    'BATH',
    'BAXTER ESTATES',
    'BAY PARK',
    'BAYPORT',
    'BAY SHORE',
    'BAYVILLE',
    'BAYWOOD',
    'BEACON',
    'BEAVERDAM LAKE-SALISBURY MILLS',
    'BEDFORD',
    'BELLEROSE',
    'BELLEROSE TERRACE',
    'BELLE TERRE',
    'BELLMORE',
    'BELLPORT',
    'BELMONT',
    'BEMUS POINT',
    'BERGEN',
    'BETHPAGE',
    'BIG FLATS',
    'BIG FLATS AIRPORT',
    'BILLINGTON HEIGHTS',
    'BINGHAMTON',
    'BLACK RIVER',
    'BLASDELL',
    'BLAUVELT',
    'BLOOMFIELD',
    'BLOOMINGBURG',
    'BLUE POINT',
    'BOHEMIA',
    'BOLIVAR',
    'BOONVILLE',
    'BRASHER FALLS-WINTHROP',
    'BRENTWOOD',
    'BREWERTON',
    'BREWSTER',
    'BREWSTER HILL',
    'BRIARCLIFF MANOR',
    'BRIDGEHAMPTON',
    'BRIDGEPORT',
    'BRIDGEWATER',
    'BRIGHTON',
    'BRIGHTWATERS',
    'BRINCKERHOFF',
    'BROADALBIN',
    'BROCKPORT',
    'BROCTON',
    'BRONXVILLE',
    'BROOKHAVEN',
    'BROOKVILLE',
    'BROWNVILLE',
    'BRUSHTON',
    'BUCHANAN',
    'BUFFALO',
    'BURDETT',
    'BURKE',
    'CAIRO',
    'CALCIUM',
    'CALEDONIA',
    'CALLICOON',
    'CALVERTON',
    'CAMBRIDGE',
    'CAMDEN',
    'CAMILLUS',
    'CANAJOHARIE',
    'CANANDAIGUA',
    'CANASERAGA',
    'CANASTOTA',
    'CANDOR',
    'CANISTEO',
    'CANTON',
    'CAPE VINCENT',
    'CARLE PLACE',
    'CARMEL HAMLET',
    'CARTHAGE',
    'CASSADAGA',
    'CASTILE',
    'CASTLETON-ON-HUDSON',
    'CASTORLAND',
    'CATO',
    'CATSKILL',
    'CATTARAUGUS',
    'CAYUGA',
    'CAYUGA HEIGHTS',
    'CAZENOVIA',
    'CEDARHURST',
    'CELORON',
    'CENTEREACH',
    'CENTER MORICHES',
    'CENTERPORT',
    'CENTRAL ISLIP',
    'CENTRAL SQUARE',
    'CENTRAL VALLEY',
    'CENTRE ISLAND',
    'CHAMPLAIN',
    'CHAPPAQUA',
    'CHATEAUGAY',
    'CHATHAM',
    'CHAUMONT',
    'CHEEKTOWAGA',
    'CHERRY CREEK',
    'CHERRY VALLEY',
    'CHESTER',
    'CHESTNUT RIDGE',
    'CHITTENANGO',
    'CHURCHVILLE',
    'CLARENCE CENTER',
    'CLARK MILLS',
    'CLAVERACK-RED MILLS',
    'CLAYTON',
    'CLAYVILLE',
    'CLEVELAND',
    'CLIFTON SPRINGS',
    'CLINTON',
    'CLINTONDALE',
    'CLYDE',
    'COBLESKILL',
    'COEYMANS',
    'COHOCTON',
    'COHOES',
    'COLD BROOK',
    'COLD SPRING',
    'COLD SPRING HARBOR',
    'COLONIE',
    'COMMACK',
    'CONGERS',
    'CONSTABLEVILLE',
    'CONSTANTIA',
    'COOPERSTOWN',
    'COPAKE LAKE',
    'COPENHAGEN',
    'COPIAGUE',
    'CORAM',
    'CORFU',
    'CORINTH',
    'CORNING',
    'CORNWALL ON HUDSON',
    'CORTLAND',
    'CORTLAND WEST',
    'COUNTRY KNOLLS',
    'COVE NECK',
    'COXSACKIE',
    'CRAGSMOOR',
    'CROGHAN',
    'CROMPOND',
    'CROTON-ON-HUDSON',
    'CROWN HEIGHTS',
    'CRUGERS',
    'CUBA',
    'CUMBERLAND HEAD',
    'CUTCHOGUE',
    'DANNEMORA',
    'DANSVILLE',
    'DEER PARK',
    'DEFERIET',
    'DELANSON',
    'DELEVAN',
    'DELHI',
    'DELMAR',
    'DEPAUVILLE',
    'DEPEW',
    'DEPOSIT',
    'DERING HARBOR',
    'DERUYTER',
    'DEXTER',
    'DIX HILLS',
    'DOBBS FERRY',
    'DOLGEVILLE',
    'DOVER PLAINS',
    'DRESDEN',
    'DRYDEN',
    'DUANE LAKE',
    'DUANESBURG',
    'DUNDEE',
    'DUNKIRK',
    'EARLVILLE',
    'EAST ATLANTIC BEACH',
    'EAST AURORA',
    'EASTCHESTER',
    'EAST FARMINGDALE',
    'EAST GARDEN CITY',
    'EAST GLENVILLE',
    'EAST GREENBUSH',
    'EAST HAMPTON',
    'EAST HAMPTON NORTH',
    'EAST HILLS',
    'EAST ISLIP',
    'EAST ITHACA',
    'EAST KINGSTON',
    'EAST MARION',
    'EAST MASSAPEQUA',
    'EAST MEADOW',
    'EAST MORICHES',
    'EAST NASSAU',
    'EAST NORTHPORT',
    'EAST NORWICH',
    'EAST PATCHOGUE',
    'EASTPORT',
    'EAST QUOGUE',
    'EAST RANDOLPH',
    'EAST ROCHESTER',
    'EAST ROCKAWAY',
    'EAST SHOREHAM',
    'EAST SYRACUSE',
    'EAST WILLISTON',
    'EATONS NECK',
    'EDEN',
    'EDWARDS',
    'ELBA',
    'ELBRIDGE',
    'ELLENVILLE',
    'ELLICOTTVILLE',
    'ELLISBURG',
    'ELMA CENTER',
    'ELMIRA',
    'ELMIRA HEIGHTS',
    'ELMONT',
    'ELMSFORD',
    'ELWOOD',
    'ENDICOTT',
    'ENDWELL',
    'ESPERANCE',
    'EVANS MILLS',
    'FABIUS',
    'FAIR HAVEN',
    'FAIRMOUNT',
    'FAIRPORT',
    'FAIRVIEW',
    'FAIRVIEW',
    'FALCONER',
    'FARMINGDALE',
    'FARMINGVILLE',
    'FARNHAM',
    'FAYETTEVILLE',
    'FIRE ISLAND',
    'FIRTHCLIFFE',
    'FISHERS ISLAND',
    'FISHKILL',
    'FLANDERS',
    'FLEISCHMANNS',
    'FLORAL PARK',
    'FLORIDA',
    'FLOWER HILL',
    'FONDA',
    'FOREST HOME',
    'FORESTVILLE',
    'FORT ANN',
    'FORT DRUM',
    'FORT EDWARD',
    'FORT JOHNSON',
    'FORT MONTGOMERY',
    'FORT PLAIN',
    'FORT SALONGA',
    'FRANKFORT',
    'FRANKLIN',
    'FRANKLIN SQUARE',
    'FRANKLINVILLE',
    'FREDONIA',
    'FREEPORT',
    'FREEVILLE',
    'FREWSBURG',
    'FRIENDSHIP',
    'FULTON',
    'FULTONVILLE',
    'GAINESVILLE',
    'GALEVILLE',
    'GALWAY',
    'GANG MILLS',
    'GARDEN CITY',
    'GARDEN CITY PARK',
    'GARDEN CITY SOUTH',
    'GARDINER',
    'GARDNERTOWN',
    'GASPORT',
    'GATES-NORTH GATES',
    'GENESEO',
    'GENEVA',
    'GERMANTOWN',
    'GHENT',
    'GILBERTSVILLE',
    'GILGO-OAK BEACH-CAPTREE',
    'GLASCO',
    'GLEN COVE',
    'GLEN HEAD',
    'GLEN PARK',
    'GLENS FALLS',
    'GLENS FALLS NORTH',
    'GLENWOOD LANDING',
    'GLOVERSVILLE',
    'GOLDEN S BRIDGE',
    'GORDON HEIGHTS',
    'GOSHEN',
    'GOUVERNEUR',
    'GOWANDA',
    'GRAND VIEW-ON-HUDSON',
    'GRANVILLE',
    'GREAT BEND',
    'GREAT NECK',
    'GREAT NECK ESTATES',
    'GREAT NECK GARDENS',
    'GREAT NECK PLAZA',
    'GREAT RIVER',
    'GREECE',
    'GREENE',
    'GREEN ISLAND',
    'GREENLAWN',
    'GREENPORT',
    'GREENPORT WEST',
    'GREENVALE',
    'GREENVILLE',
    'GREENVILLE',
    'GREENWICH',
    'GREENWOOD LAKE',
    'GROTON',
    'HAGAMAN',
    'HALESITE',
    'HAMBURG',
    'HAMILTON',
    'HAMMOND',
    'HAMMONDSPORT',
    'HAMPTON BAYS',
    'HAMPTON MANOR',
    'HANCOCK',
    'HANNIBAL',
    'HARBOR HILLS',
    'HARBOR ISLE',
    'HARRIMAN',
    'HARRIS HILL',
    'HARRISON',
    'HARRISVILLE',
    'HARTSDALE',
    'HASTINGS-ON-HUDSON',
    'HAUPPAUGE',
    'HAVERSTRAW',
    'HAVILAND',
    'HAWTHORNE',
    'HEAD OF THE HARBOR',
    'HEMPSTEAD',
    'HERITAGE HILLS',
    'HERKIMER',
    'HERMON',
    'HERRICKS',
    'HERRINGS',
    'HEUVELTON',
    'HEWLETT',
    'HEWLETT BAY PARK',
    'HEWLETT HARBOR',
    'HEWLETT NECK',
    'HICKSVILLE',
    'HIGH FALLS',
    'HIGHLAND',
    'HIGHLAND FALLS',
    'HIGHLAND MILLS',
    'HILLBURN',
    'HILLCREST',
    'HILLSIDE',
    'HILLSIDE LAKE',
    'HILTON',
    'HOBART',
    'HOLBROOK',
    'HOLLAND',
    'HOLLAND PATENT',
    'HOLLEY',
    'HOLTSVILLE',
    'HOMER',
    'HONEOYE FALLS',
    'HOOSICK FALLS',
    'HOPEWELL JUNCTION',
    'HORNELL',
    'HORSEHEADS',
    'HORSEHEADS NORTH',
    'HOUGHTON',
    'HUDSON',
    'HUDSON FALLS',
    'HUNTER',
    'HUNTINGTON',
    'HUNTINGTON BAY',
    'HUNTINGTON STATION',
    'HURLEY',
    'ILION',
    'INTERLAKEN',
    'INWOOD',
    'IRONDEQUOIT',
    'IRVINGTON',
    'ISLANDIA',
    'ISLAND PARK',
    'ISLIP',
    'ISLIP TERRACE',
    'ITHACA',
    'JAMESPORT',
    'JAMESTOWN',
    'JAMESTOWN WEST',
    'JEFFERSON HEIGHTS',
    'JEFFERSON VALLEY-YORKTOWN',
    'JEFFERSONVILLE',
    'JERICHO',
    'JOHNSON CITY',
    'JOHNSTOWN',
    'JORDAN',
    'KASER',
    'KEESEVILLE',
    'KENMORE',
    'KENSINGTON',
    'KERHONKSON',
    'KINDERHOOK',
    'KINGS PARK',
    'KINGS POINT',
    'KINGSTON',
    'KIRYAS JOEL',
    'LACKAWANNA',
    'LACONA',
    'LA FARGEVILLE',
    'LAKE CARMEL',
    'LAKE ERIE BEACH',
    'LAKE GEORGE',
    'LAKE GROVE',
    'LAKE KATRINE',
    'LAKELAND',
    'LAKE LUZERNE-HADLEY',
    'LAKE MOHEGAN',
    'LAKE PLACID',
    'LAKE RONKONKOMA',
    'LAKE SUCCESS',
    'LAKEVIEW',
    'LAKEWOOD',
    'LANCASTER',
    'LANSING',
    'LARCHMONT',
    'LATTINGTOWN',
    'LAUREL',
    'LAUREL HOLLOW',
    'LAURENS',
    'LAWRENCE',
    'LEEDS',
    'LEICESTER',
    'LE ROY',
    'LEVITTOWN',
    'LEWISTON',
    'LIBERTY',
    'LIDO BEACH',
    'LIMA',
    'LIME LAKE-MACHIAS',
    'LIMESTONE',
    'LINCOLNDALE',
    'LINCOLN PARK',
    'LINDENHURST',
    'LISLE',
    'LITTLE FALLS',
    'LITTLE VALLEY',
    'LIVERPOOL',
    'LIVINGSTON MANOR',
    'LIVONIA',
    'LLOYD HARBOR',
    'LOCKPORT',
    'LOCUST VALLEY',
    'LODI',
    'LONG BEACH',
    'LORENZ PARK',
    'LOWVILLE',
    'LYNBROOK',
    'LYNCOURT',
    'LYNDONVILLE',
    'LYON MOUNTAIN',
    'LYONS',
    'LYONS FALLS',
    'MACEDON',
    'MCGRAW',
    'MADISON',
    'MAHOPAC',
    'MALDEN',
    'MALONE',
    'MALVERNE',
    'MALVERNE PARK OAKS',
    'MAMARONECK',
    'MANCHESTER',
    'MANHASSET',
    'MANHASSET HILLS',
    'MANLIUS',
    'MANNSVILLE',
    'MANORHAVEN',
    'MANORVILLE',
    'MARATHON',
    'MARCELLUS',
    'MARGARETVILLE',
    'MARIAVILLE LAKE',
    'MARLBORO',
    'MASSAPEQUA',
    'MASSAPEQUA PARK',
    'MASSENA',
    'MASTIC',
    'MASTIC BEACH',
    'MATINECOCK',
    'MATTITUCK',
    'MATTYDALE',
    'MAYBROOK',
    'MAYFIELD',
    'MAYVILLE',
    'MECHANICSTOWN',
    'MECHANICVILLE',
    'MEDFORD',
    'MEDINA',
    'MEDUSA',
    'MELROSE PARK',
    'MELVILLE',
    'MENANDS',
    'MERIDIAN',
    'MERRICK',
    'MEXICO',
    'MIDDLEBURGH',
    'MIDDLE ISLAND',
    'MIDDLEPORT',
    'MIDDLETOWN',
    'MIDDLEVILLE',
    'MILFORD',
    'MILLBROOK',
    'MILLER PLACE',
    'MILLERTON',
    'MILL NECK',
    'MILLPORT',
    'MILTON',
    'MILTON',
    'MINEOLA',
    'MINETTO',
    'MINEVILLE-WITHERBEE',
    'MINOA',
    'MOHAWK',
    'MONROE',
    'MONSEY',
    'MONTAUK',
    'MONTEBELLO',
    'MONTGOMERY',
    'MONTICELLO',
    'MONTOUR FALLS',
    'MOOERS',
    'MORAVIA',
    'MORICHES',
    'MORRIS',
    'MORRISONVILLE',
    'MORRISTOWN',
    'MORRISVILLE',
    'MOUNT IVY',
    'MOUNT KISCO',
    'MOUNT MORRIS',
    'MOUNT SINAI',
    'MOUNT VERNON',
    'MUNNSVILLE',
    'MUNSEY PARK',
    'MUNSONS CORNERS',
    'MUTTONTOWN',
    'MYERS CORNER',
    'NANUET',
    'NAPANOCH',
    'NAPEAGUE',
    'NAPLES',
    'NARROWSBURG',
    'NASSAU',
    'NATURAL BRIDGE',
    'NEDROW',
    'NELLISTON',
    'NELSONVILLE',
    'NESCONSET',
    'NEWARK',
    'NEWARK VALLEY',
    'NEW BERLIN',
    'NEWBURGH',
    'NEW CASSEL',
    'NEW CITY',
    'NEWFANE',
    'NEWFIELD HAMLET',
    'NEW HARTFORD',
    'NEW HEMPSTEAD',
    'NEW HYDE PARK',
    'NEW PALTZ',
    'NEWPORT',
    'NEW ROCHELLE',
    'NEW SQUARE',
    'NEW SUFFOLK',
    'NEW WINDSOR',
    'NEW YORK',
    'NEW YORK MILLS',
    'NIAGARA FALLS',
    'NICHOLS',
    'NISKAYUNA',
    'NISSEQUOGUE',
    'NIVERVILLE',
    'NORFOLK',
    'NORTH AMITYVILLE',
    'NORTHAMPTON',
    'NORTH BABYLON',
    'NORTH BALLSTON SPA',
    'NORTH BAY SHORE',
    'NORTH BELLMORE',
    'NORTH BELLPORT',
    'NORTH BOSTON',
    'NORTH COLLINS',
    'NORTHEAST ITHACA',
    'NORTH GREAT RIVER',
    'NORTH HAVEN',
    'NORTH HILLS',
    'NORTH HORNELL',
    'NORTH LINDENHURST',
    'NORTH LYNBROOK',
    'NORTH MASSAPEQUA',
    'NORTH MERRICK',
    'NORTH NEW HYDE PARK',
    'NORTH PATCHOGUE',
    'NORTHPORT',
    'NORTH SEA',
    'NORTH SYRACUSE',
    'NORTH TONAWANDA',
    'NORTH VALLEY STREAM',
    'NORTHVILLE',
    'NORTHVILLE',
    'NORTH WANTAGH',
    'NORTHWEST HARBOR',
    'NORTHWEST ITHACA',
    'NORWICH',
    'NORWOOD',
    'NOYACK',
    'NUNDA',
    'NYACK',
    'OAKDALE',
    'OAKFIELD',
    'OCEAN BEACH',
    'OCEANSIDE',
    'ODESSA',
    'OGDENSBURG',
    'OLCOTT',
    'OLD BETHPAGE',
    'OLD BROOKVILLE',
    'OLD FIELD',
    'OLD WESTBURY',
    'OLEAN',
    'ONEIDA',
    'ONEIDA CASTLE',
    'ONEONTA',
    'ORANGEBURG',
    'ORANGE LAKE',
    'ORCHARD PARK',
    'ORIENT',
    'ORISKANY',
    'ORISKANY FALLS',
    'OSSINING',
    'OSWEGO',
    'OTEGO',
    'OTISVILLE',
    'OVID',
    'OWEGO',
    'OXFORD',
    'OYSTER BAY',
    'OYSTER BAY COVE',
    'PAINTED POST',
    'PALATINE BRIDGE',
    'PALENVILLE',
    'PALMYRA',
    'PANAMA',
    'PARC',
    'PARISH',
    'PATCHOGUE',
    'PATTERSONVILLE-ROTTERDAM JUNCTION',
    'PAWLING',
    'PEACH LAKE',
    'PEARL RIVER',
    'PECONIC',
    'PEEKSKILL',
    'PELHAM',
    'PELHAM MANOR',
    'PENN YAN',
    'PERRY',
    'PERRYSBURG',
    'PERU',
    'PHELPS',
    'PHILADELPHIA',
    'PHILMONT',
    'PHOENICIA',
    'PHOENIX',
    'PIERMONT',
    'PIKE',
    'PINE BUSH',
    'PINE HILL',
    'PINE PLAINS',
    'PITTSFORD',
    'PLAINEDGE',
    'PLAINVIEW',
    'PLANDOME',
    'PLANDOME HEIGHTS',
    'PLANDOME MANOR',
    'PLATTEKILL',
    'PLATTSBURGH',
    'PLATTSBURGH WEST',
    'PLEASANT VALLEY',
    'PLEASANTVILLE',
    'POESTENKILL',
    'POINT LOOKOUT',
    'POLAND',
    'POMONA',
    'POQUOTT',
    'PORT BYRON',
    'PORT CHESTER',
    'PORT DICKINSON',
    'PORT EWEN',
    'PORT HENRY',
    'PORT JEFFERSON',
    'PORT JEFFERSON STATION',
    'PORT JERVIS',
    'PORT LEYDEN',
    'PORTVILLE',
    'PORT WASHINGTON',
    'PORT WASHINGTON NORTH',
    'POTSDAM',
    'POUGHKEEPSIE',
    'PRESTON-POTTER HOLLOW',
    'PROSPECT',
    'PULASKI',
    'PUTNAM LAKE',
    'QUIOQUE',
    'QUOGUE',
    'RANDOLPH',
    'RANSOMVILLE',
    'RAPIDS',
    'RAVENA',
    'RED CREEK',
    'REDFORD',
    'RED HOOK',
    'RED OAKS MILL',
    'REDWOOD',
    'REMSEN',
    'REMSENBURG-SPEONK',
    'RENSSELAER',
    'RENSSELAER FALLS',
    'RHINEBECK',
    'RICHBURG',
    'RICHFIELD SPRINGS',
    'RICHMONDVILLE',
    'RICHVILLE',
    'RIDGE',
    'RIFTON',
    'RIPLEY',
    'RIVERHEAD',
    'RIVERSIDE',
    'RIVERSIDE',
    'ROCHESTER',
    'ROCK HILL',
    'ROCKVILLE CENTRE',
    'ROCKY POINT',
    'ROME',
    'RONKONKOMA',
    'ROOSEVELT',
    'ROSCOE',
    'ROSENDALE VILLAGE',
    'ROSLYN',
    'ROSLYN ESTATES',
    'ROSLYN HARBOR',
    'ROSLYN HEIGHTS',
    'ROTTERDAM',
    'ROUND LAKE',
    'ROUSES POINT',
    'RUSHVILLE',
    'RUSSELL GARDENS',
    'RYE',
    'RYE BROOK',
    'SACKETS HARBOR',
    'SADDLE ROCK',
    'SADDLE ROCK ESTATES',
    'SAGAPONACK',
    'SAG HARBOR',
    'ST. BONAVENTURE',
    'ST. JAMES',
    'ST. JOHNSVILLE',
    'SALAMANCA',
    'SALEM',
    'SALISBURY',
    'SALTAIRE',
    'SAND RIDGE',
    'SANDS POINT',
    'SANDY CREEK',
    'SARANAC LAKE',
    'SARATOGA SPRINGS',
    'SAUGERTIES',
    'SAUGERTIES SOUTH',
    'SAVONA',
    'SAYVILLE',
    'SCARSDALE',
    'SCHAGHTICOKE',
    'SCHENECTADY',
    'SCHOHARIE',
    'SCHUYLERVILLE',
    'SCOTCHTOWN',
    'SCOTIA',
    'SCOTTS CORNERS',
    'SCOTTSVILLE',
    'SEA CLIFF',
    'SEAFORD',
    'SEARINGTOWN',
    'SELDEN',
    'SENECA FALLS',
    'SENECA KNOLLS',
    'SETAUKET-EAST SETAUKET',
    'SHARON SPRINGS',
    'SHELTER ISLAND',
    'SHELTER ISLAND HEIGHTS',
    'SHENOROCK',
    'SHERBURNE',
    'SHERMAN',
    'SHERRILL',
    'SHINNECOCK HILLS',
    'SHIRLEY',
    'SHOKAN',
    'SHOREHAM',
    'SHORTSVILLE',
    'SHRUB OAK',
    'SIDNEY',
    'SILVER CREEK',
    'SILVER SPRINGS',
    'SINCLAIRVILLE',
    'SKANEATELES',
    'SLEEPY HOLLOW',
    'SLOAN',
    'SLOATSBURG',
    'SMALLWOOD',
    'SMITHTOWN',
    'SMYRNA',
    'SODUS',
    'SODUS POINT',
    'SOLVAY',
    'SOUND BEACH',
    'SOUTHAMPTON',
    'SOUTH CORNING',
    'SOUTH DAYTON',
    'SOUTH FALLSBURG',
    'SOUTH FARMINGDALE',
    'SOUTH FLORAL PARK',
    'SOUTH GLENS FALLS',
    'SOUTH HEMPSTEAD',
    'SOUTH HILL',
    'SOUTH HUNTINGTON',
    'SOUTH LOCKPORT',
    'SOUTH NYACK',
    'SOUTHOLD',
    'SOUTHPORT',
    'SOUTH VALLEY STREAM',
    'SPACKENKILL',
    'SPECULATOR',
    'SPENCER',
    'SPENCERPORT',
    'SPRINGS',
    'SPRING VALLEY',
    'SPRINGVILLE',
    'STAATSBURG',
    'STAMFORD',
    'STANNARDS',
    'STAR LAKE',
    'STEWART MANOR',
    'STILLWATER',
    'STONE RIDGE',
    'STONY BROOK',
    'STONY POINT',
    'STOTTVILLE',
    'SUFFERN',
    'SYLVAN BEACH',
    'SYOSSET',
    'SYRACUSE',
    'TANNERSVILLE',
    'TAPPAN',
    'TARRYTOWN',
    'TERRYVILLE',
    'THERESA',
    'THIELLS',
    'THOMASTON',
    'THORNWOOD',
    'TILLSON',
    'TIVOLI',
    'TONAWANDA',
    'TONAWANDA',
    'TOWN LINE',
    'TRIBES HILL',
    'TROY',
    'TRUMANSBURG',
    'TUCKAHOE',
    'TUCKAHOE',
    'TULLY',
    'TUPPER LAKE',
    'TURIN',
    'TUXEDO PARK',
    'UNADILLA',
    'UNIONDALE',
    'UNION SPRINGS',
    'UNIONVILLE',
    'UNIVERSITY GARDENS',
    'UPPER BROOKVILLE',
    'UPPER NYACK',
    'UTICA',
    'VAILS GATE',
    'VALATIE',
    'VALHALLA',
    'VALLEY COTTAGE',
    'VALLEY FALLS',
    'VALLEY STREAM',
    'VAN ETTEN',
    'VERNON',
    'VERPLANCK',
    'VICTOR',
    'VICTORY',
    'VILLAGE GREEN',
    'VILLAGE OF THE BRANCH',
    'VIOLA',
    'VOORHEESVILLE',
    'WADDINGTON',
    'WADING RIVER',
    'WAINSCOTT',
    'WALDEN',
    'WALKER VALLEY',
    'WALLKILL',
    'WALTON',
    'WALTON PARK',
    'WAMPSVILLE',
    'WANTAGH',
    'WAPPINGERS FALLS',
    'WARRENSBURG',
    'WARSAW',
    'WARWICK',
    'WASHINGTON HEIGHTS',
    'WASHINGTONVILLE',
    'WATERFORD',
    'WATERLOO',
    'WATERMILL',
    'WATERTOWN',
    'WATERVILLE',
    'WATERVLIET',
    'WATKINS GLEN',
    'WAVERLY',
    'WAYLAND',
    'WEBSTER',
    'WEEDSPORT',
    'WELLSBURG',
    'WELLSVILLE',
    'WESLEY HILLS',
    'WEST BABYLON',
    'WEST BAY SHORE',
    'WESTBURY',
    'WEST CARTHAGE',
    'WEST ELMIRA',
    'WEST END',
    'WESTFIELD',
    'WEST GLENS FALLS',
    'WESTHAMPTON',
    'WESTHAMPTON BEACH',
    'WEST HAMPTON DUNES',
    'WEST HAVERSTRAW',
    'WEST HEMPSTEAD',
    'WEST HILLS',
    'WEST HURLEY',
    'WEST ISLIP',
    'WESTMERE',
    'WEST NYACK',
    'WESTON MILLS',
    'WEST POINT',
    'WEST SAND LAKE',
    'WEST SAYVILLE',
    'WEST SENECA',
    'WESTVALE',
    'WEST WINFIELD',
    'WHEATLEY HEIGHTS',
    'WHITEHALL',
    'WHITE PLAINS',
    'WHITESBORO',
    'WHITNEY POINT',
    'WILLIAMSVILLE',
    'WILLISTON PARK',
    'WILSON',
    'WINDHAM',
    'WINDSOR',
    'WOLCOTT',
    'WOODBURY',
    'WOODMERE',
    'WOODRIDGE',
    'WOODSBURGH',
    'WOODSTOCK',
    'WURTSBORO',
    'WYANDANCH',
    'WYNANTSKILL',
    'WYOMING',
    'YAPHANK',
    'YONKERS',
    'YORKSHIRE',
    'YORKTOWN HEIGHTS',
    'YORKVILLE',
    'YOUNGSTOWN',
    'ZENA',
  ],
  'North Carolina': [
    'ABERDEEN',
    'AHOSKIE',
    'ALAMANCE',
    'ALBEMARLE',
    'ALLIANCE',
    'ALTAMAHAW-OSSIPEE',
    'ANDREWS',
    'ANGIER',
    'ANSONVILLE',
    'APEX',
    'ARAPAHOE',
    'ARCHDALE',
    'ARLINGTON',
    'ASHEBORO',
    'ASHEVILLE',
    'ASHLEY HEIGHTS',
    'ASKEWVILLE',
    'ATKINSON',
    'ATLANTIC BEACH',
    'AULANDER',
    'AURORA',
    'AUTRYVILLE',
    'AVERY CREEK',
    'AYDEN',
    'BADIN',
    'BAILEY',
    'BAKERSVILLE',
    'BALD HEAD ISLAND',
    'BALFOUR',
    'BANNER ELK',
    'BARKER HEIGHTS',
    'BARKER TEN MILE',
    'BATH',
    'BAYBORO',
    'BAYSHORE',
    'BEARGRASS',
    'BEAUFORT',
    'BEECH MOUNTAIN',
    'BELHAVEN',
    'BELMONT',
    'BELVILLE',
    'BELWOOD',
    'BENSON',
    'BENT CREEK',
    'BERMUDA RUN',
    'BESSEMER CITY',
    'BETHANIA',
    'BETHEL',
    'BETHLEHEM',
    'BEULAVILLE',
    'BILTMORE FOREST',
    'BISCOE',
    'BLACK CREEK',
    'BLACK MOUNTAIN',
    'BLADENBORO',
    'BLOWING ROCK',
    'BOARDMAN',
    'BOGER CITY',
    'BOGUE',
    'BOILING SPRING LAKES',
    'BOILING SPRINGS',
    'BOLIVIA',
    'BOLTON',
    'BONNETSVILLE',
    'BOONE',
    'BOONVILLE',
    'BOSTIC',
    'BOWMORE',
    'BREVARD',
    'BRICES CREEK',
    'BRIDGETON',
    'BROADWAY',
    'BROGDEN',
    'BROOKFORD',
    'BRUNSWICK',
    'BRYSON CITY',
    'BUIES CREEK',
    'BUNN',
    'BURGAW',
    'BURLINGTON',
    'BURNSVILLE',
    'BUTNER',
    'BUTTERS',
    'CAJAH S MOUNTAIN',
    'CALABASH',
    'CALYPSO',
    'CAMERON',
    'CANDOR',
    'CANTON',
    'CAPE CARTERET',
    'CAROLINA BEACH',
    'CAROLINA SHORES',
    'CARRBORO',
    'CARTHAGE',
    'CARY',
    'CASAR',
    'CASHIERS',
    'CASTALIA',
    'CASTLE HAYNE',
    'CASWELL BEACH',
    'CATAWBA',
    'CEDAR POINT',
    'CEDAR ROCK',
    'CENTERVILLE',
    'CERRO GORDO',
    'CHADBOURN',
    'CHAPEL HILL',
    'CHARLOTTE',
    'CHERRYVILLE',
    'CHIMNEY ROCK',
    'CHINA GROVE',
    'CHOCOWINITY',
    'CLAREMONT',
    'CLARKTON',
    'CLAYTON',
    'CLEMMONS',
    'CLEVELAND',
    'CLINTON',
    'CLYDE',
    'COATS',
    'COFIELD',
    'COLERAIN',
    'COLUMBIA',
    'COLUMBUS',
    'COMO',
    'CONCORD',
    'CONETOE',
    'CONNELLY SPRINGS',
    'CONOVER',
    'CONWAY',
    'COOLEEMEE',
    'CORNELIUS',
    'COVE CITY',
    'CRAMERTON',
    'CREEDMOOR',
    'CRESWELL',
    'CRICKET',
    'CROSSNORE',
    'CULLOWHEE',
    'DALLAS',
    'DANBURY',
    'DAVIDSON',
    'DELWAY',
    'DENTON',
    'DILLSBORO',
    'DOBBINS HEIGHTS',
    'DOBSON',
    'DORTCHES',
    'DOVER',
    'DREXEL',
    'DUBLIN',
    'DUNDARRACH',
    'DUNN',
    'DURHAM',
    'EARL',
    'EAST ARCADIA',
    'EAST BEND',
    'EAST FLAT ROCK',
    'EAST LAURINBURG',
    'EASTOVER',
    'EAST ROCKINGHAM',
    'EAST SPENCER',
    'EDEN',
    'EDENTON',
    'ELIZABETH CITY',
    'ELIZABETHTOWN',
    'ELKIN',
    'ELK PARK',
    'ELLENBORO',
    'ELLERBE',
    'ELM CITY',
    'ELON COLLEGE',
    'ELROD',
    'ELROY',
    'EMERALD ISLE',
    'ENFIELD',
    'ENOCHVILLE',
    'ERWIN',
    'ETOWAH',
    'EUREKA',
    'EVERETTS',
    'FAIR BLUFF',
    'FAIRFIELD HARBOUR',
    'FAIRMONT',
    'FAIRPLAINS',
    'FAIRVIEW',
    'FAISON',
    'FAITH',
    'FALCON',
    'FALKLAND',
    'FALLSTON',
    'FARMVILLE',
    'FAYETTEVILLE',
    'FEARRINGTON',
    'FIVE POINTS',
    'FLAT ROCK',
    'FLAT ROCK',
    'FLETCHER',
    'FOREST CITY',
    'FOREST HILLS',
    'FOREST OAKS',
    'FORT BRAGG',
    'FOUNTAIN',
    'FOUR OAKS',
    'FOXFIRE',
    'FRANKLIN',
    'FRANKLINTON',
    'FRANKLINVILLE',
    'FREMONT',
    'FUQUAY-VARINA',
    'GAMEWELL',
    'GARLAND',
    'GARNER',
    'GARYSBURG',
    'GASTON',
    'GASTONIA',
    'GATESVILLE',
    'GIBSON',
    'GIBSONVILLE',
    'GLEN ALPINE',
    'GLEN RAVEN',
    'GODWIN',
    'GOLDSBORO',
    'GOLDSTON',
    'GORMAN',
    'GRAHAM',
    'GRANDFATHER',
    'GRANITE FALLS',
    'GRANITE QUARRY',
    'GREENEVERS',
    'GREEN LEVEL',
    'GREENSBORO',
    'GREENVILLE',
    'GRIFTON',
    'GRIMESLAND',
    'GROVER',
    'HALF MOON',
    'HALIFAX',
    'HAMILTON',
    'HAMLET',
    'HARKERS ISLAND',
    'HARMONY',
    'HARRELLS',
    'HARRELLSVILLE',
    'HARRISBURG',
    'HASSELL',
    'HAVELOCK',
    'HAW RIVER',
    'HAYESVILLE',
    'HAYS',
    'HEMBY BRIDGE',
    'HENDERSON',
    'HENDERSONVILLE',
    'HERTFORD',
    'HICKORY',
    'HIGHLANDS',
    'HIGH POINT',
    'HIGH SHOALS',
    'HIGHTSVILLE',
    'HILDEBRAN',
    'HILLSBOROUGH',
    'HOBGOOD',
    'HOFFMAN',
    'HOLDEN BEACH',
    'HOLLY RIDGE',
    'HOLLY SPRINGS',
    'HOOKERTON',
    'HOPE MILLS',
    'HOT SPRINGS',
    'HUDSON',
    'HUNTERSVILLE',
    'ICARD',
    'INDIAN BEACH',
    'INDIAN TRAIL',
    'INGOLD',
    'IVANHOE',
    'JAARS',
    'JACKSON',
    'JACKSONVILLE',
    'JAMES CITY',
    'JAMESTOWN',
    'JAMESVILLE',
    'JEFFERSON',
    'JONESVILLE',
    'KANNAPOLIS',
    'KEENER',
    'KELFORD',
    'KELLY',
    'KENANSVILLE',
    'KENLY',
    'KERNERSVILLE',
    'KILL DEVIL HILLS',
    'KING',
    'KINGS GRANT',
    'KINGS MOUNTAIN',
    'KINGSTOWN',
    'KINSTON',
    'KIRKLAND',
    'KITTRELL',
    'KITTY HAWK',
    'KNIGHTDALE',
    'KURE BEACH',
    'LA GRANGE',
    'LAKE JUNALUSKA',
    'LAKE LURE',
    'LAKE NORMAN OF CATAWBA',
    'LAKE PARK',
    'LAKE SANTEETLAH',
    'LAKE WACCAMAW',
    'LANDIS',
    'LANSING',
    'LASKER',
    'LATTIMORE',
    'LAUREL PARK',
    'LAURINBURG',
    'LAWNDALE',
    'LEGGETT',
    'LELAND',
    'LENOIR',
    'LEWISTON WOODVILLE',
    'LEWISVILLE',
    'LEXINGTON',
    'LIBERTY',
    'LIGHT OAK',
    'LILESVILLE',
    'LILLINGTON',
    'LINCOLNTON',
    'LINDEN',
    'LITTLETON',
    'LOCUST',
    'LONG VIEW',
    'LOUISBURG',
    'LOVE VALLEY',
    'LOWELL',
    'LOWESVILLE',
    'LUCAMA',
    'LUMBER BRIDGE',
    'LUMBERTON',
    'MCADENVILLE',
    'MACCLESFIELD',
    'MCDONALD',
    'MCFARLAN',
    'MCLEANSVILLE',
    'MACON',
    'MADISON',
    'MAGGIE VALLEY',
    'MAGNOLIA',
    'MAIDEN',
    'MANTEO',
    'MARIETTA',
    'MARION',
    'MAR-MAC',
    'MARSHALL',
    'MARS HILL',
    'MARSHVILLE',
    'MARVIN',
    'MASONBORO',
    'MATTHEWS',
    'MAXTON',
    'MAYODAN',
    'MAYSVILLE',
    'MEBANE',
    'MESIC',
    'MICRO',
    'MIDDLEBURG',
    'MIDDLESEX',
    'MILLERS CREEK',
    'MILTON',
    'MINERAL SPRINGS',
    'MINNESOTT BEACH',
    'MINT HILL',
    'MOCKSVILLE',
    'MOMEYER',
    'MONROE',
    'MONTREAT',
    'MOORESBORO',
    'MOORESVILLE',
    'MORAVIAN FALLS',
    'MOREHEAD CITY',
    'MORGANTON',
    'MORRISVILLE',
    'MORVEN',
    'MOUNTAIN HOME',
    'MOUNTAIN VIEW',
    'MOUNT AIRY',
    'MOUNT GILEAD',
    'MOUNT HOLLY',
    'MOUNT OLIVE',
    'MOUNT PLEASANT',
    'MULBERRY',
    'MURFREESBORO',
    'MURPHY',
    'MURRAYSVILLE',
    'MYRTLE GROVE',
    'NAGS HEAD',
    'NASHVILLE',
    'NAVASSA',
    'NEUSE FOREST',
    'NEW BERN',
    'NEWLAND',
    'NEW LONDON',
    'NEWPORT',
    'NEWTON',
    'NEWTON GROVE',
    'NORLINA',
    'NORMAN',
    'NORTHLAKES',
    'NORTH TOPSAIL BEACH',
    'NORTHWEST',
    'NORTH WILKESBORO',
    'NORWOOD',
    'OAKBORO',
    'OAK CITY',
    'OAK ISLAND',
    'OAK RIDGE',
    'OCEAN ISLE BEACH',
    'OCRACOKE',
    'OGDEN',
    'OLD FORT',
    'ORIENTAL',
    'ORRUM',
    'OXFORD',
    'PANTEGO',
    'PARKTON',
    'PARMELE',
    'PATTERSON SPRINGS',
    'PEACHLAND',
    'PELETIER',
    'PEMBROKE',
    'PIKEVILLE',
    'PILOT MOUNTAIN',
    'PINEBLUFF',
    'PINEHURST',
    'PINE KNOLL SHORES',
    'PINE LEVEL',
    'PINETOPS',
    'PINEVILLE',
    'PINEY GREEN',
    'PINK HILL',
    'PITTSBORO',
    'PLAIN VIEW',
    'PLEASANT GARDEN',
    'PLEASANT HILL',
    'PLYMOUTH',
    'POLKTON',
    'POLKVILLE',
    'POLLOCKSVILLE',
    'POPE AFB',
    'POWELLSVILLE',
    'PRINCETON',
    'PRINCEVILLE',
    'PROCTORVILLE',
    'PROSPECT',
    'PUMPKIN CENTER',
    'RAEFORD',
    'RAEMON',
    'RALEIGH',
    'RAMSEUR',
    'RANDLEMAN',
    'RANLO',
    'RAYNHAM',
    'RED OAK',
    'RED SPRINGS',
    'REIDSVILLE',
    'RENNERT',
    'REX',
    'RHODHISS',
    'RICHFIELD',
    'RICHLANDS',
    'RICH SQUARE',
    'RIVER BEND',
    'RIVER ROAD',
    'ROANOKE RAPIDS',
    'ROBBINS',
    'ROBBINSVILLE',
    'ROBERSONVILLE',
    'ROCKFISH',
    'ROCKINGHAM',
    'ROCKWELL',
    'ROCKY MOUNT',
    'ROLESVILLE',
    'RONDA',
    'ROPER',
    'ROSEBORO',
    'ROSE HILL',
    'ROSMAN',
    'ROWLAND',
    'ROXBORO',
    'ROXOBEL',
    'ROYAL PINES',
    'RURAL HALL',
    'RUTH',
    'RUTHERFORD COLLEGE',
    'RUTHERFORDTON',
    'ST. HELENA',
    'ST. JAMES',
    'ST. PAULS',
    'ST. STEPHENS',
    'SALEM',
    'SALEMBURG',
    'SALISBURY',
    'SALUDA',
    'SANDY CREEK',
    'SANDYFIELD',
    'SANFORD',
    'SARATOGA',
    'SAWMILLS',
    'SAXAPAHAW',
    'SCOTLAND NECK',
    'SEABOARD',
    'SEA BREEZE',
    'SEAGATE',
    'SEAGROVE',
    'SEDALIA',
    'SELMA',
    'SEVEN DEVILS',
    'SEVEN LAKES',
    'SEVEN SPRINGS',
    'SEVERN',
    'SHALLOTTE',
    'SHANNON',
    'SHARPSBURG',
    'SHELBY',
    'SHERRILLS FORD',
    'SILER CITY',
    'SILVER CITY',
    'SILVER LAKE',
    'SIMPSON',
    'SIMS',
    'SKIPPERS CORNER',
    'SMITHFIELD',
    'SNEADS FERRY',
    'SNOW HILL',
    'SOUTHERN PINES',
    'SOUTHERN SHORES',
    'SOUTH GASTONIA',
    'SOUTH HENDERSON',
    'SOUTHPORT',
    'SOUTH ROSEMARY',
    'SOUTH WELDON',
    'SPARTA',
    'SPEED',
    'SPENCER',
    'SPENCER MOUNTAIN',
    'SPINDALE',
    'SPIVEYS CORNER',
    'SPRING HOPE',
    'SPRING LAKE',
    'SPRUCE PINE',
    'STALEY',
    'STALLINGS',
    'STANFIELD',
    'STANLEY',
    'STANTONSBURG',
    'STAR',
    'STATESVILLE',
    'STEDMAN',
    'STEM',
    'STOKESDALE',
    'STONEVILLE',
    'STONEWALL',
    'STONY POINT',
    'STOVALL',
    'SUGAR MOUNTAIN',
    'SUMMERFIELD',
    'SUNSET BEACH',
    'SURF CITY',
    'SWANNANOA',
    'SWANSBORO',
    'SWEPSONVILLE',
    'SYLVA',
    'TABOR CITY',
    'TARBORO',
    'TAR HEEL',
    'TAYLORSVILLE',
    'TAYLORTOWN',
    'TEACHEY',
    'THOMASVILLE',
    'TOAST',
    'TOBACCOVILLE',
    'TOPSAIL BEACH',
    'TRENTON',
    'TRENT WOODS',
    'TRINITY',
    'TROUTMAN',
    'TROY',
    'TRYON',
    'TURKEY',
    'UNIONVILLE',
    'VALDESE',
    'VALLEY HILL',
    'VANCEBORO',
    'VANDEMERE',
    'VANDER',
    'VANN CROSSROADS',
    'VARNAMTOWN',
    'VASS',
    'WACO',
    'WADE',
    'WADESBORO',
    'WAGRAM',
    'WAKE FOREST',
    'WALKERTOWN',
    'WALLACE',
    'WALNUT COVE',
    'WALNUT CREEK',
    'WALSTONBURG',
    'WANCHESE',
    'WARRENTON',
    'WARSAW',
    'WASHINGTON',
    'WASHINGTON PARK',
    'WATHA',
    'WAXHAW',
    'WAYNESVILLE',
    'WEAVERVILLE',
    'WEBSTER',
    'WEDDINGTON',
    'WELCOME',
    'WELDON',
    'WENDELL',
    'WENTWORTH',
    'WESLEY CHAPEL',
    'WEST CANTON',
    'WEST JEFFERSON',
    'WEST MARION',
    'WESTPORT',
    'WEST SMITHFIELD',
    'WHISPERING PINES',
    'WHITAKERS',
    'WHITE LAKE',
    'WHITE OAK',
    'WHITE PLAINS',
    'WHITEVILLE',
    'WHITSETT',
    'WILKESBORO',
    'WILLIAMSTON',
    'WILMINGTON',
    'WILSON',
    'WILSON S MILLS',
    'WINDSOR',
    'WINFALL',
    'WINGATE',
    'WINSTON-SALEM',
    'WINTERVILLE',
    'WINTON',
    'WOODFIN',
    'WOODLAND',
    'WOODLAWN',
    'WRIGHTSBORO',
    'WRIGHTSVILLE BEACH',
    'YADKINVILLE',
    'YANCEYVILLE',
    'YOUNGSVILLE',
    'ZEBULON',
  ],
  'North Dakota': [
    'ABERCROMBIE',
    'ADAMS',
    'ALAMO',
    'ALEXANDER',
    'ALICE',
    'ALMONT',
    'ALSEN',
    'AMBROSE',
    'AMENIA',
    'AMIDON',
    'ANAMOOSE',
    'ANETA',
    'ANTLER',
    'ARDOCH',
    'ARGUSVILLE',
    'ARNEGARD',
    'ARTHUR',
    'ASHLEY',
    'AYR',
    'BALFOUR',
    'BALTA',
    'BANTRY',
    'BARNEY',
    'BATHGATE',
    'BEACH',
    'BELCOURT',
    'BELFIELD',
    'BENEDICT',
    'BERGEN',
    'BERLIN',
    'BERTHOLD',
    'BEULAH',
    'BINFORD',
    'BISBEE',
    'BISMARCK',
    'BOTTINEAU',
    'BOWBELLS',
    'BOWDON',
    'BOWMAN',
    'BRADDOCK',
    'BRIARWOOD',
    'BRINSMADE',
    'BROCKET',
    'BUCHANAN',
    'BUCYRUS',
    'BUFFALO',
    'BURLINGTON',
    'BUTTE',
    'BUXTON',
    'CALIO',
    'CALVIN',
    'CANDO',
    'CANNON BALL',
    'CANTON CITY',
    'CARPIO',
    'CARRINGTON',
    'CARSON',
    'CASSELTON',
    'CATHAY',
    'CAVALIER',
    'CAYUGA',
    'CENTER',
    'CHRISTINE',
    'CHURCHS FERRY',
    'CLEVELAND',
    'CLIFFORD',
    'COGSWELL',
    'COLEHARBOR',
    'COLFAX',
    'COLUMBUS',
    'CONWAY',
    'COOPERSTOWN',
    'COURTENAY',
    'CRARY',
    'CROSBY',
    'CRYSTAL',
    'DAVENPORT',
    'DAWSON',
    'DAZEY',
    'DEERING',
    'DES LACS',
    'DEVILS LAKE',
    'DICKEY',
    'DICKINSON',
    'DODGE',
    'DONNYBROOK',
    'DOUGLAS',
    'DRAKE',
    'DRAYTON',
    'DUNN CENTER',
    'DUNSEITH',
    'DWIGHT',
    'EAST DUNSEITH',
    'EDGELEY',
    'EDINBURG',
    'EDMORE',
    'EGELAND',
    'ELGIN',
    'ELLENDALE',
    'ELLIOTT',
    'EMERADO',
    'ENDERLIN',
    'EPPING',
    'ERIE',
    'ESMOND',
    'FAIRDALE',
    'FAIRMOUNT',
    'FARGO',
    'FESSENDEN',
    'FINGAL',
    'FINLEY',
    'FLASHER',
    'FLAXTON',
    'FORBES',
    'FORDVILLE',
    'FOREST RIVER',
    'FORMAN',
    'FORT RANSOM',
    'FORT TOTTEN',
    'FORTUNA',
    'FORT YATES',
    'FOUR BEARS VILLAGE',
    'FREDONIA',
    'FRONTIER',
    'FULLERTON',
    'GACKLE',
    'GALESBURG',
    'GARDENA',
    'GARDNER',
    'GARRISON',
    'GASCOYNE',
    'GILBY',
    'GLADSTONE',
    'GLENBURN',
    'GLENFIELD',
    'GLEN ULLIN',
    'GOLDEN VALLEY',
    'GOLVA',
    'GOODRICH',
    'GRACE CITY',
    'GRAFTON',
    'GRAND FORKS',
    'GRAND FORKS AFB',
    'GRANDIN',
    'GRANO',
    'GRANVILLE',
    'GREAT BEND',
    'GRENORA',
    'GWINNER',
    'HAGUE',
    'HALLIDAY',
    'HAMBERG',
    'HAMILTON',
    'HAMPDEN',
    'HANKINSON',
    'HANNAFORD',
    'HANNAH',
    'HANSBORO',
    'HARVEY',
    'HARWOOD',
    'HATTON',
    'HAVANA',
    'HAYNES',
    'HAZELTON',
    'HAZEN',
    'HEBRON',
    'HETTINGER',
    'HILLSBORO',
    'HOOPLE',
    'HOPE',
    'HORACE',
    'HOVE MOBILE PARK',
    'HUNTER',
    'HURDSFIELD',
    'INKSTER',
    'JAMESTOWN',
    'JUD',
    'KARLSRUHE',
    'KATHRYN',
    'KENMARE',
    'KENSAL',
    'KIEF',
    'KILLDEER',
    'KINDRED',
    'KNOX',
    'KRAMER',
    'KULM',
    'LAKOTA',
    'LAMOURE',
    'LANDA',
    'LANGDON',
    'LANKIN',
    'LANSFORD',
    'LARIMORE',
    'LARSON',
    'LAWTON',
    'LEAL',
    'LEEDS',
    'LEHR',
    'LEITH',
    'LEONARD',
    'LIDGERWOOD',
    'LIGNITE',
    'LINCOLN',
    'LINTON',
    'LISBON',
    'LITCHVILLE',
    'LOMA',
    'LORAINE',
    'LUDDEN',
    'LUVERNE',
    'MCCLUSKY',
    'MCHENRY',
    'MCVILLE',
    'MADDOCK',
    'MAKOTI',
    'MANDAN',
    'MANDAREE',
    'MANTADOR',
    'MANVEL',
    'MAPLETON',
    'MARION',
    'MARMARTH',
    'MARTIN',
    'MAX',
    'MAXBASS',
    'MAYVILLE',
    'MAZA',
    'MEDINA',
    'MEDORA',
    'MERCER',
    'MICHIGAN CITY',
    'MILNOR',
    'MILTON',
    'MINNEWAUKAN',
    'MINOT',
    'MINOT AFB',
    'MINTO',
    'MOHALL',
    'MONANGO',
    'MONTPELIER',
    'MOORETON',
    'MOTT',
    'MOUNTAIN',
    'MUNICH',
    'MYLO',
    'NAPOLEON',
    'NECHE',
    'NEKOMA',
    'NEWBURG',
    'NEW ENGLAND',
    'NEW LEIPZIG',
    'NEW ROCKFORD',
    'NEW SALEM',
    'NEW TOWN',
    'NIAGARA',
    'NOME',
    'NOONAN',
    'NORTH RIVER',
    'NORTHWOOD',
    'OAKES',
    'OBERON',
    'ORISKA',
    'OSNABROCK',
    'OVERLY',
    'OXBOW',
    'PAGE',
    'PALERMO',
    'PARK RIVER',
    'PARSHALL',
    'PEKIN',
    'PEMBINA',
    'PERTH',
    'PETERSBURG',
    'PETTIBONE',
    'PICK CITY',
    'PILLSBURY',
    'PINGREE',
    'PISEK',
    'PLAZA',
    'PORTAL',
    'PORTLAND',
    'POWERS LAKE',
    'PRAIRIE ROSE',
    'RAWSON',
    'RAY',
    'REEDER',
    'REGAN',
    'REGENT',
    'REILE S ACRES',
    'REYNOLDS',
    'RHAME',
    'RICHARDTON',
    'RIVERDALE',
    'ROBINSON',
    'ROCKLAKE',
    'ROGERS',
    'ROLETTE',
    'ROLLA',
    'ROSS',
    'RUGBY',
    'RUSO',
    'RUTLAND',
    'RYDER',
    'ST. JOHN',
    'ST. THOMAS',
    'SANBORN',
    'SARLES',
    'SAWYER',
    'SCRANTON',
    'SELFRIDGE',
    'SENTINEL BUTTE',
    'SHARON',
    'SHELDON',
    'SHELL VALLEY',
    'SHERWOOD',
    'SHEYENNE',
    'SIBLEY',
    'SOLEN',
    'SOURIS',
    'SOUTH HEART',
    'SPIRITWOOD LAKE',
    'SPRINGBROOK',
    'STANLEY',
    'STANTON',
    'STARKWEATHER',
    'STEELE',
    'STRASBURG',
    'STREETER',
    'SURREY',
    'SYKESTON',
    'TAPPEN',
    'TAYLOR',
    'THOMPSON',
    'TIOGA',
    'TOLLEY',
    'TOLNA',
    'TOWER CITY',
    'TOWNER',
    'TURTLE LAKE',
    'TUTTLE',
    'UNDERWOOD',
    'UPHAM',
    'VALLEY CITY',
    'VELVA',
    'VENTURIA',
    'VERONA',
    'VOLTAIRE',
    'WAHPETON',
    'WALCOTT',
    'WALES',
    'WALHALLA',
    'WARWICK',
    'WASHBURN',
    'WATFORD CITY',
    'WEST FARGO',
    'WESTHOPE',
    'WHEATLAND',
    'WHITE EARTH',
    'WHITE SHIELD',
    'WILDROSE',
    'WILLISTON',
    'WILLOW CITY',
    'WILTON',
    'WIMBLEDON',
    'WING',
    'WISHEK',
    'WOLFORD',
    'WOODWORTH',
    'WYNDMERE',
    'YORK',
    'ZAP',
    'ZEELAND',
  ],
  'Northern Mariana Islands': ['NORTHERN MARIANA ISLANDS'],
  Ohio: [
    'ABERDEEN',
    'ADA',
    'ADAMSVILLE',
    'ADDYSTON',
    'ADELPHI',
    'ADENA',
    'AKRON',
    'ALBANY',
    'ALEXANDRIA',
    'ALGER',
    'ALLIANCE',
    'ALVORDTON',
    'AMANDA',
    'AMBERLEY',
    'AMELIA',
    'AMESVILLE',
    'AMHERST',
    'AMSTERDAM',
    'ANDOVER',
    'ANNA',
    'ANSONIA',
    'ANTIOCH',
    'ANTWERP',
    'APPLE CREEK',
    'AQUILLA',
    'ARCADIA',
    'ARCANUM',
    'ARCHBOLD',
    'ARLINGTON',
    'ARLINGTON HEIGHTS',
    'ASHLAND',
    'ASHLEY',
    'ASHTABULA',
    'ASHVILLE',
    'ATHALIA',
    'ATHENS',
    'ATTICA',
    'AURORA',
    'AUSTINTOWN',
    'AVON',
    'AVON LAKE',
    'BAILEY LAKES',
    'BAINBRIDGE',
    'BAINBRIDGE',
    'BAIRDSTOWN',
    'BALLVILLE',
    'BALTIC',
    'BALTIMORE',
    'BARBERTON',
    'BARNESVILLE',
    'BARNHILL',
    'BATAVIA',
    'BATESVILLE',
    'BAY VIEW',
    'BAY VILLAGE',
    'BEACH CITY',
    'BEACHWOOD',
    'BEALLSVILLE',
    'BEAVER',
    'BEAVERCREEK',
    'BEAVERDAM',
    'BECKETT RIDGE',
    'BEDFORD',
    'BEDFORD HEIGHTS',
    'BEECHWOOD TRAILS',
    'BELLAIRE',
    'BELLBROOK',
    'BELLE CENTER',
    'BELLEFONTAINE',
    'BELLE VALLEY',
    'BELLEVUE',
    'BELLVILLE',
    'BELMONT',
    'BELMORE',
    'BELOIT',
    'BELPRE',
    'BENTLEYVILLE',
    'BENTON RIDGE',
    'BEREA',
    'BERGHOLZ',
    'BERKEY',
    'BERLIN HEIGHTS',
    'BETHEL',
    'BETHESDA',
    'BETTSVILLE',
    'BEVERLY',
    'BEXLEY',
    'BLACKLICK ESTATES',
    'BLAKESLEE',
    'BLANCHESTER',
    'BLOOMDALE',
    'BLOOMINGBURG',
    'BLOOMINGDALE',
    'BLOOMVILLE',
    'BLUE ASH',
    'BLUFFTON',
    'BOARDMAN',
    'BOLINDALE',
    'BOLIVAR',
    'BOSTON HEIGHTS',
    'BOTKINS',
    'BOWERSTON',
    'BOWERSVILLE',
    'BOWLING GREEN',
    'BRADFORD',
    'BRADNER',
    'BRADY LAKE',
    'BRATENAHL',
    'BRECKSVILLE',
    'BREMEN',
    'BREWSTER',
    'BRICE',
    'BRIDGEPORT',
    'BRIDGETOWN NORTH',
    'BRIMFIELD',
    'BROADVIEW HEIGHTS',
    'BROOKFIELD CENTER',
    'BROOKLYN',
    'BROOKLYN HEIGHTS',
    'BROOK PARK',
    'BROOKSIDE',
    'BROOKVILLE',
    'BROUGHTON',
    'BRUNSWICK',
    'BRYAN',
    'BUCHTEL',
    'BUCKEYE LAKE',
    'BUCKLAND',
    'BUCYRUS',
    'BURBANK',
    'BURGOON',
    'BURKETTSVILLE',
    'BURLINGTON',
    'BURTON',
    'BUTLER',
    'BUTLERVILLE',
    'BYESVILLE',
    'CADIZ',
    'CAIRO',
    'CALCUTTA',
    'CALDWELL',
    'CALEDONIA',
    'CAMBRIDGE',
    'CAMDEN',
    'CAMPBELL',
    'CANAL FULTON',
    'CANAL WINCHESTER',
    'CANFIELD',
    'CANTON',
    'CARDINGTON',
    'CAREY',
    'CARLISLE',
    'CARROLL',
    'CARROLLTON',
    'CASSTOWN',
    'CASTALIA',
    'CASTINE',
    'CATAWBA',
    'CECIL',
    'CEDARVILLE',
    'CELINA',
    'CENTERBURG',
    'CENTERVILLE',
    'CENTERVILLE',
    'CHAGRIN FALLS',
    'CHAMPION HEIGHTS',
    'CHARDON',
    'CHATFIELD',
    'CHAUNCEY',
    'CHERRY FORK',
    'CHERRY GROVE',
    'CHESAPEAKE',
    'CHESHIRE',
    'CHESTERHILL',
    'CHESTERLAND',
    'CHESTERVILLE',
    'CHEVIOT',
    'CHICKASAW',
    'CHILLICOTHE',
    'CHILO',
    'CHIPPEWA LAKE',
    'CHOCTAW LAKE',
    'CHRISTIANSBURG',
    'CHURCHILL',
    'CINCINNATI',
    'CIRCLEVILLE',
    'CLARINGTON',
    'CLARKSBURG',
    'CLARKSVILLE',
    'CLAY CENTER',
    'CLAYTON',
    'CLEVELAND',
    'CLEVELAND HEIGHTS',
    'CLEVES',
    'CLIFTON',
    'CLINTON',
    'CLOVERDALE',
    'CLYDE',
    'COAL GROVE',
    'COALTON',
    'COLDWATER',
    'COLLEGE CORNER',
    'COLUMBIANA',
    'COLUMBUS',
    'COLUMBUS GROVE',
    'COMMERCIAL POINT',
    'CONESVILLE',
    'CONGRESS',
    'CONNEAUT',
    'CONTINENTAL',
    'CONVOY',
    'COOLVILLE',
    'CORNING',
    'CORTLAND',
    'CORWIN',
    'COSHOCTON',
    'COVEDALE',
    'COVINGTON',
    'CRAIG BEACH',
    'CRESTLINE',
    'CRESTON',
    'CRIDERSVILLE',
    'CROOKSVILLE',
    'CROWN CITY',
    'CRYSTAL LAKES',
    'CUMBERLAND',
    'CUSTAR',
    'CUYAHOGA FALLS',
    'CUYAHOGA HEIGHTS',
    'CYGNET',
    'DALTON',
    'DANVILLE',
    'DARBYVILLE',
    'DAY HEIGHTS',
    'DAYTON',
    'DEER PARK',
    'DEERSVILLE',
    'DEFIANCE',
    'DE GRAFF',
    'DELAWARE',
    'DELLROY',
    'DELPHOS',
    'DELTA',
    'DENNISON',
    'DENT',
    'DESHLER',
    'DEVOLA',
    'DEXTER CITY',
    'DILLONVALE',
    'DILLONVALE',
    'DONNELSVILLE',
    'DOVER',
    'DOYLESTOWN',
    'DRESDEN',
    'DREXEL',
    'DRY RUN',
    'DUBLIN',
    'DUNKIRK',
    'DUPONT',
    'EAST CANTON',
    'EAST CLEVELAND',
    'EASTLAKE',
    'EAST LIVERPOOL',
    'EAST PALESTINE',
    'EAST SPARTA',
    'EATON',
    'EATON ESTATES',
    'EDGERTON',
    'EDGEWOOD',
    'EDISON',
    'EDON',
    'ELDORADO',
    'ELGIN',
    'ELIDA',
    'ELMORE',
    'ELMWOOD PLACE',
    'ELYRIA',
    'EMPIRE',
    'ENGLEWOOD',
    'ENON',
    'EUCLID',
    'EVENDALE',
    'FAIRBORN',
    'FAIRFAX',
    'FAIRFIELD',
    'FAIRFIELD BEACH',
    'FAIRLAWN',
    'FAIRPORT HARBOR',
    'FAIRVIEW',
    'FAIRVIEW LANES',
    'FAIRVIEW PARK',
    'FARMERSVILLE',
    'FAYETTE',
    'FAYETTEVILLE',
    'FELICITY',
    'FINDLAY',
    'FINNEYTOWN',
    'FIVE POINTS',
    'FLETCHER',
    'FLORIDA',
    'FLUSHING',
    'FOREST',
    'FOREST PARK',
    'FORESTVILLE',
    'FORT JENNINGS',
    'FORT LORAMIE',
    'FORT MCKINLEY',
    'FORT RECOVERY',
    'FORT SHAWNEE',
    'FOSTORIA',
    'FRANKFORT',
    'FRANKLIN',
    'FRANKLIN FURNACE',
    'FRAZEYSBURG',
    'FREDERICKSBURG',
    'FREDERICKTOWN',
    'FREEPORT',
    'FREMONT',
    'FRUIT HILL',
    'FULTON',
    'FULTONHAM',
    'GAHANNA',
    'GALENA',
    'GALION',
    'GALLIPOLIS',
    'GAMBIER',
    'GANN',
    'GARFIELD HEIGHTS',
    'GARRETTSVILLE',
    'GATES MILLS',
    'GENEVA',
    'GENEVA-ON-THE-LAKE',
    'GENOA',
    'GEORGETOWN',
    'GERMANTOWN',
    'GETTYSBURG',
    'GIBSONBURG',
    'GILBOA',
    'GIRARD',
    'GLANDORF',
    'GLENDALE',
    'GLENFORD',
    'GLENMONT',
    'GLENMOOR',
    'GLENWILLOW',
    'GLORIA GLENS PARK',
    'GLOUSTER',
    'GNADENHUTTEN',
    'GOLF MANOR',
    'GORDON',
    'GRAFTON',
    'GRAND RAPIDS',
    'GRAND RIVER',
    'GRANDVIEW',
    'GRANDVIEW HEIGHTS',
    'GRANVILLE',
    'GRANVILLE SOUTH',
    'GRATIOT',
    'GRATIS',
    'GRAYSVILLE',
    'GREEN',
    'GREEN CAMP',
    'GREENFIELD',
    'GREENHILLS',
    'GREEN MEADOWS',
    'GREEN SPRINGS',
    'GREENTOWN',
    'GREENVILLE',
    'GREENWICH',
    'GROESBECK',
    'GROVE CITY',
    'GROVEPORT',
    'GROVER HILL',
    'HAMDEN',
    'HAMERSVILLE',
    'HAMILTON',
    'HAMLER',
    'HANGING ROCK',
    'HANOVER',
    'HANOVERTON',
    'HARBOR HILLS',
    'HARBOR VIEW',
    'HARPSTER',
    'HARRISBURG',
    'HARRISON',
    'HARRISVILLE',
    'HARROD',
    'HARTFORD',
    'HARTVILLE',
    'HARVEYSBURG',
    'HASKINS',
    'HAVILAND',
    'HAYESVILLE',
    'HEATH',
    'HEBRON',
    'HELENA',
    'HEMLOCK',
    'HICKSVILLE',
    'HIGGINSPORT',
    'HIGHLAND',
    'HIGHLAND HEIGHTS',
    'HIGHLAND HILLS',
    'HILLIARD',
    'HILLS AND DALES',
    'HILLSBORO',
    'HILLTOP',
    'HIRAM',
    'HOLGATE',
    'HOLIDAY CITY',
    'HOLIDAY VALLEY',
    'HOLLAND',
    'HOLLANSBURG',
    'HOLLOWAY',
    'HOLMESVILLE',
    'HOPEDALE',
    'HOWLAND CENTER',
    'HOYTVILLE',
    'HUBBARD',
    'HUBER HEIGHTS',
    'HUBER RIDGE',
    'HUDSON',
    'HUNTER',
    'HUNTING VALLEY',
    'HUNTSVILLE',
    'HURON',
    'INDEPENDENCE',
    'IRONDALE',
    'IRONTON',
    'ITHACA',
    'JACKSON',
    'JACKSONBURG',
    'JACKSON CENTER',
    'JACKSONVILLE',
    'JAMESTOWN',
    'JEFFERSON',
    'JEFFERSONVILLE',
    'JENERA',
    'JEROMESVILLE',
    'JERRY CITY',
    'JERUSALEM',
    'JEWETT',
    'JOHNSTOWN',
    'JUNCTION CITY',
    'KALIDA',
    'KELLEYS ISLAND',
    'KENT',
    'KENTON',
    'KENWOOD',
    'KETTERING',
    'KETTLERSVILLE',
    'KILLBUCK',
    'KIMBOLTON',
    'KINGSTON',
    'KIPTON',
    'KIRBY',
    'KIRKERSVILLE',
    'KIRTLAND',
    'KIRTLAND HILLS',
    'LA CROFT',
    'LAFAYETTE',
    'LAGRANGE',
    'LAKE DARBY',
    'LAKELINE',
    'LAKEMORE',
    'LAKEVIEW',
    'LAKEWOOD',
    'LANCASTER',
    'LANDEN',
    'LA RUE',
    'LATTY',
    'LAURA',
    'LAURELVILLE',
    'LAWRENCEVILLE',
    'LEAVITTSBURG',
    'LEBANON',
    'LEESBURG',
    'LEESVILLE',
    'LEETONIA',
    'LEIPSIC',
    'LEWISBURG',
    'LEWISVILLE',
    'LEXINGTON',
    'LIBERTY CENTER',
    'LIMA',
    'LIMAVILLE',
    'LINCOLN HEIGHTS',
    'LINCOLN VILLAGE',
    'LINDSEY',
    'LINNDALE',
    'LISBON',
    'LITHOPOLIS',
    'LOCKBOURNE',
    'LOCKINGTON',
    'LOCKLAND',
    'LODI',
    'LOGAN',
    'LOGAN ELM VILLAGE',
    'LONDON',
    'LORAIN',
    'LORDSTOWN',
    'LORE CITY',
    'LOUDONVILLE',
    'LOUISVILLE',
    'LOVELAND',
    'LOVELAND PARK',
    'LOWELL',
    'LOWELLVILLE',
    'LOWER SALEM',
    'LUCAS',
    'LUCASVILLE',
    'LUCKEY',
    'LUDLOW FALLS',
    'LYNCHBURG',
    'LYNDHURST',
    'LYONS',
    'MCARTHUR',
    'MCCLURE',
    'MCCOMB',
    'MCCONNELSVILLE',
    'MCDONALD',
    'MACEDONIA',
    'MCGUFFEY',
    'MACK NORTH',
    'MACKSBURG',
    'MACK SOUTH',
    'MADEIRA',
    'MADISON',
    'MAGNETIC SPRINGS',
    'MAGNOLIA',
    'MAINEVILLE',
    'MALINTA',
    'MALTA',
    'MALVERN',
    'MANCHESTER',
    'MANSFIELD',
    'MANTUA',
    'MAPLE HEIGHTS',
    'MAPLE RIDGE',
    'MAPLEWOOD PARK',
    'MARBLE CLIFF',
    'MARBLEHEAD',
    'MARENGO',
    'MARIEMONT',
    'MARIETTA',
    'MARION',
    'MARSEILLES',
    'MARSHALLVILLE',
    'MARTINSBURG',
    'MARTINS FERRY',
    'MARTINSVILLE',
    'MARYSVILLE',
    'MASON',
    'MASSILLON',
    'MASURY',
    'MATAMORAS',
    'MAUMEE',
    'MAYFIELD',
    'MAYFIELD HEIGHTS',
    'MECHANICSBURG',
    'MEDINA',
    'MELROSE',
    'MENDON',
    'MENTOR',
    'MENTOR-ON-THE-LAKE',
    'METAMORA',
    'MEYERS LAKE',
    'MIAMISBURG',
    'MIDDLEBURG HEIGHTS',
    'MIDDLEFIELD',
    'MIDDLE POINT',
    'MIDDLEPORT',
    'MIDDLETOWN',
    'MIDLAND',
    'MIDVALE',
    'MIDWAY',
    'MIFFLIN',
    'MILAN',
    'MILFORD',
    'MILFORD CENTER',
    'MILLBURY',
    'MILLEDGEVILLE',
    'MILLER CITY',
    'MILLERSBURG',
    'MILLERSPORT',
    'MILLVILLE',
    'MILTON CENTER',
    'MILTONSBURG',
    'MINERAL CITY',
    'MINERAL RIDGE',
    'MINERVA',
    'MINERVA PARK',
    'MINGO JUNCTION',
    'MINSTER',
    'MOGADORE',
    'MONFORT HEIGHTS EAST',
    'MONFORT HEIGHTS SOUTH',
    'MONROE',
    'MONROEVILLE',
    'MONTEZUMA',
    'MONTGOMERY',
    'MONTPELIER',
    'MONTROSE-GHENT',
    'MORAINE',
    'MORELAND HILLS',
    'MORRAL',
    'MORRISTOWN',
    'MORROW',
    'MOSCOW',
    'MOUNT BLANCHARD',
    'MOUNT CARMEL',
    'MOUNT CORY',
    'MOUNT EATON',
    'MOUNT GILEAD',
    'MOUNT HEALTHY',
    'MOUNT HEALTHY HEIGHTS',
    'MOUNT ORAB',
    'MOUNT PLEASANT',
    'MOUNT REPOSE',
    'MOUNT STERLING',
    'MOUNT VERNON',
    'MOUNT VICTORY',
    'MOWRYSTOWN',
    'MULBERRY',
    'MUNROE FALLS',
    'MURRAY CITY',
    'MUTUAL',
    'NAPOLEON',
    'NASHVILLE',
    'NAVARRE',
    'NEFFS',
    'NELLIE',
    'NELSONVILLE',
    'NEVADA',
    'NEVILLE',
    'NEW ALBANY',
    'NEW ALEXANDRIA',
    'NEWARK',
    'NEW ATHENS',
    'NEW BAVARIA',
    'NEW BLOOMINGTON',
    'NEW BOSTON',
    'NEW BREMEN',
    'NEWBURGH HEIGHTS',
    'NEW CARLISLE',
    'NEWCOMERSTOWN',
    'NEW CONCORD',
    'NEW FRANKLIN',
    'NEW HOLLAND',
    'NEW KNOXVILLE',
    'NEW LEBANON',
    'NEW LEXINGTON',
    'NEW LONDON',
    'NEW MADISON',
    'NEW MIAMI',
    'NEW MIDDLETOWN',
    'NEW PARIS',
    'NEW PHILADELPHIA',
    'NEW RICHMOND',
    'NEW RIEGEL',
    'NEW ROME',
    'NEW STRAITSVILLE',
    'NEWTON FALLS',
    'NEWTONSVILLE',
    'NEWTOWN',
    'NEW VIENNA',
    'NEW WASHINGTON',
    'NEW WATERFORD',
    'NEW WESTON',
    'NEY',
    'NILES',
    'NORTH BALTIMORE',
    'NORTH BEND',
    'NORTHBROOK',
    'NORTH CANTON',
    'NORTH COLLEGE HILL',
    'NORTH FAIRFIELD',
    'NORTHFIELD',
    'NORTH FORK VILLAGE',
    'NORTHGATE',
    'NORTH HAMPTON',
    'NORTH KINGSVILLE',
    'NORTH LEWISBURG',
    'NORTH MADISON',
    'NORTH OLMSTED',
    'NORTH PERRY',
    'NORTH RANDALL',
    'NORTHRIDGE',
    'NORTHRIDGE',
    'NORTH RIDGEVILLE',
    'NORTH ROBINSON',
    'NORTH ROYALTON',
    'NORTH STAR',
    'NORTHWOOD',
    'NORTH ZANESVILLE',
    'NORTON',
    'NORWALK',
    'NORWICH',
    'NORWOOD',
    'OAK HARBOR',
    'OAK HILL',
    'OAKWOOD',
    'OAKWOOD',
    'OAKWOOD',
    'OBERLIN',
    'OBETZ',
    'OCTA',
    'OHIO CITY',
    'OLDE WEST CHESTER',
    'OLD WASHINGTON',
    'OLMSTED FALLS',
    'ONTARIO',
    'ORANGE',
    'ORANGEVILLE',
    'OREGON',
    'ORIENT',
    'ORRVILLE',
    'ORWELL',
    'OSGOOD',
    'OSTRANDER',
    'OTTAWA',
    'OTTAWA HILLS',
    'OTTOVILLE',
    'OTWAY',
    'OWENSVILLE',
    'OXFORD',
    'PAINESVILLE',
    'PALESTINE',
    'PANDORA',
    'PARK LAYNE',
    'PARMA',
    'PARMA HEIGHTS',
    'PARRAL',
    'PATASKALA',
    'PATTERSON',
    'PAULDING',
    'PAYNE',
    'PEEBLES',
    'PEMBERVILLE',
    'PENINSULA',
    'PEPPER PIKE',
    'PERRY',
    'PERRY HEIGHTS',
    'PERRYSBURG',
    'PERRYSVILLE',
    'PHILLIPSBURG',
    'PHILO',
    'PICKERINGTON',
    'PIGEON CREEK',
    'PIKETON',
    'PIONEER',
    'PIQUA',
    'PITSBURG',
    'PLAIN CITY',
    'PLAINFIELD',
    'PLEASANT CITY',
    'PLEASANT GROVE',
    'PLEASANT HILL',
    'PLEASANT PLAIN',
    'PLEASANT RUN',
    'PLEASANT RUN FARM',
    'PLEASANTVILLE',
    'PLYMOUTH',
    'POLAND',
    'POLK',
    'POMEROY',
    'PORTAGE',
    'PORTAGE LAKES',
    'PORT CLINTON',
    'PORT JEFFERSON',
    'PORTSMOUTH',
    'PORT WASHINGTON',
    'PORT WILLIAM',
    'POTSDAM',
    'POWELL',
    'POWHATAN POINT',
    'PROCTORVILLE',
    'PROSPECT',
    'PUT-IN-BAY',
    'QUAKER CITY',
    'QUINCY',
    'RACINE',
    'RARDEN',
    'RAVENNA',
    'RAWSON',
    'RAYLAND',
    'READING',
    'REMINDERVILLE',
    'RENDVILLE',
    'REPUBLIC',
    'REYNOLDSBURG',
    'RICHFIELD',
    'RICHMOND',
    'RICHMOND HEIGHTS',
    'RICHWOOD',
    'RIDGEWAY',
    'RIO GRANDE',
    'RIPLEY',
    'RISINGSUN',
    'RITTMAN',
    'RIVERLEA',
    'RIVERSIDE',
    'ROAMING SHORES',
    'ROCHESTER',
    'ROCK CREEK',
    'ROCKFORD',
    'ROCKY RIDGE',
    'ROCKY RIVER',
    'ROGERS',
    'ROME',
    'ROSEMOUNT',
    'ROSEVILLE',
    'ROSS',
    'ROSSBURG',
    'ROSSFORD',
    'ROSWELL',
    'RUSHSYLVANIA',
    'RUSHVILLE',
    'RUSSELLS POINT',
    'RUSSELLVILLE',
    'RUSSIA',
    'RUTLAND',
    'SABINA',
    'ST. BERNARD',
    'ST. CLAIRSVILLE',
    'ST. HENRY',
    'ST. LOUISVILLE',
    'ST. MARTIN',
    'ST. MARYS',
    'ST. PARIS',
    'SALEM',
    'SALESVILLE',
    'SALINEVILLE',
    'SANDUSKY',
    'SANDUSKY SOUTH',
    'SARAHSVILLE',
    'SARDINIA',
    'SAVANNAH',
    'SCIO',
    'SCIOTODALE',
    'SCOTT',
    'SEAMAN',
    'SEBRING',
    'SENECAVILLE',
    'SEVEN HILLS',
    'SEVEN MILE',
    'SEVILLE',
    'SHADYSIDE',
    'SHAKER HEIGHTS',
    'SHARONVILLE',
    'SHAWNEE',
    'SHAWNEE HILLS',
    'SHAWNEE HILLS',
    'SHEFFIELD',
    'SHEFFIELD LAKE',
    'SHELBY',
    'SHERRODSVILLE',
    'SHERWOOD',
    'SHERWOOD',
    'SHILOH',
    'SHILOH',
    'SHREVE',
    'SIDNEY',
    'SILVER LAKE',
    'SILVERTON',
    'SINKING SPRING',
    'SMITHFIELD',
    'SMITHVILLE',
    'SOLON',
    'SOMERSET',
    'SOMERVILLE',
    'SOUTH AMHERST',
    'SOUTH BLOOMFIELD',
    'SOUTH CANAL',
    'SOUTH CHARLESTON',
    'SOUTH EUCLID',
    'SOUTH LEBANON',
    'SOUTH MIDDLETOWN',
    'SOUTH POINT',
    'SOUTH RUSSELL',
    'SOUTH SALEM',
    'SOUTH SOLON',
    'SOUTH VIENNA',
    'SOUTH WEBSTER',
    'SOUTH ZANESVILLE',
    'SPARTA',
    'SPENCER',
    'SPENCERVILLE',
    'SPRINGBORO',
    'SPRINGDALE',
    'SPRINGFIELD',
    'SPRING VALLEY',
    'STAFFORD',
    'STEUBENVILLE',
    'STOCKPORT',
    'STONE CREEK',
    'STONY PRAIRIE',
    'STOUTSVILLE',
    'STOW',
    'STRASBURG',
    'STRATTON',
    'STREETSBORO',
    'STRONGSVILLE',
    'STRUTHERS',
    'STRYKER',
    'SUGAR BUSH KNOLLS',
    'SUGARCREEK',
    'SUGAR GROVE',
    'SUMMERFIELD',
    'SUMMERSIDE',
    'SUMMITVILLE',
    'SUNBURY',
    'SWANTON',
    'SYCAMORE',
    'SYLVANIA',
    'SYRACUSE',
    'TALLMADGE',
    'TARLTON',
    'TERRACE PARK',
    'THE PLAINS',
    'THE VILLAGE OF INDIAN HILL',
    'THORNVILLE',
    'THURSTON',
    'TIFFIN',
    'TILTONSVILLE',
    'TIMBERLAKE',
    'TIPP CITY',
    'TIRO',
    'TOLEDO',
    'TONTOGANY',
    'TORONTO',
    'TREMONT CITY',
    'TRENTON',
    'TRIMBLE',
    'TROTWOOD',
    'TROY',
    'TURPIN HILLS',
    'TUSCARAWAS',
    'TWINSBURG',
    'UHRICHSVILLE',
    'UNION',
    'UNION CITY',
    'UNIONTOWN',
    'UNIONVILLE CENTER',
    'UNIOPOLIS',
    'UNIVERSITY HEIGHTS',
    'UPPER ARLINGTON',
    'UPPER SANDUSKY',
    'URBANA',
    'URBANCREST',
    'UTICA',
    'VALLEY HI',
    'VALLEY VIEW',
    'VALLEYVIEW',
    'VAN BUREN',
    'VANDALIA',
    'VANLUE',
    'VAN WERT',
    'VENEDOCIA',
    'VERMILION',
    'VERONA',
    'VERSAILLES',
    'VIENNA CENTER',
    'VINTON',
    'WADSWORTH',
    'WAITE HILL',
    'WAKEMAN',
    'WALBRIDGE',
    'WALDO',
    'WALTON HILLS',
    'WAPAKONETA',
    'WARREN',
    'WARRENSVILLE HEIGHTS',
    'WARSAW',
    'WASHINGTON',
    'WASHINGTONVILLE',
    'WATERVILLE',
    'WAUSEON',
    'WAVERLY CITY',
    'WAYNE',
    'WAYNE LAKES',
    'WAYNESBURG',
    'WAYNESFIELD',
    'WAYNESVILLE',
    'WELLINGTON',
    'WELLSTON',
    'WELLSVILLE',
    'WEST ALEXANDRIA',
    'WEST CARROLLTON CITY',
    'WEST ELKTON',
    'WESTERVILLE',
    'WEST FARMINGTON',
    'WESTFIELD CENTER',
    'WEST HILL',
    'WEST JEFFERSON',
    'WEST LAFAYETTE',
    'WESTLAKE',
    'WEST LEIPSIC',
    'WEST LIBERTY',
    'WEST MANCHESTER',
    'WEST MANSFIELD',
    'WEST MILLGROVE',
    'WEST MILTON',
    'WESTON',
    'WEST PORTSMOUTH',
    'WEST RUSHVILLE',
    'WEST SALEM',
    'WEST UNION',
    'WEST UNITY',
    'WETHERINGTON',
    'WHARTON',
    'WHEELERSBURG',
    'WHITEHALL',
    'WHITEHOUSE',
    'WHITE OAK',
    'WHITE OAK EAST',
    'WHITE OAK WEST',
    'WICKLIFFE',
    'WILBERFORCE',
    'WILKESVILLE',
    'WILLARD',
    'WILLIAMSBURG',
    'WILLIAMSPORT',
    'WILLOUGHBY',
    'WILLOUGHBY HILLS',
    'WILLOWICK',
    'WILLSHIRE',
    'WILMINGTON',
    'WILMOT',
    'WILSON',
    'WINCHESTER',
    'WINDHAM',
    'WINTERSVILLE',
    'WITHAMSVILLE',
    'WOODBOURNE-HYDE PARK',
    'WOODLAWN',
    'WOODMERE',
    'WOODSFIELD',
    'WOODSTOCK',
    'WOODVILLE',
    'WOOSTER',
    'WORTHINGTON',
    'WREN',
    'WRIGHT-PATTERSON AFB',
    'WYOMING',
    'XENIA',
    'YANKEE LAKE',
    'YELLOW SPRINGS',
    'YORKSHIRE',
    'YORKVILLE',
    'YOUNGSTOWN',
    'ZALESKI',
    'ZANESFIELD',
    'ZANESVILLE',
    'ZOAR',
  ],
  Oklahoma: [
    'ACHILLE',
    'ADA',
    'ADAIR',
    'ADDINGTON',
    'AFTON',
    'AGRA',
    'AKINS',
    'ALBION',
    'ALDERSON',
    'ALEX',
    'ALINE',
    'ALLEN',
    'ALTUS',
    'ALVA',
    'AMBER',
    'AMES',
    'AMORITA',
    'ANADARKO',
    'ANTLERS',
    'APACHE',
    'ARAPAHO',
    'ARCADIA',
    'ARDMORE',
    'ARKOMA',
    'ARMSTRONG',
    'ARNETT',
    'ASHER',
    'ASHLAND',
    'ATOKA',
    'ATWOOD',
    'AVANT',
    'AVARD',
    'BALLOU',
    'BARNSDALL',
    'BARTLESVILLE',
    'BEARDEN',
    'BEAVER',
    'BEGGS',
    'BELFONTE',
    'BELL',
    'BENNINGTON',
    'BERNICE',
    'BESSIE',
    'BETHANY',
    'BETHEL ACRES',
    'BIG CABIN',
    'BILLINGS',
    'BINGER',
    'BIXBY',
    'BLACKBURN',
    'BLACKWELL',
    'BLAIR',
    'BLANCHARD',
    'BLUEJACKET',
    'BOISE CITY',
    'BOKCHITO',
    'BOKOSHE',
    'BOLEY',
    'BOSWELL',
    'BOWLEGS',
    'BOYNTON',
    'BRADLEY',
    'BRAGGS',
    'BRAMAN',
    'BRAY',
    'BRECKENRIDGE',
    'BRENT',
    'BRIDGEPORT',
    'BRIGGS',
    'BRISTOW',
    'BROKEN ARROW',
    'BROKEN BOW',
    'BROMIDE',
    'BROOKSVILLE',
    'BRUSH CREEK',
    'BRUSHY',
    'BUFFALO',
    'BULL HOLLOW',
    'BURBANK',
    'BURLINGTON',
    'BURNS FLAT',
    'BUSHYHEAD',
    'BUTLER',
    'BYARS',
    'BYNG',
    'BYRON',
    'CACHE',
    'CADDO',
    'CALERA',
    'CALUMET',
    'CALVIN',
    'CAMARGO',
    'CAMERON',
    'CANADIAN',
    'CANEY',
    'CANTON',
    'CANUTE',
    'CAPRON',
    'CARDIN',
    'CARLILE',
    'CARMEN',
    'CARNEGIE',
    'CARNEY',
    'CARRIER',
    'CARTER',
    'CASHION',
    'CASTLE',
    'CATOOSA',
    'CAYUGA',
    'CEDAR CREST',
    'CEDAR VALLEY',
    'CEMENT',
    'CENTRAHOMA',
    'CENTRAL HIGH',
    'CHANDLER',
    'CHATTANOOGA',
    'CHECOTAH',
    'CHELSEA',
    'CHEROKEE',
    'CHERRY TREE',
    'CHEWEY',
    'CHEYENNE',
    'CHICKASHA',
    'CHOCTAW',
    'CHOUTEAU',
    'CHRISTIE',
    'CIMARRON CITY',
    'CLAREMORE',
    'CLAYTON',
    'CLEARVIEW',
    'CLEORA',
    'CLEO SPRINGS',
    'CLEVELAND',
    'CLINTON',
    'CLOUD CREEK',
    'COALGATE',
    'COLBERT',
    'COLCORD',
    'COLE',
    'COLLINSVILLE',
    'COLONY',
    'COMANCHE',
    'COMMERCE',
    'COOPERTON',
    'COPAN',
    'COPELAND',
    'CORN',
    'CORNISH',
    'COUNCIL HILL',
    'COVINGTON',
    'COWETA',
    'COWLINGTON',
    'COYLE',
    'CRESCENT',
    'CROMWELL',
    'CROWDER',
    'CUSHING',
    'CUSTER CITY',
    'CYRIL',
    'DACOMA',
    'DAVENPORT',
    'DAVIDSON',
    'DAVIS',
    'DEER CREEK',
    'DELAWARE',
    'DEL CITY',
    'DENNIS',
    'DEPEW',
    'DEVOL',
    'DEWAR',
    'DEWEY',
    'DIBBLE',
    'DICKSON',
    'DILL CITY',
    'DISNEY',
    'DODGE',
    'DOTYVILLE',
    'DOUGHERTY',
    'DOUGLAS',
    'DOVER',
    'DRIPPING SPRINGS',
    'DRUMMOND',
    'DRUMRIGHT',
    'DRY CREEK',
    'DUCHESS LANDING',
    'DUNCAN',
    'DURANT',
    'DUSTIN',
    'DWIGHT MISSION',
    'EAKLY',
    'EARLSBORO',
    'EAST DUKE',
    'EDMOND',
    'ELDON',
    'ELDORADO',
    'ELGIN',
    'ELK CITY',
    'ELMER',
    'ELMORE CITY',
    'EL RENO',
    'EMPIRE CITY',
    'ENID',
    'ERICK',
    'ERIN SPRINGS',
    'ETOWAH',
    'EUFAULA',
    'EVENING SHADE',
    'FAIRFAX',
    'FAIRFIELD',
    'FAIRLAND',
    'FAIRMONT',
    'FAIR OAKS',
    'FAIRVIEW',
    'FALLIS',
    'FANSHAWE',
    'FARGO',
    'FAXON',
    'FITZHUGH',
    'FLETCHER',
    'FLINT CREEK',
    'FLUTE SPRINGS',
    'FORAKER',
    'FOREST PARK',
    'FORGAN',
    'FORT COBB',
    'FORT COFFEE',
    'FORT GIBSON',
    'FORT SUPPLY',
    'FORT TOWSON',
    'FOSS',
    'FOYIL',
    'FRANCIS',
    'FREDERICK',
    'FREEDOM',
    'GAGE',
    'GANS',
    'GARBER',
    'GARVIN',
    'GATE',
    'GEARY',
    'GENE AUTRY',
    'GERONIMO',
    'GERTY',
    'GLENCOE',
    'GLENPOOL',
    'GOLDSBY',
    'GOLTRY',
    'GOODWELL',
    'GORE',
    'GOTEBO',
    'GOULD',
    'GRACEMONT',
    'GRAINOLA',
    'GRANDFIELD',
    'GRAND LAKE TOWNE',
    'GRANITE',
    'GRAYSON',
    'GREASY',
    'GREENFIELD',
    'GREGORY',
    'GROVE',
    'GUTHRIE',
    'GUYMON',
    'HAILEYVILLE',
    'HALLETT',
    'HALL PARK',
    'HAMMON',
    'HANNA',
    'HARDESTY',
    'HARRAH',
    'HARTSHORNE',
    'HASKELL',
    'HASTINGS',
    'HAWORTH',
    'HEADRICK',
    'HEALDTON',
    'HEAVENER',
    'HELENA',
    'HENDRIX',
    'HENNESSEY',
    'HENRYETTA',
    'HICKORY',
    'HILLSDALE',
    'HINTON',
    'HITCHCOCK',
    'HITCHITA',
    'HOBART',
    'HOFFMAN',
    'HOLDENVILLE',
    'HOLLIS',
    'HOLLISTER',
    'HOMINY',
    'HOOKER',
    'HOOT OWL',
    'HORNTOWN',
    'HOWE',
    'HUGO',
    'HULBERT',
    'HUNTER',
    'HYDRO',
    'IDABEL',
    'INDIAHOMA',
    'INDIANOLA',
    'INOLA',
    'IRON POST',
    'JAMESTOWN',
    'JAY',
    'JEFFERSON',
    'JENKS',
    'JENNINGS',
    'JET',
    'JOHNSON',
    'JONES',
    'JUSTICE',
    'KANSAS',
    'KAW CITY',
    'KELLYVILLE',
    'KEMP',
    'KENDRICK',
    'KENEFIC',
    'KEOTA',
    'KETCHUM',
    'KEYES',
    'KEYS',
    'KIEFER',
    'KILDARE',
    'KINGFISHER',
    'KINGSTON',
    'KINTA',
    'KIOWA',
    'KNOWLES',
    'KONAWA',
    'KREBS',
    'KREMLIN',
    'LAHOMA',
    'LAKE ALUMA',
    'LAMAR',
    'LAMBERT',
    'LAMONT',
    'LANGLEY',
    'LANGSTON',
    'LAVERNE',
    'LAWRENCE CREEK',
    'LAWTON',
    'LEACH',
    'LEEDEY',
    'LE FLORE',
    'LEHIGH',
    'LENAPAH',
    'LEON',
    'LEXINGTON',
    'LIBERTY',
    'LIMA',
    'LIMESTONE',
    'LINDSAY',
    'LOCO',
    'LOCUST GROVE',
    'LONE GROVE',
    'LONE WOLF',
    'LONG',
    'LONGDALE',
    'LONGTOWN',
    'LOOKEBA',
    'LOST CITY',
    'LOTSEE',
    'LOVELAND',
    'LOYAL',
    'LUTHER',
    'LYONS SWITCH',
    'MCALESTER',
    'MCCORD',
    'MCCURTAIN',
    'MCKEY',
    'MCLOUD',
    'MACOMB',
    'MADILL',
    'MANCHESTER',
    'MANGUM',
    'MANITOU',
    'MANNFORD',
    'MANNSVILLE',
    'MARAMEC',
    'MARBLE CITY',
    'MARBLE CITY COMMUNITY',
    'MARIETTA',
    'MARLAND',
    'MARLOW',
    'MARSHALL',
    'MARTHA',
    'MARYETTA',
    'MAUD',
    'MAY',
    'MAYSVILLE',
    'MAZIE',
    'MEAD',
    'MEDFORD',
    'MEDICINE PARK',
    'MEEKER',
    'MENO',
    'MERIDIAN',
    'MERIDIAN',
    'MIAMI',
    'MIDWEST CITY',
    'MILBURN',
    'MILL CREEK',
    'MILLERTON',
    'MINCO',
    'MOFFETT',
    'MOORE',
    'MOORELAND',
    'MORRIS',
    'MORRISON',
    'MOUNDS',
    'MOUNTAIN PARK',
    'MOUNTAIN VIEW',
    'MULDROW',
    'MULE BARN',
    'MULHALL',
    'MURPHY',
    'MUSKOGEE',
    'MUSTANG',
    'MUTUAL',
    'NARCISSA',
    'NASH',
    'NEW ALLUWE',
    'NEWCASTLE',
    'NEW CORDELL',
    'NEW EUCHA',
    'NEWKIRK',
    'NEW TULSA',
    'NICHOLS HILLS',
    'NICOMA PARK',
    'NINNEKAH',
    'NOBLE',
    'NORGE',
    'NORMAN',
    'NORTH ENID',
    'NORTH MIAMI',
    'NOTCHIETOWN',
    'NOWATA',
    'OAK GROVE',
    'OAKHURST',
    'OAKLAND',
    'OAKS',
    'OAKWOOD',
    'OCHELATA',
    'OILTON',
    'OKARCHE',
    'OKAY',
    'OKEENE',
    'OKEMAH',
    'OKLAHOMA CITY',
    'OKMULGEE',
    'OKTAHA',
    'OLD EUCHA',
    'OLUSTEE',
    'OOLOGAH',
    'OPTIMA',
    'ORLANDO',
    'OSAGE',
    'OWASSO',
    'PADEN',
    'PANAMA',
    'PAOLI',
    'PARADISE HILL',
    'PARK HILL',
    'PAULS VALLEY',
    'PAWHUSKA',
    'PAWNEE',
    'PEAVINE',
    'PENSACOLA',
    'PEORIA',
    'PERKINS',
    'PERRY',
    'PETTIT',
    'PHILLIPS',
    'PICHER',
    'PIEDMONT',
    'PINHOOK CORNERS',
    'PINK',
    'PIN OAK ACRES',
    'PITTSBURG',
    'POCASSET',
    'POCOLA',
    'PONCA CITY',
    'POND CREEK',
    'PORTER',
    'PORUM',
    'POTEAU',
    'PRAGUE',
    'PRUE',
    'PRYOR CREEK',
    'PUMP BACK',
    'PURCELL',
    'PUTNAM',
    'QUAPAW',
    'QUAY',
    'QUINTON',
    'RALSTON',
    'RAMONA',
    'RANDLETT',
    'RATLIFF CITY',
    'RATTAN',
    'RAVIA',
    'REDBIRD',
    'REDBIRD SMITH',
    'RED OAK',
    'RED ROCK',
    'REMY',
    'RENFROW',
    'RENTIESVILLE',
    'REYDON',
    'RINGLING',
    'RINGWOOD',
    'RIPLEY',
    'RIVER BOTTOM',
    'ROCK ISLAND',
    'ROCKY',
    'ROCKY FORD',
    'ROCKY MOUNTAIN',
    'ROFF',
    'ROLAND',
    'ROOSEVELT',
    'ROSEDALE',
    'ROSSTON',
    'RUSH SPRINGS',
    'RYAN',
    'ST. LOUIS',
    'SALEM',
    'SALINA',
    'SALLISAW',
    'SAMS CORNER',
    'SAND HILLS',
    'SAND SPRINGS',
    'SAPULPA',
    'SASAKWA',
    'SAVANNA',
    'SAWYER',
    'SAYRE',
    'SCHULTER',
    'SCRAPER',
    'SEILING',
    'SEMINOLE',
    'SENTINEL',
    'SEQUOYAH',
    'SHADY GROVE',
    'SHADY GROVE',
    'SHADY GROVE',
    'SHADY POINT',
    'SHAMROCK',
    'SHARON',
    'SHATTUCK',
    'SHAWNEE',
    'SHIDLER',
    'SHORT',
    'SILO',
    'SIMMS',
    'SKEDEE',
    'SKIATOOK',
    'SLAUGHTERVILLE',
    'SLICK',
    'SMITH VILLAGE',
    'SMITHVILLE',
    'SNAKE CREEK',
    'SNYDER',
    'SOPER',
    'SOUR JOHN',
    'SOUTH COFFEYVILLE',
    'SPARKS',
    'SPAULDING',
    'SPAVINAW',
    'SPENCER',
    'SPERRY',
    'SPIRO',
    'SPORTSMEN ACRES',
    'SPORTSMEN ACRES COMMUNITY',
    'SPRINGER',
    'STERLING',
    'STIDHAM',
    'STIGLER',
    'STILLWATER',
    'STILWELL',
    'STONEWALL',
    'STONY POINT',
    'STRANG',
    'STRATFORD',
    'STRINGTOWN',
    'STRONG CITY',
    'STROUD',
    'STUART',
    'SUGDEN',
    'SULPHUR',
    'SUMMIT',
    'SWINK',
    'SYCAMORE',
    'SYCAMORE',
    'TAFT',
    'TAGG FLATS',
    'TAHLEQUAH',
    'TALALA',
    'TALIHINA',
    'TALOGA',
    'TAMAHA',
    'TATUMS',
    'TECUMSEH',
    'TEMPLE',
    'TENKILLER',
    'TERLTON',
    'TERRAL',
    'TEXANNA',
    'TEXHOMA',
    'TEXOLA',
    'THACKERVILLE',
    'THE VILLAGE',
    'THOMAS',
    'TAIWAH',
    'TIPTON',
    'TISHOMINGO',
    'TONKAWA',
    'TRIBBEY',
    'TRYON',
    'TULLAHASSEE',
    'TULSA',
    'TUPELO',
    'TURLEY',
    'TUSHKA',
    'TUTTLE',
    'TWIN OAKS',
    'TYRONE',
    'UNION CITY',
    'VALLEY BROOK',
    'VALLEY PARK',
    'VALLIANT',
    'VELMA',
    'VERA',
    'VERDEN',
    'VIAN',
    'VICI',
    'VINITA',
    'WAGONER',
    'WAINWRIGHT',
    'WAKITA',
    'WALTERS',
    'WANETTE',
    'WANN',
    'WAPANUCKA',
    'WARNER',
    'WARR ACRES',
    'WARWICK',
    'WASHINGTON',
    'WATONGA',
    'WATTS',
    'WATTS COMMUNITY',
    'WAUKOMIS',
    'WAURIKA',
    'WAYNE',
    'WAYNOKA',
    'WEATHERFORD',
    'WEBB CITY',
    'WEBBERS FALLS',
    'WELCH',
    'WELEETKA',
    'WELLING',
    'WELLSTON',
    'WEST PEAVINE',
    'WESTPORT',
    'WEST SILOAM SPRINGS',
    'WESTVILLE',
    'WETUMKA',
    'WEWOKA',
    'WHITEFIELD',
    'WICKLIFFE',
    'WILBURTON',
    'WILLOW',
    'WILSON',
    'WINCHESTER',
    'WISTER',
    'WOODALL',
    'WOODLAWN PARK',
    'WOODVILLE',
    'WOODWARD',
    'WRIGHT CITY',
    'WYANDOTTE',
    'WYNNEWOOD',
    'WYNONA',
    'YALE',
    'YEAGER',
    'YUKON',
    'ZEB',
    'ZENA',
    'ZION',
  ],
  Oregon: [
    'ADAIR VILLAGE',
    'ADAMS',
    'ADRIAN',
    'ALBANY',
    'ALOHA',
    'ALTAMONT',
    'AMITY',
    'ANTELOPE',
    'ARLINGTON',
    'ASHLAND',
    'ASTORIA',
    'ATHENA',
    'AUMSVILLE',
    'AURORA',
    'BAKER CITY',
    'BANDON',
    'BANKS',
    'BARLOW',
    'BARVIEW',
    'BAY CITY',
    'BEAVER',
    'BEAVERTON',
    'BEND',
    'BIGGS JUNCTION',
    'BOARDMAN',
    'BONANZA',
    'BROOKINGS',
    'BROOKS',
    'BROWNSVILLE',
    'BUNKER HILL',
    'BURNS',
    'BUTTE FALLS',
    'BUTTEVILLE',
    'CANBY',
    'CANNON BEACH',
    'CANYON CITY',
    'CANYONVILLE',
    'CAPE MEARES',
    'CARLTON',
    'CASCADE LOCKS',
    'CAVE JUNCTION',
    'CAYUSE',
    'CEDAR HILLS',
    'CEDAR MILL',
    'CENTRAL POINT',
    'CHENOWETH',
    'CHILOQUIN',
    'CITY OF THE DALLES',
    'CLACKAMAS',
    'CLATSKANIE',
    'CLOVERDALE',
    'COBURG',
    'COLUMBIA CITY',
    'CONDON',
    'COOS BAY',
    'COQUILLE',
    'CORNELIUS',
    'CORVALLIS',
    'COTTAGE GROVE',
    'COVE',
    'CRESWELL',
    'CULVER',
    'DALLAS',
    'DAYTON',
    'DAYVILLE',
    'DEPOE BAY',
    'DESCHUTES RIVER WOODS',
    'DETROIT',
    'DONALD',
    'DRAIN',
    'DUFUR',
    'DUNDEE',
    'DUNES CITY',
    'DURHAM',
    'EAGLE POINT',
    'ECHO',
    'ELGIN',
    'ELKTON',
    'ENTERPRISE',
    'EOLA',
    'ESTACADA',
    'EUGENE',
    'FAIRVIEW',
    'FALLS CITY',
    'FLORENCE',
    'FOREST GROVE',
    'FOSSIL',
    'FOUR CORNERS',
    'GARDEN HOME-WHITFORD',
    'GARIBALDI',
    'GASTON',
    'GATES',
    'GEARHART',
    'GERVAIS',
    'GLADSTONE',
    'GLENDALE',
    'GLIDE',
    'GOLD BEACH',
    'GOLD HILL',
    'GOPHER FLATS',
    'GRAND RONDE',
    'GRANITE',
    'GRANTS PASS',
    'GRASS VALLEY',
    'GREEN',
    'GREENHORN',
    'GRESHAM',
    'HAINES',
    'HALFWAY',
    'HALSEY',
    'HAPPY VALLEY',
    'HARBECK-FRUITDALE',
    'HARBOR',
    'HARRISBURG',
    'HAYESVILLE',
    'HEBO',
    'HELIX',
    'HEPPNER',
    'HERMISTON',
    'HILLSBORO',
    'HINES',
    'HOOD RIVER',
    'HUBBARD',
    'HUNTINGTON',
    'IDANHA',
    'IMBLER',
    'INDEPENDENCE',
    'IONE',
    'IRRIGON',
    'ISLAND CITY',
    'JACKSONVILLE',
    'JEFFERSON',
    'JENNINGS LODGE',
    'JOHN DAY',
    'JOHNSON CITY',
    'JORDAN VALLEY',
    'JOSEPH',
    'JUNCTION CITY',
    'KEIZER',
    'KING CITY',
    'KIRKPATRICK',
    'KLAMATH FALLS',
    'LABISH VILLAGE',
    'LAFAYETTE',
    'LA GRANDE',
    'LAKE OSWEGO',
    'LAKESIDE',
    'LAKEVIEW',
    'LA PINE',
    'LEBANON',
    'LEXINGTON',
    'LINCOLN BEACH',
    'LINCOLN CITY',
    'LONEROCK',
    'LONG CREEK',
    'LOSTINE',
    'LOWELL',
    'LYONS',
    'MCMINNVILLE',
    'MADRAS',
    'MALIN',
    'MANZANITA',
    'MARION',
    'MAUPIN',
    'MAYWOOD PARK',
    'MEDFORD',
    'MEHAMA',
    'MERRILL',
    'METOLIUS',
    'METZGER',
    'MILL CITY',
    'MILLERSBURG',
    'MILTON-FREEWATER',
    'MILWAUKIE',
    'MISSION',
    'MITCHELL',
    'MOLALLA',
    'MONMOUTH',
    'MONROE',
    'MONUMENT',
    'MORO',
    'MOSIER',
    'MOUNT ANGEL',
    'MOUNT HOOD VILLAGE',
    'MOUNT VERNON',
    'MYRTLE CREEK',
    'MYRTLE POINT',
    'NEHALEM',
    'NESKOWIN',
    'NETARTS',
    'NEWBERG',
    'NEWPORT',
    'NORTH BEND',
    'NORTH PLAINS',
    'NORTH POWDER',
    'NYSSA',
    'OAK GROVE',
    'OAK HILLS',
    'OAKLAND',
    'OAKRIDGE',
    'OATFIELD',
    'OCEANSIDE',
    'ODELL',
    'ONTARIO',
    'OREGON CITY',
    'PACIFIC CITY',
    'PAISLEY',
    'PARKDALE',
    'PENDLETON',
    'PHILOMATH',
    'PHOENIX',
    'PILOT ROCK',
    'PINE GROVE',
    'PINE HOLLOW',
    'PORTLAND',
    'PORT ORFORD',
    'POWERS',
    'PRAIRIE CITY',
    'PRESCOTT',
    'PRINEVILLE',
    'RAINIER',
    'RALEIGH HILLS',
    'REDMOND',
    'REDWOOD',
    'REEDSPORT',
    'RICHLAND',
    'RICKREALL',
    'RIDDLE',
    'RIVERGROVE',
    'RIVERSIDE',
    'ROCKAWAY BEACH',
    'ROCKCREEK',
    'ROGUE RIVER',
    'ROSEBURG',
    'ROSEBURG NORTH',
    'ROSE LODGE',
    'ROWENA',
    'RUFUS',
    'ST. HELENS',
    'ST. PAUL',
    'SALEM',
    'SANDY',
    'SCAPPOOSE',
    'SCIO',
    'SCOTTS MILLS',
    'SEASIDE',
    'SENECA',
    'SHADY COVE',
    'SHANIKO',
    'SHERIDAN',
    'SHERWOOD',
    'SILETZ',
    'SILVERTON',
    'SISTERS',
    'SODAVILLE',
    'SOUTH LEBANON',
    'SPRAY',
    'SPRINGFIELD',
    'STANFIELD',
    'STAYTON',
    'SUBLIMITY',
    'SUMMERVILLE',
    'SUMPTER',
    'SUNNYSIDE',
    'SUTHERLIN',
    'SWEET HOME',
    'TALENT',
    'TANGENT',
    'TERREBONNE',
    'THREE RIVERS',
    'TIGARD',
    'TILLAMOOK',
    'TOLEDO',
    'TRI-CITY',
    'TROUTDALE',
    'TUALATIN',
    'TURNER',
    'TUTUILLA',
    'TYGH VALLEY',
    'UKIAH',
    'UMATILLA',
    'UNION',
    'UNITY',
    'VALE',
    'VENETA',
    'VERNONIA',
    'WALDPORT',
    'WALLOWA',
    'WAMIC',
    'WARM SPRINGS',
    'WARRENTON',
    'WASCO',
    'WATERLOO',
    'WESTFIR',
    'WEST HAVEN-SYLVAN',
    'WEST LINN',
    'WESTON',
    'WEST SLOPE',
    'WHEELER',
    'WHITE CITY',
    'WILLAMINA',
    'WILSONVILLE',
    'WINCHESTER BAY',
    'WINSTON',
    'WOODBURN',
    'WOOD VILLAGE',
    'YACHATS',
    'YAMHILL',
    'YONCALLA',
  ],
  Palau: ['Palau'],
  Pennsylvania: [
    'AARONSBURG',
    'ABBOTTSTOWN',
    'ADAMSBURG',
    'ADAMSTOWN',
    'ADAMSVILLE',
    'ADDISON',
    'AKRON',
    'ALBA',
    'ALBION',
    'ALBURTIS',
    'ALDAN',
    'ALEXANDRIA',
    'ALIQUIPPA',
    'ALLENPORT',
    'ALLENTOWN',
    'ALMEDIA',
    'ALTAMONT',
    'ALTOONA',
    'AMBLER',
    'AMBRIDGE',
    'AMITY GARDENS',
    'ANCIENT OAKS',
    'ANNVILLE',
    'APOLLO',
    'APPLEWOLD',
    'ARCHBALD',
    'ARDMORE',
    'ARENDTSVILLE',
    'ARISTES',
    'ARLINGTON HEIGHTS',
    'ARMAGH',
    'ARNOLD',
    'ARONA',
    'ASHLAND',
    'ASHLEY',
    'ASHVILLE',
    'ASPINWALL',
    'ATGLEN',
    'ATHENS',
    'ATLANTIC',
    'ATWOOD',
    'AUBURN',
    'AUDUBON',
    'AUSTIN',
    'AVALON',
    'AVIS',
    'AVOCA',
    'AVON',
    'AVONDALE',
    'AVONIA',
    'AVONMORE',
    'BACK MOUNTAIN',
    'BADEN',
    'BAIDLAND',
    'BALDWIN',
    'BALLY',
    'BANGOR',
    'BARKEYVILLE',
    'BATH',
    'BEALLSVILLE',
    'BEAR CREEK VILLAGE',
    'BEAR LAKE',
    'BEAVER',
    'BEAVERDALE-LLOYDELL',
    'BEAVER FALLS',
    'BEAVER MEADOWS',
    'BEAVER SPRINGS',
    'BEAVERTOWN',
    'BECHTELSVILLE',
    'BEDFORD',
    'BEECH CREEK',
    'BELFAST',
    'BELL ACRES',
    'BELLEFONTE',
    'BELLE VERNON',
    'BELLEVILLE',
    'BELLEVUE',
    'BELLWOOD',
    'BELMONT',
    'BEN AVON',
    'BEN AVON HEIGHTS',
    'BENDERSVILLE',
    'BENDERSVILLE STATION-ASPERS',
    'BENSON',
    'BENTLEYVILLE',
    'BENTON',
    'BERLIN',
    'BERNVILLE',
    'BERRYSBURG',
    'BERWICK',
    'BESSEMER',
    'BETHANY',
    'BETHEL PARK',
    'BETHLEHEM',
    'BEURYS LAKE',
    'BIG BEAVER',
    'BIGLERVILLE',
    'BIG RUN',
    'BIRDSBORO',
    'BIRMINGHAM',
    'BLACK LICK',
    'BLAIN',
    'BLAIRSVILLE',
    'BLAKELY',
    'BLANCHARD',
    'BLAWNOX',
    'BLOOMFIELD',
    'BLOOMING VALLEY',
    'BLOOMSBURG',
    'BLOSSBURG',
    'BLUE BELL',
    'BOALSBURG',
    'BOILING SPRINGS',
    'BOLIVAR',
    'BONNEAUVILLE',
    'BOOTHWYN',
    'BOSWELL',
    'BOWMANSTOWN',
    'BOYERTOWN',
    'BRACKENRIDGE',
    'BRADDOCK',
    'BRADDOCK HILLS',
    'BRADFORD',
    'BRADFORDWOODS',
    'BRANCHDALE',
    'BRANDONVILLE',
    'BRENTWOOD',
    'BRESSLER-ENHAUT-OBERLIN',
    'BRIAR CREEK',
    'BRICKERVILLE',
    'BRIDGEPORT',
    'BRIDGEVILLE',
    'BRIDGEWATER',
    'BRISBIN',
    'BRISTOL',
    'BRITTANY FARMS-HIGHLANDS',
    'BROAD TOP CITY',
    'BROCKWAY',
    'BRODHEADSVILLE',
    'BROOKHAVEN',
    'BROOKVILLE',
    'BROOMALL',
    'BROWNSTOWN',
    'BROWNSVILLE',
    'BRUIN',
    'BRYN ATHYN',
    'BRYN MAWR',
    'BUCKHORN',
    'BUCK RUN',
    'BURGETTSTOWN',
    'BURLINGTON',
    'BURNHAM',
    'BURNSIDE',
    'BUTLER',
    'CALIFORNIA',
    'CALLENSBURG',
    'CALLERY',
    'CALLIMONT',
    'CALUMET-NORVELT',
    'CAMBRIDGE SPRINGS',
    'CAMPBELLTOWN',
    'CAMP HILL',
    'CANADOHTA LAKE',
    'CANONSBURG',
    'CANTON',
    'CARBONDALE',
    'CARLISLE',
    'CARMICHAELS',
    'CARNEGIE',
    'CARNOT-MOON',
    'CARROLLTOWN',
    'CARROLL VALLEY',
    'CASHTOWN-MCKNIGHTSTOWN',
    'CASSANDRA',
    'CASSELMAN',
    'CASSVILLE',
    'CASTANEA',
    'CASTLE SHANNON',
    'CATASAUQUA',
    'CATAWISSA',
    'CECIL-BISHOP',
    'CENTERPORT',
    'CENTERVILLE',
    'CENTERVILLE',
    'CENTRAL CITY',
    'CENTRALIA',
    'CENTRE HALL',
    'CHALFANT',
    'CHALFONT',
    'CHAMBERSBURG',
    'CHAPMAN',
    'CHARLEROI',
    'CHERRY TREE',
    'CHERRY VALLEY',
    'CHESTER',
    'CHESTERBROOK',
    'CHESTER HEIGHTS',
    'CHESTER HILL',
    'CHESTER TOWNSHIP',
    'CHEST SPRINGS',
    'CHESWICK',
    'CHEVY CHASE HEIGHTS',
    'CHICORA',
    'CHRISTIANA',
    'CHURCHILL',
    'CHURCHVILLE',
    'CLAIRTON',
    'CLARENCE',
    'CLARENDON',
    'CLARION',
    'CLARK',
    'CLARKS GREEN',
    'CLARKS SUMMIT',
    'CLARKSVILLE',
    'CLAYSBURG',
    'CLAYSVILLE',
    'CLEARFIELD',
    'CLEONA',
    'CLIFTON HEIGHTS',
    'CLINTONVILLE',
    'CLYMER',
    'COAL CENTER',
    'COALDALE',
    'COALDALE',
    'COALMONT',
    'COALPORT',
    'COATESVILLE',
    'COBURN',
    'COCHRANTON',
    'COKEBURG',
    'COLLEGEVILLE',
    'COLLINGDALE',
    'COLONIAL PARK',
    'COLUMBIA',
    'COLVER',
    'COLWYN',
    'COMMODORE',
    'CONFLUENCE',
    'CONNEAUT LAKE',
    'CONNEAUT LAKESHORE',
    'CONNEAUTVILLE',
    'CONNELLSVILLE',
    'CONNOQUENESSING',
    'CONSHOHOCKEN',
    'CONWAY',
    'CONYNGHAM',
    'COOPERSBURG',
    'COOPERSTOWN',
    'COPLAY',
    'CORAOPOLIS',
    'CORNWALL',
    'CORNWELLS HEIGHTS-EDDINGTON',
    'CORRY',
    'CORSICA',
    'COUDERSPORT',
    'COURTDALE',
    'CRABTREE',
    'CRAFTON',
    'CRANESVILLE',
    'CREEKSIDE',
    'CRESSON',
    'CRESSONA',
    'CROSS ROADS',
    'CROYDON',
    'CURTISVILLE',
    'CURWENSVILLE',
    'DAISYTOWN',
    'DALE',
    'DALLAS',
    'DALLASTOWN',
    'DALTON',
    'DANVILLE',
    'DARBY',
    'DARBY TOWNSHIP',
    'DARLINGTON',
    'DAUPHIN',
    'DAVIDSVILLE',
    'DAWSON',
    'DAYTON',
    'DEEMSTON',
    'DEER LAKE',
    'DELANO',
    'DELAWARE WATER GAP',
    'DELMONT',
    'DELTA',
    'DENVER',
    'DERRY',
    'DEVON-BERWYN',
    'DICKSON CITY',
    'DICKSONVILLE',
    'DILLSBURG',
    'DONALDSON',
    'DONEGAL',
    'DONORA',
    'DORMONT',
    'DOVER',
    'DOWNINGTOWN',
    'DOYLESTOWN',
    'DRAVOSBURG',
    'DREXEL HILL',
    'DRIFTWOOD',
    'DUBLIN',
    'DUBOIS',
    'DUBOISTOWN',
    'DUDLEY',
    'DUNBAR',
    'DUNCANNON',
    'DUNCANSVILLE',
    'DUNLEVY',
    'DUNMORE',
    'DUNNSTOWN',
    'DUPONT',
    'DUQUESNE',
    'DURYEA',
    'DUSHORE',
    'EAGLES MERE',
    'EAGLEVILLE',
    'EAST BANGOR',
    'EAST BERLIN',
    'EAST BERWICK',
    'EAST BRADY',
    'EAST BUTLER',
    'EAST CONEMAUGH',
    'EAST GREENVILLE',
    'EAST LANSDOWNE',
    'EASTLAWN GARDENS',
    'EAST MCKEESPORT',
    'EAST NORRITON',
    'EASTON',
    'EAST PETERSBURG',
    'EAST PITTSBURGH',
    'EAST PROSPECT',
    'EAST ROCHESTER',
    'EAST RUTHERFORD',
    'EAST SIDE',
    'EAST STROUDSBURG',
    'EAST UNIONTOWN',
    'EASTVALE',
    'EAST VANDERGRIFT',
    'EAST WASHINGTON',
    'EAST YORK',
    'EAU CLAIRE',
    'EBENSBURG',
    'ECONOMY',
    'EDDYSTONE',
    'EDGEWOOD',
    'EDGEWOOD',
    'EDGEWORTH',
    'EDINBORO',
    'EDWARDSVILLE',
    'EHRENFELD',
    'ELCO',
    'ELDERTON',
    'ELDRED',
    'ELGIN',
    'ELIM',
    'ELIZABETH',
    'ELIZABETHTOWN',
    'ELIZABETHVILLE',
    'ELKLAND',
    'ELLPORT',
    'ELLSWORTH',
    'ELLWOOD CITY',
    'ELVERSON',
    'ELYSBURG',
    'EMIGSVILLE',
    'EMLENTON',
    'EMMAUS',
    'EMPORIUM',
    'EMSWORTH',
    'ENGLEWOOD',
    'ENOLA',
    'ENON VALLEY',
    'EPHRATA',
    'ERIE',
    'ERNEST',
    'ESPY',
    'ETNA',
    'EVANSBURG',
    'EVANS CITY',
    'EVERETT',
    'EVERSON',
    'EXETER',
    'EXPORT',
    'EXTON',
    'EYERS GROVE',
    'FACTORYVILLE',
    'FAIRCHANCE',
    'FAIRDALE',
    'FAIRFIELD',
    'FAIRLESS HILLS',
    'FAIRVIEW',
    'FAIRVIEW-FERNDALE',
    'FALLS CREEK',
    'FALLSTON',
    'FARRELL',
    'FAWN GROVE',
    'FAYETTE CITY',
    'FAYETTEVILLE',
    'FEASTERVILLE-TREVOSE',
    'FELTON',
    'FERNDALE',
    'FERNVILLE',
    'FERNWAY',
    'FINLEYVILLE',
    'FLEETWOOD',
    'FLEMINGTON',
    'FLOURTOWN',
    'FLYING HILLS',
    'FOLCROFT',
    'FOLSOM',
    'FORD CITY',
    'FORD CLIFF',
    'FOREST CITY',
    'FOREST HILLS',
    'FORKSVILLE',
    'FORRESTVILLE',
    'FORT INDIANTOWN GAP',
    'FORT WASHINGTON',
    'FORTY FORT',
    'FOUNDRYVILLE',
    'FOUNTAIN HILL',
    'FOUNTAIN SPRINGS',
    'FOXBURG',
    'FOX CHAPEL',
    'FOX RUN',
    'FRACKVILLE',
    'FRANKFORT SPRINGS',
    'FRANKLIN',
    'FRANKLIN',
    'FRANKLIN PARK',
    'FRANKLINTOWN',
    'FREDERICKSBURG',
    'FREDERICKSBURG',
    'FREDERICKTOWN-MILLSBORO',
    'FREDONIA',
    'FREEBURG',
    'FREEDOM',
    'FREELAND',
    'FREEMANSBURG',
    'FREEPORT',
    'FRIEDENS',
    'FRIEDENSBURG',
    'FRIENDSVILLE',
    'FULLERTON',
    'GALETON',
    'GALLITZIN',
    'GAP',
    'GARDEN VIEW',
    'GARRETT',
    'GASTONVILLE',
    'GEISTOWN',
    'GENEVA',
    'GEORGETOWN',
    'GETTYSBURG',
    'GILBERTON',
    'GILBERTSVILLE',
    'GIRARD',
    'GIRARDVILLE',
    'GLASGOW',
    'GLASSPORT',
    'GLENBURN',
    'GLEN CAMPBELL',
    'GLENDON',
    'GLENFIELD',
    'GLEN HOPE',
    'GLEN LYON',
    'GLENOLDEN',
    'GLEN ROCK',
    'GLENSIDE',
    'GOLDSBORO',
    'GORDON',
    'GRAMPIAN',
    'GRANTLEY',
    'GRAPEVILLE',
    'GRATZ',
    'GREAT BEND',
    'GREENCASTLE',
    'GREEN HILLS',
    'GREEN LANE',
    'GREENSBORO',
    'GREENSBURG',
    'GREEN TREE',
    'GREENVILLE',
    'GRIER CITY-PARK CREST',
    'GRINDSTONE-ROWES RUN',
    'GROVE CITY',
    'GUILFORD',
    'GUYS MILLS',
    'HALFWAY HOUSE',
    'HALIFAX',
    'HALLAM',
    'HALLSTEAD',
    'HAMBURG',
    'HAMPTON',
    'HAMPTON TOWNSHIP',
    'HANOVER',
    'HARLEYSVILLE',
    'HARMONSBURG',
    'HARMONY',
    'HARMONY TOWNSHIP',
    'HARRISBURG',
    'HARRISON CITY',
    'HARRISON TOWNSHIP',
    'HARRISVILLE',
    'HARTLETON',
    'HARTSTOWN',
    'HARVEYS LAKE',
    'HASSON HEIGHTS',
    'HASTINGS',
    'HATBORO',
    'HATFIELD',
    'HAWLEY',
    'HAWTHORN',
    'HAYSVILLE',
    'HAZLETON',
    'HECKSCHERVILLE',
    'HEIDELBERG',
    'HEILWOOD',
    'HELLERTOWN',
    'HERMINIE',
    'HERMITAGE',
    'HERNDON',
    'HERSHEY',
    'HIGHLAND PARK',
    'HIGHSPIRE',
    'HILLER',
    'HOKENDAUQUA',
    'HOLLIDAYSBURG',
    'HOMEACRE-LYNDORA',
    'HOMER CITY',
    'HOMESTEAD',
    'HOMETOWN',
    'HOMEWOOD',
    'HONESDALE',
    'HONEY BROOK',
    'HOOKSTOWN',
    'HOOVERSVILLE',
    'HOP BOTTOM',
    'HOPEWELL',
    'HOPWOOD',
    'HORSHAM',
    'HOUSERVILLE',
    'HOUSTON',
    'HOUTZDALE',
    'HOWARD',
    'HUGHESTOWN',
    'HUGHESVILLE',
    'HULMEVILLE',
    'HUMMELSTOWN',
    'HUMMELS WHARF',
    'HUNKER',
    'HUNTINGDON',
    'HYDE',
    'HYDE PARK',
    'HYDETOWN',
    'HYNDMAN',
    'IMPERIAL-ENLOW',
    'INDIANA',
    'INDIAN LAKE',
    'INDUSTRY',
    'INGRAM',
    'IOLA',
    'IRVONA',
    'IRWIN',
    'IVYLAND',
    'JACKSON CENTER',
    'JACKSONVILLE',
    'JACOBUS',
    'JAMESTOWN',
    'JAMISON CITY',
    'JEANNETTE',
    'JEDDO',
    'JEFFERSON',
    'JEFFERSON',
    'JEFFERSON HILLS',
    'JENKINTOWN',
    'JENNERSTOWN',
    'JERMYN',
    'JEROME',
    'JERSEY SHORE',
    'JERSEYTOWN',
    'JESSUP',
    'JIM THORPE',
    'JOHNSONBURG',
    'JOHNSTOWN',
    'JONESTOWN',
    'JONESTOWN',
    'JULIAN',
    'JUNIATA TERRACE',
    'KANE',
    'KARNS CITY',
    'KENHORST',
    'KENILWORTH',
    'KENNEDY TOWNSHIP',
    'KENNETT SQUARE',
    'KING OF PRUSSIA',
    'KINGSTON',
    'KISTLER',
    'KITTANNING',
    'KLINGERSTOWN',
    'KNOX',
    'KNOXVILLE',
    'KOPPEL',
    'KRATZERVILLE',
    'KREAMER',
    'KULPMONT',
    'KULPSVILLE',
    'KUTZTOWN',
    'LACEYVILLE',
    'LAFLIN',
    'LAKE CITY',
    'LAKE HERITAGE',
    'LAKE MEADE',
    'LAKE WYNONAH',
    'LANCASTER',
    'LANDINGVILLE',
    'LANDISBURG',
    'LANESBORO',
    'LANGHORNE',
    'LANGHORNE MANOR',
    'LANSDALE',
    'LANSDOWNE',
    'LANSFORD',
    'LAPORTE',
    'LARKSVILLE',
    'LATROBE',
    'LAURELDALE',
    'LAUREL MOUNTAIN',
    'LAUREL RUN',
    'LAVELLE-LOCUSTDALE',
    'LAWNTON',
    'LAWRENCE PARK',
    'LAWRENCEVILLE',
    'LAWSON HEIGHTS',
    'LEACOCK-LEOLA-BAREVILLE',
    'LEBANON',
    'LEBANON SOUTH',
    'LEECHBURG',
    'LEESPORT',
    'LEETSDALE',
    'LEHIGHTON',
    'LEITH-HATFIELD',
    'LEMONT',
    'LEMOYNE',
    'LENAPE HEIGHTS',
    'LENHARTSVILLE',
    'LE RAYSVILLE',
    'LEVITTOWN',
    'LEWISBERRY',
    'LEWISBURG',
    'LEWIS RUN',
    'LEWISTOWN',
    'LIBERTY',
    'LIBERTY',
    'LIGHTSTREET',
    'LIGONIER',
    'LILLY',
    'LIMA',
    'LIME RIDGE',
    'LINCOLN',
    'LINCOLNVILLE',
    'LINESVILLE',
    'LINGLESTOWN',
    'LINNTOWN',
    'LINWOOD',
    'LIONVILLE-MARCHWOOD',
    'LITITZ',
    'LITTLE MEADOWS',
    'LITTLESTOWN',
    'LIVERPOOL',
    'LOCK HAVEN',
    'LOCUSTDALE',
    'LOGANTON',
    'LOGANVILLE',
    'LONG BRANCH',
    'LORAIN',
    'LORANE',
    'LORETTO',
    'LOWER ALLEN',
    'LOWER BURRELL',
    'LUCERNE MINES',
    'LUMBER CITY',
    'LUZERNE',
    'LYKENS',
    'LYNNWOOD-PRICEDALE',
    'LYONS',
    'MCADOO',
    'MCALISTERVILLE',
    'MCCANDLESS TOWNSHIP',
    'MCCHESNEYTOWN-LOYALHANNA',
    'MCCLURE',
    'MCCONNELLSBURG',
    'MCDONALD',
    'MCEWENSVILLE',
    'MCGOVERN',
    'MCKEAN',
    'MCKEANSBURG',
    'MCKEESPORT',
    'MCKEES ROCKS',
    'MCMURRAY',
    'MCSHERRYSTOWN',
    'MACUNGIE',
    'MCVEYTOWN',
    'MADISON',
    'MADISONBURG',
    'MAHAFFEY',
    'MAHANOY CITY',
    'MAINVILLE',
    'MALVERN',
    'MANCHESTER',
    'MANHEIM',
    'MANNS CHOICE',
    'MANOR',
    'MANORVILLE',
    'MANSFIELD',
    'MAPLE GLEN',
    'MAPLETON',
    'MARCUS HOOK',
    'MARIANNA',
    'MARIETTA',
    'MARION CENTER',
    'MARION HEIGHTS',
    'MARKLESBURG',
    'MARKLEYSBURG',
    'MARLIN',
    'MARS',
    'MARSHALLTON',
    'MARTINSBURG',
    'MARYSVILLE',
    'MASONTOWN',
    'MATAMORAS',
    'MAYFIELD',
    'MAYTOWN',
    'MEADOWOOD',
    'MEADVILLE',
    'MECHANICSBURG',
    'MECHANICSVILLE',
    'MECHANICSVILLE',
    'MEDIA',
    'MERCER',
    'MERCERSBURG',
    'MERIDIAN',
    'MESHOPPEN',
    'MEXICO',
    'MEYERSDALE',
    'MIDDLEBURG',
    'MIDDLEPORT',
    'MIDDLETOWN',
    'MIDDLETOWN',
    'MIDLAND',
    'MIDWAY',
    'MIDWAY',
    'MIFFLIN',
    'MIFFLINBURG',
    'MIFFLINTOWN',
    'MIFFLINVILLE',
    'MILESBURG',
    'MILFORD',
    'MILLBOURNE',
    'MILL CREEK',
    'MILLERSBURG',
    'MILLERSTOWN',
    'MILLERSVILLE',
    'MILL HALL',
    'MILLHEIM',
    'MILLVALE',
    'MILL VILLAGE',
    'MILLVILLE',
    'MILROY',
    'MILTON',
    'MINERSVILLE',
    'MODENA',
    'MOHNTON',
    'MONACA',
    'MONESSEN',
    'MONONGAHELA',
    'MONROE',
    'MONT ALTO',
    'MONTGOMERY',
    'MONTGOMERYVILLE',
    'MONTOURSVILLE',
    'MONTROSE',
    'MONUMENT',
    'MOOSIC',
    'MORRISVILLE',
    'MORRISVILLE',
    'MORTON',
    'MOSCOW',
    'MOUNTAINHOME',
    'MOUNTAIN TOP',
    'MOUNT CARBON',
    'MOUNT CARMEL',
    'MOUNT COBB',
    'MOUNT GRETNA',
    'MOUNT GRETNA HEIGHTS',
    'MOUNT HOLLY SPRINGS',
    'MOUNT JEWETT',
    'MOUNT JOY',
    'MOUNT LEBANON',
    'MOUNT OLIVER',
    'MOUNT PENN',
    'MOUNT PLEASANT',
    'MOUNT PLEASANT MILLS',
    'MOUNT POCONO',
    'MOUNT UNION',
    'MOUNTVILLE',
    'MOUNT WOLF',
    'MUNCY',
    'MUNHALL',
    'MUNICIPALITY OF MONROEVILLE',
    'MUNICIPALITY OF MURRYSVILLE',
    'MYERSTOWN',
    'NANTICOKE',
    'NANTY-GLO',
    'NARBERTH',
    'NAZARETH',
    'NEMACOLIN',
    'NESCOPECK',
    'NESQUEHONING',
    'NETHER PROVIDENCE TOWNSHIP',
    'NEW ALBANY',
    'NEW ALEXANDRIA',
    'NEW BALTIMORE',
    'NEW BEAVER',
    'NEW BERLIN',
    'NEW BETHLEHEM',
    'NEW BOSTON-MOREA',
    'NEW BRIGHTON',
    'NEW BRITAIN',
    'NEW BUFFALO',
    'NEWBURG',
    'NEWBURG',
    'NEW CASTLE',
    'NEW CASTLE NORTHWEST',
    'NEW CENTERVILLE',
    'NEW COLUMBUS',
    'NEW CUMBERLAND',
    'NEW EAGLE',
    'NEWELL',
    'NEW FLORENCE',
    'NEW FREEDOM',
    'NEW GALILEE',
    'NEW HOLLAND',
    'NEW HOPE',
    'NEW KENSINGTON',
    'NEW KINGSTOWN',
    'NEW LEBANON',
    'NEWMANSTOWN',
    'NEW MILFORD',
    'NEW MORGAN',
    'NEW OXFORD',
    'NEW PARIS',
    'NEW PHILADELPHIA',
    'NEWPORT',
    'NEW RINGGOLD',
    'NEWRY',
    'NEW SALEM',
    'NEW SALEM-BUFFINGTON',
    'NEW STANTON',
    'NEWTON HAMILTON',
    'NEWTOWN',
    'NEWTOWN',
    'NEWTOWN GRANT',
    'NEWVILLE',
    'NEW WASHINGTON',
    'NEW WILMINGTON',
    'NICHOLSON',
    'NIXON',
    'NORRISTOWN',
    'NORTHAMPTON',
    'NORTH APOLLO',
    'NORTH BELLE VERNON',
    'NORTH BRADDOCK',
    'NORTH CATASAUQUA',
    'NORTH CHARLEROI',
    'NORTH EAST',
    'NORTHERN CAMBRIA',
    'NORTH IRWIN',
    'NORTH PHILIPSBURG',
    'NORTHUMBERLAND',
    'NORTH VANDERGRIFT-PLEASANT VIEW',
    'NORTH VERSAILLES',
    'NORTH WALES',
    'NORTHWEST HARBORCREEK',
    'NORTH YORK',
    'NORWOOD',
    'NUANGOLA',
    'NUMIDIA',
    'NUREMBERG',
    'OAKDALE',
    'OAK HILLS',
    'OAKLAND',
    'OAKMONT',
    'OAKWOOD',
    'O HARA TOWNSHIP',
    'OHIOPYLE',
    'OHIOVILLE',
    'OIL CITY',
    'OKLAHOMA',
    'OLD FORGE',
    'OLD ORCHARD',
    'OLIVER',
    'OLYPHANT',
    'ONEIDA',
    'ORANGEVILLE',
    'ORBISONIA',
    'ORCHARD HILLS',
    'ORELAND',
    'ORRSTOWN',
    'ORRTANNA',
    'ORWIGSBURG',
    'OSBORNE',
    'OSCEOLA MILLS',
    'OSWAYO',
    'OXFORD',
    'PAINT',
    'PALMER HEIGHTS',
    'PALMERTON',
    'PALMYRA',
    'PALO ALTO',
    'PAOLI',
    'PARADISE',
    'PARKER',
    'PARKESBURG',
    'PARK FOREST VILLAGE',
    'PARKSIDE',
    'PARKVILLE',
    'PARRYVILLE',
    'PATTERSON HEIGHTS',
    'PATTERSON TOWNSHIP',
    'PATTON',
    'PAXTANG',
    'PAXTONIA',
    'PAXTONVILLE',
    'PEN ARGYL',
    'PENBROOK',
    'PENN',
    'PENNDEL',
    'PENN HILLS',
    'PENN LAKE PARK',
    'PENNSBURG',
    'PENNSBURY VILLAGE',
    'PENNS CREEK',
    'PENNVILLE',
    'PENN WYNNE',
    'PERKASIE',
    'PERRYOPOLIS',
    'PETERSBURG',
    'PETROLIA',
    'PHILADELPHIA',
    'PHILIPSBURG',
    'PHOENIXVILLE',
    'PICTURE ROCKS',
    'PILLOW',
    'PINE GLEN',
    'PINE GROVE',
    'PINE GROVE MILLS',
    'PITCAIRN',
    'PITTSBURGH',
    'PITTSTON',
    'PLAINFIELD',
    'PLATEA',
    'PLEASANT GAP',
    'PLEASANT HILL',
    'PLEASANT HILLS',
    'PLEASANTVILLE',
    'PLEASANTVILLE',
    'PLUM',
    'PLUMVILLE',
    'PLYMOUTH',
    'PLYMOUTH MEETING',
    'PLYMPTONVILLE',
    'POCONO PINES',
    'POINT MARION',
    'POLK',
    'PORTAGE',
    'PORT ALLEGANY',
    'PORT CARBON',
    'PORT CLINTON',
    'PORTERSVILLE',
    'PORTLAND',
    'PORT MATILDA',
    'PORT ROYAL',
    'PORT TREVORTON',
    'PORT VUE',
    'POTTSGROVE',
    'POTTSTOWN',
    'POTTSVILLE',
    'PRINGLE',
    'PROGRESS',
    'PROMPTON',
    'PROSPECT',
    'PROSPECT PARK',
    'PUNXSUTAWNEY',
    'PYMATUNING CENTRAL',
    'PYMATUNING NORTH',
    'PYMATUNING SOUTH',
    'QUAKERTOWN',
    'QUARRYVILLE',
    'QUENTIN',
    'RADNOR TOWNSHIP',
    'RAILROAD',
    'RAINSBURG',
    'RAMBLEWOOD',
    'RAMEY',
    'RANKIN',
    'RAVINE',
    'READING',
    'REAMSTOWN',
    'REBERSBURG',
    'RED HILL',
    'RED LION',
    'REEDSVILLE',
    'REIFFTON',
    'REINERTON-ORWIN-MUIR',
    'RENNINGERS',
    'RENOVO',
    'REPUBLIC',
    'RESERVE TOWNSHIP',
    'REYNOLDSVILLE',
    'RHEEMS',
    'RICES LANDING',
    'RICEVILLE',
    'RICHBORO',
    'RICHFIELD',
    'RICHLAND',
    'RICHLANDTOWN',
    'RIDGWAY',
    'RIDLEY PARK',
    'RIEGELSVILLE',
    'RIMERSBURG',
    'RINGTOWN',
    'RIVERSIDE',
    'ROARING SPRING',
    'ROBESONIA',
    'ROBINSON TOWNSHIP',
    'ROCHESTER',
    'ROCKHILL FURNACE',
    'ROCKLEDGE',
    'ROCKWOOD',
    'ROHRSBURG',
    'ROME',
    'ROSCOE',
    'ROSETO',
    'ROSE VALLEY',
    'ROSEVILLE',
    'ROSSITER',
    'ROSSLYN FARMS',
    'ROSS TOWNSHIP',
    'ROTHSVILLE',
    'ROUSEVILLE',
    'ROUZERVILLE',
    'ROYALTON',
    'ROYERSFORD',
    'RUPERT',
    'RURAL VALLEY',
    'RUSSELLTON',
    'RUTHERFORD',
    'RUTLEDGE',
    'SAEGERTOWN',
    'ST. CLAIR',
    'ST. CLAIRSVILLE',
    'ST. LAWRENCE',
    'ST. MARYS',
    'ST. MICHAEL-SIDMAN',
    'ST. PETERSBURG',
    'SALISBURY',
    'SALIX-BEAUTY LINE PARK',
    'SALLADASBURG',
    'SALTILLO',
    'SALTSBURG',
    'SALUNGA-LANDISVILLE',
    'SANATOGA',
    'SAND HILL',
    'SANDY',
    'SANDY LAKE',
    'SANDY RIDGE',
    'SANKERTOWN',
    'SAXONBURG',
    'SAXTON',
    'SAYRE',
    'SCALP LEVEL',
    'SCHAEFFERSTOWN',
    'SCHELLSBURG',
    'SCHLUSSER',
    'SCHNECKSVILLE',
    'SCHUYLKILL HAVEN',
    'SCHWENKSVILLE',
    'SCOTTDALE',
    'SCOTT TOWNSHIP',
    'SCRANTON',
    'SELINSGROVE',
    'SELLERSVILLE',
    'SELTZER',
    'SENECA',
    'SEVEN FIELDS',
    'SEVEN SPRINGS',
    'SEVEN VALLEYS',
    'SEWARD',
    'SEWICKLEY',
    'SEWICKLEY HEIGHTS',
    'SEWICKLEY HILLS',
    'SHADE GAP',
    'SHALER TOWNSHIP',
    'SHAMOKIN',
    'SHAMOKIN DAM',
    'SHANKSVILLE',
    'SHANOR-NORTHVUE',
    'SHARON',
    'SHARON HILL',
    'SHARPSBURG',
    'SHARPSVILLE',
    'SHEAKLEYVILLE',
    'SHEFFIELD',
    'SHELOCTA',
    'SHENANDOAH',
    'SHENANDOAH HEIGHTS',
    'SHEPPTON',
    'SHICKSHINNY',
    'SHILLINGTON',
    'SHILOH',
    'SHINGLEHOUSE',
    'SHIPPENSBURG',
    'SHIPPENVILLE',
    'SHIPPINGPORT',
    'SHIREMANSTOWN',
    'SHIRLEYSBURG',
    'SHOEMAKERSVILLE',
    'SHREWSBURY',
    'SILVERDALE',
    'SINKING SPRING',
    'SKIPPACK',
    'SKYLINE VIEW',
    'SLABTOWN',
    'SLATINGTON',
    'SLICKVILLE',
    'SLIGO',
    'SLIPPERY ROCK',
    'SMETHPORT',
    'SMICKSBURG',
    'SMITHFIELD',
    'SMITHTON',
    'SNOW SHOE',
    'S.N.P.J.',
    'SNYDERTOWN',
    'SOMERSET',
    'SOUDERTON',
    'SOUTH BETHLEHEM',
    'SOUTH COATESVILLE',
    'SOUTH CONNELLSVILLE',
    'SOUTH FORK',
    'SOUTH GREENSBURG',
    'SOUTH HEIGHTS',
    'SOUTHMONT',
    'SOUTH NEW CASTLE',
    'SOUTH PARK TOWNSHIP',
    'SOUTH PHILIPSBURG',
    'SOUTH POTTSTOWN',
    'SOUTH RENOVO',
    'SOUTH WAVERLY',
    'SOUTHWEST GREENSBURG',
    'SOUTH WILLIAMSPORT',
    'SPARTANSBURG',
    'SPEERS',
    'SPRINGBORO',
    'SPRING CITY',
    'SPRINGDALE',
    'SPRINGETTS MANOR-YORKLYN',
    'SPRINGFIELD',
    'SPRING GROVE',
    'SPRING HILL',
    'SPRING HOUSE',
    'SPRING MILLS',
    'SPRING MOUNT',
    'SPRING RIDGE',
    'SPRY',
    'STARRUCCA',
    'STATE COLLEGE',
    'STEELTON',
    'STEWARTSTOWN',
    'STILLWATER',
    'STOCKDALE',
    'STOCKERTOWN',
    'STONEBORO',
    'STONYBROOK-WILSHIRE',
    'STORMSTOWN',
    'STOWE',
    'STOWE TOWNSHIP',
    'STOYSTOWN',
    'STRASBURG',
    'STRATTANVILLE',
    'STRAUSSTOWN',
    'STROUDSBURG',
    'STURGEON-NOBLESTOWN',
    'SUGARCREEK',
    'SUGAR GROVE',
    'SUGAR NOTCH',
    'SUMMERHILL',
    'SUMMERVILLE',
    'SUMMIT HILL',
    'SUMMIT STATION',
    'SUNBURY',
    'SUSQUEHANNA DEPOT',
    'SUSQUEHANNA TRAILS',
    'SUTERSVILLE',
    'SWARTHMORE',
    'SWISSVALE',
    'SWOYERSVILLE',
    'SYKESVILLE',
    'SYLVANIA',
    'TAMAQUA',
    'TARENTUM',
    'TATAMY',
    'TAYLOR',
    'TELFORD',
    'TERRE HILL',
    'THOMPSON',
    'THOMPSONTOWN',
    'THOMPSONVILLE',
    'THORNBURG',
    'THORNDALE',
    'THREE SPRINGS',
    'THROOP',
    'TIDIOUTE',
    'TIMBER HILLS',
    'TIMBLIN',
    'TINICUM TOWNSHIP',
    'TIOGA',
    'TIONESTA',
    'TIPTON',
    'TITUSVILLE',
    'TOPTON',
    'TOUGHKENAMON',
    'TOWANDA',
    'TOWER CITY',
    'TOWNVILLE',
    'TRAFFORD',
    'TRAINER',
    'TRAPPE',
    'TREASURE LAKE',
    'TREMONT',
    'TRESCKOW',
    'TREVORTON',
    'TROOPER',
    'TROUTVILLE',
    'TROXELVILLE',
    'TROY',
    'TRUMBAUERSVILLE',
    'TULLYTOWN',
    'TUNKHANNOCK',
    'TUNNELHILL',
    'TURBOTVILLE',
    'TURTLE CREEK',
    'TUSCARORA',
    'TWILIGHT',
    'TYLER RUN-QUEENS GATE',
    'TYRONE',
    'ULYSSES',
    'UNION CITY',
    'UNION DALE',
    'UNIONTOWN',
    'UNIONVILLE',
    'UPLAND',
    'UPPER PROVIDENCE TOWNSHIP',
    'UPPER ST. CLAIR',
    'URSINA',
    'UTICA',
    'VALENCIA',
    'VALLEY GREEN',
    'VALLEY-HI',
    'VALLEY VIEW',
    'VALLEY VIEW',
    'VANDERBILT',
    'VANDERGRIFT',
    'VANDLING',
    'VENANGO',
    'VERONA',
    'VERSAILLES',
    'VILLAGE GREEN-GREEN RIDGE',
    'VILLAGE SHIRES',
    'VINCO',
    'VINTONDALE',
    'VOLANT',
    'WALL',
    'WALLACETON',
    'WALLER',
    'WALNUTPORT',
    'WAMPUM',
    'WARMINSTER HEIGHTS',
    'WARREN',
    'WARREN SOUTH',
    'WARRIOR RUN',
    'WASHINGTON',
    'WASHINGTONVILLE',
    'WATERFORD',
    'WATSONTOWN',
    'WATTSBURG',
    'WAYMART',
    'WAYNE HEIGHTS',
    'WAYNESBORO',
    'WAYNESBURG',
    'WEATHERLY',
    'WEIGELSTOWN',
    'WEISSPORT',
    'WEISSPORT EAST',
    'WELLERSBURG',
    'WELLSBORO',
    'WELLSVILLE',
    'WERNERSVILLE',
    'WESLEYVILLE',
    'WEST ALEXANDER',
    'WEST BROWNSVILLE',
    'WEST CHESTER',
    'WEST CONSHOHOCKEN',
    'WEST EASTON',
    'WEST ELIZABETH',
    'WESTFIELD',
    'WEST GOSHEN',
    'WEST GROVE',
    'WEST HAZLETON',
    'WEST HILLS',
    'WEST HOMESTEAD',
    'WEST KITTANNING',
    'WEST LAWN',
    'WEST LEECHBURG',
    'WEST LIBERTY',
    'WEST MAYFIELD',
    'WEST MIDDLESEX',
    'WEST MIDDLETOWN',
    'WEST MIFFLIN',
    'WESTMONT',
    'WEST NEWTON',
    'WEST NORRITON',
    'WESTOVER',
    'WEST PITTSTON',
    'WEST READING',
    'WEST SUNBURY',
    'WEST VIEW',
    'WEST WYOMING',
    'WEST WYOMISSING',
    'WEST YORK',
    'WHEATLAND',
    'WHITAKER',
    'WHITEHALL',
    'WHITE HAVEN',
    'WHITE OAK',
    'WHITFIELD',
    'WICKERHAM MANOR-FISHER',
    'WILBURTON NUMBER ONE',
    'WILBURTON NUMBER TWO',
    'WILKES-BARRE',
    'WILKES-BARRE TOWNSHIP',
    'WILKINSBURG',
    'WILKINS TOWNSHIP',
    'WILLIAMSBURG',
    'WILLIAMSPORT',
    'WILLIAMSTOWN',
    'WILLOW GROVE',
    'WILLOW STREET',
    'WILMERDING',
    'WILMORE',
    'WILSON',
    'WINDBER',
    'WIND GAP',
    'WINDSOR',
    'WINTERSTOWN',
    'WOLFDALE',
    'WOMELSDORF',
    'WOODBOURNE',
    'WOODBURY',
    'WOODCOCK',
    'WOODLAND HEIGHTS',
    'WOODLYN',
    'WOODSIDE',
    'WOODWARD',
    'WORMLEYSBURG',
    'WORTHINGTON',
    'WORTHVILLE',
    'WRIGHTSVILLE',
    'WYALUSING',
    'WYNCOTE',
    'WYNDMOOR',
    'WYOMING',
    'WYOMISSING',
    'WYOMISSING HILLS',
    'YARDLEY',
    'YATESVILLE',
    'YEADON',
    'YEAGERTOWN',
    'YOE',
    'YORK',
    'YORKANA',
    'YORK HAVEN',
    'YORK SPRINGS',
    'YOUNGSTOWN',
    'YOUNGSVILLE',
    'YOUNGWOOD',
    'ZELIENOPLE',
    'ZION',
  ],
  'Puerto Rico': [
    'ACEITUNAS',
    'ADJUNTAS',
    'AGUADA',
    'AGUADILLA',
    'AGUAS BUENAS',
    'AGUAS CLARAS',
    'AGUILITA',
    'AIBONITO',
    'ANASCO',
    'ANIMAS',
    'ANTON RUIZ',
    'ARECIBO',
    'ARROYO',
    'BAIROA',
    'BAJADERO',
    'BAJANDAS',
    'BARAHONA',
    'BARCELONETA',
    'BARRANQUITAS',
    'BARTOLO',
    'BAYAMON',
    'BAYAMON',
    'BENITEZ',
    'BETANCES',
    'BOQUERON',
    'BOQUERON',
    'BRENAS',
    'BUENA VISTA',
    'BUFALO',
    'CABAN',
    'CABO ROJO',
    'CACAO',
    'CAGUAS',
    'CAMPANILLA',
    'CAMPO RICO',
    'CAMUY',
    'CANDELARIA',
    'CANDELARIA ARENAS',
    'CANDELERO ARRIBA',
    'CANOVANAS',
    'CAPITANEJO',
    'CAROLINA',
    'CARRIZALES',
    'CATANO',
    'CAYEY',
    'CAYUCO',
    'CEIBA',
    'CEIBA',
    'CELADA',
    'CENTRAL AGUIRRE',
    'CIALES',
    'CIDRA',
    'COAMO',
    'COCO',
    'COMERIO',
    'COMUNAS',
    'COQUI',
    'CORAZON',
    'CORCOVADO',
    'COROZAL',
    'COTO LAUREL',
    'COTO NORTE',
    'CULEBRA',
    'DAGUAO',
    'DORADO',
    'DUQUE',
    'EL MANGO',
    'EL NEGRO',
    'EL OJO',
    'EMAJAGUA',
    'ESPERANZA',
    'ESPINO',
    'FAJARDO',
    'FLORIDA',
    'FRANQUEZ',
    'FUIG',
    'GALATEO',
    'GARROCHALES',
    'G. L. GARCIA',
    'GUANICA',
    'GUAYABAL',
    'GUAYAMA',
    'GUAYANILLA',
    'GUAYNABO',
    'GURABO',
    'HATILLO',
    'HATO ARRIBA',
    'HATO CANDAL',
    'HORMIGUEROS',
    'H. RIVERA COLON',
    'HUMACAO',
    'IMBERY',
    'INDIOS',
    'INGENIO',
    'ISABELA',
    'JAGUAL',
    'JAUCA',
    'JAYUYA',
    'JOBOS',
    'JUANA DIAZ',
    'JUNCAL',
    'JUNCOS',
    'LA ALIANZA',
    'LA DOLORES',
    'LA FERMINA',
    'LAJAS',
    'LA LUISA',
    'LAMBOGLIA',
    'LA PARGUERA',
    'LA PLAYA',
    'LA PLENA',
    'LARES',
    'LAS MARIAS',
    'LAS MARIAS',
    'LAS OCHENTA',
    'LAS OLLAS',
    'LAS PIEDRAS',
    'LEVITTOWN',
    'LIBORIO NEGRON TORRES',
    'LLUVERAS',
    'LOIZA',
    'LOMAS',
    'LOS LLANOS',
    'LUIS LLORENS TORRES',
    'LUIS M. CINTRON',
    'LUQUILLO',
    'LUYANDO',
    'MAGAS ARRIBA',
    'MANATI',
    'MARIA ANTONIA',
    'MARIANO COLON',
    'MARICAO',
    'MARTORELL',
    'MARUENO',
    'MAUNABO',
    'MAYAGUEZ',
    'MIRANDA',
    'MOCA',
    'MONSERRATE',
    'MONTE GRANDE',
    'MORA',
    'MOROVIS',
    'MUCARABONES',
    'NAGUABO',
    'NARANJITO',
    'OLIMPO',
    'OROCOVIS',
    'PAJAROS',
    'PAJONAL',
    'PALMAREJO',
    'PALMAREJO',
    'PALMAS',
    'PALMER',
    'PALOMAS',
    'PALOMAS',
    'PALO SECO',
    'PARCELAS LA MILAGROSA',
    'PARCELAS NUEVAS',
    'PARCELAS PENUELAS',
    'PASTOS',
    'PATILLAS',
    'PENA POBRE',
    'PENUELAS',
    'PIEDRA GORDA',
    'PLAYA FORTUNA',
    'PLAYITA',
    'PLAYITA',
    'PLAYITA CORTADA',
    'POLE OJEA',
    'PONCE',
    'POTALA PASTILLO',
    'PUEBLITO DEL RIO',
    'PUERTO REAL',
    'PUNTA SANTIAGO',
    'QUEBRADA',
    'QUEBRADILLAS',
    'RAFAEL CAPO',
    'RAFAEL GONZALEZ',
    'RAFAEL HERNANDEZ',
    'RAMOS',
    'RINCON',
    'RIO BLANCO',
    'RIO CANAS ABAJO',
    'RIO GRANDE',
    'RIO LAJAS',
    'ROOSEVELT ROADS',
    'ROSA SANCHEZ',
    'SABANA',
    'SABANA ENEAS',
    'SABANA GRANDE',
    'SABANA HOYOS',
    'SABANA SECA',
    'SALINAS',
    'SAN ANTONIO',
    'SAN ANTONIO',
    'SAN ANTONIO',
    'SAN GERMAN',
    'SAN ISIDRO',
    'SAN JOSE',
    'SAN JUAN',
    'SAN LORENZO',
    'SAN SEBASTIAN',
    'SANTA BARBARA',
    'SANTA CLARA',
    'SANTA ISABEL',
    'SANTO DOMINGO',
    'STELLA',
    'SUAREZ',
    'TALLABOA',
    'TALLABOA ALTA',
    'TIBURONES',
    'TIERRAS NUEVAS PONIENTE',
    'TOA ALTA',
    'TOA BAJA',
    'TRUJILLO ALTO',
    'UTUADO',
    'VAZQUEZ',
    'VEGA ALTA',
    'VEGA BAJA',
    'VIEQUES',
    'VIEQUES',
    'VILLALBA',
    'YABUCOA',
    'YAUCO',
    'YAUREL',
  ],
  'Rhode Island': [
    'ASHAWAY',
    'BARRINGTON',
    'BRADFORD',
    'BRISTOL',
    'CENTRAL FALLS',
    'CRANSTON',
    'CUMBERLAND HILL',
    'EAST PROVIDENCE',
    'GREENVILLE',
    'HARRISVILLE',
    'HOPE VALLEY',
    'KINGSTON',
    'MELVILLE',
    'NARRAGANSETT PIER',
    'NEWPORT',
    'NEWPORT EAST',
    'NORTH PROVIDENCE',
    'PASCOAG',
    'PAWTUCKET',
    'PROVIDENCE',
    'TIVERTON',
    'VALLEY FALLS',
    'WAKEFIELD-PEACEDALE',
    'WARWICK',
    'WESTERLY',
    'WEST WARWICK',
    'WOONSOCKET',
  ],
  'South Carolina': [
    'ABBEVILLE',
    'AIKEN',
    'ALLENDALE',
    'ANDERSON',
    'ANDREWS',
    'ANTREVILLE',
    'ARCADIA LAKES',
    'ARIAL',
    'ATLANTIC BEACH',
    'AWENDAW',
    'AYNOR',
    'BAMBERG',
    'BARNWELL',
    'BATESBURG-LEESVILLE',
    'BEAUFORT',
    'BELTON',
    'BELVEDERE',
    'BENNETTSVILLE',
    'BEREA',
    'BETHUNE',
    'BISHOPVILLE',
    'BLACKSBURG',
    'BLACKVILLE',
    'BLENHEIM',
    'BLUFFTON',
    'BLYTHEWOOD',
    'BOILING SPRINGS',
    'BONNEAU',
    'BOWMAN',
    'BRADLEY',
    'BRANCHVILLE',
    'BRIARCLIFFE ACRES',
    'BROOKDALE',
    'BRUNSON',
    'BUCKSPORT',
    'BUFFALO',
    'BURNETTOWN',
    'BURTON',
    'CALHOUN FALLS',
    'CAMDEN',
    'CAMERON',
    'CAMPOBELLO',
    'CANE SAVANNAH',
    'CARLISLE',
    'CAYCE',
    'CENTERVILLE',
    'CENTRAL',
    'CENTRAL PACOLET',
    'CHAPIN',
    'CHARLESTON',
    'CHERAW',
    'CHERRYVALE',
    'CHESNEE',
    'CHESTER',
    'CHESTERFIELD',
    'CITY VIEW',
    'CLARKS HILL',
    'CLEARWATER',
    'CLEMSON',
    'CLINTON',
    'CLIO',
    'CLOVER',
    'COKESBURY',
    'COLUMBIA',
    'CONWAY',
    'COPE',
    'CORDOVA',
    'CORONACA',
    'COTTAGEVILLE',
    'COWARD',
    'COWPENS',
    'CROSS HILL',
    'DALZELL',
    'DARLINGTON',
    'DENMARK',
    'DENTSVILLE',
    'DILLON',
    'DONALDS',
    'DUE WEST',
    'DUNCAN',
    'DUNEAN',
    'EASLEY',
    'EAST GAFFNEY',
    'EASTOVER',
    'EAST SUMTER',
    'EDGEFIELD',
    'EDISTO',
    'EDISTO BEACH',
    'EHRHARDT',
    'ELGIN',
    'ELGIN',
    'ELKO',
    'ELLOREE',
    'ESTILL',
    'EUREKA MILL',
    'EUTAWVILLE',
    'FAIRFAX',
    'FIVE FORKS',
    'FLORENCE',
    'FOLLY BEACH',
    'FOREST ACRES',
    'FORESTBROOK',
    'FORT LAWN',
    'FORT MILL',
    'FOUNTAIN INN',
    'FURMAN',
    'GAFFNEY',
    'GANTT',
    'GARDEN CITY',
    'GASTON',
    'GAYLE MILL',
    'GEORGETOWN',
    'GIFFORD',
    'GILBERT',
    'GLOVERVILLE',
    'GOLDEN GROVE',
    'GOOSE CREEK',
    'GOVAN',
    'GRAY COURT',
    'GREAT FALLS',
    'GREELEYVILLE',
    'GREENVILLE',
    'GREENWOOD',
    'GREER',
    'HAMPTON',
    'HANAHAN',
    'HARDEEVILLE',
    'HARLEYVILLE',
    'HARTSVILLE',
    'HEATH SPRINGS',
    'HEMINGWAY',
    'HICKORY GROVE',
    'HILDA',
    'HILTON HEAD ISLAND',
    'HODGES',
    'HOLLY HILL',
    'HOLLYWOOD',
    'HOMELAND PARK',
    'HONEA PATH',
    'INDIA HOOK',
    'INMAN',
    'INMAN MILLS',
    'IRMO',
    'IRWIN',
    'ISLE OF PALMS',
    'IVA',
    'JACKSON',
    'JAMESTOWN',
    'JEFFERSON',
    'JOANNA',
    'JOHNSONVILLE',
    'JOHNSTON',
    'JONESVILLE',
    'JUDSON',
    'KERSHAW',
    'KIAWAH ISLAND',
    'KINGSTREE',
    'KLINE',
    'LADSON',
    'LAKE CITY',
    'LAKE MURRAY OF RICHLAND',
    'LAKE SECESSION',
    'LAKE VIEW',
    'LAKEWOOD',
    'LAKE WYLIE',
    'LAMAR',
    'LANCASTER',
    'LANCASTER MILL',
    'LANDRUM',
    'LANE',
    'LATTA',
    'LAUREL BAY',
    'LAURENS',
    'LESSLIE',
    'LEXINGTON',
    'LIBERTY',
    'LINCOLNVILLE',
    'LITTLE MOUNTAIN',
    'LITTLE RIVER',
    'LIVINGSTON',
    'LOCKHART',
    'LODGE',
    'LORIS',
    'LOWNDESVILLE',
    'LOWRYS',
    'LUGOFF',
    'LURAY',
    'LYMAN',
    'LYNCHBURG',
    'MCBEE',
    'MCCLELLANVILLE',
    'MCCOLL',
    'MCCONNELLS',
    'MCCORMICK',
    'MANNING',
    'MARION',
    'MAULDIN',
    'MAYESVILLE',
    'MAYO',
    'MEGGETT',
    'MILLWOOD',
    'MODOC',
    'MONARCH MILL',
    'MONCKS CORNER',
    'MONETTA',
    'MOUNT CARMEL',
    'MOUNT CROGHAN',
    'MOUNT PLEASANT',
    'MOUNTVILLE',
    'MULBERRY',
    'MULLINS',
    'MURPHYS ESTATES',
    'MURRELLS INLET',
    'MYRTLE BEACH',
    'NEESES',
    'NEWBERRY',
    'NEW ELLENTON',
    'NEWPORT',
    'NICHOLS',
    'NINETY SIX',
    'NORRIS',
    'NORTH',
    'NORTH AUGUSTA',
    'NORTH CHARLESTON',
    'NORTH HARTSVILLE',
    'NORTHLAKE',
    'NORTH MYRTLE BEACH',
    'NORWAY',
    'OAK GROVE',
    'OAKLAND',
    'OLANTA',
    'OLAR',
    'ORANGEBURG',
    'OSWEGO',
    'PACOLET',
    'PAGELAND',
    'PAMPLICO',
    'PARKER',
    'PARKSVILLE',
    'PARRIS ISLAND',
    'PATRICK',
    'PAWLEYS ISLAND',
    'PAXVILLE',
    'PEAK',
    'PELION',
    'PELZER',
    'PENDLETON',
    'PERRY',
    'PICKENS',
    'PIEDMONT',
    'PINE RIDGE',
    'PINEWOOD',
    'PLUM BRANCH',
    'POMARIA',
    'PORT ROYAL',
    'POWDERVILLE',
    'PRINCETON',
    'PRIVATEER',
    'PROMISED LAND',
    'PROSPERITY',
    'QUINBY',
    'RAVENEL',
    'RED BANK',
    'RED HILL',
    'REEVESVILLE',
    'REIDVILLE',
    'REMBERT',
    'RICHBURG',
    'RIDGELAND',
    'RIDGE SPRING',
    'RIDGEVILLE',
    'RIDGEWAY',
    'RIVERVIEW',
    'ROCK HILL',
    'ROCKVILLE',
    'ROEBUCK',
    'ROWESVILLE',
    'RUBY',
    'ST. ANDREWS',
    'ST. GEORGE',
    'ST. MATTHEWS',
    'ST. STEPHEN',
    'SALEM',
    'SALLEY',
    'SALUDA',
    'SANS SOUCI',
    'SANTEE',
    'SAXON',
    'SCOTIA',
    'SCRANTON',
    'SEABROOK ISLAND',
    'SELLERS',
    'SENECA',
    'SEVEN OAKS',
    'SHARON',
    'SHELL POINT',
    'SHILOH',
    'SILVERSTREET',
    'SIMPSONVILLE',
    'SIX MILE',
    'SLATER-MARIETTA',
    'SMOAKS',
    'SMYRNA',
    'SNELLING',
    'SOCASTEE',
    'SOCIETY HILL',
    'SOUTH CONGAREE',
    'SOUTHERN SHOPS',
    'SOUTH SUMTER',
    'SPARTANBURG',
    'SPRINGDALE',
    'SPRINGDALE',
    'SPRINGFIELD',
    'STARR',
    'STARTEX',
    'STATEBURG',
    'STUCKEY',
    'SULLIVAN S ISLAND',
    'SUMMERTON',
    'SUMMERVILLE',
    'SUMMIT',
    'SUMTER',
    'SURFSIDE BEACH',
    'SWANSEA',
    'SYCAMORE',
    'TATUM',
    'TAYLORS',
    'TEGA CAY',
    'TIMMONSVILLE',
    'TRAVELERS REST',
    'TRENTON',
    'TROY',
    'TURBEVILLE',
    'ULMER',
    'UNION',
    'UTICA',
    'VALLEY FALLS',
    'VANCE',
    'VARNVILLE',
    'WADE HAMPTON',
    'WAGENER',
    'WALHALLA',
    'WALTERBORO',
    'WARD',
    'WARE SHOALS',
    'WATERLOO',
    'WATTS MILLS',
    'WEDGEWOOD',
    'WELCOME',
    'WELLFORD',
    'WEST COLUMBIA',
    'WESTMINSTER',
    'WEST PELZER',
    'WEST UNION',
    'WHITMIRE',
    'WILKINSON HEIGHTS',
    'WILLIAMS',
    'WILLIAMSTON',
    'WILLINGTON',
    'WILLISTON',
    'WINDSOR',
    'WINNSBORO',
    'WINNSBORO MILLS',
    'WOODFIELD',
    'WOODFORD',
    'WOODRUFF',
    'YEMASSEE',
    'YORK',
  ],
  'South Dakota': [
    'ABERDEEN',
    'AGAR',
    'AKASKA',
    'ALBEE',
    'ALCESTER',
    'ALEXANDRIA',
    'ALLEN',
    'ALPENA',
    'ALTAMONT',
    'ANDOVER',
    'ANTELOPE',
    'ARLINGTON',
    'ARMOUR',
    'ARTAS',
    'ARTESIAN',
    'ASHLAND HEIGHTS',
    'ASHTON',
    'ASTORIA',
    'AURORA',
    'AURORA CENTER',
    'AVON',
    'BADGER',
    'BALTIC',
    'BANCROFT',
    'BATESLAND',
    'BELLE FOURCHE',
    'BELVIDERE',
    'BERESFORD',
    'BIG STONE CITY',
    'BISON',
    'BLACKHAWK',
    'BLUNT',
    'BONESTEEL',
    'BOWDLE',
    'BOX ELDER',
    'BRADLEY',
    'BRANDON',
    'BRANDT',
    'BRENTFORD',
    'BRIDGEWATER',
    'BRISTOL',
    'BRITTON',
    'BROADLAND',
    'BROOKINGS',
    'BRUCE',
    'BRYANT',
    'BUFFALO',
    'BUFFALO GAP',
    'BULLHEAD',
    'BURKE',
    'BUSHNELL',
    'BUTLER',
    'CAMP CROOK',
    'CANISTOTA',
    'CANOVA',
    'CANTON',
    'CARTHAGE',
    'CASTLEWOOD',
    'CAVOUR',
    'CENTERVILLE',
    'CENTRAL CITY',
    'CHAMBERLAIN',
    'CHANCELLOR',
    'CHELSEA',
    'CLAIRE CITY',
    'CLAREMONT',
    'CLARK',
    'CLEAR LAKE',
    'COLMAN',
    'COLOME',
    'COLONIAL PINE HILLS',
    'COLTON',
    'COLUMBIA',
    'CONDE',
    'CORONA',
    'CORSICA',
    'COTTONWOOD',
    'CRESBARD',
    'CROOKS',
    'CUSTER',
    'DALLAS',
    'DANTE',
    'DAVIS',
    'DEADWOOD',
    'DELL RAPIDS',
    'DELMONT',
    'DE SMET',
    'DIMOCK',
    'DOLAND',
    'DOLTON',
    'DRAPER',
    'DUPREE',
    'EAGLE BUTTE',
    'EDEN',
    'EDGEMONT',
    'EGAN',
    'ELK POINT',
    'ELKTON',
    'ELLSWORTH AFB',
    'EMERY',
    'ERWIN',
    'ESTELLINE',
    'ETHAN',
    'EUREKA',
    'FAIRBURN',
    'FAIRFAX',
    'FAIRVIEW',
    'FAITH',
    'FARMER',
    'FAULKTON',
    'FLANDREAU',
    'FLORENCE',
    'FORT PIERRE',
    'FORT THOMPSON',
    'FRANKFORT',
    'FREDERICK',
    'FREEMAN',
    'FRUITDALE',
    'FULTON',
    'GARDEN CITY',
    'GARRETSON',
    'GARY',
    'GAYVILLE',
    'GEDDES',
    'GETTYSBURG',
    'GLENHAM',
    'GOODWIN',
    'GREEN GRASS',
    'GREEN VALLEY',
    'GREGORY',
    'GRENVILLE',
    'GROTON',
    'HAMILL',
    'HARRISBURG',
    'HARRISON',
    'HARROLD',
    'HARTFORD',
    'HAYTI',
    'HAZEL',
    'HECLA',
    'HENRY',
    'HERMOSA',
    'HERREID',
    'HERRICK',
    'HETLAND',
    'HIGHMORE',
    'HILL CITY',
    'HILLSVIEW',
    'HITCHCOCK',
    'HOSMER',
    'HOT SPRINGS',
    'HOVEN',
    'HOWARD',
    'HUDSON',
    'HUMBOLDT',
    'HURLEY',
    'HURON',
    'INTERIOR',
    'IPSWICH',
    'IRENE',
    'IROQUOIS',
    'ISABEL',
    'JAVA',
    'JEFFERSON',
    'KADOKA',
    'KAYLOR',
    'KENNEBEC',
    'KEYSTONE',
    'KIMBALL',
    'KRANZBURG',
    'KYLE',
    'LA BOLT',
    'LAKE ANDES',
    'LAKE CITY',
    'LAKE NORDEN',
    'LAKE PRESTON',
    'LANE',
    'LANGFORD',
    'LA PLANT',
    'LEAD',
    'LEBANON',
    'LEMMON',
    'LENNOX',
    'LEOLA',
    'LESTERVILLE',
    'LETCHER',
    'LILY',
    'LITTLE EAGLE',
    'LONG LAKE',
    'LOOMIS',
    'LOWER BRULE',
    'LOWRY',
    'MCINTOSH',
    'MCLAUGHLIN',
    'MADISON',
    'MANDERSON-WHITE HORSE CREEK',
    'MARION',
    'MARTIN',
    'MARTY',
    'MARVIN',
    'MELLETTE',
    'MENNO',
    'MIDLAND',
    'MILBANK',
    'MILLER',
    'MILLTOWN',
    'MISSION',
    'MISSION HILL',
    'MITCHELL',
    'MOBRIDGE',
    'MONROE',
    'MONTROSE',
    'MORRISTOWN',
    'MOUND CITY',
    'MOUNT VERNON',
    'MURDO',
    'NAPLES',
    'NEW EFFINGTON',
    'NEWELL',
    'NEW HOLLAND',
    'NEW UNDERWOOD',
    'NEW WITTEN',
    'NISLAND',
    'NORTH EAGLE BUTTE',
    'NORTH SIOUX CITY',
    'NORTH SPEARFISH',
    'NORTHVILLE',
    'NUNDA',
    'OACOMA',
    'OELRICHS',
    'OGLALA',
    'OKATON',
    'OLDHAM',
    'OLIVET',
    'ONAKA',
    'ONIDA',
    'ORIENT',
    'ORTLEY',
    'PARKER',
    'PARKSTON',
    'PARMELEE',
    'PEEVER',
    'PHILIP',
    'PICKSTOWN',
    'PIERPONT',
    'PIERRE',
    'PINE RIDGE',
    'PLANKINTON',
    'PLATTE',
    'POLLOCK',
    'PORCUPINE',
    'PRESHO',
    'PRINGLE',
    'PUKWANA',
    'QUINN',
    'RAMONA',
    'RAPID CITY',
    'RAPID VALLEY',
    'RAVINIA',
    'RAYMOND',
    'REDFIELD',
    'REE HEIGHTS',
    'RELIANCE',
    'REVILLO',
    'ROCKHAM',
    'ROSCOE',
    'ROSEBUD',
    'ROSHOLT',
    'ROSLYN',
    'ROSWELL',
    'ST. CHARLES',
    'ST. FRANCIS',
    'ST. LAWRENCE',
    'SALEM',
    'SCOTLAND',
    'SELBY',
    'SENECA',
    'SHERMAN',
    'SINAI',
    'SIOUX FALLS',
    'SISSETON',
    'SOUTH SHORE',
    'SPEARFISH',
    'SPENCER',
    'SPRING CREEK',
    'SPRINGFIELD',
    'STICKNEY',
    'STOCKHOLM',
    'STORLA',
    'STRANDBURG',
    'STRATFORD',
    'STURGIS',
    'SUMMIT',
    'TABOR',
    'TEA',
    'TIMBER LAKE',
    'TOLSTOY',
    'TORONTO',
    'TRENT',
    'TRIPP',
    'TULARE',
    'TURTON',
    'TWIN BROOKS',
    'TWO STRIKE',
    'TYNDALL',
    'UTICA',
    'VALLEY SPRINGS',
    'VEBLEN',
    'VERDON',
    'VERMILLION',
    'VIBORG',
    'VIENNA',
    'VILAS',
    'VIRGIL',
    'VIVIAN',
    'VOLGA',
    'VOLIN',
    'WAGNER',
    'WAKONDA',
    'WALL',
    'WALLACE',
    'WANBLEE',
    'WARD',
    'WARNER',
    'WASTA',
    'WATERTOWN',
    'WAUBAY',
    'WEBSTER',
    'WENTWORTH',
    'WESSINGTON',
    'WESSINGTON SPRINGS',
    'WESTPORT',
    'WETONKA',
    'WHITE',
    'WHITEHORSE',
    'WHITE HORSE',
    'WHITE LAKE',
    'WHITE RIVER',
    'WHITE ROCK',
    'WHITEWOOD',
    'WILLOW LAKE',
    'WILMOT',
    'WINNER',
    'WOLSEY',
    'WOOD',
    'WOONSOCKET',
    'WORTHING',
    'WOUNDED KNEE',
    'YALE',
    'YANKTON',
  ],
  Tennessee: [
    'ADAMS',
    'ADAMSVILLE',
    'ALAMO',
    'ALCOA',
    'ALEXANDRIA',
    'ALGOOD',
    'ALLARDT',
    'ALTAMONT',
    'ARDMORE',
    'ARLINGTON',
    'ASHLAND CITY',
    'ATHENS',
    'ATOKA',
    'ATWOOD',
    'AUBURNTOWN',
    'BAILEYTON',
    'BANEBERRY',
    'BANNER HILL',
    'BARTLETT',
    'BAXTER',
    'BEERSHEBA SPRINGS',
    'BELL BUCKLE',
    'BELLE MEADE',
    'BELLS',
    'BENTON',
    'BERRY HILL',
    'BETHEL SPRINGS',
    'BIG SANDY',
    'BLAINE',
    'BLOOMINGDALE',
    'BLOUNTVILLE',
    'BLUFF CITY',
    'BOLIVAR',
    'BRADEN',
    'BRADFORD',
    'BRENTWOOD',
    'BRIGHTON',
    'BRISTOL',
    'BROWNSVILLE',
    'BRUCETON',
    'BULLS GAP',
    'BURLISON',
    'BURNS',
    'BYRDSTOWN',
    'CALHOUN',
    'CAMDEN',
    'CARTHAGE',
    'CARYVILLE',
    'CEDAR HILL',
    'CELINA',
    'CENTERTOWN',
    'CENTERVILLE',
    'CENTRAL',
    'CHAPEL HILL',
    'CHARLESTON',
    'CHARLOTTE',
    'CHATTANOOGA',
    'CHURCH HILL',
    'CLARKSBURG',
    'CLARKSVILLE',
    'CLEVELAND',
    'CLIFTON',
    'CLINTON',
    'COALMONT',
    'COLLEGEDALE',
    'COLLIERVILLE',
    'COLLINWOOD',
    'COLONIAL HEIGHTS',
    'COLUMBIA',
    'COOKEVILLE',
    'COOPERTOWN',
    'COPPERHILL',
    'CORNERSVILLE',
    'COTTAGE GROVE',
    'COVINGTON',
    'COWAN',
    'CRAB ORCHARD',
    'CROSS PLAINS',
    'CROSSVILLE',
    'CRUMP',
    'CUMBERLAND CITY',
    'CUMBERLAND GAP',
    'DANDRIDGE',
    'DAYTON',
    'DECATUR',
    'DECATURVILLE',
    'DECHERD',
    'DICKSON',
    'DOVER',
    'DOWELLTOWN',
    'DOYLE',
    'DRESDEN',
    'DUCKTOWN',
    'DUNLAP',
    'DYER',
    'DYERSBURG',
    'EAGLETON VILLAGE',
    'EAGLEVILLE',
    'EAST BRAINERD',
    'EAST CLEVELAND',
    'EAST RIDGE',
    'EASTVIEW',
    'ELIZABETHTON',
    'ELKTON',
    'ENGLEWOOD',
    'ENVILLE',
    'ERIN',
    'ERWIN',
    'ESTILL SPRINGS',
    'ETHRIDGE',
    'ETOWAH',
    'FAIRFIELD GLADE',
    'FAIRMOUNT',
    'FAIRVIEW',
    'FALL BRANCH',
    'FARRAGUT',
    'FAYETTEVILLE',
    'FINGER',
    'FOREST HILLS',
    'FRANKLIN',
    'FRIENDSHIP',
    'FRIENDSVILLE',
    'GADSDEN',
    'GAINESBORO',
    'GALLATIN',
    'GALLAWAY',
    'GARLAND',
    'GATES',
    'GATLINBURG',
    'GERMANTOWN',
    'GIBSON',
    'GILT EDGE',
    'GLEASON',
    'GOODLETTSVILLE',
    'GORDONSVILLE',
    'GRAND JUNCTION',
    'GRAY',
    'GRAYSVILLE',
    'GREENBACK',
    'GREENBRIER',
    'GREENEVILLE',
    'GREENFIELD',
    'GREEN HILL',
    'GRUETLI-LAAGER',
    'GUYS',
    'HALLS',
    'HARRIMAN',
    'HARRISON',
    'HARROGATE-SHAWANEE',
    'HARTSVILLE',
    'HELENWOOD',
    'HENDERSON',
    'HENDERSONVILLE',
    'HENNING',
    'HENRY',
    'HICKORY VALLEY',
    'HICKORY WITHE',
    'HOHENWALD',
    'HOLLOW ROCK',
    'HOPEWELL',
    'HORNBEAK',
    'HORNSBY',
    'HUMBOLDT',
    'HUNTER',
    'HUNTINGDON',
    'HUNTLAND',
    'HUNTSVILLE',
    'IRON CITY',
    'JACKSBORO',
    'JACKSON',
    'JAMESTOWN',
    'JASPER',
    'JEFFERSON CITY',
    'JELLICO',
    'JOHNSON CITY',
    'JONESBOROUGH',
    'KENTON',
    'KIMBALL',
    'KINGSPORT',
    'KINGSTON',
    'KINGSTON SPRINGS',
    'KNOXVILLE',
    'LAFAYETTE',
    'LA FOLLETTE',
    'LA GRANGE',
    'LAKE CITY',
    'LAKELAND',
    'LAKESITE',
    'LAKE TANSI',
    'LAKEWOOD',
    'LA VERGNE',
    'LAWRENCEBURG',
    'LEBANON',
    'LENOIR CITY',
    'LEWISBURG',
    'LEXINGTON',
    'LIBERTY',
    'LINDEN',
    'LIVINGSTON',
    'LOBELVILLE',
    'LOOKOUT MOUNTAIN',
    'LORETTO',
    'LOUDON',
    'LOUISVILLE',
    'LUTTRELL',
    'LYNCHBURG',
    'LYNNVILLE',
    'MCEWEN',
    'MCKENZIE',
    'MCLEMORESVILLE',
    'MCMINNVILLE',
    'MADISONVILLE',
    'MANCHESTER',
    'MARTIN',
    'MARYVILLE',
    'MASCOT',
    'MASON',
    'MAURY CITY',
    'MAYNARDVILLE',
    'MEDINA',
    'MEDON',
    'MEMPHIS',
    'MICHIE',
    'MIDDLETON',
    'MIDDLE VALLEY',
    'MIDTOWN',
    'MIDWAY',
    'MILAN',
    'MILLEDGEVILLE',
    'MILLERSVILLE',
    'MILLINGTON',
    'MINOR HILL',
    'MITCHELLVILLE',
    'MONTEAGLE',
    'MONTEREY',
    'MORRISON',
    'MORRISTOWN',
    'MOSCOW',
    'MOSHEIM',
    'MOUNTAIN CITY',
    'MOUNT CARMEL',
    'MOUNT JULIET',
    'MOUNT PLEASANT',
    'MUNFORD',
    'MURFREESBORO',
    'NASHVILLE-DAVIDSON',
    'NEWBERN',
    'NEW HOPE',
    'NEW JOHNSONVILLE',
    'NEW MARKET',
    'NEWPORT',
    'NEW TAZEWELL',
    'NIOTA',
    'NOLENSVILLE',
    'NORMANDY',
    'NORRIS',
    'OAKDALE',
    'OAK GROVE',
    'OAK HILL',
    'OAKLAND',
    'OAK RIDGE',
    'OBION',
    'OLIVER SPRINGS',
    'ONEIDA',
    'OOLTEWAH',
    'ORLINDA',
    'ORME',
    'PALMER',
    'PARIS',
    'PARKERS CROSSROADS',
    'PARROTTSVILLE',
    'PARSONS',
    'PEGRAM',
    'PETERSBURG',
    'PHILADELPHIA',
    'PIGEON FORGE',
    'PIKEVILLE',
    'PINE CREST',
    'PIPERTON',
    'PITTMAN CENTER',
    'PLAINVIEW',
    'PLEASANT HILL',
    'PLEASANT VIEW',
    'PORTLAND',
    'POWELLS CROSSROADS',
    'PULASKI',
    'PURYEAR',
    'RAMER',
    'RED BANK',
    'RED BOILING SPRINGS',
    'RIDGELY',
    'RIDGESIDE',
    'RIDGETOP',
    'RIPLEY',
    'RIVES',
    'ROAN MOUNTAIN',
    'ROCKFORD',
    'ROCKWOOD',
    'ROGERSVILLE',
    'ROSSVILLE',
    'RURAL HILL',
    'RUTHERFORD',
    'RUTLEDGE',
    'ST. JOSEPH',
    'SALTILLO',
    'SAMBURG',
    'SARDIS',
    'SAULSBURY',
    'SAVANNAH',
    'SCOTTS HILL',
    'SELMER',
    'SEVIERVILLE',
    'SEWANEE',
    'SEYMOUR',
    'SHARON',
    'SHELBYVILLE',
    'SIGNAL MOUNTAIN',
    'SILERTON',
    'SLAYDEN',
    'SMITHVILLE',
    'SMYRNA',
    'SNEEDVILLE',
    'SODDY-DAISY',
    'SOMERVILLE',
    'SOUTH CARTHAGE',
    'SOUTH CLEVELAND',
    'SOUTH FULTON',
    'SOUTH PITTSBURG',
    'SPARTA',
    'SPENCER',
    'SPRING CITY',
    'SPRINGFIELD',
    'SPRING HILL',
    'SPURGEON',
    'STANTON',
    'STANTONVILLE',
    'SUNBRIGHT',
    'SURGOINSVILLE',
    'SWEETWATER',
    'TAZEWELL',
    'TELLICO PLAINS',
    'TENNESSEE RIDGE',
    'THOMPSON S STATION',
    'THREE WAY',
    'TIPTONVILLE',
    'TOONE',
    'TOWNSEND',
    'TRACY CITY',
    'TRENTON',
    'TREZEVANT',
    'TRIMBLE',
    'TROY',
    'TULLAHOMA',
    'TUSCULUM',
    'UNICOI',
    'UNION CITY',
    'VANLEER',
    'VIOLA',
    'VONORE',
    'WALDEN',
    'WALNUT GROVE',
    'WALNUT HILL',
    'WALTERHILL',
    'WARTBURG',
    'WARTRACE',
    'WATAUGA',
    'WATERTOWN',
    'WAVERLY',
    'WAYNESBORO',
    'WESTMORELAND',
    'WHITE BLUFF',
    'WHITE HOUSE',
    'WHITE PINE',
    'WHITEVILLE',
    'WHITWELL',
    'WILDWOOD LAKE',
    'WILLISTON',
    'WINCHESTER',
    'WINFIELD',
    'WOODBURY',
    'WOODLAND MILLS',
    'YORKVILLE',
  ],
  Texas: [
    'ABBOTT',
    'ABERNATHY',
    'ABILENE',
    'ABRAM-PEREZVILLE',
    'ACKERLY',
    'ADDISON',
    'ADRIAN',
    'AGUA DULCE',
    'AGUA DULCE',
    'AIRPORT ROAD ADDITION',
    'ALAMO',
    'ALAMO HEIGHTS',
    'ALBA',
    'ALBANY',
    'ALDINE',
    'ALEDO',
    'ALFRED-SOUTH LA PALOMA',
    'ALICE',
    'ALICE ACRES',
    'ALLEN',
    'ALMA',
    'ALPINE',
    'ALTO',
    'ALTO BONITO',
    'ALTON',
    'ALTON NORTH',
    'ALVARADO',
    'ALVIN',
    'ALVORD',
    'AMARILLO',
    'AMES',
    'AMHERST',
    'ANAHUAC',
    'ANDERSON',
    'ANDERSON MILL',
    'ANDREWS',
    'ANGLETON',
    'ANGUS',
    'ANNA',
    'ANNETTA',
    'ANNETTA NORTH',
    'ANNETTA SOUTH',
    'ANNONA',
    'ANSON',
    'ANTHONY',
    'ANTON',
    'APPLEBY',
    'AQUILLA',
    'ARANSAS PASS',
    'ARCHER CITY',
    'ARCOLA',
    'ARGYLE',
    'ARLINGTON',
    'ARP',
    'ARROYO ALTO',
    'ARROYO COLORADO ESTATES',
    'ARROYO GARDENS-LA TINA RANCH',
    'ASHERTON',
    'ASPERMONT',
    'ATASCOCITA',
    'ATHENS',
    'ATLANTA',
    'AUBREY',
    'AURORA',
    'AUSTIN',
    'AUSTWELL',
    'AVERY',
    'AVINGER',
    'AZLE',
    'BACLIFF',
    'BAILEY',
    'BAILEY S PRAIRIE',
    'BAIRD',
    'BALCH SPRINGS',
    'BALCONES HEIGHTS',
    'BALLINGER',
    'BALMORHEA',
    'BANDERA',
    'BANGS',
    'BARDWELL',
    'BARRETT',
    'BARRY',
    'BARSTOW',
    'BARTLETT',
    'BARTON CREEK',
    'BARTONVILLE',
    'BASTROP',
    'BATESVILLE',
    'BAUSELL AND ELLIS',
    'BAY CITY',
    'BAYOU VISTA',
    'BAYSIDE',
    'BAYTOWN',
    'BAYVIEW',
    'BEACH CITY',
    'BEAR CREEK',
    'BEASLEY',
    'BEAUMONT',
    'BECKVILLE',
    'BEDFORD',
    'BEE CAVE',
    'BEEVILLE',
    'BELLAIRE',
    'BELLEVUE',
    'BELLMEAD',
    'BELLS',
    'BELLVILLE',
    'BELTON',
    'BENAVIDES',
    'BENBROOK',
    'BENJAMIN',
    'BERRYVILLE',
    'BERTRAM',
    'BEVERLY HILLS',
    'BEVIL OAKS',
    'BIGFOOT',
    'BIG LAKE',
    'BIG SANDY',
    'BIG SPRING',
    'BIG WELLS',
    'BISHOP',
    'BISHOP HILLS',
    'BIXBY',
    'BLACKWELL',
    'BLANCO',
    'BLANKET',
    'BLESSING',
    'BLOOMBURG',
    'BLOOMING GROVE',
    'BLOOMINGTON',
    'BLOSSOM',
    'BLUE BERRY HILL',
    'BLUE MOUND',
    'BLUE RIDGE',
    'BLUETOWN-IGLESIA ANTIGUA',
    'BLUM',
    'BOERNE',
    'BOGATA',
    'BOLING-IAGO',
    'BOLIVAR PENINSULA',
    'BONHAM',
    'BONNEY',
    'BOOKER',
    'BORGER',
    'BOTINES',
    'BOVINA',
    'BOWIE',
    'BOX CANYON-AMISTAD',
    'BOYD',
    'BRACKETTVILLE',
    'BRADY',
    'BRAZORIA',
    'BRECKENRIDGE',
    'BREMOND',
    'BRENHAM',
    'BRIAR',
    'BRIARCLIFF',
    'BRIAROAKS',
    'BRIDGE CITY',
    'BRIDGEPORT',
    'BROADDUS',
    'BRONTE',
    'BROOKSHIRE',
    'BROOKSIDE VILLAGE',
    'BROWNDELL',
    'BROWNFIELD',
    'BROWNSBORO',
    'BROWNSVILLE',
    'BROWNWOOD',
    'BRUCEVILLE-EDDY',
    'BRUNDAGE',
    'BRUNI',
    'BRUSHY CREEK',
    'BRYAN',
    'BRYSON',
    'BUCHANAN DAM',
    'BUCKHOLTS',
    'BUDA',
    'BUFFALO',
    'BUFFALO GAP',
    'BUFFALO SPRINGS',
    'BULLARD',
    'BULVERDE',
    'BUNA',
    'BUNKER HILL VILLAGE',
    'BURKBURNETT',
    'BURKE',
    'BURLESON',
    'BURNET',
    'BURTON',
    'BUTTERFIELD',
    'BYERS',
    'BYNUM',
    'CACTUS',
    'CADDO MILLS',
    'CALDWELL',
    'CALLISBURG',
    'CALVERT',
    'CAMERON',
    'CAMERON PARK',
    'CAMPBELL',
    'CAMP SWIFT',
    'CAMP WOOD',
    'CANADIAN',
    'CANEY CITY',
    'CANTON',
    'CANTU ADDITION',
    'CANUTILLO',
    'CANYON',
    'CANYON LAKE',
    'CARBON',
    'CARL S CORNER',
    'CARMINE',
    'CARRIZO HILL',
    'CARRIZO SPRINGS',
    'CARROLLTON',
    'CARTHAGE',
    'CASTLE HILLS',
    'CASTROVILLE',
    'CATARINA',
    'CEDAR HILL',
    'CEDAR PARK',
    'CELESTE',
    'CELINA',
    'CENTER',
    'CENTERVILLE',
    'CENTRAL GARDENS',
    'CESAR CHAVEZ',
    'CHANDLER',
    'CHANNELVIEW',
    'CHANNING',
    'CHARLOTTE',
    'CHESTER',
    'CHICO',
    'CHILDRESS',
    'CHILLICOTHE',
    'CHINA',
    'CHINA GROVE',
    'CHIRENO',
    'CHRISTINE',
    'CHRISTOVAL',
    'CHULA VISTA-ORASON',
    'CHULA VISTA-RIVER SPUR',
    'CIBOLO',
    'CIENEGAS TERRACE',
    'CINCO RANCH',
    'CIRCLE D-KC ESTATES',
    'CISCO',
    'CITRUS CITY',
    'CLARENDON',
    'CLARKSVILLE',
    'CLARKSVILLE CITY',
    'CLAUDE',
    'CLEAR LAKE SHORES',
    'CLEBURNE',
    'CLEVELAND',
    'CLIFTON',
    'CLINT',
    'CLOVERLEAF',
    'CLUTE',
    'CLYDE',
    'COAHOMA',
    'COCKRELL HILL',
    'COFFEE CITY',
    'COLDSPRING',
    'COLEMAN',
    'COLLEGE STATION',
    'COLLEYVILLE',
    'COLLINSVILLE',
    'COLMESNEIL',
    'COLORADO CITY',
    'COLUMBUS',
    'COMANCHE',
    'COMBES',
    'COMBINE',
    'COMFORT',
    'COMMERCE',
    'COMO',
    'CONCEPCION',
    'CONROE',
    'CONVERSE',
    'COOL',
    'COOLIDGE',
    'COOPER',
    'COPPELL',
    'COPPERAS COVE',
    'COPPER CANYON',
    'CORINTH',
    'CORPUS CHRISTI',
    'CORRAL CITY',
    'CORRIGAN',
    'CORSICANA',
    'COTTONWOOD',
    'COTTONWOOD SHORES',
    'COTULLA',
    'COVE',
    'COVINGTON',
    'COYANOSA',
    'COYOTE ACRES',
    'CRANDALL',
    'CRANE',
    'CRANFILLS GAP',
    'CRAWFORD',
    'CREEDMOOR',
    'CROCKETT',
    'CROSBY',
    'CROSBYTON',
    'CROSS MOUNTAIN',
    'CROSS PLAINS',
    'CROSS ROADS',
    'CROSS TIMBER',
    'CROWELL',
    'CROWLEY',
    'CRYSTAL CITY',
    'CUERO',
    'CUEVITAS',
    'CUMBY',
    'CUMINGS',
    'CUNEY',
    'CUSHING',
    'CUT AND SHOOT',
    'DAINGERFIELD',
    'DAISETTA',
    'DALHART',
    'DALLAS',
    'DALWORTHINGTON GARDENS',
    'DAMON',
    'DANBURY',
    'DARROUZETT',
    'DAWSON',
    'DAYTON',
    'DAYTON LAKES',
    'DEAN',
    'DECATUR',
    'DEER PARK',
    'DE KALB',
    'DE LEON',
    'DELL CITY',
    'DEL MAR HEIGHTS',
    'DEL RIO',
    'DEL SOL-LOMA LINDA',
    'DENISON',
    'DENTON',
    'DENVER CITY',
    'DEPORT',
    'DESOTO',
    'DETROIT',
    'DEVERS',
    'DEVINE',
    'DEWEYVILLE',
    'DIBOLL',
    'DICKENS',
    'DICKINSON',
    'DILLEY',
    'DIMMITT',
    'DODD CITY',
    'DODSON',
    'DOFFING',
    'DOMINO',
    'DONNA',
    'DOOLITTLE',
    'DORCHESTER',
    'DOUBLE OAK',
    'DOUGLASSVILLE',
    'DOYLE',
    'DRIPPING SPRINGS',
    'DRISCOLL',
    'DUBLIN',
    'DUMAS',
    'DUNCANVILLE',
    'EAGLE LAKE',
    'EAGLE MOUNTAIN',
    'EAGLE PASS',
    'EARLY',
    'EARTH',
    'EAST BERNARD',
    'EASTLAND',
    'EAST MOUNTAIN',
    'EASTON',
    'EAST TAWAKONI',
    'ECTOR',
    'EDCOUCH',
    'EDEN',
    'EDGECLIFF VILLAGE',
    'EDGEWATER-PAISANO',
    'EDGEWOOD',
    'EDINBURG',
    'EDMONSON',
    'EDNA',
    'EDOM',
    'EDROY',
    'EIDSON ROAD',
    'ELBERT',
    'EL CAMINO ANGOSTO',
    'EL CAMPO',
    'EL CENIZO',
    'ELDORADO',
    'ELECTRA',
    'ELGIN',
    'EL INDIO',
    'ELKHART',
    'EL LAGO',
    'ELM CREEK',
    'ELMENDORF',
    'EL PASO',
    'EL REFUGIO',
    'ELSA',
    'EMHOUSE',
    'EMORY',
    'ENCANTADA-RANCHITO EL CALABOZ',
    'ENCHANTED OAKS',
    'ENCINAL',
    'ENCINO',
    'ENNIS',
    'ESCOBARES',
    'ESTELLINE',
    'EULESS',
    'EUREKA',
    'EUSTACE',
    'EVADALE',
    'EVANT',
    'EVERMAN',
    'FABENS',
    'FAIRCHILDS',
    'FAIRFIELD',
    'FAIR OAKS RANCH',
    'FAIRVIEW',
    'FALCON HEIGHTS',
    'FALCON LAKE ESTATES',
    'FALCON MESA',
    'FALCON VILLAGE',
    'FALFURRIAS',
    'FALLS CITY',
    'FALMAN-COUNTY ACRES',
    'FARMERS BRANCH',
    'FARMERSVILLE',
    'FARWELL',
    'FATE',
    'FAYETTEVILLE',
    'FAYSVILLE',
    'FERRIS',
    'FIFTH STREET',
    'FLATONIA',
    'FLORENCE',
    'FLORESVILLE',
    'FLOWELLA',
    'FLOWER MOUND',
    'FLOYDADA',
    'FOLLETT',
    'FOREST HILL',
    'FORNEY',
    'FORSAN',
    'FORT BLISS',
    'FORT DAVIS',
    'FORT HANCOCK',
    'FORT HOOD',
    'FORT STOCKTON',
    'FORT WORTH',
    'FOUR CORNERS',
    'FOWLERTON',
    'FRANKLIN',
    'FRANKSTON',
    'FREDERICKSBURG',
    'FREEPORT',
    'FREER',
    'FRESNO',
    'FRIENDSWOOD',
    'FRIONA',
    'FRISCO',
    'FRITCH',
    'FRONTON',
    'FROST',
    'FRUITVALE',
    'FULSHEAR',
    'FULTON',
    'GAINESVILLE',
    'GALENA PARK',
    'GALLATIN',
    'GALVESTON',
    'GANADO',
    'GARCENO',
    'GARDENDALE',
    'GARDEN RIDGE',
    'GARFIELD',
    'GARLAND',
    'GARRETT',
    'GARRISON',
    'GARY CITY',
    'GATESVILLE',
    'GEORGETOWN',
    'GEORGE WEST',
    'GERONIMO',
    'GHOLSON',
    'GIDDINGS',
    'GILMER',
    'GIRARD',
    'GLADEWATER',
    'GLENN HEIGHTS',
    'GLEN ROSE',
    'GODLEY',
    'GOLDSMITH',
    'GOLDTHWAITE',
    'GOLIAD',
    'GOLINDA',
    'GONZALES',
    'GOODLOW',
    'GOODRICH',
    'GORDON',
    'GOREE',
    'GORMAN',
    'GRAFORD',
    'GRAHAM',
    'GRANBURY',
    'GRAND ACRES',
    'GRANDFALLS',
    'GRAND PRAIRIE',
    'GRAND SALINE',
    'GRANDVIEW',
    'GRANGER',
    'GRANITE SHOALS',
    'GRANJENO',
    'GRAPE CREEK',
    'GRAPELAND',
    'GRAPEVINE',
    'GRAYS PRAIRIE',
    'GREATWOOD',
    'GREEN VALLEY FARMS',
    'GREENVILLE',
    'GREGORY',
    'GREY FOREST',
    'GROESBECK',
    'GROOM',
    'GROVES',
    'GROVETON',
    'GRUVER',
    'GUERRA',
    'GUN BARREL CITY',
    'GUNTER',
    'GUSTINE',
    'HACKBERRY',
    'HALE CENTER',
    'HALLETTSVILLE',
    'HALLSBURG',
    'HALLSVILLE',
    'HALTOM CITY',
    'HAMILTON',
    'HAMLIN',
    'HAPPY',
    'HARDIN',
    'HARKER HEIGHTS',
    'HARLINGEN',
    'HARPER',
    'HART',
    'HARTLEY',
    'HASKELL',
    'HASLET',
    'HAVANA',
    'HAWK COVE',
    'HAWKINS',
    'HAWLEY',
    'HAYS',
    'HEARNE',
    'HEATH',
    'HEBBRONVILLE',
    'HEBRON',
    'HEDLEY',
    'HEDWIG VILLAGE',
    'HEIDELBERG',
    'HELOTES',
    'HEMPHILL',
    'HEMPSTEAD',
    'HENDERSON',
    'HENRIETTA',
    'HEREFORD',
    'HERMLEIGH',
    'HEWITT',
    'HICKORY CREEK',
    'HICO',
    'HIDALGO',
    'HIGGINS',
    'HIGHLAND HAVEN',
    'HIGHLAND PARK',
    'HIGHLANDS',
    'HIGHLAND VILLAGE',
    'HILL COUNTRY VILLAGE',
    'HILLCREST',
    'HILLSBORO',
    'HILLTOP',
    'HILSHIRE VILLAGE',
    'HITCHCOCK',
    'HOLIDAY LAKES',
    'HOLLAND',
    'HOLLIDAY',
    'HOLLYWOOD PARK',
    'HOMESTEAD MEADOWS NORTH',
    'HOMESTEAD MEADOWS SOUTH',
    'HONDO',
    'HONEY GROVE',
    'HOOKS',
    'HORIZON CITY',
    'HORSESHOE BAY',
    'HOUSTON',
    'HOWARDWICK',
    'HOWE',
    'HUBBARD',
    'HUDSON',
    'HUDSON BEND',
    'HUDSON OAKS',
    'HUGHES SPRINGS',
    'HUMBLE',
    'HUNGERFORD',
    'HUNTERS CREEK VILLAGE',
    'HUNTINGTON',
    'HUNTSVILLE',
    'HURST',
    'HUTCHINS',
    'HUTTO',
    'HUXLEY',
    'IDALOU',
    'IMPACT',
    'IMPERIAL',
    'INDIAN HILLS',
    'INDIAN LAKE',
    'INDUSTRY',
    'INEZ',
    'INGLESIDE',
    'INGLESIDE ON THE BAY',
    'INGRAM',
    'IOWA COLONY',
    'IOWA PARK',
    'IRAAN',
    'IREDELL',
    'IRVING',
    'ITALY',
    'ITASCA',
    'JACINTO CITY',
    'JACKSBORO',
    'JACKSONVILLE',
    'JAMAICA BEACH',
    'JASPER',
    'JAYTON',
    'JEFFERSON',
    'JERSEY VILLAGE',
    'JEWETT',
    'JOAQUIN',
    'JOHNSON CITY',
    'JOLLY',
    'JOLLYVILLE',
    'JONES CREEK',
    'JONESTOWN',
    'JOSEPHINE',
    'JOSHUA',
    'JOURDANTON',
    'JUNCTION',
    'JUSTIN',
    'KARNES CITY',
    'KATY',
    'KAUFMAN',
    'K-BAR RANCH',
    'KEENE',
    'KELLER',
    'KEMAH',
    'KEMP',
    'KEMPNER',
    'KENDLETON',
    'KENEDY',
    'KENEFICK',
    'KENNARD',
    'KENNEDALE',
    'KERENS',
    'KERMIT',
    'KERRVILLE',
    'KILGORE',
    'KILLEEN',
    'KINGSBURY',
    'KINGSLAND',
    'KINGSVILLE',
    'KIRBY',
    'KIRBYVILLE',
    'KIRVIN',
    'KNIPPA',
    'KNOLLWOOD',
    'KNOX CITY',
    'KOSSE',
    'KOUNTZE',
    'KRESS',
    'KRUGERVILLE',
    'KRUM',
    'KYLE',
    'LA BLANCA',
    'LA CASITA-GARCIASVILLE',
    'LACKLAND AFB',
    'LACOSTE',
    'LACY-LAKEVIEW',
    'LADONIA',
    'LA FERIA',
    'LA FERIA NORTH',
    'LAGO',
    'LAGO VISTA',
    'LA GRANGE',
    'LA GRULLA',
    'LAGUNA HEIGHTS',
    'LAGUNA SECA',
    'LAGUNA VISTA',
    'LA HOMA',
    'LA JOYA',
    'LAKE BRIDGEPORT',
    'LAKE BROWNWOOD',
    'LAKE CITY',
    'LAKE DALLAS',
    'LAKEHILLS',
    'LAKE JACKSON',
    'LAKE KIOWA',
    'LAKEPORT',
    'LAKESHORE GARDENS-HIDDEN ACRES',
    'LAKESIDE',
    'LAKESIDE',
    'LAKESIDE CITY',
    'LAKE TANGLEWOOD',
    'LAKEVIEW',
    'LAKE VIEW',
    'LAKEWAY',
    'LAKEWOOD VILLAGE',
    'LAKE WORTH',
    'LA MARQUE',
    'LAMESA',
    'LAMPASAS',
    'LANCASTER',
    'LA PALOMA',
    'LA PALOMA-LOST CREEK',
    'LA PORTE',
    'LA PRESA',
    'LA PRYOR',
    'LA PUERTA',
    'LAREDO',
    'LAREDO RANCHETTES',
    'LARGA VISTA',
    'LA ROSITA',
    'LASANA',
    'LASARA',
    'LAS COLONIAS',
    'LAS LOMAS',
    'LAS LOMITAS',
    'LAS PALMAS-JUAREZ',
    'LAS QUINTAS FRONTERIZAS',
    'LATEXO',
    'LAUGHLIN AFB',
    'LAURELES',
    'LA VERNIA',
    'LA VICTORIA',
    'LA VILLA',
    'LAVON',
    'LA WARD',
    'LAWN',
    'LEAGUE CITY',
    'LEAKEY',
    'LEANDER',
    'LEARY',
    'LEFORS',
    'LEONA',
    'LEONARD',
    'LEON VALLEY',
    'LEROY',
    'LEVELLAND',
    'LEWISVILLE',
    'LEXINGTON',
    'LIBERTY',
    'LIBERTY CITY',
    'LIBERTY HILL',
    'LINCOLN PARK',
    'LINDALE',
    'LINDEN',
    'LINDSAY',
    'LINDSAY',
    'LIPAN',
    'LIPSCOMB',
    'LITTLE ELM',
    'LITTLEFIELD',
    'LITTLE RIVER-ACADEMY',
    'LIVE OAK',
    'LIVERPOOL',
    'LIVINGSTON',
    'LLANO',
    'LLANO GRANDE',
    'LOCKHART',
    'LOCKNEY',
    'LOG CABIN',
    'LOLITA',
    'LOMA LINDA EAST',
    'LOMETA',
    'LONE OAK',
    'LONE STAR',
    'LONGVIEW',
    'LOPENO',
    'LOPEZVILLE',
    'LORAINE',
    'LORENA',
    'LORENZO',
    'LOS ALVAREZ',
    'LOS ANGELES SUBDIVISION',
    'LOS EBANOS',
    'LOS FRESNOS',
    'LOS INDIOS',
    'LOST CREEK',
    'LOS VILLAREALES',
    'LOS YBANEZ',
    'LOTT',
    'LOUISE',
    'LOVELADY',
    'LOWRY CROSSING',
    'LOZANO',
    'LUBBOCK',
    'LUCAS',
    'LUEDERS',
    'LUFKIN',
    'LULING',
    'LUMBERTON',
    'LYFORD',
    'LYFORD SOUTH',
    'LYTLE',
    'MABANK',
    'MCALLEN',
    'MCCAMEY',
    'MCGREGOR',
    'MCKINNEY',
    'MCLEAN',
    'MCLENDON-CHISHOLM',
    'MCQUEENEY',
    'MADISONVILLE',
    'MAGNOLIA',
    'MALAKOFF',
    'MALONE',
    'MANOR',
    'MANSFIELD',
    'MANVEL',
    'MARATHON',
    'MARBLE FALLS',
    'MARFA',
    'MARIETTA',
    'MARION',
    'MARKHAM',
    'MARLIN',
    'MARQUEZ',
    'MARSHALL',
    'MARSHALL CREEK',
    'MART',
    'MARTINDALE',
    'MASON',
    'MATADOR',
    'MATHIS',
    'MAUD',
    'MAURICEVILLE',
    'MAYPEARL',
    'MEADOW',
    'MEADOWLAKES',
    'MEADOWS PLACE',
    'MEDINA',
    'MEGARGEL',
    'MELISSA',
    'MELVIN',
    'MEMPHIS',
    'MENARD',
    'MERCEDES',
    'MERIDIAN',
    'MERKEL',
    'MERTENS',
    'MERTZON',
    'MESQUITE',
    'MEXIA',
    'MIAMI',
    'MIDLAND',
    'MIDLOTHIAN',
    'MIDWAY',
    'MIDWAY NORTH',
    'MIDWAY SOUTH',
    'MILA DOCE',
    'MILAM',
    'MILANO',
    'MILDRED',
    'MILES',
    'MILFORD',
    'MILLER S COVE',
    'MILLICAN',
    'MILLSAP',
    'MINEOLA',
    'MINERAL WELLS',
    'MINGUS',
    'MIRANDO CITY',
    'MISSION',
    'MISSION BEND',
    'MISSOURI CITY',
    'MOBEETIE',
    'MOBILE CITY',
    'MONAHANS',
    'MONT BELVIEU',
    'MONTE ALTO',
    'MONTGOMERY',
    'MOODY',
    'MOORE',
    'MOORE STATION',
    'MORALES-SANCHEZ',
    'MORAN',
    'MORGAN',
    'MORGAN FARM AREA',
    'MORGANS POINT',
    'MORGAN S POINT RESORT',
    'MORNING GLORY',
    'MORSE',
    'MORTON',
    'MOULTON',
    'MOUNTAIN CITY',
    'MOUNT CALM',
    'MOUNT ENTERPRISE',
    'MOUNT PLEASANT',
    'MOUNT VERNON',
    'MUENSTER',
    'MULESHOE',
    'MULLIN',
    'MUNDAY',
    'MUNIZ',
    'MURCHISON',
    'MURPHY',
    'MUSTANG',
    'MUSTANG RIDGE',
    'NACOGDOCHES',
    'NAPLES',
    'NASH',
    'NASSAU BAY',
    'NATALIA',
    'NAVARRO',
    'NAVASOTA',
    'NAZARETH',
    'NEDERLAND',
    'NEEDVILLE',
    'NESBITT',
    'NEVADA',
    'NEWARK',
    'NEW BERLIN',
    'NEW BOSTON',
    'NEW BRAUNFELS',
    'NEWCASTLE',
    'NEW CHAPEL HILL',
    'NEW DEAL',
    'NEW FAIRVIEW',
    'NEW FALCON',
    'NEW HOME',
    'NEW HOPE',
    'NEW LONDON',
    'NEW SUMMERFIELD',
    'NEW TERRITORY',
    'NEWTON',
    'NEW WAVERLY',
    'NEYLANDVILLE',
    'NIEDERWALD',
    'NIXON',
    'NOCONA',
    'NOLANVILLE',
    'NOME',
    'NOONDAY',
    'NORDHEIM',
    'NORMANGEE',
    'NORMANNA',
    'NORTH ALAMO',
    'NORTH CLEVELAND',
    'NORTHCLIFF',
    'NORTH ESCOBARES',
    'NORTHLAKE',
    'NORTH PEARSALL',
    'NORTH RICHLAND HILLS',
    'NORTH SAN PEDRO',
    'NOVICE',
    'NURILLO',
    'OAK GROVE',
    'OAKHURST',
    'OAK LEAF',
    'OAK POINT',
    'OAK RIDGE',
    'OAK RIDGE',
    'OAK RIDGE NORTH',
    'OAK TRAIL SHORES',
    'OAK VALLEY',
    'OAKWOOD',
    'O BRIEN',
    'ODEM',
    'ODESSA',
    'O DONNELL',
    'OGLESBY',
    'OILTON',
    'OLD RIVER-WINFREE',
    'OLIVAREZ',
    'OLMITO',
    'OLMOS PARK',
    'OLNEY',
    'OLTON',
    'OMAHA',
    'ONALASKA',
    'ONION CREEK',
    'OPDYKE WEST',
    'ORANGE',
    'ORANGE GROVE',
    'ORCHARD',
    'ORE CITY',
    'OVERTON',
    'OVILLA',
    'OWL RANCH-AMARGOSA',
    'OYSTER CREEK',
    'O',
    'PADUCAH',
    'PAINT ROCK',
    'PALACIOS',
    'PALESTINE',
    'PALISADES',
    'PALMER',
    'PALMHURST',
    'PALM VALLEY',
    'PALMVIEW',
    'PALMVIEW SOUTH',
    'PAMPA',
    'PANHANDLE',
    'PANORAMA VILLAGE',
    'PANTEGO',
    'PARADISE',
    'PARIS',
    'PARKER',
    'PASADENA',
    'PATTISON',
    'PATTON VILLAGE',
    'PAWNEE',
    'PAYNE SPRINGS',
    'PEARLAND',
    'PEARSALL',
    'PECAN ACRES',
    'PECAN GAP',
    'PECAN GROVE',
    'PECAN HILL',
    'PECAN PLANTATION',
    'PECOS',
    'PELICAN BAY',
    'PENELOPE',
    'PENITAS',
    'PERNITAS POINT',
    'PERRYTON',
    'PETERSBURG',
    'PETROLIA',
    'PETRONILA',
    'PETTUS',
    'PFLUGERVILLE',
    'PHARR',
    'PILOT POINT',
    'PINE FOREST',
    'PINEHURST',
    'PINEHURST',
    'PINE ISLAND',
    'PINELAND',
    'PINEWOOD ESTATES',
    'PINEY POINT VILLAGE',
    'PITTSBURG',
    'PLAINS',
    'PLAINVIEW',
    'PLANO',
    'PLEAK',
    'PLEASANTON',
    'PLEASANT VALLEY',
    'PLUM GROVE',
    'POINT',
    'POINT BLANK',
    'POINT COMFORT',
    'PONDER',
    'PORT ARANSAS',
    'PORT ARTHUR',
    'PORTER HEIGHTS',
    'PORT ISABEL',
    'PORTLAND',
    'PORT LAVACA',
    'PORT MANSFIELD',
    'PORT NECHES',
    'POST',
    'POST OAK BEND CITY',
    'POTEET',
    'POTH',
    'POTOSI',
    'POTTSBORO',
    'POWELL',
    'POYNOR',
    'PRADO VERDE',
    'PRAIRIE VIEW',
    'PREMONT',
    'PRESIDIO',
    'PRIMERA',
    'PRINCETON',
    'PROGRESO',
    'PROGRESO LAKES',
    'PROSPER',
    'PUTNAM',
    'PYOTE',
    'QUAIL',
    'QUANAH',
    'QUEEN CITY',
    'QUEMADO',
    'QUINLAN',
    'QUINTANA',
    'QUITAQUE',
    'QUITMAN',
    'RADAR BASE',
    'RALLS',
    'RANCHETTE ESTATES',
    'RANCHITOS LAS LOMAS',
    'RANCHO ALEGRE',
    'RANCHO BANQUETE',
    'RANCHO CHICO',
    'RANCHOS PENITAS WEST',
    'RANCHO VIEJO',
    'RANGER',
    'RANGERVILLE',
    'RANKIN',
    'RANSOM CANYON',
    'RATAMOSA',
    'RAVENNA',
    'RAYMONDVILLE',
    'REALITOS',
    'REDFORD',
    'RED LICK',
    'RED OAK',
    'REDWATER',
    'REDWOOD',
    'REESE CENTER',
    'REFUGIO',
    'REID HOPE KING',
    'REKLAW',
    'RELAMPAGO',
    'RENDON',
    'RENO',
    'RENO',
    'RETREAT',
    'RHOME',
    'RICE',
    'RICHARDSON',
    'RICHLAND',
    'RICHLAND HILLS',
    'RICHLAND SPRINGS',
    'RICHMOND',
    'RICHWOOD',
    'RIESEL',
    'RIO BRAVO',
    'RIO GRANDE CITY',
    'RIO HONDO',
    'RIO VISTA',
    'RISING STAR',
    'RIVER OAKS',
    'RIVERSIDE',
    'ROANOKE',
    'ROARING SPRINGS',
    'ROBERT LEE',
    'ROBINSON',
    'ROBSTOWN',
    'ROBY',
    'ROCHESTER',
    'ROCKDALE',
    'ROCKPORT',
    'ROCKSPRINGS',
    'ROCKWALL',
    'ROCKY MOUND',
    'ROGERS',
    'ROLLINGWOOD',
    'ROMA',
    'ROMA CREEK',
    'ROMAN FOREST',
    'ROPESVILLE',
    'ROSCOE',
    'ROSEBUD',
    'ROSE CITY',
    'ROSE HILL ACRES',
    'ROSENBERG',
    'ROSITA NORTH',
    'ROSITA SOUTH',
    'ROSS',
    'ROSSER',
    'ROTAN',
    'ROUND MOUNTAIN',
    'ROUND ROCK',
    'ROUND TOP',
    'ROWLETT',
    'ROXTON',
    'ROYSE CITY',
    'RULE',
    'RUNAWAY BAY',
    'RUNGE',
    'RUSK',
    'SABINAL',
    'SACHSE',
    'SADLER',
    'SAGINAW',
    'ST. HEDWIG',
    'ST. JO',
    'ST. PAUL',
    'ST. PAUL',
    'SALADO',
    'SALINENO',
    'SAMNORWOOD',
    'SAN ANGELO',
    'SAN ANTONIO',
    'SAN AUGUSTINE',
    'SAN BENITO',
    'SAN CARLOS',
    'SANCTUARY',
    'SANDERSON',
    'SANDIA',
    'SAN DIEGO',
    'SANDY HOLLOW-ESCONDIDAS',
    'SAN ELIZARIO',
    'SAN FELIPE',
    'SANFORD',
    'SANGER',
    'SAN IGNACIO',
    'SAN ISIDRO',
    'SAN JUAN',
    'SAN LEANNA',
    'SAN LEON',
    'SAN MANUEL-LINN',
    'SAN MARCOS',
    'SAN PATRICIO',
    'SAN PEDRO',
    'SAN PERLITA',
    'SAN SABA',
    'SANSOM PARK',
    'SANTA ANNA',
    'SANTA CLARA',
    'SANTA CRUZ',
    'SANTA FE',
    'SANTA MARIA',
    'SANTA MONICA',
    'SANTA ROSA',
    'SAVOY',
    'SCENIC OAKS',
    'SCHERTZ',
    'SCHULENBURG',
    'SCISSORS',
    'SCOTLAND',
    'SCOTTSVILLE',
    'SEABROOK',
    'SEADRIFT',
    'SEAGOVILLE',
    'SEAGRAVES',
    'SEALY',
    'SEBASTIAN',
    'SEGUIN',
    'SELMA',
    'SEMINOLE',
    'SERENADA',
    'SETH WARD',
    'SEVEN OAKS',
    'SEVEN POINTS',
    'SEYMOUR',
    'SHADY HOLLOW',
    'SHADY SHORES',
    'SHALLOWATER',
    'SHAMROCK',
    'SHAVANO PARK',
    'SHELDON',
    'SHENANDOAH',
    'SHEPHERD',
    'SHERMAN',
    'SHINER',
    'SHOREACRES',
    'SIENNA PLANTATION',
    'SIERRA BLANCA',
    'SIESTA SHORES',
    'SILSBEE',
    'SILVERTON',
    'SIMONTON',
    'SINTON',
    'SKELLYTOWN',
    'SKIDMORE',
    'SLATON',
    'SMILEY',
    'SMITHVILLE',
    'SMYER',
    'SNOOK',
    'SNYDER',
    'SOCORRO',
    'SOLIS',
    'SOMERSET',
    'SOMERVILLE',
    'SONORA',
    'SOUR LAKE',
    'SOUTH ALAMO',
    'SOUTH FORK ESTATES',
    'SOUTH HOUSTON',
    'SOUTHLAKE',
    'SOUTHMAYD',
    'SOUTH MOUNTAIN',
    'SOUTH PADRE ISLAND',
    'SOUTH POINT',
    'SOUTHSIDE PLACE',
    'SOUTH TOLEDO BEND',
    'SPADE',
    'SPARKS',
    'SPEARMAN',
    'SPLENDORA',
    'SPOFFORD',
    'SPRING',
    'SPRING GARDEN-TERRA VERDE',
    'SPRINGLAKE',
    'SPRINGTOWN',
    'SPRING VALLEY',
    'SPUR',
    'STAFFORD',
    'STAGECOACH',
    'STAMFORD',
    'STANTON',
    'STAR HARBOR',
    'STEPHENVILLE',
    'STERLING CITY',
    'STINNETT',
    'STOCKDALE',
    'STONEWALL',
    'STOWELL',
    'STRATFORD',
    'STRAWN',
    'STREETMAN',
    'STUDY BUTTE-TERLINGUA',
    'SUDAN',
    'SUGAR LAND',
    'SULLIVAN CITY',
    'SULPHUR SPRINGS',
    'SUNDOWN',
    'SUNNYVALE',
    'SUNRAY',
    'SUNRISE BEACH VILLAGE',
    'SUNSET',
    'SUNSET VALLEY',
    'SUN VALLEY',
    'SURFSIDE BEACH',
    'SWEENY',
    'SWEETWATER',
    'TAFT',
    'TAFT SOUTHWEST',
    'TAHOKA',
    'TALCO',
    'TALTY',
    'TATUM',
    'TAYLOR',
    'TAYLOR LAKE VILLAGE',
    'TEAGUE',
    'TEHUACANA',
    'TEMPLE',
    'TENAHA',
    'TERRELL',
    'TERRELL HILLS',
    'TEXARKANA',
    'TEXAS CITY',
    'TEXHOMA',
    'TEXLINE',
    'THE COLONY',
    'THE HILLS',
    'THE WOODLANDS',
    'THOMPSONS',
    'THORNDALE',
    'THORNTON',
    'THORNTONVILLE',
    'THRALL',
    'THREE RIVERS',
    'THROCKMORTON',
    'TIERRA BONITA',
    'TIERRA GRANDE',
    'TIKI ISLAND',
    'TIMBERCREEK CANYON',
    'TIMBERWOOD PARK',
    'TIMPSON',
    'TIOGA',
    'TIRA',
    'TOCO',
    'TODD MISSION',
    'TOLAR',
    'TOMBALL',
    'TOM BEAN',
    'TOOL',
    'TORNILLO',
    'TOYAH',
    'TRADEWINDS',
    'TRENT',
    'TRENTON',
    'TRINIDAD',
    'TRINITY',
    'TROPHY CLUB',
    'TROUP',
    'TROY',
    'TULETA',
    'TULIA',
    'TULSITA',
    'TURKEY',
    'TUSCOLA',
    'TYE',
    'TYLER',
    'TYNAN',
    'UHLAND',
    'UNCERTAIN',
    'UNION GROVE',
    'UNIVERSAL CITY',
    'UNIVERSITY PARK',
    'UTOPIA',
    'UVALDE',
    'UVALDE ESTATES',
    'VALENTINE',
    'VALLEY MILLS',
    'VALLEY VIEW',
    'VAL VERDE PARK',
    'VAN',
    'VAN ALSTYNE',
    'VANDERBILT',
    'VAN HORN',
    'VAN VLECK',
    'VEGA',
    'VENUS',
    'VERNON',
    'VICTORIA',
    'VIDOR',
    'VILLA DEL SOL',
    'VILLA PANCHO',
    'VILLA VERDE',
    'VINTON',
    'WACO',
    'WAELDER',
    'WAKE VILLAGE',
    'WALLER',
    'WALLIS',
    'WALNUT SPRINGS',
    'WARREN CITY',
    'WASKOM',
    'WATAUGA',
    'WAXAHACHIE',
    'WEATHERFORD',
    'WEBSTER',
    'WEIMAR',
    'WEINERT',
    'WEIR',
    'WELLINGTON',
    'WELLMAN',
    'WELLS',
    'WELLS BRANCH',
    'WESLACO',
    'WEST',
    'WESTBROOK',
    'WEST COLUMBIA',
    'WESTDALE',
    'WESTLAKE',
    'WEST LAKE HILLS',
    'WEST LIVINGSTON',
    'WESTMINSTER',
    'WEST ODESSA',
    'WESTON',
    'WEST ORANGE',
    'WESTOVER HILLS',
    'WEST PEARSALL',
    'WEST SHARYLAND',
    'WEST TAWAKONI',
    'WEST UNIVERSITY PLACE',
    'WESTWAY',
    'WESTWORTH VILLAGE',
    'WHARTON',
    'WHEELER',
    'WHITE DEER',
    'WHITEFACE',
    'WHITEHOUSE',
    'WHITE OAK',
    'WHITESBORO',
    'WHITE SETTLEMENT',
    'WHITEWRIGHT',
    'WHITNEY',
    'WICHITA FALLS',
    'WICKETT',
    'WILD PEACH VILLAGE',
    'WILLAMAR',
    'WILLIS',
    'WILLOW PARK',
    'WILLS POINT',
    'WILMER',
    'WILSON',
    'WIMBERLEY',
    'WINDCREST',
    'WINDEMERE',
    'WINDOM',
    'WINDTHORST',
    'WINFIELD',
    'WINK',
    'WINNIE',
    'WINNSBORO',
    'WINONA',
    'WINTERS',
    'WIXON VALLEY',
    'WOLFE CITY',
    'WOLFFORTH',
    'WOODBRANCH',
    'WOODCREEK',
    'WOODLOCH',
    'WOODSBORO',
    'WOODSON',
    'WOODVILLE',
    'WOODWAY',
    'WORTHAM',
    'WYLDWOOD',
    'WYLIE',
    'YANTIS',
    'YOAKUM',
    'YORKTOWN',
    'YZNAGA',
    'ZAPATA',
    'ZAPATA RANCH',
    'ZAVALLA',
    'ZUEHL',
  ],
  Utah: [
    'ALPINE',
    'ALTA',
    'ALTAMONT',
    'ALTON',
    'AMALGA',
    'AMERICAN FORK',
    'ANETH',
    'ANNABELLA',
    'ANTIMONY',
    'AURORA',
    'AVON',
    'BALLARD',
    'BEAR RIVER CITY',
    'BEAVER',
    'BENJAMIN',
    'BENSON',
    'BICKNELL',
    'BIG WATER',
    'BLANDING',
    'BLUFF',
    'BLUFFDALE',
    'BOULDER',
    'BOUNTIFUL',
    'BRIAN HEAD',
    'BRIGHAM CITY',
    'CACHE',
    'CANNONVILLE',
    'CANYON RIM',
    'CASTLE DALE',
    'CASTLE VALLEY',
    'CEDAR CITY',
    'CEDAR FORT',
    'CEDAR HILLS',
    'CENTERFIELD',
    'CENTERVILLE',
    'CHARLESTON',
    'CIRCLEVILLE',
    'CLARKSTON',
    'CLAWSON',
    'CLEARFIELD',
    'CLEVELAND',
    'CLINTON',
    'COALVILLE',
    'CORINNE',
    'CORNISH',
    'COTTONWOOD HEIGHTS',
    'COTTONWOOD WEST',
    'COVE',
    'DANIEL',
    'DELTA',
    'DEWEYVILLE',
    'DRAPER',
    'DUCHESNE',
    'DUGWAY',
    'EAGLE MOUNTAIN',
    'EAST CARBON',
    'EAST MILLCREEK',
    'ELBERTA',
    'ELK RIDGE',
    'ELMO',
    'ELSINORE',
    'ELWOOD',
    'EMERY',
    'ENOCH',
    'ENTERPRISE',
    'EPHRAIM',
    'ERDA',
    'ESCALANTE',
    'EUREKA',
    'FAIRVIEW',
    'FARMINGTON',
    'FARR WEST',
    'FAYETTE',
    'FERRON',
    'FIELDING',
    'FILLMORE',
    'FORT DUCHESNE',
    'FOUNTAIN GREEN',
    'FRANCIS',
    'FRUIT HEIGHTS',
    'GARDEN',
    'GARDEN CITY',
    'GARLAND',
    'GENOLA',
    'GLENDALE',
    'GLENWOOD',
    'GOSHEN',
    'GRANITE',
    'GRANTSVILLE',
    'GREEN RIVER',
    'GUNNISON',
    'HALCHITA',
    'HALLS CROSSING',
    'HARRISVILLE',
    'HATCH',
    'HEBER',
    'HELPER',
    'HENEFER',
    'HENRIEVILLE',
    'HERRIMAN',
    'HIGHLAND',
    'HILDALE',
    'HINCKLEY',
    'HOLDEN',
    'HOLLADAY',
    'HONEYVILLE',
    'HOOPER',
    'HOWELL',
    'HUNTINGTON',
    'HUNTSVILLE',
    'HURRICANE',
    'HYDE PARK',
    'HYRUM',
    'IVINS',
    'JOSEPH',
    'JUNCTION',
    'KAMAS',
    'KANAB',
    'KANARRAVILLE',
    'KANOSH',
    'KAYSVILLE',
    'KEARNS',
    'KINGSTON',
    'KOOSHAREM',
    'LAKE SHORE',
    'LAKETOWN',
    'LA SAL',
    'LA VERKIN',
    'LAYTON',
    'LEAMINGTON',
    'LEEDS',
    'LEHI',
    'LEVAN',
    'LEWISTON',
    'LINDON',
    'LITTLE COTTONWOOD CREEK VALLEY',
    'LOA',
    'LOGAN',
    'LYMAN',
    'LYNNDYL',
    'MAESER',
    'MAGNA',
    'MANILA',
    'MANTI',
    'MANTUA',
    'MAPLETON',
    'MARRIOTT-SLATERVILLE',
    'MARYSVALE',
    'MAYFIELD',
    'MEADOW',
    'MENDON',
    'MEXICAN HAT',
    'MIDVALE',
    'MIDWAY',
    'MILFORD',
    'MILLCREEK',
    'MILLVILLE',
    'MINERSVILLE',
    'MOAB',
    'MONA',
    'MONROE',
    'MONTEZUMA CREEK',
    'MONTICELLO',
    'MORGAN',
    'MORONI',
    'MOUNT OLYMPUS',
    'MOUNT PLEASANT',
    'MURRAY',
    'MYTON',
    'NAPLES',
    'NAVAJO MOUNTAIN',
    'NEOLA',
    'NEPHI',
    'NEW HARMONY',
    'NEWTON',
    'NIBLEY',
    'NORTH LOGAN',
    'NORTH OGDEN',
    'NORTH SALT LAKE',
    'NORTH SNYDERVILLE BASIN',
    'OAK CITY',
    'OAKLEY',
    'OGDEN',
    'OLJATO-MONUMENT VALLEY',
    'OPHIR',
    'OQUIRRH',
    'ORANGEVILLE',
    'ORDERVILLE',
    'OREM',
    'PALMYRA',
    'PANGUITCH',
    'PARADISE',
    'PARAGONAH',
    'PARK CITY',
    'PAROWAN',
    'PAYSON',
    'PERRY',
    'PETER',
    'PLAIN CITY',
    'PLEASANT GROVE',
    'PLEASANT VIEW',
    'PLYMOUTH',
    'PORTAGE',
    'PRICE',
    'PROVIDENCE',
    'PROVO',
    'RANDLETT',
    'RANDOLPH',
    'REDMOND',
    'RICHFIELD',
    'RICHMOND',
    'RIVERDALE',
    'RIVER HEIGHTS',
    'RIVERSIDE',
    'RIVERTON',
    'ROCKVILLE',
    'ROCKY RIDGE',
    'ROOSEVELT',
    'ROY',
    'RUSH VALLEY',
    'ST. GEORGE',
    'SALEM',
    'SALINA',
    'SALT LAKE CITY',
    'SAMAK',
    'SANDY',
    'SANTA CLARA',
    'SANTAQUIN',
    'SARATOGA SPRINGS',
    'SCIPIO',
    'SCOFIELD',
    'SIGURD',
    'SMITHFIELD',
    'SNOWVILLE',
    'SOUTH JORDAN',
    'SOUTH OGDEN',
    'SOUTH SALT LAKE',
    'SOUTH SNYDERVILLE BASIN',
    'SOUTH WEBER',
    'SOUTH WILLARD',
    'SPANISH FORK',
    'SPANISH VALLEY',
    'SPRING CITY',
    'SPRINGDALE',
    'SPRING LAKE',
    'SPRINGVILLE',
    'STANSBURY PARK',
    'STERLING',
    'STOCKTON',
    'SUMMIT PARK',
    'SUNNYSIDE',
    'SUNSET',
    'SYRACUSE',
    'TABIONA',
    'TAYLORSVILLE',
    'TIMBER LAKES',
    'TOOELE',
    'TOQUERVILLE',
    'TORREY',
    'TREMONTON',
    'TRENTON',
    'TROPIC',
    'TSELAKAI DEZZA',
    'UINTAH',
    'VERNAL',
    'VERNON',
    'VINEYARD',
    'VIRGIN',
    'WALES',
    'WALLSBURG',
    'WASHINGTON',
    'WASHINGTON TERRACE',
    'WELLINGTON',
    'WELLSVILLE',
    'WENDOVER',
    'WEST BOUNTIFUL',
    'WEST HAVEN',
    'WEST JORDAN',
    'WEST MOUNTAIN',
    'WEST POINT',
    'WEST VALLEY CITY',
    'WHITE CITY',
    'WHITE MESA',
    'WHITEROCKS',
    'WILLARD',
    'WOODLAND',
    'WOODLAND HILLS',
    'WOODRUFF',
    'WOODS CROSS',
  ],
  Vermont: [
    'ALBANY',
    'ALBURG',
    'ARLINGTON',
    'BARRE',
    'BARTON',
    'BELLOWS FALLS',
    'BENNINGTON',
    'BRADFORD',
    'BRANDON',
    'BRATTLEBORO',
    'BURLINGTON',
    'CABOT',
    'CHARLOTTE',
    'CAMBRIDGE',
    'CHESTER-CHESTER DEPOT',
    'DERBY CENTER',
    'DERBY LINE',
    'ENOSBURG FALLS',
    'ESSEX JUNCTION',
    'FAIR HAVEN',
    'GRANITEVILLE-EAST BARRE',
    'HYDE PARK',
    'ISLAND POND',
    'JACKSONVILLE',
    'JEFFERSONVILLE',
    'JERICHO',
    'JOHNSON',
    'LUDLOW',
    'LYNDONVILLE',
    'MANCHESTER',
    'MANCHESTER CENTER',
    'MARSHFIELD',
    'MIDDLEBURY',
    'MILTON',
    'MONTPELIER',
    'MORRISVILLE',
    'NEWBURY',
    'NEWFANE',
    'NEWPORT',
    'NORTH BENNINGTON',
    'NORTHFIELD',
    'NORTH TROY',
    'NORTH WESTMINSTER',
    'OLD BENNINGTON',
    'ORLEANS',
    'PERKINSVILLE',
    'POULTNEY',
    'RUTLAND',
    'ST. ALBANS',
    'ST. JOHNSBURY',
    'SAXTONS RIVER',
    'SOUTH BARRE',
    'SOUTH BURLINGTON',
    'SOUTH SHAFTSBURY',
    'SPRINGFIELD',
    'SWANTON',
    'VERGENNES',
    'WALLINGFORD',
    'WATERBURY',
    'WELLS RIVER',
    'WEST BRATTLEBORO',
    'WEST BURKE',
    'WESTMINSTER',
    'WEST RUTLAND',
    'WHITE RIVER JUNCTION',
    'WILDER',
    'WINOOSKI',
    'WOODSTOCK',
  ],
  'Virgin Islands': ['Virgin Islands'],
  Virginia: [
    'ABINGDON',
    'ACCOMAC',
    'ADWOLF',
    'ALBERTA',
    'ALEXANDRIA',
    'ALTAVISTA',
    'AMHERST',
    'ANNANDALE',
    'APPALACHIA',
    'APPOMATTOX',
    'AQUIA HARBOUR',
    'ARLINGTON',
    'ASHLAND',
    'ATKINS',
    'BAILEY S CROSSROADS',
    'BASSETT',
    'BASYE-BRYCE MOUNTAIN',
    'BEDFORD',
    'BELLE HAVEN',
    'BELLE HAVEN',
    'BELLWOOD',
    'BENSLEY',
    'BERRYVILLE',
    'BIG STONE GAP',
    'BLACKSBURG',
    'BLACKSTONE',
    'BLOXOM',
    'BLUEFIELD',
    'BLUE RIDGE',
    'BON AIR',
    'BOONES MILL',
    'BOWLING GREEN',
    'BOYCE',
    'BOYDTON',
    'BOYKINS',
    'BRANCHVILLE',
    'BRIDGEWATER',
    'BRISTOL',
    'BROADWAY',
    'BRODNAX',
    'BROOKNEAL',
    'BUCHANAN',
    'BUENA VISTA',
    'BULL RUN',
    'BURKE',
    'BURKEVILLE',
    'CANA',
    'CAPE CHARLES',
    'CAPRON',
    'CASTLEWOOD',
    'CAVE SPRING',
    'CEDAR BLUFF',
    'CENTREVILLE',
    'CHAMBERLAYNE',
    'CHANTILLY',
    'CHARLOTTE COURT HOUSE',
    'CHARLOTTESVILLE',
    'CHASE CITY',
    'CHATHAM',
    'CHATMOSS',
    'CHERITON',
    'CHESAPEAKE',
    'CHESTER',
    'CHESTERFIELD COURT HOUSE',
    'CHILHOWIE',
    'CHINCOTEAGUE',
    'CHRISTIANSBURG',
    'CLAREMONT',
    'CLARKSVILLE',
    'CLAYPOOL HILL',
    'CLEVELAND',
    'CLIFTON',
    'CLIFTON FORGE',
    'CLINCHCO',
    'CLINCHPORT',
    'CLINTWOOD',
    'CLOVERDALE',
    'COEBURN',
    'COLLINSVILLE',
    'COLONIAL BEACH',
    'COLONIAL HEIGHTS',
    'COLUMBIA',
    'COURTLAND',
    'COVINGTON',
    'CRAIGSVILLE',
    'CREWE',
    'CRIMORA',
    'CROZET',
    'CULPEPER',
    'DAHLGREN',
    'DALE CITY',
    'DALEVILLE',
    'DAMASCUS',
    'DANVILLE',
    'DAYTON',
    'DENDRON',
    'DILLWYN',
    'DOOMS',
    'DRAKES BRANCH',
    'DRYDEN',
    'DUBLIN',
    'DUFFIELD',
    'DUMBARTON',
    'DUMFRIES',
    'DUNGANNON',
    'DUNN LORING',
    'EAST HIGHLAND PARK',
    'EASTVILLE',
    'EDINBURG',
    'ELKTON',
    'ELLISTON-LAFAYETTE',
    'EMORY-MEADOW VIEW',
    'EMPORIA',
    'ETTRICK',
    'EWING',
    'EXMORE',
    'FAIRFAX',
    'FAIRLAWN',
    'FAIRVIEW BEACH',
    'FALLS CHURCH',
    'FALMOUTH',
    'FANCY GAP',
    'FARMVILLE',
    'FERRUM',
    'FIELDALE',
    'FINCASTLE',
    'FISHERSVILLE',
    'FLOYD',
    'FOREST',
    'FORT BELVOIR',
    'FORT CHISWELL',
    'FORT HUNT',
    'FORT LEE',
    'FRANCONIA',
    'FRANKLIN',
    'FREDERICKSBURG',
    'FRIES',
    'FRONT ROYAL',
    'GAINESVILLE',
    'GALAX',
    'GATE CITY',
    'GLADE SPRING',
    'GLASGOW',
    'GLEN ALLEN',
    'GLEN LYN',
    'GLOUCESTER COURTHOUSE',
    'GLOUCESTER POINT',
    'GORDONSVILLE',
    'GOSHEN',
    'GREAT FALLS',
    'GREENVILLE',
    'GRETNA',
    'GROTTOES',
    'GROVETON',
    'GRUNDY',
    'HALIFAX',
    'HALLWOOD',
    'HAMILTON',
    'HAMPDEN SYDNEY',
    'HAMPTON',
    'HARRISONBURG',
    'HAYMARKET',
    'HAYSI',
    'HERNDON',
    'HIGHLAND SPRINGS',
    'HILLSBORO',
    'HILLSVILLE',
    'HOLLINS',
    'HONAKER',
    'HOPEWELL',
    'HORSE PASTURE',
    'HUNTINGTON',
    'HURT',
    'HYBLA VALLEY',
    'IDYLWOOD',
    'INDEPENDENCE',
    'IRON GATE',
    'IRVINGTON',
    'IVOR',
    'JARRATT',
    'JEFFERSON',
    'JOLIVUE',
    'JONESVILLE',
    'KELLER',
    'KENBRIDGE',
    'KEOKEE',
    'KEYSVILLE',
    'KILMARNOCK',
    'LA CROSSE',
    'LAKE BARCROFT',
    'LAKE MONTICELLO',
    'LAKE RIDGE',
    'LAKESIDE',
    'LAUREL',
    'LAUREL PARK',
    'LAWRENCEVILLE',
    'LAYMANTOWN',
    'LEBANON',
    'LEESBURG',
    'LEXINGTON',
    'LINCOLNIA',
    'LINTON HALL',
    'LOCH LOMOND',
    'LORTON',
    'LOUISA',
    'LOVETTSVILLE',
    'LOW MOOR',
    'LURAY',
    'LYNCHBURG',
    'LYNDHURST',
    'MCKENNEY',
    'MCLEAN',
    'MADISON',
    'MADISON HEIGHTS',
    'MANASSAS',
    'MANASSAS PARK',
    'MANTUA',
    'MARION',
    'MARTINSVILLE',
    'MASSANUTTEN',
    'MATOACA',
    'MAX MEADOWS',
    'MECHANICSVILLE',
    'MELFA',
    'MERRIFIELD',
    'MERRIMAC',
    'MIDDLEBURG',
    'MIDDLETOWN',
    'MINERAL',
    'MONTCLAIR',
    'MONTEREY',
    'MONTROSE',
    'MONTROSS',
    'MOUNT CRAWFORD',
    'MOUNT JACKSON',
    'MOUNT VERNON',
    'NARROWS',
    'NASSAWADOX',
    'NEW CASTLE',
    'NEWINGTON',
    'NEW MARKET',
    'NEWPORT NEWS',
    'NEWSOMS',
    'NICKELSVILLE',
    'NOKESVILLE',
    'NORFOLK',
    'NORTH SHORE',
    'NORTH SPRINGFIELD',
    'NORTON',
    'OAK LEVEL',
    'OAKTON',
    'OCCOQUAN',
    'ONANCOCK',
    'ONLEY',
    'ORANGE',
    'PAINTER',
    'PAMPLIN CITY',
    'PARKSLEY',
    'PATRICK SPRINGS',
    'PEARISBURG',
    'PEMBROKE',
    'PENHOOK',
    'PENNINGTON GAP',
    'PETERSBURG',
    'PHENIX',
    'PIMMIT HILLS',
    'POCAHONTAS',
    'POQUOSON',
    'PORT ROYAL',
    'PORTSMOUTH',
    'POUND',
    'PULASKI',
    'PURCELLVILLE',
    'QUANTICO',
    'QUANTICO STATION',
    'RADFORD',
    'RAVEN',
    'REMINGTON',
    'RESTON',
    'RICH CREEK',
    'RICHLANDS',
    'RICHMOND',
    'RIDGEWAY',
    'ROANOKE',
    'ROCKY MOUNT',
    'ROSE HILL',
    'ROSE HILL',
    'ROUND HILL',
    'RURAL RETREAT',
    'RUSHMERE',
    'RUSTBURG',
    'ST. CHARLES',
    'ST. PAUL',
    'SALEM',
    'SALTVILLE',
    'SANDY LEVEL',
    'SAXIS',
    'SCOTTSBURG',
    'SCOTTSVILLE',
    'SELMA',
    'SEVEN CORNERS',
    'SHAWSVILLE',
    'SHENANDOAH',
    'SHERANDO',
    'SHORT PUMP',
    'SMITHFIELD',
    'SOUTH BOSTON',
    'SOUTH HILL',
    'SPOTSYLVANIA COURTHOUSE',
    'SPRINGFIELD',
    'STANARDSVILLE',
    'STANLEY',
    'STANLEYTOWN',
    'STAUNTON',
    'STEPHENS CITY',
    'STONY CREEK',
    'STRASBURG',
    'STUART',
    'STUARTS DRAFT',
    'SUDLEY',
    'SUFFOLK',
    'SUGAR GROVE',
    'SURRY',
    'TANGIER',
    'TAPPAHANNOCK',
    'TAZEWELL',
    'THE PLAINS',
    'TIMBERLAKE',
    'TIMBERVILLE',
    'TOMS BROOK',
    'TRIANGLE',
    'TROUTDALE',
    'TROUTVILLE',
    'TUCKAHOE',
    'TYSONS CORNER',
    'UNION HALL',
    'URBANNA',
    'VANSANT',
    'VERONA',
    'VICTORIA',
    'VIENNA',
    'VILLA HEIGHTS',
    'VINTON',
    'VIRGILINA',
    'VIRGINIA BEACH',
    'WACHAPREAGUE',
    'WAKEFIELD',
    'WARRENTON',
    'WARSAW',
    'WASHINGTON',
    'WAVERLY',
    'WAYNESBORO',
    'WEBER CITY',
    'WEST GATE',
    'WESTLAKE CORNER',
    'WEST POINT',
    'WEST SPRINGFIELD',
    'WEYERS CAVE',
    'WHITE STONE',
    'WILLIAMSBURG',
    'WINCHESTER',
    'WINDSOR',
    'WISE',
    'WOLF TRAP',
    'WOODBRIDGE',
    'WOODLAWN',
    'WOODSTOCK',
    'WYNDHAM',
    'WYTHEVILLE',
    'YORKSHIRE',
    'YORKTOWN',
  ],
  Washington: [
    'ABERDEEN',
    'ABERDEEN GARDENS',
    'ACME',
    'AHTANUM',
    'AIRWAY HEIGHTS',
    'ALBION',
    'ALDERWOOD MANOR',
    'ALGER',
    'ALGONA',
    'ALLYN-GRAPEVIEW',
    'ALMIRA',
    'AMBOY',
    'AMES LAKE',
    'ANACORTES',
    'ARLINGTON',
    'ARLINGTON HEIGHTS',
    'ARTONDALE',
    'ASHFORD',
    'ASOTIN',
    'AUBURN',
    'AULT FIELD',
    'BAINBRIDGE ISLAND',
    'BANGOR TRIDENT BASE',
    'BANKS LAKE SOUTH',
    'BARBERTON',
    'BARING',
    'BASIN CITY',
    'BATTLE GROUND',
    'BAY CENTER',
    'BAY VIEW',
    'BEAUX ARTS VILLAGE',
    'BELLEVUE',
    'BELL HILL',
    'BELLINGHAM',
    'BENTON CITY',
    'BICKLETON',
    'BIG LAKE',
    'BINGEN',
    'BIRCH BAY',
    'BLACK DIAMOND',
    'BLAINE',
    'BLYN',
    'BONNEY LAKE',
    'BOTHELL',
    'BRADY',
    'BREMERTON',
    'BREWSTER',
    'BRIDGEPORT',
    'BRIER',
    'BRINNON',
    'BRUSH PRAIRIE',
    'BRYN MAWR-SKYWAY',
    'BUCKLEY',
    'BUCODA',
    'BURBANK',
    'BURIEN',
    'BURLINGTON',
    'CAMANO',
    'CAMAS',
    'CARBONADO',
    'CARLSBORG',
    'CARNATION',
    'CARSON RIVER VALLEY',
    'CASCADE-FAIRWOOD',
    'CASCADE VALLEY',
    'CASHMERE',
    'CASTLE ROCK',
    'CATHAN',
    'CATHCART',
    'CATHLAMET',
    'CENTERVILLE',
    'CENTRALIA',
    'CENTRAL PARK',
    'CHEHALIS',
    'CHEHALIS VILLAGE',
    'CHELAN',
    'CHENEY',
    'CHERRY GROVE',
    'CHEWELAH',
    'CHINOOK',
    'CLARKSTON',
    'CLARKSTON HEIGHTS-VINELAND',
    'CLEAR LAKE',
    'CLE ELUM',
    'CLINTON',
    'CLYDE HILL',
    'COHASSETT BEACH',
    'COLFAX',
    'COLLEGE PLACE',
    'COLTON',
    'COLVILLE',
    'CONCONULLY',
    'CONCRETE',
    'CONNELL',
    'CONWAY',
    'COPALIS BEACH',
    'COSMOPOLIS',
    'COTTAGE LAKE',
    'COULEE CITY',
    'COULEE DAM',
    'COUNTRY HOMES',
    'COUPEVILLE',
    'COVINGTON',
    'CRESTON',
    'CUSICK',
    'CUSTER',
    'DALLESPORT',
    'DARRINGTON',
    'DAVENPORT',
    'DAYTON',
    'DEER PARK',
    'DEMING',
    'DESERT AIRE',
    'DES MOINES',
    'DISHMAN',
    'DIXIE',
    'DOLLAR CORNER',
    'DUPONT',
    'DUVALL',
    'EAST CATHLAMET',
    'EASTGATE',
    'EAST HILL-MERIDIAN',
    'EASTON',
    'EAST PORT ORCHARD',
    'EAST RENTON HIGHLANDS',
    'EAST WENATCHEE',
    'EAST WENATCHEE BENCH',
    'EATONVILLE',
    'ECHO LAKE',
    'EDGEWOOD',
    'EDISON',
    'EDMONDS',
    'ELBE',
    'ELECTRIC CITY',
    'ELK PLAIN',
    'ELLENSBURG',
    'ELMA',
    'ELMER CITY',
    'ENDICOTT',
    'ENTIAT',
    'ENUMCLAW',
    'EPHRATA',
    'ERLANDS POINT-KITSAP LAKE',
    'ESCHBACH',
    'ESPERANCE',
    'EVERETT',
    'EVERSON',
    'FAIRCHILD AFB',
    'FAIRFIELD',
    'FAIRWOOD',
    'FALL CITY',
    'FARMINGTON',
    'FEDERAL WAY',
    'FELIDA',
    'FERNDALE',
    'FIFE',
    'FINLEY',
    'FIRCREST',
    'FIVE CORNERS',
    'FORDS PRAIRIE',
    'FORKS',
    'FORT LEWIS',
    'FOX ISLAND',
    'FREDERICKSON',
    'FREELAND',
    'FRIDAY HARBOR',
    'GARFIELD',
    'GARRETT',
    'GENEVA',
    'GEORGE',
    'GIG HARBOR',
    'GLACIER',
    'GLEED',
    'GOLD BAR',
    'GOLDENDALE',
    'GRAHAM',
    'GRAND COULEE',
    'GRAND MOUND',
    'GRANDVIEW',
    'GRANGER',
    'GRANITE FALLS',
    'GRAYLAND',
    'GREEN ACRES',
    'GREENWATER',
    'HAMILTON',
    'HARRAH',
    'HARRINGTON',
    'HARTLINE',
    'HATTON',
    'HAZEL DELL NORTH',
    'HAZEL DELL SOUTH',
    'HIGHLAND',
    'HOBART',
    'HOCKINSON',
    'HOQUIAM',
    'HUMPTULIPS',
    'HUNTS POINT',
    'ILWACO',
    'INCHELIUM',
    'INDEX',
    'INDIANOLA',
    'INGLEWOOD-FINN HILL',
    'IONE',
    'ISSAQUAH',
    'JOHN SAM LAKE',
    'JORDAN ROAD-CANYON CREEK',
    'JUNCTION CITY',
    'KAHLOTUS',
    'KALAMA',
    'KELSO',
    'KENDALL',
    'KENMORE',
    'KENNEWICK',
    'KENT',
    'KETTLE FALLS',
    'KINGSGATE',
    'KINGSTON',
    'KIRKLAND',
    'KITTITAS',
    'KLICKITAT',
    'KRUPP',
    'LA CENTER',
    'LACEY',
    'LA CONNER',
    'LA CROSSE',
    'LAKE BOSWORTH',
    'LAKE CAVANAUGH',
    'LAKE FOREST PARK',
    'LAKE GOODWIN',
    'LAKE KETCHUM',
    'LAKELAND NORTH',
    'LAKELAND SOUTH',
    'LAKE MCMURRAY',
    'LAKE MARCEL-STILLWATER',
    'LAKE MORTON-BERRYDALE',
    'LAKE ROESIGER',
    'LAKE SHORE',
    'LAKE STEVENS',
    'LAKEVIEW',
    'LAKEWOOD',
    'LAMONT',
    'LANGLEY',
    'LATAH',
    'LEA HILL',
    'LEAVENWORTH',
    'LEBAM',
    'LEWISVILLE',
    'LIBERTY LAKE',
    'LIND',
    'LOCHSLOY',
    'LONG BEACH',
    'LONGVIEW',
    'LONGVIEW HEIGHTS',
    'LYLE',
    'LYMAN',
    'LYNDEN',
    'LYNNWOOD',
    'MABTON',
    'MCCHORD AFB',
    'MCCLEARY',
    'MACHIAS',
    'MALDEN',
    'MALONE-PORTER',
    'MALTBY',
    'MANCHESTER',
    'MANSFIELD',
    'MAPLE FALLS',
    'MAPLE HEIGHTS-LAKE DESIRE',
    'MAPLE VALLEY',
    'MARBLEMOUNT',
    'MARCUS',
    'MARIETTA-ALDERWOOD',
    'MARKHAM',
    'MARROWSTONE',
    'MARTHA LAKE',
    'MARYHILL',
    'MARYSVILLE',
    'MATTAWA',
    'MAY CREEK',
    'MEADOW GLADE',
    'MEDICAL LAKE',
    'MEDINA',
    'MERCER ISLAND',
    'MESA',
    'METALINE',
    'METALINE FALLS',
    'MIDLAND',
    'MILL CREEK',
    'MILL PLAIN',
    'MILLWOOD',
    'MILTON',
    'MINNEHAHA',
    'MIRRORMONT',
    'MOCLIPS',
    'MONROE',
    'MONTESANO',
    'MORTON',
    'MOSES LAKE',
    'MOSES LAKE NORTH',
    'MOSSYROCK',
    'MOUNTLAKE TERRACE',
    'MOUNT VERNON',
    'MOUNT VISTA',
    'MOXEE',
    'MUKILTEO',
    'NACHES',
    'NAPAVINE',
    'NASELLE',
    'NAVY YARD CITY',
    'NEAH BAY',
    'NEILTON',
    'NESPELEM',
    'NESPELEM COMMUNITY',
    'NEWCASTLE',
    'NEWPORT',
    'NISQUALLY INDIAN COMMUNITY',
    'NOOKSACK',
    'NORMANDY PARK',
    'NORTH BEND',
    'NORTH BONNEVILLE',
    'NORTH CREEK',
    'NORTH MARYSVILLE',
    'NORTH OMAK',
    'NORTHPORT',
    'NORTH STANWOOD',
    'NORTH SULTAN',
    'NORTHWEST SNOHOMISH',
    'NORTH YELM',
    'OAKESDALE',
    'OAK HARBOR',
    'OAKVILLE',
    'OCEAN CITY',
    'OCEAN PARK',
    'OCEAN SHORES',
    'ODESSA',
    'OKANOGAN',
    'OLYMPIA',
    'OMAK',
    'OPPORTUNITY',
    'ORCHARDS',
    'OROVILLE',
    'ORTING',
    'OSO',
    'OTHELLO',
    'OTIS ORCHARDS-EAST FARMS',
    'OYEHUT-HOGANS CORNER',
    'PACIFIC',
    'PAINE FIELD-LAKE STICKNEY',
    'PALOUSE',
    'PARKLAND',
    'PARKWOOD',
    'PASCO',
    'PATEROS',
    'PEACEFUL VALLEY',
    'PE ELL',
    'PICNIC POINT-NORTH LYNNWOOD',
    'POMEROY',
    'PORT ANGELES',
    'PORT ANGELES EAST',
    'PORT HADLOCK-IRONDALE',
    'PORT LUDLOW',
    'PORT ORCHARD',
    'PORT TOWNSEND',
    'POULSBO',
    'PRAIRIE RIDGE',
    'PRESCOTT',
    'PRIEST POINT',
    'PROSSER',
    'PULLMAN',
    'PUYALLUP',
    'QUILCENE',
    'QUINCY',
    'RAINIER',
    'RAVENSDALE',
    'RAYMOND',
    'REARDAN',
    'REDMOND',
    'RENTON',
    'REPUBLIC',
    'RICHLAND',
    'RIDGEFIELD',
    'RITZVILLE',
    'RIVERBEND',
    'RIVER ROAD',
    'RIVERSIDE',
    'RIVERTON-BOULEVARD PARK',
    'ROCHESTER',
    'ROCKFORD',
    'ROCK ISLAND',
    'ROCKPORT',
    'RONALD',
    'ROOSEVELT',
    'ROSALIA',
    'ROSLYN',
    'ROY',
    'ROYAL CITY',
    'RUSTON',
    'ST. JOHN',
    'SALMON CREEK',
    'SAMMAMISH',
    'SATSOP',
    'SATUS',
    'SEATAC',
    'SEATTLE',
    'SEATTLE HILL-SILVER FIRS',
    'SEDRO-WOOLLEY',
    'SELAH',
    'SEQUIM',
    'SHAKER CHURCH',
    'SHELTON',
    'SHORELINE',
    'SILVANA',
    'SILVERDALE',
    'SKOKOMISH',
    'SKYKOMISH',
    'SMOKEY POINT',
    'SNOHOMISH',
    'SNOQUALMIE',
    'SNOQUALMIE PASS',
    'SOAP LAKE',
    'SOUTH BEND',
    'SOUTH CLE ELUM',
    'SOUTH HILL',
    'SOUTH PRAIRIE',
    'SOUTH WENATCHEE',
    'SPANAWAY',
    'SPANGLE',
    'SPOKANE',
    'SPRAGUE',
    'SPRINGDALE',
    'STANWOOD',
    'STARBUCK',
    'STARTUP',
    'STEILACOOM',
    'STEVENSON',
    'STIMSON CROSSING',
    'SUDDEN VALLEY',
    'SULTAN',
    'SUMAS',
    'SUMMIT',
    'SUMMITVIEW',
    'SUMNER',
    'SUNNYSIDE',
    'SUNNYSLOPE',
    'SUQUAMISH',
    'TACOMA',
    'TAHOLAH',
    'TANGLEWILDE-THOMPSON PLACE',
    'TANNER',
    'TEKOA',
    'TENINO',
    'TERRACE HEIGHTS',
    'THORP',
    'THREE LAKES',
    'TIETON',
    'TOKELAND',
    'TOLEDO',
    'TONASKET',
    'TOPPENISH',
    'TOUCHET',
    'TOWN AND COUNTRY',
    'TRACYTON',
    'TRENTWOOD',
    'TROUT LAKE',
    'TUKWILA',
    'TULALIP BAY',
    'TUMWATER',
    'TWISP',
    'UNION GAP',
    'UNION HILL-NOVELTY HILL',
    'UNIONTOWN',
    'UNIVERSITY PLACE',
    'VADER',
    'VANCOUVER',
    'VANTAGE',
    'VASHON',
    'VENERSBORG',
    'VERADALE',
    'VERLOT',
    'WAITSBURG',
    'WALLA WALLA',
    'WALLA WALLA EAST',
    'WALLER',
    'WALLULA',
    'WALNUT GROVE',
    'WAPATO',
    'WARDEN',
    'WARM BEACH',
    'WASHOUGAL',
    'WASHTUCNA',
    'WATERVILLE',
    'WAVERLY',
    'WEALLUP LAKE',
    'WENATCHEE',
    'WEST CLARKSTON-HIGHLAND',
    'WEST LAKE SAMMAMISH',
    'WEST LAKE STEVENS',
    'WEST LONGVIEW',
    'WEST PASCO',
    'WESTPORT',
    'WEST RICHLAND',
    'WEST SIDE HIGHWAY',
    'WEST VALLEY',
    'WEST WENATCHEE',
    'WHITE CENTER',
    'WHITE SALMON',
    'WHITE SWAN',
    'WILBUR',
    'WILKESON',
    'WILSON CREEK',
    'WINLOCK',
    'WINTHROP',
    'WISHRAM',
    'WOODINVILLE',
    'WOODLAND',
    'WOODS CREEK',
    'WOODWAY',
    'YACOLT',
    'YAKIMA',
    'YARROW POINT',
    'YELM',
    'ZILLAH',
  ],
  'West Virginia': [
    'ADDISON (WEBSTER SPRINGS)',
    'ALBRIGHT',
    'ALDERSON',
    'ALUM CREEK',
    'AMHERSTDALE-ROBINETTE',
    'ANAWALT',
    'ANMOORE',
    'ANSTED',
    'ATHENS',
    'AUBURN',
    'BANCROFT',
    'BARBOURSVILLE',
    'BARRACKVILLE',
    'BATH (BERKELEY SPRINGS)',
    'BAYARD ',
    'BEAVER',
    'BECKLEY',
    'BEECH BOTTOM ',
    'BELINGTON ',
    'BELLE ',
    'BELMONT ',
    'BENWOOD ',
    'BETHANY ',
    'BETHLEHEM',
    'BEVERLY',
    'BLACKSVILLE',
    'BLENNERHASSETT',
    'BLUEFIELD',
    'BOAZ',
    'BOLIVAR',
    'BRADLEY',
    'BRADSHAW',
    'BRAMWELL',
    'BRANDONVILLE',
    'BRIDGEPORT',
    'BROOKHAVEN',
    'BRUCETON MILLS',
    'BUCKHANNON',
    'BUFFALO',
    'BURNSVILLE',
    'CAIRO',
    'CAMDEN-ON-GAULEY',
    'CAMERON',
    'CAPON BRIDGE',
    'CARPENDALE',
    'CASSVILLE',
    'CEDAR GROVE',
    'CEREDO',
    'CHAPMANVILLE',
    'CHARLESTON',
    'CHARLES TOWN',
    'CHATTAROY',
    'CHEAT LAKE',
    'CHESAPEAKE',
    'CHESTER',
    'CLARKSBURG',
    'CLAY',
    'CLEARVIEW',
    'CLENDENIN',
    'COAL CITY',
    'COAL FORK',
    'CORPORATION OF RANSON',
    'COWEN',
    'CRAB ORCHARD',
    'CRAIGSVILLE',
    'CROSS LANES',
    'CULLODEN',
    'DANIELS',
    'DANVILLE',
    'DAVIS',
    'DAVY',
    'DELBARTON',
    'DESPARD',
    'DUNBAR',
    'DURBIN',
    'EAST BANK',
    'ELEANOR',
    'ELIZABETH',
    'ELK GARDEN',
    'ELKINS',
    'ELKVIEW',
    'ELLENBORO',
    'ENTERPRISE',
    'FAIRLEA',
    'FAIRMONT',
    'FAIRVIEW',
    'FALLING SPRING',
    'FARMINGTON',
    'FAYETTEVILLE',
    'FLATWOODS',
    'FLEMINGTON',
    'FOLLANSBEE',
    'FORT ASHBY',
    'FORT GAY',
    'FRANKLIN',
    'FRIENDLY',
    'GARY',
    'GASSAWAY',
    'GAULEY BRIDGE',
    'GILBERT',
    'GILBERT CREEK',
    'GLASGOW',
    'GLEN DALE',
    'GLENVILLE',
    'GRAFTON',
    'GRANTSVILLE',
    'GRANT TOWN',
    'GRANVILLE',
    'HAMBLETON',
    'HAMLIN',
    'HANDLEY',
    'HARMAN',
    'HARPERS FERRY',
    'HARRISVILLE',
    'HARTFORD CITY',
    'HARTS',
    'HEDGESVILLE',
    'HENDERSON',
    'HENDRICKS',
    'HILLSBORO',
    'HINTON',
    'HOLDEN',
    'HOOVERSON HEIGHTS',
    'HUNDRED',
    'HUNTINGTON',
    'HURRICANE',
    'HUTTONSVILLE',
    'IAEGER',
    'INWOOD',
    'JANE LEW',
    'JEFFERSON',
    'JUNIOR',
    'KENOVA',
    'KERMIT',
    'KEYSER',
    'KEYSTONE',
    'KIMBALL',
    'KINGWOOD',
    'LEON',
    'LESTER',
    'LEWISBURG',
    'LITTLETON',
    'LOGAN',
    'LOST CREEK',
    'LUBECK',
    'LUMBERPORT',
    'MABSCOTT',
    'MACARTHUR',
    'MCMECHEN',
    'MADISON',
    'MALLORY',
    'MAN',
    'MANNINGTON',
    'MARLINTON',
    'MARMET',
    'MARTINSBURG',
    'MASON',
    'MASONTOWN',
    'MATEWAN',
    'MATOAKA',
    'MEADOW BRIDGE',
    'MIDDLEBOURNE',
    'MILL CREEK',
    'MILTON',
    'MINERALWELLS',
    'MITCHELL HEIGHTS',
    'MONONGAH',
    'MONTCALM',
    'MONTGOMERY',
    'MONTROSE',
    'MOOREFIELD',
    'MORGANTOWN',
    'MOUNDSVILLE',
    'MOUNT GAY-SHAMROCK',
    'MOUNT HOPE',
    'MULLENS',
    'NEWBURG',
    'NEW CUMBERLAND',
    'NEWELL',
    'NEW HAVEN',
    'NEW MARTINSVILLE',
    'NITRO',
    'NORTHFORK',
    'NORTH HILLS',
    'NUTTER FORT',
    'OAK HILL',
    'OAKVALE',
    'OCEANA',
    'PADEN CITY',
    'PARKERSBURG',
    'PARSONS',
    'PAW PAW',
    'PAX',
    'PEA RIDGE',
    'PENNSBORO',
    'PETERSBURG',
    'PETERSTOWN',
    'PHILIPPI',
    'PIEDMONT',
    'PINCH',
    'PINE GROVE',
    'PINEVILLE',
    'PINEY VIEW',
    'PLEASANT VALLEY',
    'POCA',
    'POINT PLEASANT',
    'POWELLTON',
    'PRATT',
    'PRINCETON',
    'PROSPERITY',
    'PULLMAN',
    'QUINWOOD',
    'RAINELLE',
    'RAVENSWOOD',
    'RED JACKET',
    'REEDSVILLE',
    'REEDY',
    'RHODELL',
    'RICHWOOD',
    'RIDGELEY',
    'RIPLEY',
    'RIVESVILLE',
    'ROMNEY',
    'RONCEVERTE',
    'ROWLESBURG',
    'RUPERT',
    'ST. ALBANS',
    'ST. MARYS',
    'SALEM',
    'SAND FORK',
    'SHADY SPRING',
    'SHEPHERDSTOWN',
    'SHINNSTON',
    'SISSONVILLE',
    'SISTERSVILLE',
    'SMITHERS',
    'SMITHFIELD',
    'SOPHIA',
    'SOUTH CHARLESTON',
    'SPENCER',
    'STANAFORD',
    'STAR CITY',
    'STONEWOOD',
    'SUMMERSVILLE',
    'SUTTON',
    'SWITZER',
    'SYLVESTER',
    'TEAYS VALLEY',
    'TERRA ALTA',
    'THOMAS',
    'THURMOND',
    'TORNADO',
    'TRIADELPHIA',
    'TUNNELTON',
    'UNION',
    'VALLEY GROVE',
    'VIENNA',
    'WAR',
    'WARDENSVILLE',
    'WASHINGTON',
    'WAYNE',
    'WEIRTON',
    'WELCH',
    'WELLSBURG',
    'WEST HAMLIN',
    'WEST LIBERTY',
    'WEST LOGAN',
    'WEST MILFORD',
    'WESTON',
    'WESTOVER',
    'WEST UNION',
    'WHEELING',
    'WHITEHALL',
    'WHITE SULPHUR SPRINGS',
    'WHITESVILLE',
    'WILEY FORD',
    'WILLIAMSON',
    'WILLIAMSTOWN',
    'WINDSOR HEIGHTS',
    'WINFIELD',
    'WOMELSDORF (COALTON)',
    'WORTHINGTON',
  ],
  Wisconsin: [
    'ABBOTSFORD',
    'ADAMS',
    'ADELL',
    'ALBANY',
    'ALGOMA',
    'ALLOUEZ',
    'ALMA',
    'ALMA CENTER',
    'ALMENA',
    'ALMOND',
    'ALTOONA',
    'AMERY',
    'AMHERST',
    'AMHERST JUNCTION',
    'ANIWA',
    'ANTIGO',
    'APPLETON',
    'ARCADIA',
    'ARENA',
    'ARGYLE',
    'ARLINGTON',
    'ARPIN',
    'ASHLAND',
    'ASHWAUBENON',
    'ATHENS',
    'AUBURNDALE',
    'AUGUSTA',
    'AVOCA',
    'BAGLEY',
    'BALDWIN',
    'BALSAM LAKE',
    'BANGOR',
    'BARABOO',
    'BARNEVELD',
    'BARRON',
    'BAY CITY',
    'BAYFIELD',
    'BAYSIDE',
    'BEAR CREEK',
    'BEAVER DAM',
    'BELGIUM',
    'BELL CENTER',
    'BELLEVILLE',
    'BELLEVUE TOWN',
    'BELMONT',
    'BELOIT',
    'BENTON',
    'BERLIN',
    'BIG BEND',
    'BIG FALLS',
    'BIRCHWOOD',
    'BIRNAMWOOD',
    'BIRON',
    'BLACK CREEK',
    'BLACK EARTH',
    'BLACK RIVER FALLS',
    'BLAIR',
    'BLANCHARDVILLE',
    'BLOOMER',
    'BLOOMINGTON',
    'BLUE MOUNDS',
    'BLUE RIVER',
    'BOAZ',
    'BOHNERS LAKE',
    'BONDUEL',
    'BOSCOBEL',
    'BOWLER',
    'BOYCEVILLE',
    'BOYD',
    'BRANDON',
    'BRICE PRAIRIE',
    'BRILLION',
    'BRODHEAD',
    'BROKAW',
    'BROOKFIELD',
    'BROOKLYN',
    'BROWN DEER',
    'BROWNS LAKE',
    'BROWNSVILLE',
    'BROWNTOWN',
    'BRUCE',
    'BUFFALO CITY',
    'BURLINGTON',
    'BUTLER',
    'BUTTERNUT',
    'CADOTT',
    'CAMBRIA',
    'CAMBRIDGE',
    'CAMERON',
    'CAMPBELLSPORT',
    'CAMP DOUGLAS',
    'CAMP LAKE',
    'CASCADE',
    'CASCO',
    'CASHTON',
    'CASSVILLE',
    'CATAWBA',
    'CAZENOVIA',
    'CECIL',
    'CEDARBURG',
    'CEDAR GROVE',
    'CENTURIA',
    'CHAIN O LAKES-KING',
    'CHASEBURG',
    'CHENEQUA',
    'CHETEK',
    'CHIEF LAKE',
    'CHILTON',
    'CHIPPEWA FALLS',
    'CLAYTON',
    'CLEAR LAKE',
    'CLEVELAND',
    'CLINTON',
    'CLINTONVILLE',
    'CLYMAN',
    'COBB',
    'COCHRANE',
    'COLBY',
    'COLEMAN',
    'COLFAX',
    'COLOMA',
    'COLUMBUS',
    'COMBINED LOCKS',
    'COMO',
    'CONRATH',
    'COON VALLEY',
    'CORNELL',
    'COTTAGE GROVE',
    'COUDERAY',
    'CRANDON',
    'CRIVITZ',
    'CROSS PLAINS',
    'CUBA CITY',
    'CUDAHY',
    'CUMBERLAND',
    'CURTISS',
    'DALLAS',
    'DANE',
    'DARIEN',
    'DARLINGTON',
    'DEERFIELD',
    'DEER PARK',
    'DEFOREST',
    'DELAFIELD',
    'DELAVAN',
    'DELAVAN LAKE',
    'DENMARK',
    'DE PERE',
    'DE SOTO',
    'DICKEYVILLE',
    'DODGEVILLE',
    'DORCHESTER',
    'DOUSMAN',
    'DOWNING',
    'DOYLESTOWN',
    'DRESSER',
    'DURAND',
    'EAGLE',
    'EAGLE LAKE',
    'EAGLE RIVER',
    'EASTMAN',
    'EAST TROY',
    'EAU CLAIRE',
    'EDEN',
    'EDGAR',
    'EDGERTON',
    'EGG HARBOR',
    'ELAND',
    'ELDERON',
    'ELEVA',
    'ELKHART LAKE',
    'ELKHORN',
    'ELK MOUND',
    'ELLSWORTH',
    'ELM GROVE',
    'ELMWOOD',
    'ELMWOOD PARK',
    'ELROY',
    'EMBARRASS',
    'ENDEAVOR',
    'EPHRAIM',
    'ETTRICK',
    'EVANSVILLE',
    'EVERGREEN',
    'EXELAND',
    'FAIRCHILD',
    'FAIRWATER',
    'FALL CREEK',
    'FALL RIVER',
    'FENNIMORE',
    'FENWOOD',
    'FERRYVILLE',
    'FITCHBURG',
    'FOND DU LAC',
    'FONTANA-ON-GENEVA LAKE',
    'FOOTVILLE',
    'FORESTVILLE',
    'FORT ATKINSON',
    'FOUNTAIN CITY',
    'FOX LAKE',
    'FOX POINT',
    'FRANCIS CREEK',
    'FRANKLIN',
    'FRANKSVILLE',
    'FREDERIC',
    'FREDONIA',
    'FREMONT',
    'FRENCH ISLAND',
    'FRIENDSHIP',
    'FRIESLAND',
    'GALESVILLE',
    'GAYS MILLS',
    'GENOA',
    'GENOA CITY',
    'GERMANTOWN',
    'GILLETT',
    'GILMAN',
    'GLENBEULAH',
    'GLENDALE',
    'GLEN FLORA',
    'GLENWOOD CITY',
    'GRAFTON',
    'GRANTON',
    'GRANTSBURG',
    'GRATIOT',
    'GREEN BAY',
    'GREENDALE',
    'GREENFIELD',
    'GREEN LAKE',
    'GREENWOOD',
    'GRESHAM',
    'HALES CORNERS',
    'HAMMOND',
    'HANCOCK',
    'HARTFORD',
    'HARTLAND',
    'HATLEY',
    'HAUGEN',
    'HAWKINS',
    'HAYWARD',
    'HAZEL GREEN',
    'HEBRON',
    'HELENVILLE',
    'HEWITT',
    'HIGHLAND',
    'HILBERT',
    'HILLSBORO',
    'HIXTON',
    'HOLLANDALE',
    'HOLMEN',
    'HORICON',
    'HORTONVILLE',
    'HOWARD',
    'HOWARDS GROVE',
    'HUDSON',
    'HURLEY',
    'HUSTISFORD',
    'HUSTLER',
    'INDEPENDENCE',
    'INGRAM',
    'IOLA',
    'IRON RIDGE',
    'IRONTON',
    'IXONIA',
    'JACKSON',
    'JANESVILLE',
    'JEFFERSON',
    'JOHNSON CREEK',
    'JUNCTION CITY',
    'JUNEAU',
    'KAUKAUNA',
    'KEKOSKEE',
    'KELLNERSVILLE',
    'KENDALL',
    'KENNAN',
    'KENOSHA',
    'KESHENA',
    'KEWASKUM',
    'KEWAUNEE',
    'KIEL',
    'KIMBERLY',
    'KINGSTON',
    'KNAPP',
    'KOHLER',
    'LAC DU FLAMBEAU',
    'LAC LA BELLE',
    'LA CROSSE',
    'LADYSMITH',
    'LA FARGE',
    'LAKE DELTON',
    'LAKE GENEVA',
    'LAKE KOSHKONONG',
    'LAKE LAC LA BELLE',
    'LAKE MILLS',
    'LAKE NEBAGAMON',
    'LAKE RIPLEY',
    'LAKE SHANGRILA',
    'LAKE WAZEECHA',
    'LAKE WISCONSIN',
    'LAKE WISSOTA',
    'LANCASTER',
    'LANNON',
    'LA VALLE',
    'LEGEND LAKE',
    'LENA',
    'LIME RIDGE',
    'LINDEN',
    'LITTLE CHUTE',
    'LITTLE ROUND LAKE',
    'LIVINGSTON',
    'LODI',
    'LOGANVILLE',
    'LOHRVILLE',
    'LOMIRA',
    'LONE ROCK',
    'LOWELL',
    'LOYAL',
    'LUBLIN',
    'LUCK',
    'LUXEMBURG',
    'LYNDON STATION',
    'LYNXVILLE',
    'MCFARLAND',
    'MADISON',
    'MAIDEN ROCK',
    'MANAWA',
    'MANITOWOC',
    'MAPLE BLUFF',
    'MARATHON CITY',
    'MARIBEL',
    'MARINETTE',
    'MARION',
    'MARKESAN',
    'MARQUETTE',
    'MARSHALL',
    'MARSHFIELD',
    'MASON',
    'MATTOON',
    'MAUSTON',
    'MAYVILLE',
    'MAZOMANIE',
    'MEDFORD',
    'MELLEN',
    'MELROSE',
    'MELVINA',
    'MENASHA',
    'MENOMONEE FALLS',
    'MENOMONIE',
    'MEQUON',
    'MERRILL',
    'MERRILLAN',
    'MERRIMAC',
    'MERTON',
    'MIDDLETON',
    'MIDDLE VILLAGE',
    'MILLADORE',
    'MILLTOWN',
    'MILTON',
    'MILWAUKEE',
    'MINERAL POINT',
    'MINONG',
    'MISHICOT',
    'MONDOVI',
    'MONONA',
    'MONROE',
    'MONTELLO',
    'MONTFORT',
    'MONTICELLO',
    'MONTREAL',
    'MOSINEE',
    'MOUNT CALVARY',
    'MOUNT HOPE',
    'MOUNT HOREB',
    'MOUNT STERLING',
    'MUKWONAGO',
    'MUSCODA',
    'MUSKEGO',
    'NASHOTAH',
    'NECEDAH',
    'NEENAH',
    'NEILLSVILLE',
    'NEKOOSA',
    'NELSON',
    'NELSONVILLE',
    'NEOPIT',
    'NEOSHO',
    'NESHKORO',
    'NEW AUBURN',
    'NEW BERLIN',
    'NEWBURG',
    'NEW GLARUS',
    'NEW HOLSTEIN',
    'NEW LISBON',
    'NEW LONDON',
    'NEW POST',
    'NEW RICHMOND',
    'NIAGARA',
    'NICHOLS',
    'NORTH BAY',
    'NORTH FOND DU LAC',
    'NORTH FREEDOM',
    'NORTH HUDSON',
    'NORTH PRAIRIE',
    'NORWALK',
    'OAK CREEK',
    'OAKDALE',
    'OAKFIELD',
    'OCONOMOWOC',
    'OCONOMOWOC LAKE',
    'OCONTO',
    'OCONTO FALLS',
    'ODANAH',
    'OGDENSBURG',
    'OKAUCHEE LAKE',
    'OLIVER',
    'OMRO',
    'ONALASKA',
    'ONEIDA',
    'ONTARIO',
    'OOSTBURG',
    'OREGON',
    'ORFORDVILLE',
    'OSCEOLA',
    'OSHKOSH',
    'OSSEO',
    'OWEN',
    'OXFORD',
    'PADDOCK LAKE',
    'PALMYRA',
    'PARDEEVILLE',
    'PARK FALLS',
    'PARK RIDGE',
    'PATCH GROVE',
    'PELL LAKE',
    'PEPIN',
    'PESHTIGO',
    'PEWAUKEE',
    'PEWAUKEE',
    'PHILLIPS',
    'PIGEON FALLS',
    'PITTSVILLE',
    'PLAIN',
    'PLAINFIELD',
    'PLATTEVILLE',
    'PLEASANT PRAIRIE',
    'PLOVER',
    'PLUM CITY',
    'PLYMOUTH',
    'POPLAR',
    'PORTAGE',
    'PORT EDWARDS',
    'PORT WASHINGTON',
    'POTOSI',
    'POTTER',
    'POTTER LAKE',
    'POUND',
    'POWERS LAKE',
    'POYNETTE',
    'PRAIRIE DU CHIEN',
    'PRAIRIE DU SAC',
    'PRAIRIE FARM',
    'PRENTICE',
    'PRESCOTT',
    'PRINCETON',
    'PULASKI',
    'RACINE',
    'RADISSON',
    'RANDOLPH',
    'RANDOM LAKE',
    'READSTOWN',
    'REDGRANITE',
    'REEDSBURG',
    'REEDSVILLE',
    'REESEVILLE',
    'RESERVE',
    'REWEY',
    'RHINELANDER',
    'RIB LAKE',
    'RIB MOUNTAIN',
    'RICE LAKE',
    'RICHLAND CENTER',
    'RIDGELAND',
    'RIDGEWAY',
    'RIO',
    'RIPON',
    'RIVER FALLS',
    'RIVER HILLS',
    'ROBERTS',
    'ROCHESTER',
    'ROCKDALE',
    'ROCKLAND',
    'ROCK SPRINGS',
    'ROME',
    'ROSENDALE',
    'ROSHOLT',
    'ROTHSCHILD',
    'RUDOLPH',
    'ST. CLOUD',
    'ST. CROIX FALLS',
    'ST. FRANCIS',
    'ST. NAZIANZ',
    'SAUK CITY',
    'SAUKVILLE',
    'SCANDINAVIA',
    'SCHOFIELD',
    'SEYMOUR',
    'SEYMOUR',
    'SHARON',
    'SHAWANO',
    'SHEBOYGAN',
    'SHEBOYGAN FALLS',
    'SHELDON',
    'SHELL LAKE',
    'SHERWOOD',
    'SHIOCTON',
    'SHOREWOOD',
    'SHOREWOOD HILLS',
    'SHULLSBURG',
    'SILVER LAKE',
    'SIREN',
    'SISTER BAY',
    'SLINGER',
    'SOLDIERS GROVE',
    'SOLON SPRINGS',
    'SOMERSET',
    'SOUTH MILWAUKEE',
    'SOUTH WAYNE',
    'SPARTA',
    'SPENCER',
    'SPOONER',
    'SPRING GREEN',
    'SPRING VALLEY',
    'STANLEY',
    'STAR PRAIRIE',
    'STETSONVILLE',
    'STEUBEN',
    'STEVENS POINT',
    'STOCKBRIDGE',
    'STOCKHOLM',
    'STODDARD',
    'STOUGHTON',
    'STRATFORD',
    'STRUM',
    'STURGEON BAY',
    'STURTEVANT',
    'SULLIVAN',
    'SUN PRAIRIE',
    'SUPERIOR',
    'SUPERIOR',
    'SURING',
    'SUSSEX',
    'TAINTER LAKE',
    'TAYLOR',
    'TENNYSON',
    'THERESA',
    'THIENSVILLE',
    'THORP',
    'TIGERTON',
    'TOMAH',
    'TOMAHAWK',
    'TONY',
    'TREMPEALEAU',
    'TURTLE LAKE',
    'TWIN LAKES',
    'TWO RIVERS',
    'UNION CENTER',
    'UNION GROVE',
    'UNITY',
    'VALDERS',
    'VERONA',
    'VESPER',
    'VIOLA',
    'VIROQUA',
    'WALDO',
    'WALES',
    'WALWORTH',
    'WARRENS',
    'WASHBURN',
    'WATERFORD',
    'WATERFORD NORTH',
    'WATERLOO',
    'WATERTOWN',
    'WAUKESHA',
    'WAUNAKEE',
    'WAUPACA',
    'WAUPUN',
    'WAUSAU',
    'WAUSAUKEE',
    'WAUTOMA',
    'WAUWATOSA',
    'WAUZEKA',
    'WEBSTER',
    'WEST ALLIS',
    'WEST BARABOO',
    'WEST BEND',
    'WESTBY',
    'WESTFIELD',
    'WEST MILWAUKEE',
    'WESTON',
    'WEST SALEM',
    'WEYAUWEGA',
    'WEYERHAEUSER',
    'WHEELER',
    'WHITEFISH BAY',
    'WHITEHALL',
    'WHITE LAKE',
    'WHITELAW',
    'WHITEWATER',
    'WHITING',
    'WILD ROSE',
    'WILLIAMS BAY',
    'WILSON',
    'WILTON',
    'WIND LAKE',
    'WIND POINT',
    'WINDSOR',
    'WINNECONNE',
    'WINTER',
    'WISCONSIN DELLS',
    'WISCONSIN RAPIDS',
    'WITHEE',
    'WITTENBERG',
    'WONEWOC',
    'WOODMAN',
    'WOODVILLE',
    'WRIGHTSTOWN',
    'WYEVILLE',
    'WYOCENA',
    'YUBA',
    'ZOAR',
  ],
  Wyoming: [
    'AFTON',
    'AIRPORT ROAD',
    'ALBANY',
    'ALBIN',
    'ALCOVA',
    'ALPINE',
    'ALPINE NORTHEAST',
    'ALPINE NORTHWEST',
    'ALTA',
    'ANTELOPE HILLS',
    'ANTELOPE VALLEY-CRESTVIEW',
    'ARAPAHOE',
    'ARROWHEAD SPRINGS',
    'ARVADA',
    'ATLANTIC CITY',
    'AUBURN',
    'BAGGS',
    'BAIROIL',
    'BAR NUNN',
    'BASIN',
    'BEDFORD',
    'BESSEMER BEND',
    'BIG HORN',
    'BIG PINEY',
    'BONDURANT',
    'BOULDER',
    'BOULDER FLATS',
    'BROOKHURST',
    'BUFFALO',
    'BURLINGTON',
    'BURNS',
    'BYRON',
    'CALPET',
    'CARTER',
    'CASPER',
    'CASPER MOUNTAIN',
    'CENTENNIAL',
    'CHEYENNE',
    'CHUGCREEK',
    'CHUGWATER',
    'CLEARMONT',
    'CLEARVIEW ACRES',
    'CODY',
    'COKEVILLE',
    'CORA',
    'COWLEY',
    'CROWHEART',
    'DANIEL',
    'DAYTON',
    'DEAVER',
    'DIAMONDVILLE',
    'DIXON',
    'DOUGLAS',
    'DUBOIS',
    'EAST THERMOPOLIS',
    'EDEN',
    'EDGERTON',
    'ELK MOUNTAIN',
    'ESTERBROOK',
    'ETHETE',
    'ETNA',
    'EVANSTON',
    'EVANSVILLE',
    'FAIRVIEW',
    'FARSON',
    'FONTENELLE',
    'FORT BRIDGER',
    'FORT LARAMIE',
    'FORT WASHAKIE',
    'FOX FARM-COLLEGE',
    'FRANNIE',
    'GARLAND',
    'GILLETTE',
    'GLENDO',
    'GLENROCK',
    'GRAND ENCAMPMENT',
    'GRANGER',
    'GREEN RIVER',
    'GREYBULL',
    'GROVER',
    'GUERNSEY',
    'HANNA',
    'HARTRANDT',
    'HARTVILLE',
    'HAWK SPRINGS',
    'HILL VIEW HEIGHTS',
    'HOBACK',
    'HOMA HILLS',
    'HUDSON',
    'HULETT',
    'HUNTLEY',
    'HYATTVILLE',
    'JACKSON',
    'JAMES TOWN',
    'JEFFREY CITY',
    'JOHNSTOWN',
    'KAYCEE',
    'KEMMERER',
    'KIRBY',
    'LA BARGE',
    'LA GRANGE',
    'LAKEVIEW NORTH',
    'LANCE CREEK',
    'LANDER',
    'LARAMIE',
    'LINGLE',
    'LITTLE AMERICA',
    'LONETREE',
    'LOST SPRINGS',
    'LOVELL',
    'LUCERNE',
    'LUSK',
    'LYMAN',
    'MCKINNON',
    'MC NUTT',
    'MANDERSON',
    'MANVILLE',
    'MARBLETON',
    'MEADOW ACRES',
    'MEADOW LARK LAKE',
    'MEDICINE BOW',
    'MEETEETSE',
    'MIDWEST',
    'MILLS',
    'MOORCROFT',
    'MOOSE WILSON ROAD',
    'MOUNTAIN VIEW',
    'NEWCASTLE',
    'NORTH ROCK SPRINGS',
    'OAKLEY',
    'OPAL',
    'OSAGE',
    'OWL CREEK',
    'PARKMAN',
    'PAVILLION',
    'PINE BLUFFS',
    'PINEDALE',
    'PINE HAVEN',
    'POINT OF ROCKS',
    'POWDER RIVER',
    'POWELL',
    'PURPLE SAGE',
    'RAFTER J RANCH',
    'RALSTON',
    'RANCHESTER',
    'RANCHETTES',
    'RAWLINS',
    'RED BUTTE',
    'RELIANCE',
    'RIVERSIDE',
    'RIVERTON',
    'ROBERTSON',
    'ROCK RIVER',
    'ROCK SPRINGS',
    'ROLLING HILLS',
    'SARATOGA',
    'SHERIDAN',
    'SHOSHONI',
    'SINCLAIR',
    'SLATER',
    'SLEEPY HOLLOW',
    'SMOOT',
    'SOUTH FLAT',
    'SOUTH GREELEY',
    'SOUTH PARK',
    'STAR VALLEY RANCH',
    'STORY',
    'SUNDANCE',
    'SUPERIOR',
    'SWEENEY RANCH',
    'TABLE ROCK',
    'TAYLOR',
    'TEN SLEEP',
    'TETON VILLAGE',
    'THAYNE',
    'THE BUTTES',
    'THERMOPOLIS',
    'TORRINGTON',
    'TURNERVILLE',
    'UPTON',
    'VAN TASSELL',
    'VETERAN',
    'VISTA WEST',
    'WAMSUTTER',
    'WARREN AFB',
    'WASHAKIE TEN',
    'WASHAM',
    'WEST RIVER',
    'WESTVIEW CIRCLE',
    'WHEATLAND',
    'WILSON',
    'WINCHESTER',
    'WOODS LANDING-JELM',
    'WORLAND',
    'WRIGHT',
    'YODER',
    'Y-O RANCH',
  ],
};
