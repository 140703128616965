import { toast } from 'react-toastify';
import { format } from 'date-fns';
import axiosService from './api/axios';
import axios from 'axios';
import { stateAndCities, stateHash } from './settings/state&cities';
import { userIdRegExp } from './validators';

/* eslint-disable no-console */
export const monthsName = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'Aug',
  '09': 'Sept',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};
export const getFormatDate = (val: Date | string) => {
  try {
    const monthNum = format(new Date(val), 'MM-dd-yyyy').split('-')[0];
    let formatDate = format(new Date(val), 'MM-dd-yyyy').split('-');
    formatDate.splice(0, 1, monthsName[monthNum]);
    let formatDateString = '';
    for (const ele of formatDate) {
      formatDateString += ele + ' ';
    }
    return formatDateString.trim();
  } catch (e) {
    return undefined;
  }
};
export const getChatFormatDate = (val: Date | string) => {
  try {
    const currentDate = new Date();
    const chatDate = new Date(val);
    if (currentDate.toLocaleDateString() === chatDate.toLocaleDateString()) {
      const newTime = format(new Date(val), 'hh:mm a');
      return newTime;
    } else {
      if (currentDate.getFullYear() === chatDate.getFullYear()) {
        const monthNum = format(new Date(val), 'MM-dd-yyyy').split('-')[0];
        let formatDate = format(new Date(val), 'MM-dd-yyyy').split('-')[1];
        let formatDateString = `${monthsName[monthNum]} ${formatDate} `;
        return formatDateString.trim();
      } else {
        let formatDate = format(new Date(val), 'MM-dd-yyyy').split('-');
        let formatDateString = formatDate.join('/');
        return formatDateString.trim();
      }
    }
  } catch (e) {
    return undefined;
  }
};
export const getFormatTime = (val: Date | string) => {
  try {
    const newTime = format(new Date(val), 'hh:mm a');
    return newTime;
  } catch (e) {
    return undefined;
  }
};

export const log = (e: any) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(e);
  }
};
export const logError = (e: any) => {
  if (process.env.NODE_ENV !== 'production') {
    console.error(e);
  }
};

export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export const copyToClipBoard = async (copyMe: string) => {
  try {
    await navigator.clipboard.writeText(copyMe);
    toast.success('Copied!');
  } catch (err) {
    logError(err);
  }
};

export const deformatPhone = (value: string) => {
  if (value) {
    return value.replace(/[^\d]/g, '');
  }
  return value;
};
export const formatOnlyNumsWithDecimal = (value: string) => {
  const onlyNums = `${value}`;
  return onlyNums.replace(/[^\d.]/g, '');
};

export const formatOnlyNums = (value: string) => {
  const onlyNums = `${value}`;
  return onlyNums.replace(/[^\d]/g, '');
};

export function formatPhoneNumber(value: string) {
  if (!value) return value;

  const phoneNumber = deformatPhone(value);

  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)})-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

export const capitalize = (value: string) => {
  return value?.charAt(0).toUpperCase() + value?.slice(1);
};

export const getFormattedName = (firstName?: string, lastName?: string) => {
  return `${capitalize(firstName || '')} ${
    (lastName || '')
      ?.slice(0, 1)
      ?.charAt(0)
      ?.toUpperCase()
      ?.concat(lastName ? '.' : '') || 'User'
  }`;
};

export const priceFormat = (value?: string[] | any) => {
  if (value) {
    const updatedValue = value?.toString().split('.');
    return (
      updatedValue[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') +
      (updatedValue[1] ? `,${updatedValue[1]}` : ',00')
    );
  }
  return value;
};

export const IntercomKeys = {
  APP_ID: 'zor2w60j',
};

export const toCurrencyFormat = (value?: string | any) => {
  if (value) {
    return value?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }
  return value;
};

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor;
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }
  return 'unknown';
}

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const isEmailAvailable = async (email: string) => {
  if (validateEmail(email)) {
    try {
      const { data } = await axiosService.post(`auth/user-check`, { email });

      return !data.data.status;
    } catch (e) {
      return false;
    }
  }
  return true;
};

export const isPhoneAvailable = async (value: string) => {
  try {
    let mobile = deformatPhone(value);
    const { data } = await axiosService.post(`auth/user-check`, { mobile });
    return !data.data.status;
  } catch (e) {
    return false;
  }
};

export const isAdminEmailAvailable = async (email: string) => {
  if (validateEmail(email)) {
    try {
      const { data } = await axiosService.post(`auth/user-check-admin`, { email });

      return !data.data.status;
    } catch (e) {
      return false;
    }
  }
  return true;
};

export const isAdminPhoneAvailable = async (value: string) => {
  try {
    let mobile = deformatPhone(value);
    const { data } = await axiosService.post(`auth/user-check-admin`, { mobile });
    return !data.data.status;
  } catch (e) {
    return false;
  }
};

export const isUserIdAvailable = async (value: string) => {
  const userId = value.trim();
  if (userIdRegExp.test(userId)) {
    try {
      const { data } = await axiosService.post(`auth/user-check`, { userId });

      return !data.data.status;
    } catch (e) {
      return false;
    }
  }
  return true;
};

export const getPDFURL = async (id: string) => {
  if (id) {
    try {
      const { data } = await axiosService.get(`/appointment/${id}/invoice`);
      return data?.data?.invoiceLink;
    } catch (e) {
      // @ts-ignore
      toast.error(e?.response?.data?.message);
      return undefined;
    }
  }
};

// @ts-ignore
export const downloadFile = (blob, fileName) => {
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  document.body.append(link);
  link.click();
  link.remove();
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export const DownloadPDF = async (id: string, callBack: (val: any) => void, link?: string) => {
  callBack('1%');
  try {
    let data;
    if (link) data = link;
    else data = await getPDFURL(id);

    if (!data) return callBack('');

    const response = await axios({
      url: data,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: function (progressEvent) {
        if (progressEvent?.lengthComputable) {
          const percentage = Math.round((progressEvent?.loaded / progressEvent?.total) * 100) + '%';
          callBack(percentage);

          if (percentage === '100%') setTimeout(() => callBack(''), 1500);
        }
      },
    });

    const url = new Blob([response?.data], { type: 'application/pdf' });
    downloadFile(url, 'Invoice.pdf');
  } catch (e) {
    // @ts-ignore
    // console.log('error', e?.response?.data?.message);
    callBack('');
  }
};

export const DownloadDocFile = async (url: string, type?: string, fileName?: string) => {
  try {
    const response = await axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
    });
    const blob = new Blob([response?.data], { type: type });
    downloadFile(blob, fileName);
  } catch (e) {
    // @ts-ignore
    console.log('error', e?.response?.data?.message);
  }
};

export const AmountTo2Digit = (number: number) =>
  Math.floor(number) === number ? number : number?.toFixed(2);
interface cityArrayProps {
  label: string;
  value: string;
}
export const getCitiesList = (stateValue: any) => {
  let cityListArray: cityArrayProps[] = [];
  const stateCode = Object.entries(stateHash).find((v) => v[0] === stateValue);
  if (stateCode) {
    const citiesList = Object.entries(stateAndCities).find((v) => v[0] === stateCode[1]);
    if (citiesList) {
      cityListArray = citiesList[1].map((item) => ({ label: item, value: item }));
    }
    return cityListArray;
  }
  return cityListArray;
};

export const isArrayofStrings = (arr: string[] | {}[]) => {
  return arr?.every((element) => typeof element === 'string');
};

// Function to print values of the same key separated by commas
export const printValuesByKey = (arr: [], key: string) => {
  const values = arr?.map((obj) => obj[key]).filter(Boolean);
  return values?.join(',');
};

export const filterObjectKeys = (arrayOfObjects: [{}]) => {
  let filteredObject = {};
  for (const obj of arrayOfObjects) {
    for (const [key, value] of Object.entries(obj)) {
      if (
        value !== null &&
        value?.length !== 0 &&
        value !== '' &&
        // Object.keys(value)?.length &&
        value !== undefined &&
        !(key in filteredObject)
      ) {
        filteredObject[key] = value;
      }
    }
  }

  return filteredObject;
};

export const splitCamelCase = (input: string) => {
  let result = input.charAt(0);

  for (let i = 1; i < input.length; i++) {
    const currentChar = input.charAt(i);
    if (currentChar === currentChar.toUpperCase()) {
      result += ' ';
    }

    result += currentChar;
  }

  return result;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  // Get year, month, and day
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
  const day = String(date.getDate()).padStart(2, '0');

  let formattedDate = `${month}/${day}/${year}`;

  return formattedDate;
};

export const dateRangeFormat = (date_range: any) => {
  let query = null;
  if (date_range) {
    if (Array.isArray(date_range)) {
      [query] = date_range;
      return query;
    } else {
      const arrayDate = date_range?.split('-').map((val: any) => new Date(+val));
      query = arrayDate;
      return query;
    }
  }
  return [null, null];
};
