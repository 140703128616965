import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { withRouter } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    const root = document.getElementById("root");
    root?.scrollTo(0, 0);
    window?.scrollTo(0, 0);
  }, [pathname, search]);

  return null;
};

export default withRouter(ScrollToTop)
