import React, { FC, useEffect, useState } from 'react';
import { Button, CancelButton } from 'molecules/Button';
import { Form, Formik, FormikHelpers } from 'formik';
import * as yup from 'yup';
import FormikSelect from 'molecules/FormikSelect';
import { useFormSubmitWithLoading } from 'utils/hooks/useFormSubmitWithLoading';
import RichTextEditor from 'react-rte';
import getAdminDashboard from 'contexts/AdminDashboardContext';
import Modal from 'molecules/ConfirmModalTemplate';
import Loader from 'atoms/Loader';

const ValidationSchema = yup.object().shape({
  soapNoteTemplateId: yup.object().required('required'),
  templateData: yup.string(),
});

export type FormProps = {
  onSubmit: (values: any, formikHelpers?: FormikHelpers<any>) => void;
  onClose: () => void;
  open: boolean;
  appointmentDetails?: any;
};

const ChangeSoapNoteModal: FC<FormProps> = ({ onSubmit, onClose, open, appointmentDetails }) => {
  const {
    state: { soapNoteTemplatesList, soapNoteTemplatesLoading },
    actions: { getSoapNoteTemplates },
  } = getAdminDashboard();
  const [templateValue, setTemplateValue] = useState<any>({
    soapNoteTemplateId: { id: '' },
  });
  useEffect(() => {
    getSoapNoteTemplates();
  }, [getSoapNoteTemplates]);

  useEffect(() => {
    if (soapNoteTemplatesList?.length > 0) {
      const mytempid = soapNoteTemplatesList?.find(
        (item: any) => item?.name === appointmentDetails?.soapNoteTemplateName,
      );
      setTemplateValue({
        soapNoteTemplateId: {
          ...mytempid,
          template: appointmentDetails?.soapNote
            ? appointmentDetails?.soapNote
            : appointmentDetails?.soapNoteTemplate,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentDetails?.soapNoteTemplateName, soapNoteTemplatesList]);
  // console.log('templateValue', templateValue);
  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);
  return (
    <Modal
      headerTextId="provider.soapnote"
      open={open}
      // @ts-ignore
      onClose={onClose}
    >
      {soapNoteTemplatesLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={templateValue}
          validationSchema={ValidationSchema}
          onSubmit={onSubmitHandler}
          enableReinitialize
        >
          {({ values, setFieldValue, touched, setFieldTouched }) => (
            <Form className="grid gap-3 sm:px-2 bg-white">
              {appointmentDetails?.appointmentStatus === 'SCHEDULED' && (
                <FormikSelect
                  label="admin.soap.note.templates.heading"
                  name="soapNoteTemplateId"
                  //@ts-ignore
                  maxMenuHeight={200}
                  options={soapNoteTemplatesList?.map((item: any) => {
                    return {
                      label: item?.name,
                      value: item,
                    };
                  })}
                />
              )}
              <div className="z-0">
                <RichTextEditor
                  editorClassName="h-80 z-0 overflow-y"
                  readOnly
                  value={RichTextEditor.createValueFromString(
                    values?.soapNoteTemplateId?.template,
                    'html',
                  )}
                />
              </div>
              {appointmentDetails?.appointmentStatus === 'SCHEDULED' && (
                <div className="flex sm:flex-row flex-col justify-center gap-3 sm:gap-6 mt-4">
                  <Button
                    type="submit"
                    loading={loading}
                    textid="confirm"
                    className="px-0 justify-center w-auto sm:px-16 sm:py-3"
                  />
                  <CancelButton
                    type="button"
                    textid="cancel"
                    onClick={onClose}
                    className="px-0 justify-center w-auto sm:px-16 sm:py-3"
                  ></CancelButton>
                </div>
              )}
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default ChangeSoapNoteModal;
