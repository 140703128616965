import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { appRoutes } from 'utils/settings/constants';
import { toast } from 'react-toastify';
import { Button } from 'molecules/Button';
import Loader from 'atoms/Loader';

type CheckoutFormProps = {
  appointmentId: string;
  clientSecret: string;
  onSuccessfulCheckout?: (val?: any) => void;
  setPaymentIsProcessing: (val: boolean) => void;
  totalAmount: string | number;
};

export default function CheckoutForm({
  appointmentId,
  onSuccessfulCheckout,
  setPaymentIsProcessing,
  totalAmount,
  clientSecret,
}: CheckoutFormProps) {
  const stripe = useStripe();
  const elements = useElements();

  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState<undefined | string>('');

  const handleCardDetailsChange = (ev: any) => {
    ev.error ? setCheckoutError(ev?.error?.message) : setCheckoutError('');
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setCheckoutError('');
    setProcessingTo(true);
    setPaymentIsProcessing(true);

    try {
      const data = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window?.location?.origin}${appRoutes.APPOINTMENT_DETAILS}/${appointmentId}`,
        },
        redirect: 'if_required',
      });

      if (data?.error) {
        if (
          data?.error?.type === 'card_error' ||
          data?.error?.type === 'validation_error' ||
          data?.error?.code === 'payment_intent_authentication_failure'
        ) {
          setCheckoutError(data?.error?.message);
        } else {
          setCheckoutError('An unexpected error occured, Try Again!');
        }
        setProcessingTo(false);
        setPaymentIsProcessing(false);
        return;
      }

      if (data?.paymentIntent?.status === 'succeeded') {
        setProcessingTo(false);
        setPaymentIsProcessing(false);
        toast.success('Payment is Successful!');
        onSuccessfulCheckout && onSuccessfulCheckout();
      }
      //
    } catch (err) {
      console.log('catching error');
      // @ts-ignore
      setCheckoutError(err?.message);
      setProcessingTo(false);
      setPaymentIsProcessing(false);
    }
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <>
      <div className={`${loading ? 'opacity-0 h-0' : 'opacity-1 h-full'}`}>
        <form onSubmit={handleSubmit}>
          <PaymentElement onChange={handleCardDetailsChange} />

          {checkoutError && (
            <div className="my-3 text-red-500 font-medium text-sm">{checkoutError}</div>
          )}

          <div className="flex pt-4 pb-2 justify-center">
            <Button
              disabled={isProcessing || !stripe || !elements}
              loading={isProcessing}
              id="submit"
              type="submit"
              className="sm:w-3/5"
            >
              <div className="flex gap-2.5">
                <span>Pay</span>
                <span>${totalAmount}</span>
              </div>
            </Button>
          </div>
        </form>
      </div>

      {loading && (
        <div className="h-44">
          <Loader />
        </div>
      )}
    </>
  );
}
