import { useCallback, useReducer } from 'react';
import { toast } from 'react-toastify';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';

const initialState = {
  bbposReaders: { data: [], meta: {} },
  bbposReadersLoading: false,
  //   subscriptionDetails: {},
  //   subscriptionDetailsLoading: false,
};

const actions = {
  bbposReaders: createAsyncActions('GET_ALL_BBPOS_READERS_LIST'),
};

const paymentByPOSReducer = createReducer({
  // ----- For Listing -----
  [`${actions.bbposReaders.loading}`]: (state) => ({
    ...state,
    bbposReadersLoading: true,
  }),
  [`${actions.bbposReaders.success}`]: (state, { payload }) => ({
    ...state,
    bbposReaders: payload,
    bbposReadersLoading: false,
  }),
  [`${actions.bbposReaders.failure}`]: (state) => ({
    ...state,
    bbposReadersLoading: false,
  }),
});

export const { useContext: usePaymentByPOS, Provider: PaymentByBBPOSProvider } = createContainer(
  () => {
    const [state, dispatch] = useReducer(paymentByPOSReducer, initialState);

    const getBBPOSReadersList = useCallback(async () => {
      try {
        dispatch(actions.bbposReaders.loading());

        let api = `/payments/list-all-BBPOS-readers`;
        const response = await axiosService?.get(api);

        dispatch(actions.bbposReaders.success(response?.data?.data));

        return response?.data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
        dispatch(actions.bbposReaders.failure());
      }
      return undefined;
    }, []);

    const processPaymentByPOS = useCallback(async (body) => {
      try {
        let api = `/payments/process-payment-for-reader`;
        const response = await axiosService?.post(api, body);
        // toast.success(data.message);
        response?.data &&
          response.data?.success &&
          toast.success('Please swap or tap the card on the selected reader now');
        return response?.data;
      } catch (e: any) {
        toast.error(e?.response?.data?.message);
      }
      return undefined;
    }, []);

    return {
      state,
      actions: {
        getBBPOSReadersList,
        processPaymentByPOS,
      },
    };
  },
);

export default usePaymentByPOS;
