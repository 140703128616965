import React, { FC, useCallback, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import Select from 'react-select';

import { LocaleString } from 'locales';
import { Text } from 'molecules/Text';
import { FormikError } from 'atoms/Error';

export interface FormikSelecProps {
  name: string;
  label?: LocaleString;
  options: any;
  customStyles?: any;
  disabled?: boolean;
  setOptionValue?: any;
  optionValue?: boolean;
  fieldRequired?: boolean;
  multiSelect?: true;
  selectAll?: boolean;
  onMenuScroll?: (e: any) => void;
}

export const FormikSelect: FC<FormikSelecProps> = ({
  name,
  label,
  options,
  customStyles,
  disabled,
  setOptionValue,
  optionValue,
  fieldRequired,
  multiSelect,
  selectAll,
  onMenuScroll,
  ...rest
}) => {
  const { setFieldValue, values, setFieldTouched } = useFormikContext<any>();

  const [selectAllSelected, setSelectAllSelected] = useState(false);

  const onChangeHandler = useCallback(
    (formValue) => {
      if (multiSelect && formValue && formValue?.some((obj: any) => obj.label === 'Select All')) {
        const allValues = options
          .filter((opt: any) => opt.label !== 'Select All')
          .map((opt: any) => ({
            label: opt?.label,
            value: opt?.value,
          }));

        setFieldValue(name, allValues);
        if (optionValue) {
          setOptionValue(allValues);
        }
        setSelectAllSelected(true);
      } else {
        setSelectAllSelected(false);
        setFieldValue(name, multiSelect ? formValue : formValue.value);
        if (optionValue) {
          setOptionValue(formValue?.value);
        }
      }
    },
    [setFieldValue, name, options, multiSelect, optionValue, setOptionValue],
  );

  const onHandleBlur = useCallback(() => {
    setFieldTouched(name, true, true);
  }, [name, setFieldTouched]);

  const newValue = useMemo(() => {
    if (options.length) {
      if (!multiSelect) {
        return options?.find((obj: any) => {
          if (typeof obj?.value === 'object' && !Array.isArray(obj?.value) && obj?.value !== null) {
            return obj?.value?.id === values[name]?.id;
          }
          return obj?.value === values[name];
        });
      } else {
      }
    }
  }, [options, multiSelect, values, name]);
  return (
    <div>
      {label && (
        <label htmlFor={name} className="flex items-center text-sm font-medium text-primary mb-1">
          <Text id={label} />
          {fieldRequired && <span className="text-red-600 ml-0.5">*</span>}
        </label>
      )}
      {multiSelect ? (
        <Select
          isMulti={multiSelect}
          value={values[name]}
          onMenuScrollToBottom={onMenuScroll}
          className="text-sm font-semibold text-primary"
          styles={customStyles}
          options={
            selectAllSelected || values[name]?.length === options?.length
              ? []
              : selectAll
              ? [{ label: 'Select All', value: 'selectAll' }, ...options] || []
              : options || []
          }
          isDisabled={disabled}
          onChange={(values) => {
            setFieldTouched(name, true, true);
            onChangeHandler(values);
          }}
          onBlur={onHandleBlur}
          {...rest}
        />
      ) : (
        <Select
          isMulti={multiSelect}
          value={newValue || values[name]}
          className="text-sm font-semibold text-primary"
          styles={customStyles}
          options={options || []}
          isDisabled={disabled}
          onChange={(values) => {
            setFieldTouched(name, true, true);
            onChangeHandler(values);
          }}
          onBlur={onHandleBlur}
          {...rest}
        />
      )}

      <FormikError name={name} />
    </div>
  );
};
