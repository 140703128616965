import { useReducer } from 'react';
import { toast } from 'react-toastify';
import { AppointmentDetailsType, AppointmentListType } from 'typings/appointments';
import { createContainer, createReducer, createAsyncActions } from 'utils/context';
import axiosService from '../utils/api/axios';
export type AppointmentContextType = {
  AppointmentsList?: AppointmentListType;
  CompletedAppointmentsList?: AppointmentListType;
  AppointmentDetails?: AppointmentDetailsType;
  appointmentsLoading: boolean;
  completedAppointmentsLoading: boolean;
  officeLocationArray: [];
  officeLocationLoading: boolean;
  addCalenderEventLoading: boolean;
};
const initialState: AppointmentContextType = {
  appointmentsLoading: false,
  completedAppointmentsLoading: false,
  officeLocationArray: [],
  officeLocationLoading: false,
  addCalenderEventLoading: false,
};

const actions = {
  appointmentsList: createAsyncActions('APPOINTMENTS_LIST'),
  completedAppointmentsList: createAsyncActions('COMPLETED_APPOINTMENTS_LIST'),
  appointmentDetails: createAsyncActions('APPOINTMENT_DETAILS'),
  officeLocationsList: createAsyncActions('GET_OFFICE_LOCATIONS'),
  addCalenderEvent: createAsyncActions('ADD_CALENDAR_EVENT'),
};

const appointmentReducer = createReducer<AppointmentContextType>({
  [`${actions.appointmentsList.loading}`]: (state) => ({
    ...state,
    appointmentsLoading: true,
  }),
  [`${actions.appointmentsList.success}`]: (state, { payload }) => ({
    ...state,
    AppointmentsList: payload,
    appointmentsLoading: false,
  }),
  [`${actions.appointmentsList.failure}`]: (state) => ({
    ...state,
    appointmentsLoading: false,
  }),

  [`${actions.completedAppointmentsList.loading}`]: (state) => ({
    ...state,
    completedAppointmentsLoading: true,
  }),
  [`${actions.completedAppointmentsList.success}`]: (state, { payload }) => ({
    ...state,
    CompletedAppointmentsList: payload,
    completedAppointmentsLoading: false,
  }),
  [`${actions.completedAppointmentsList.failure}`]: (state) => ({
    ...state,
    completedAppointmentsLoading: false,
  }),

  [`${actions.appointmentDetails.loading}`]: (state) => ({
    ...state,
    appointmentsLoading: true,
  }),
  [`${actions.appointmentDetails.success}`]: (state, { payload }) => ({
    ...state,
    AppointmentDetails: payload,
    appointmentsLoading: false,
  }),
  [`${actions.appointmentDetails.failure}`]: (state) => ({
    ...state,
    appointmentsLoading: false,
  }),

  [`${actions.officeLocationsList.loading}`]: (state) => ({
    ...state,
    officeLocationLoading: true,
  }),
  [`${actions.officeLocationsList.success}`]: (state, { payload }) => ({
    ...state,
    officeLocationArray: payload,
    officeLocationLoading: false,
  }),
  [`${actions.officeLocationsList.failure}`]: (state) => ({
    ...state,
    officeLocationLoading: false,
  }),
  [`${actions.addCalenderEvent.loading}`]: (state) => ({
    ...state,
    addCalenderEventLoading: true,
  }),
  [`${actions.addCalenderEvent.success}`]: (state) => ({
    ...state,
    addCalenderEventLoading: false,
  }),
  [`${actions.addCalenderEvent.failure}`]: (state) => ({
    ...state,
    addCalenderEventLoading: false,
  }),
});

export const { useContext: useAppointment, Provider: AppointmentProvider } = createContainer(() => {
  const [state, dispatch] = useReducer(appointmentReducer, initialState);

  const AddAppointment = async (formData: any) => {
    try {
      const { data } = await axiosService?.post(`/appointment/add-appointment`, formData);
      toast.success(data.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  };

  const getAppointments = async (PageNum: string | number, status: string) => {
    try {
      dispatch(actions.appointmentsList.loading());

      const { data } = await axiosService?.get(
        `/appointment/?page=${Number(PageNum)}&take=10&status=${status?.toUpperCase()}`,
      );
      dispatch(actions.appointmentsList.success(data?.data?.appointments));

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.appointmentsList.failure());
    }
    return undefined;
  };

  const getCompletedAppointments = async (
    PageNum: string | number,
    status: string,
    startDate?: string,
    endDate?: string,
  ) => {
    try {
      dispatch(actions.completedAppointmentsList.loading());

      const { data } = await axiosService?.get(
        `/appointment/?page=${Number(PageNum)}&take=10&status=${status?.toUpperCase()}&startDate=${
          startDate || ''
        }&endDate=${endDate || ''}`,
      );
      dispatch(actions.completedAppointmentsList.success(data?.data?.appointments));

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.completedAppointmentsList.failure());
    }
    return undefined;
  };

  const getAppointmentDetails = async (id: string) => {
    try {
      dispatch(actions.appointmentDetails.loading());

      const { data } = await axiosService?.get(`/appointment/detail/${id}`);
      dispatch(actions.appointmentDetails.success(data?.data));

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.appointmentDetails.failure());
    }
    return undefined;
  };

  const cancelAppointment = async (formData: any) => {
    try {
      const { data } = await axiosService?.put(`appointment/${formData?.id}/cancel`, {
        ...formData,
      });
      toast.success(data?.message);
      getAppointmentDetails(formData?.id);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  };

  const patientRescheduleAppointment = async (formData: any) => {
    try {
      const { data } = await axiosService?.post(`/appointment/reschedule-appointment`, {
        ...formData,
      });
      toast.success(data?.message);

      getAppointmentDetails(formData?.appointmentId);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
    }
    return undefined;
  };
  const addCalenderEvent = async (formData: any) => {
    try {
      dispatch(actions.addCalenderEvent.loading());

      const { data } = await axiosService.post(
        `/appointment/createOrUpdateCalendarEvent/${formData.id}`,
        { description: formData.description }, // Ensuring it's sent as an object
      );

      dispatch(actions.addCalenderEvent.success());
      toast.success(data.message);
      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.addCalenderEvent.failure());
    }
    return undefined;
  };

  const getOfficeLocations = async () => {
    try {
      dispatch(actions.officeLocationsList.loading());

      const { data } = await axiosService?.get(`/list/office-locations`);

      dispatch(actions.officeLocationsList.success(data?.data?.locationList));

      return data;
    } catch (e) {
      toast.error(e?.response?.data?.message);
      dispatch(actions.officeLocationsList.failure());
    }
    return undefined;
  };

  const clearData = () => {
    dispatch(actions.appointmentsList.success({ data: [], meta: {} }));
    dispatch(actions.completedAppointmentsList.success({ data: [], meta: {} }));
    dispatch(actions.appointmentDetails.success(''));
  };

  return {
    state,
    actions: {
      getAppointments,
      getCompletedAppointments,
      AddAppointment,
      getAppointmentDetails,
      patientRescheduleAppointment,
      cancelAppointment,
      clearData,
      getOfficeLocations,
      addCalenderEvent,
    },
  };
});

export default useAppointment;
