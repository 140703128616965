// import { Dialog, Paper, PaperProps } from '@mui/material';
// import * as React from 'react';
// import Draggable from 'react-draggable';

// function PaperComponent(props: PaperProps) {
//   return (
//     <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
//       <Paper {...props} className="w-max" />
//     </Draggable>
//   );
// }

// export const Modal = ({ isOpen, onClose, children, ...rest }: any) => {
//   return (
//     <div>
//       <Dialog
//         open={isOpen}
//         onClose={onClose}
//         PaperComponent={PaperComponent}
//         aria-labelledby="draggable-dialog-title"
//       >
//         {children}
//       </Dialog>
//     </div>
//   );
// };

//////////////////////////////////////////////////////////////////////////////////////////////////

// import React, { FC } from 'react';

// import ReactModal from 'react-modal';

// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     width: 'max-content',
//     maxWidth: '100vw',
//     borderRadius: '4px',
//     border: 'none',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     overflow: 'initial',
//   },
//   overlay: {
//     background: 'rgb(25 31 41 / 51%)',
//     zIndex: 100,
//   },
// };

// export type ModalProps = { onClose?: () => void } & ReactModal.Props;

// export const Modal: FC<ModalProps> = ({ isOpen, onClose, ...rest }) => (
//   <ReactModal isOpen={isOpen} onRequestClose={onClose} style={customStyles} {...rest} />
// );
import { Dialog } from '@headlessui/react';
import React, { useRef } from 'react';
import Draggable from 'react-draggable';

// export const Modal = ({ isOpen, onClose, children, ...rest }: any) => {
//   return (
//     <Draggable>
//       {/* <Dialog open={isOpen} onClose={onClose} draggable="true" className="absolute">
//         {children}
//       </Dialog> */}
//       <Dialog
//         className="absolute top-1/2 left-1/2 m-auto transform -translate-x-1/2 -translate-y-1/2"
//         onClose={onClose}
//         open={isOpen}
//       >
//         <div className="flex min-h-full">
//           <Dialog.Panel className="w-full max-w-min rounded-2xl bg-white p-4 text-left align-middle shadow-corner">
//             {children}
//           </Dialog.Panel>
//         </div>
//       </Dialog>
//     </Draggable>
//   );
// };

export const Modal = ({ isOpen, onClose, children, ...rest }: any) => {
  const modalRef = useRef(null);
  let details = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(details);
  return (
    <>
      <Dialog
        initialFocus={modalRef}
        as="div"
        className="relative z-50"
        onClose={onClose}
        open={isOpen}
      >
        <div className="fixed inset-0 bg-black bg-opacity-40" />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            {!isMobileDevice ? (
              <Draggable handle="#draggable-dialog-title">
                <Dialog.Panel
                  ref={modalRef}
                  className=" relative w-full max-w-min rounded-md bg-white p-3 sm:p-6 text-left align-middle shadow-xl"
                >
                  {children}
                </Dialog.Panel>
              </Draggable>
            ) : (
              <Dialog.Panel
                ref={modalRef}
                className=" relative w-full max-w-min rounded-md bg-white p-3 sm:p-6 text-left align-middle shadow-xl"
              >
                {children}
              </Dialog.Panel>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};
