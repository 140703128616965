import axios from 'axios';
import { getAccessToken } from './getTokens';

const serverUrl: string | undefined = process.env.REACT_APP_API_HOST;
const instance = axios.create({
  baseURL: serverUrl,
});
instance.interceptors.request.use(
  async function (config) {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);
export const axiosInterceptor = () => {
  instance.interceptors.response.use(
    (response) => {
      return response.data;
    },
    (errorResponse) => {
      return errorResponse.response.data;
    },
  );
};

const axiosService = {
  get: (endPoint: string, headers = {}) => {
    const config = { params: {}, headers: {} };
    if (!endPoint) {
      throw Error('endPoint is required params');
    } else {
      config.headers = headers;
      return instance.get(endPoint, config);
    }
  },
  downloadFile: async (endPoint: string, data?: {}, headers: Record<string, any> = {}) => {
    try {
      if (!endPoint) {
        throw new Error('endPoint is a required parameter');
      }

      const response = await instance.post(endPoint, data, {
        headers,
        responseType: 'arraybuffer', // Setting responseType as 'arraybuffer' for downloading file
      });

      return response;
    } catch (error) {
      console.error('Request failed:', error);
      throw error;
    }
  },
  post: (endPoint: string, data: any, headers = {}) => {
    if (!(endPoint || !data)) {
      throw Error('endPoint and data are required params');
    }
    return instance.post(endPoint, data, { headers });
  },
  put: (endPoint: string, data: any, headers = {}) => {
    if (!(endPoint || !data)) {
      throw Error('endPoint and data are required params');
    }
    return instance.put(endPoint, data, { headers });
  },
  patch: (endPoint: string, data: any, headers = {}) => {
    if (!(endPoint || !data)) {
      throw Error('endPoint and data are required params');
    }
    return instance.put(endPoint, data, { headers });
  },
  delete: (endPoint: string, data: any, headers = {}) => {
    if (!endPoint) {
      throw Error('endPoint is required params');
    } else {
      return instance.delete(endPoint, { data: data, headers: headers });
    }
  },
};

export default axiosService;
