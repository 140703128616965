import { CheckIcon } from '@heroicons/react/solid';
import Loader from 'atoms/Loader';
import useProfileDetails from 'contexts/ProfileDetailContext';
import { Button, CancelButton } from 'molecules/Button';
import { Text } from 'molecules/Text';
import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router';
import useVisibleState from 'utils/hooks/useVisibleStates';
// import { getFormatDate } from 'utils/utility';
import ConsentConfirmActionModal from './ConsentConfirmActionModal';

function ConsentConfirmation() {
  const { pathname } = useLocation();
  const [consentAction, setConsentAction] = useState('');
  const id = pathname.split('/')[2];
  const {
    state: { consentDetailsLoading, consentDetails },
    actions: { getConsentDetails, clearConsentDetails, userConsentAction },
  } = useProfileDetails();
  const {
    visible: consentConfirmAction,
    hide: hideConsentConfrimModal,
    show: showConsentConfrimModal,
  } = useVisibleState(false);

  useEffect(() => {
    if (id) {
      getConsentDetails(id);
    }
    return () => {
      clearConsentDetails();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onSubmitUserResponse = useCallback(
    async () => {
      const response = await userConsentAction({ id: id, action: consentAction });
      response && getConsentDetails(id);
      response && hideConsentConfrimModal();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, consentAction],
  );

  // const Checkboxinput = ({ ...props }) => {
  //   return (
  //     <div className="flex items-center">
  //       <input
  //         {...props}
  //         className={`h-4 w-4 text-indigo-600 focus:ring-indigo-500 rounded border-gray-300`}
  //         readOnly
  //       />
  //     </div>
  //   );
  // };
  return (
    <>
      {consentDetailsLoading ? (
        <Loader />
      ) : (
        <>
          <ConsentConfirmActionModal
            open={consentConfirmAction}
            onClose={hideConsentConfrimModal}
            onSubmit={onSubmitUserResponse}
            action={consentAction}
            mode={consentDetails ? consentDetails?.consentMode : ''}
          />
          <div className="px-4 max-w-5xl mx-auto overflow-y-auto">
            <div className="flex flex-col py-3 space-y-8">
              {/* Heading - Type of consent request */}
              <div className="border-b-4 pb-2">
                <Text className="font-semibold text-3xl text-gray-700">
                  {consentDetails?.consentMode === 'REQUEST' ? 'REQUEST' : 'CONSENT'}
                </Text>
              </div>
              {/* Right now we are going to handle when someone requesting consent */}

              {/* User detail paragraph */}
              <div className="space-y-3">
                <Text className="font-medium text-xl capitalize">
                  Hello {consentDetails?.requestTo?.firstName} {consentDetails?.requestTo?.lastName}
                  !!!
                </Text>
                {consentDetails?.consentStatus === 'SENT' && (
                  <Text className="font-normal text-xl text-gray-800">
                    <span className="capitalize font-medium whitespace-nowrap">
                      {consentDetails?.requestFrom?.firstName}{' '}
                      {consentDetails?.requestFrom?.lastName}
                    </span>{' '}
                    {consentDetails?.consentMode === 'REQUEST' ? (
                      <span>
                        is requesting to access infomation about your account with LX Medical.
                      </span>
                    ) : (
                      <span>is consenting to infomation about their account with LX Medical.</span>
                    )}
                  </Text>
                )}
              </div>
              {(consentDetails?.consentStatus === 'REJECTED' ||
                consentDetails?.consentStatus === 'WITHDRAWN') && (
                <div className="space-y-3">
                  <Text className="font-normal text-xl text-gray-800">
                    The Consent has been Withdrawn to view LX Medical information.
                  </Text>
                  <Text className="font-normal text-xl text-gray-800">
                    Please reach out us at{' '}
                    <span className="capitalize font-medium whitespace-nowrap">651-565-9633</span>{' '}
                    if you have any questions
                  </Text>
                </div>
              )}

              {consentDetails?.consentMode === 'CONSENT' &&
                consentDetails?.consentStatus === 'APPROVED' && (
                  <Text className="font-normal text-xl text-gray-800">
                    You have now access to{' '}
                    <span className="capitalize font-medium whitespace-nowrap">
                      {consentDetails?.requestFrom?.firstName}{' '}
                      {consentDetails?.requestFrom?.lastName}'s'
                    </span>{' '}
                    {consentDetails?.appointment && 'Appointments'}
                    {consentDetails?.appointment && consentDetails?.billing && ' and '}
                    {consentDetails?.billing && 'Billing'} information.
                  </Text>
                )}

              {/* User detail card */}
              {(consentDetails?.consentStatus === 'SENT' ||
                consentDetails?.consentStatus === 'APPROVED') && (
                <div className="">
                  <div className="border-b-2 pb-1 mb-2">
                    <Text className="font-medium text-xl text-gray-700">Person Details</Text>
                  </div>
                  <div className="space-y-3 sm:space-y-0 sm:flex sm:gap-x-3 border-b-2 pb-4 px-1.5 py-2">
                    <div className="space-y-3 md:flex-1">
                      <div className="grow">
                        <span className="text-gray-500 font-semibold">Name : </span>
                        <span className="text-gray-700 ml-2 font-medium capitalize">
                          {consentDetails?.requestFrom?.firstName}{' '}
                          {consentDetails?.requestFrom?.lastName}
                        </span>
                      </div>
                      <div className="grow">
                        <span className="text-gray-500 font-semibold">Email : </span>
                        <span className="text-gray-700 ml-2 font-medium">
                          {consentDetails?.requestFrom?.email}
                        </span>
                      </div>
                    </div>

                    <div className="space-y-3 md:flex-1">
                      <div className="grow">
                        <span className="text-gray-500 font-semibold">Phone : </span>
                        <span className="text-gray-700 ml-2 font-medium">
                          {consentDetails?.requestFrom?.phone
                            ? consentDetails?.requestFrom?.phone
                            : ' - '}
                        </span>
                      </div>{' '}
                      <div className="grow">
                        <span className="text-gray-500 font-semibold">Address : </span>
                        <span className="text-gray-700 ml-2 font-medium">
                          {[
                            consentDetails?.requestFrom?.address1.trim(),
                            consentDetails?.requestFrom?.address2
                              ? `${consentDetails?.requestFrom?.address2.trim()}`
                              : '',
                            consentDetails?.requestFrom?.city,
                            consentDetails?.requestFrom?.state,
                          ]
                            .filter(Boolean)
                            .join(', ')}
                          {` - ${consentDetails?.requestFrom?.postalCode}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {(consentDetails?.consentStatus === 'SENT' ||
                consentDetails?.consentStatus === 'APPROVED') &&
              consentDetails?.consentMode === 'REQUEST' ? (
                <Text className="font-normal text-xl text-gray-800">
                  {consentDetails?.consentStatus === 'SENT' && (
                    <>
                      {' '}
                      <span>By approving this request you are going to provide </span>
                      <span className="capitalize font-medium whitespace-nowrap">
                        {consentDetails?.requestFrom?.firstName}{' '}
                        {consentDetails?.requestFrom?.lastName}
                      </span>{' '}
                    </>
                  )}
                  {consentDetails?.consentStatus === 'APPROVED' && (
                    <>
                      <span>Has </span>
                    </>
                  )}
                  access to your {consentDetails?.appointment && 'Appointments'}
                  {consentDetails?.appointment && consentDetails?.billing && ' and '}
                  {consentDetails?.billing && 'Billing'} information.
                </Text>
              ) : (
                consentDetails?.consentStatus === 'SENT' && (
                  <Text className="font-normal text-xl text-gray-800">
                    By approving this consent you are going to have access to their{' '}
                    {consentDetails?.appointment && 'Appointments'}
                    {consentDetails?.appointment && consentDetails?.billing && ' and '}
                    {consentDetails?.billing && 'Billing'} information.
                  </Text>
                )
              )}

              {/* Appointment and Billing Check Icon with text*/}
              {(consentDetails?.consentStatus === 'SENT' ||
                consentDetails?.consentStatus === 'APPROVED') && (
                <div className="space-y-3">
                  {consentDetails?.appointment && (
                    <div className="flex items-center">
                      <div className="mr-2">
                        <CheckIcon className="h-6 w-6 font-bold text-green-700" />
                      </div>
                      <div className="col-span-3 text-lg font-semibold text-gray-500">
                        Appointment
                      </div>
                    </div>
                  )}
                  {consentDetails?.billing && (
                    <div className="flex items-center">
                      <div className="mr-2">
                        <CheckIcon className="h-6 w-6 font-bold text-green-700" />
                      </div>
                      <div className="col-span-3 text-lg font-semibold text-gray-500">Billing</div>
                    </div>
                  )}
                </div>
              )}

              {/* Billing Special Remminder Paragraph */}
              {consentDetails?.billing &&
                (consentDetails?.consentStatus === 'SENT' ||
                  consentDetails?.consentStatus === 'APPROVED') &&
                (consentDetails?.consentMode === 'REQUEST' ? (
                  <Text className="font-medium text-xl text-gray-400">
                    {consentDetails?.consentStatus === 'SENT' && (
                      <span>
                        Additionally, By Approving this request your Saved Cards Could be charged
                        with bills of{' '}
                      </span>
                    )}
                    {consentDetails?.consentStatus === 'APPROVED' && (
                      <span>Your Saved Cards Could be charged with bills of </span>
                    )}
                    <span className="capitalize text-gray-800 whitespace-nowrap">
                      {consentDetails?.requestFrom?.firstName}{' '}
                      {consentDetails?.requestFrom?.lastName}
                    </span>
                    .
                  </Text>
                ) : (
                  <></>
                ))}

              {/* Description Paragraph */}
              {/* <div className="">
                {consentDetails?.consentStatus === 'SENT' && (
                  <Text className="font-medium text-xl">
                    {consentDetails?.consentMode === 'REQUEST'
                      ? `By approving this request you are going to provide access of your ${
                          consentDetails?.appointment ? 'Appointments' : ''
                        }${consentDetails?.appointment && consentDetails?.billing ? ' and ' : ''}${
                          consentDetails?.billing ? 'Billing' : ''
                        } and ${consentDetails?.requestFrom?.firstName} ${
                          consentDetails?.requestFrom?.lastName
                        } can have the access to your ${
                          consentDetails?.appointment ? 'Appointments' : ''
                        }${consentDetails?.appointment && consentDetails?.billing ? ' and ' : ''}${
                          consentDetails?.billing ? 'Billing' : ''
                        }.`
                      : `By approving this consent you will be having access to view ${
                          consentDetails?.appointment ? 'Appointments' : ''
                        }${consentDetails?.appointment && consentDetails?.billing ? ' and ' : ''}${
                          consentDetails?.billing ? 'Billing' : ''
                        } of ${consentDetails?.requestFrom?.firstName} ${
                          consentDetails?.requestFrom?.lastName
                        }.`}
                  </Text>
                )}
                {consentDetails?.consentStatus === 'APPROVED' && (
                  <>
                    <Text className="font-medium text-xl">
                      Above {consentDetails?.consentMode === 'REQUEST' ? 'request ' : 'consent '}
                      is already approved by you. You can withdraw this approval anytime you want.
                    </Text>
                    <Text className="font-medium text-xl">
                      {consentDetails?.consentMode === 'REQUEST'
                        ? `After withdrawing, ${consentDetails?.requestFrom?.firstName} ${
                            consentDetails?.requestFrom?.lastName
                          } won't be able to have any access to your ${
                            consentDetails?.appointment ? 'Appointments' : ''
                          }${
                            consentDetails?.appointment && consentDetails?.billing ? ' and ' : ''
                          }${consentDetails?.billing ? 'Billing' : ''}.`
                        : `After withdrawing, you won't be able to view and access ${
                            consentDetails?.requestFrom?.firstName
                          } ${consentDetails?.requestFrom?.lastName}'s ${
                            consentDetails?.appointment ? 'Appointments' : ''
                          }${
                            consentDetails?.appointment && consentDetails?.billing ? ' and ' : ''
                          }${consentDetails?.billing ? 'Billing' : ''}`}
                    </Text>
                  </>
                )}

                {consentDetails?.consentStatus === 'WITHDRAWN' && (
                  <>
                    <Text className="font-medium text-xl">
                      This {consentDetails?.consentMode === 'REQUEST' ? 'request ' : 'consent '}
                      is already withdrawn by you.
                    </Text>
                  </>
                )}
                {consentDetails?.consentStatus === 'REJECTED' && (
                  <>
                    <Text className="font-medium text-xl">
                      This {consentDetails?.consentMode === 'REQUEST' ? 'request ' : 'consent '}
                      is already rejected by you.
                    </Text>
                  </>
                )}
              </div> */}

              {/* Buttons */}

              {consentDetails?.consentStatus === 'APPROVED' && (
                <div className="flex sm:flex-row flex-col justify-center gap-3 sm:gap-6 mt-4">
                  <Button
                    type="button"
                    textid="consent.withdraw"
                    onClick={() => {
                      setConsentAction('WITHDRAWN');
                      showConsentConfrimModal();
                    }}
                    className="px-0 justify-center sm:w-auto sm:px-16 sm:py-3"
                  />
                </div>
              )}

              {consentDetails?.consentStatus === 'SENT' && (
                <div className="flex sm:flex-row flex-col justify-center gap-3 sm:gap-6 mt-4">
                  <Button
                    type="button"
                    textid="consent.approve"
                    onClick={() => {
                      setConsentAction('APPROVED');
                      showConsentConfrimModal();
                    }}
                    className="px-0 justify-center sm:w-auto sm:px-16 sm:py-3"
                  />

                  <CancelButton
                    type="button"
                    onClick={() => {
                      setConsentAction('REJECTED');
                      showConsentConfrimModal();
                    }}
                    textid="consent.reject"
                    className="w-full px-0 justify-center sm:w-auto sm:px-16 sm:py-3"
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ConsentConfirmation;
