import { InformationCircleIcon, BanIcon } from '@heroicons/react/solid';
import { SearchBar } from 'atoms/SearchBar';
import { Button } from 'molecules/Button';
import { Text } from 'molecules/Text';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useAdminServices from 'contexts/AdminServicesContext';
import { useSearchQuery } from 'utils/hooks/useSearchQuery';
import { RCH_ABOUT, ROLES } from 'utils/settings/constants';
import Modal from 'molecules/ConfirmModalTemplate';
import Iframe from 'react-iframe';
import { RotateLoader } from 'react-spinners';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import { useFormSubmitWithLoading } from 'utils/hooks/useFormSubmitWithLoading';
import { debounce } from 'lodash';
import useAuth from 'contexts/AuthenticationContext';

type modalStateType = {
  consentServiceId: string;
  isOpen: boolean;
};

const modalState: modalStateType = { consentServiceId: '', isOpen: false };

const warnMessage = debounce((val?: string) => {
  toast.warn(
    val ? (
      val
    ) : (
      <div className="flex">
        <BanIcon className="w-6 h-6 mr-2 text-red-700" />
        <span>This service is available only for single patient.</span>
      </div>
    ),
  );
}, 500);

function ListofServices({ setFormStep, onSubmit, initialValues }: any) {
  const Checkboxinput = ({ ...props }) => {
    return (
      <div className="flex items-center">
        <input
          {...props}
          className={`h-4 w-4 text-indigo-600 focus:ring-indigo-500 rounded border-gray-300`}
        />
      </div>
    );
  };

  const {
    state: { webFormServiceList, webFormServiceLoading },
    actions: { getWebServiceList },
  } = useAdminServices();

  const [servicesListState, setServicesListState] = useState<any[]>([]);
  const [serviceIds, setServiceIds] = useState<string[]>(initialValues?.serviceIds || []);
  const [isTelemedicineIds, setIsTelemedicineIds] = useState<string[]>([]);

  const searchQuery = useSearchQuery();
  const [pageNumber, setPageNumber] = useState(1);

  const [hasMore, setHasMore] = useState<boolean>(false);

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (webFormServiceLoading) return;
      // @ts-ignore
      if (observer.current) observer.current?.disconnect();
      // @ts-ignore
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      // @ts-ignore
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [webFormServiceLoading, hasMore],
  );

  useEffect(() => {
    getWebServiceList(pageNumber, searchQuery);
  }, [getWebServiceList, pageNumber, searchQuery]);

  useEffect(() => {
    setServicesListState((preState) => [...preState, ...webFormServiceList?.data]);
  }, [webFormServiceList?.data]);

  useEffect(() => {
    setHasMore(webFormServiceList?.meta?.page < webFormServiceList?.meta?.pageCount);
  }, [webFormServiceList]);

  useEffect(() => {
    setServicesListState([]);
    setPageNumber(1);
  }, [searchQuery]);

  // const [serviceId, setServiceId] = useState<string | undefined>(undefined);

  const { onSubmitHandler, loading } = useFormSubmitWithLoading(onSubmit);

  // For consent
  const [showModal, setShowModal] = useState({ ...modalState });

  const openModal = (id: string) => setShowModal({ consentServiceId: id, isOpen: true });

  const resetModal = (val?: boolean) => {
    setShowModal({ ...modalState });
    setConsentError(false);
    if (!val) {
      const tempIds = serviceIds?.filter((ele) => ele !== showModal?.consentServiceId);
      setServiceIds([...tempIds]);
    }
  };

  const [consentError, setConsentError] = useState(false);
  // For consent
  const {
    state: { user },
  } = useAuth();

  const code = useMemo(() => user?.role === ROLES?.ADMIN, [user]);

  return (
    <>
      {!code && (
        <Modal
          headerTextId="consent.form.heading"
          open={Boolean(showModal.isOpen)}
          onClose={() => resetModal()}
        >
          <Text className="font-bold text-primary -mt-4 mb-3" id="consent.note" />

          <div className="hidden sm:block">
            <Iframe height="320vh" width="100%" url={RCH_ABOUT.BOTOX_CONSENT_URL} />
          </div>

          <div className="block sm:hidden">
            <Iframe height="200vh" width="100%" url={RCH_ABOUT.BOTOX_CONSENT_URL} />
          </div>

          <div className="flex mt-5 space-x-2 items-center font-bold">
            <input
              id="cc"
              type="checkbox"
              className="w-6 h-4"
              onChange={(e) => {
                setConsentError(!e?.target?.checked);
              }}
            />
            <label htmlFor="cc">
              <Text id="condition.above" className={`text-gray-600 mb-0.5`} />
            </label>
          </div>

          {consentError && (
            <Text id="disclosure.validation" className={`text-red-600 text-sm my-1`} />
          )}

          <div className="flex justify-center my-2">
            <Button
              textid="continue"
              className="px-16"
              onClick={() => {
                // @ts-ignore
                const val = document.getElementById('cc')?.checked;
                if (!val) setConsentError(true);
                else resetModal(val);
              }}
            />
          </div>
        </Modal>
      )}

      <div className="">
        <div className="pb-4">
          <Text className="text-gray-800 font-bold" id="form.heading.2" />
        </div>
        <div className="flex flex-row pt-3">
          <InformationCircleIcon className="h-4 w-4 relative text-gray-400" />
          <Text className="text-gray-400 text-xs ml-1" id="services.additional.fee.text" />
        </div>

        <div className="sm:w-2/4 pt-6">
          <SearchBar placeholder="search.booking.service.placeholder" />
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={(val) => {
            onSubmitHandler({ ...val, serviceIds });
            setFormStep(3);
          }}
        >
          {({ values, submitForm }) => (
            <Form className="">
              <div className="min-h-72 max-h-96 overflow-y-auto py-4 text-sm md:text-base flex flex-col gap-3 md:gap-4 md:grid md:grid-cols-2 capitalize">
                {servicesListState?.map((val: any, i) => (
                  <>
                    <div
                      key={i}
                      onClick={() => {
                        // for preventing "Telemedicine Visit service" to be added with other services
                        if (
                          val?.category?.toLowerCase() === 'telemedicine' &&
                          serviceIds?.length > 0 &&
                          !serviceIds?.includes(val?.id)
                        ) {
                          return warnMessage(
                            '"Telemedicine Visit" service cannot be availed with any other services.',
                          );
                        }

                        // for preventing other service to be added with Telemedicine Visit service
                        if (
                          isTelemedicineIds?.length > 0 &&
                          val?.category?.toLowerCase() !== 'telemedicine'
                        ) {
                          return warnMessage(
                            'Any other services cannot be availed with "Telemedicine Visit" service.',
                          );
                        }

                        if (!serviceIds?.includes(val?.id)) {
                          setServiceIds([...serviceIds, val?.id]);
                          if (val?.category?.toLowerCase() === 'telemedicine')
                            setIsTelemedicineIds([...isTelemedicineIds, val?.id]);
                          // added for botox consent
                          if (val?.name === 'In-Home Aesthetics') openModal(val?.id);
                        } else {
                          const tempIds = serviceIds?.filter((ele) => ele !== val?.id);
                          setServiceIds([...tempIds]);
                          if (val?.category?.toLowerCase() === 'telemedicine') {
                            if (isTelemedicineIds?.length === 1)
                              setIsTelemedicineIds(
                                isTelemedicineIds?.filter((ele) => ele !== val?.id),
                              );
                          }
                        }
                      }}
                      className={`font-semibold text-gray-700 flex flex-col gap-2 p-2 rounded-md cursor-pointer hover:shadow-md bg-gray-100
                            ${
                              serviceIds?.includes(val?.id) &&
                              'bg-white border-2 shadow-md border-primary'
                            }
                            `}
                    >
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-x-2">
                          <Checkboxinput type="checkbox" checked={serviceIds?.includes(val?.id)} />
                          <div className="">{val?.name}</div>
                        </div>
                        <div className="text-primary  text-right">{val?.rate}</div>
                      </div>

                      <div className="font-medium text-xs text-gray-500 ">{val?.description}</div>
                    </div>
                    {servicesListState?.length === i + 1 && (
                      <div ref={servicesListState?.length === i + 1 ? lastBookElementRef : null} />
                    )}
                  </>
                ))}

                {webFormServiceLoading && (
                  <div className="col-span-2">
                    <div className="w-full flex justify-center my-6 mb-12">
                      <RotateLoader size={12} />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-start gap-3 sm:gap-6 border-t-2 border-gray-100 py-6">
                <Button
                  type="button"
                  onClick={() => setFormStep(1)}
                  textid="previous"
                  className="border-primary border-1 w-full px-0 justify-center sm:w-auto sm:px-16 sm:py-3 text-primary hover:bg-primary hover:text-white bg-lightBlue"
                />
                <Button
                  loading={loading}
                  onClick={() => {
                    submitForm();
                  }}
                  type="button"
                  textid="continue"
                  className="w-full px-0 justify-center sm:w-auto sm:px-16 sm:py-3"
                  disabled={!Boolean(serviceIds?.length)}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default ListofServices;
