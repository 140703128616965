import React, { useCallback, useState } from 'react';

import { twCascade } from '@mariusmarais/tailwind-cascade';

import { BeatLoader, ClipLoader } from 'react-spinners';
import { Text } from 'molecules/Text';
import { LocaleString } from 'locales';

type ButtonProps = {
  loading?: boolean;
  id?: string;
  disabled?: boolean;
  beatLoaderSize?: number;
  textid?: LocaleString;
  className?: any;
  children?: any;
  type?: 'submit' | 'button' | 'reset';
  onClick?: any;
  showAsyncLoad?: any;
  style?: any;
  btnLoading?: boolean;
  image?: any;
};

export function Button(props: ButtonProps) {
  const propsNew = { ...props };
  delete propsNew['loading'];
  delete propsNew['beatLoaderSize'];

  const [asyncLoading, setLoading] = useState(false);
  const onClickHandler = useCallback(
    async (...arg) => {
      setLoading(true);
      try {
        await props?.onClick?.(arg);
      } finally {
        setLoading(false);
      }
    },
    [props],
  );
  return (
    <>
      <button
        type="submit"
        onClick={onClickHandler}
        {...propsNew}
        className={twCascade(
          `flex justify-center items-center shadow-sm border-2 rounded-md hover:font-bold  focus:outline-none text-sm py-3 px-16   font-bold  text-white  
 `,
          props.disabled
            ? 'bg-gray-500 cursor-not-allowed hover:border-gray-400'
            : 'bg-primary cursor-pointer hover:border-primary',

          props.className,
        )}
        disabled={props?.loading || props?.disabled || (props?.showAsyncLoad && asyncLoading)}
      >
        {props?.image && <img src={props?.image} className="w-4 h-4 mr-2" alt="" />}
        {props?.loading || (props.showAsyncLoad && asyncLoading) ? (
          props?.btnLoading === true ? (
            <div className="text-center">
              <ClipLoader size={20} color="#fff" />
            </div>
          ) : (
            <BeatLoader size={props?.beatLoaderSize || 11} color="#fff" />
          )
        ) : props?.textid ? (
          <Text id={props?.textid} />
        ) : props?.children ? (
          props?.children
        ) : null}
      </button>
    </>
  );
}

export function CancelButton(props: ButtonProps) {
  const propsNew = { ...props };
  delete propsNew['loading'];
  delete propsNew['beatLoaderSize'];

  return (
    <>
      <button
        {...propsNew}
        className={twCascade(
          `flex justify-center items-center text-xs font-medium rounded shadow-sm text-primary border-primary border-2 px-2 py-1 focus:outline-none`,
          props.className,
        )}
      >
        {props?.loading ? (
          <BeatLoader loading size={props?.beatLoaderSize || 11} color="#fff" />
        ) : props?.textid ? (
          <Text id={props?.textid} />
        ) : props?.children ? (
          props?.children
        ) : null}
      </button>
    </>
  );
}

export function TextButton(props: ButtonProps) {
  const propsNew = { ...props };
  delete propsNew['loading'];
  delete propsNew['beatLoaderSize'];

  const [asyncLoading, setLoading] = useState(false);
  const onClickHandler = useCallback(
    async (...arg) => {
      setLoading(true);
      try {
        await props?.onClick?.(arg);
      } finally {
        setLoading(false);
      }
    },
    [props],
  );

  return (
    <>
      <div
        onClick={onClickHandler}
        className={twCascade(
          `  text-xs font-medium px-4  shadow-sm text-white hover:border-primary border-2 font-bold  hover:font-bold  focus:outline-none text-sm  font-bold  text-white  
 `,
          props.disabled ? 'bg-gray-500 cursor-not-allowed' : 'bg-primary cursor-pointer',

          props.className,
        )}
        disabled={props?.loading || props?.disabled || (props?.showAsyncLoad && asyncLoading)}
        {...propsNew}
      >
        {props?.loading || (props.showAsyncLoad && asyncLoading) ? (
          <BeatLoader size={props?.beatLoaderSize || 11} color="#000" />
        ) : props?.textid ? (
          <Text id={props?.textid} />
        ) : props?.children ? (
          props?.children
        ) : null}
      </div>
    </>
  );
}
