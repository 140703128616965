import React from 'react';
import { useField } from 'formik';
import { InputField } from 'atoms/InputField';
import InputDateField from 'atoms/InputDateField';
import InputTimeField from 'atoms/InputTimeField';
import { Text } from 'molecules/Text';
import { LocaleString } from 'locales';
import { FormikError } from 'atoms/Error';
import InputCheckbox from 'atoms/InputCheckbox/InputCheckbox';
import InputTextArea from 'atoms/InputTextArea';

interface IProps {
  color?: string;
  name: string;
  type?: string;
  placeholder?: string;
  label?: LocaleString;
  disabled?: boolean;
  validation?: boolean;
  optionsForSelect?: any[];
  valueOfLabel?: string;
  checked?: string | boolean;
  useridfield?: boolean;
  generateID?: () => void;
  loading?: boolean;
  fieldRequired?: boolean;
  phoneInputWarning?: boolean;
  emailInputWarning?: boolean;
  customError?: boolean;
}

export const FormikInput = ({ ...props }: IProps) => {
  const [field, meta] = useField(props.name);
  const { touched, error } = { ...meta };
  return (
    <div className="w-full">
      {props.label && (
        <label
          htmlFor={props.name}
          className="flex items-center text-sm font-medium text-primary mb-1"
        >
          <Text id={props.label} />
          {props?.fieldRequired && <span className="text-red-600 ml-0.5">*</span>}
        </label>
      )}
      <div className="mt-1 relative">
        <InputField
          touched={touched}
          error={error}
          {...field}
          {...props}
          validation={props.validation}
          customError={props.customError}
        />
      </div>
      {/* {props?.totalError && } */}
      {props?.phoneInputWarning || props?.emailInputWarning ? (
        <div className="mt-1 flex gap-2 items-center">
          <div className="h-5 w-5">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
              <defs>
                <linearGradient
                  gradientTransform="matrix(1.31117 0 0 1.30239 737.39 159.91)"
                  gradientUnits="userSpaceOnUse"
                  id="0"
                  y2="-.599"
                  x2="0"
                  y1="45.47"
                >
                  <stop stop-color="#ffc515" />
                  <stop offset="1" stop-color="#ffd55b" />
                </linearGradient>
              </defs>
              <g transform="matrix(.85714 0 0 .85714-627.02-130.8)">
                <path
                  d="m797.94 212.01l-25.607-48c-.736-1.333-2.068-2.074-3.551-2.074-1.483 0-2.822.889-3.569 2.222l-25.417 48c-.598 1.185-.605 2.815.132 4 .737 1.185 1.921 1.778 3.404 1.778h51.02c1.483 0 2.821-.741 3.42-1.926.747-1.185.753-2.667.165-4"
                  fill="url(#0)"
                />
                <path
                  d="m-26.309 18.07c-1.18 0-2.135.968-2.135 2.129v12.82c0 1.176.948 2.129 2.135 2.129 1.183 0 2.135-.968 2.135-2.129v-12.82c0-1.176-.946-2.129-2.135-2.129zm0 21.348c-1.18 0-2.135.954-2.135 2.135 0 1.18.954 2.135 2.135 2.135 1.181 0 2.135-.954 2.135-2.135 0-1.18-.952-2.135-2.135-2.135z"
                  transform="matrix(1.05196 0 0 1.05196 796.53 161.87)"
                  fill="#000"
                  stroke="#40330d"
                  fill-opacity=".75"
                />
              </g>
            </svg>
          </div>
          <div className="text-xs font-semibold text-red-700 tracking-wider">
            {props?.phoneInputWarning ? 'Phone number already exist' : 'Email already exist'}
          </div>
        </div>
      ) : (
        <FormikError name={props?.name} />
      )}
    </div>
  );
};

export const FormikTextArea = ({ ...props }: IProps) => {
  const [field, meta] = useField(props.name);
  const { touched, error } = { ...meta };
  return (
    <div className="w-full">
      {props.label && (
        <label
          htmlFor={props.name}
          className={`flex text-sm font-medium ${
            props.disabled ? 'text-gray-400' : 'text-primary'
          } mb-1`}
        >
          <Text id={props.label} />
          {props?.fieldRequired && <span className="text-red-600 ml-0.5">*</span>}
        </label>
      )}
      <div className="mt-1 relative">
        <InputTextArea
          touched={touched}
          error={error}
          {...field}
          {...props}
          validation={props.validation}
        />
      </div>

      <FormikError name={props?.name} />
    </div>
  );
};

export const FormikCheckbox = ({ ...props }: IProps) => {
  const [field, meta] = useField(props.name);
  const { touched, error, value } = { ...meta };
  return (
    <div>
      <div className="flex items-center">
        <InputCheckbox touched={touched} checked={value} error={error} {...field} {...props} />
        {/* dangerouslySetInnerHTML used if the label is of htmltype*/}
        <label
          htmlFor={props.name}
          className={`ml-2 block text-sm ${
            props.disabled ? ' text-gray-400' : ' text-primary'
          } font-bold`}
        >
          <Text id={props.label} className={props.color}>
            {props.label}
          </Text>
        </label>
      </div>
      {
        // @ts-ignore
        !props.noError && <FormikError name={props?.name} />
      }
    </div>
  );
};

interface FormikInputDateFieldProps {
  name: string;
  type?: string;
  placeholder?: string;
  label?: LocaleString;
  disabled?: boolean;
  validation?: boolean;
  minDate?: Date;
  maxDate?: Date;
  fieldRequired?: boolean;
  excludeDates?: Date[];
}
export const FormikInputDateField = ({ ...props }: FormikInputDateFieldProps) => {
  const [field, meta] = useField(props.name);
  const { touched, error } = { ...meta };
  return (
    <>
      <div className="w-full">
        {props.label && (
          <label htmlFor={props.name} className="flex text-sm font-medium text-primary mb-1">
            <Text id={props.label} />
            {props?.fieldRequired && <span className="text-red-600 ml-0.5">*</span>}
          </label>
        )}
        <div className="mt-1 relative">
          <InputDateField
            touched={touched}
            error={error}
            {...field}
            {...props}
            validation={props.validation}
          />
        </div>

        <FormikError name={props?.name} />
      </div>
    </>
  );
};

interface FormikInputTimeFieldProps {
  name: string;
  label?: LocaleString;
  disabled?: boolean;
  fieldRequired?: boolean;
  // format?: '12' | '24';
}

export const FormikInputTimeField = ({ ...props }: FormikInputTimeFieldProps) => {
  const [field, meta] = useField(props.name);
  const { touched, error } = { ...meta };
  return (
    <>
      <div className="w-full">
        {props.label && (
          <label htmlFor={props.name} className="flex text-sm font-medium text-primary mb-1">
            <Text id={props.label} />
            {props?.fieldRequired && <span className="text-red-600 ml-0.5">*</span>}
          </label>
        )}
        <div className="mt-1 relative">
          <InputTimeField touched={touched} error={error} {...field} {...props} />
        </div>
        <FormikError name={props?.name} />
      </div>
    </>
  );
};
