import React, { FC, useMemo } from 'react';

import Loader from 'atoms/Loader';
import { appRoutes, ROLES } from 'utils/settings/constants';
import { UnauthenticatedRoutes, AdminRoutes, AuthenticatedRoutes } from './Routes';
import useAuth from '../contexts/AuthenticationContext';
import Header from 'organisms/Header';
import { useLocation } from 'react-router';
import PaymentFromPatients from './PaymentFromPatients';
import SetNewAccountPassword from 'organisms/SetNewAccountPassword';
import RequestAppointment from 'modules/WebForm';
import VitalChart from './VitalChart';
import ConsentConfirmation from '../modules/ConsentConfirmation/ConsentConfirmation';

// import PatientSelectPlan from 'modules/PatientPanel/PatientSignup/PatientSelectPlan';

export type AuthGateProps = {};

export const AuthGate: FC<AuthGateProps> = () => {
  const {
    state: { isLoggedIn, user, authenticating },
  } = useAuth();
  const { pathname } = useLocation();

  const pathVaribale = pathname.split('/')[1];
  const urlPath = `${process.env.REACT_APP_BASE_URL}${pathVaribale}`;

  const code: string = useMemo(
    () => (user?.role === ROLES?.ADMIN ? ROLES?.ADMIN : ROLES?.PATIENT),
    [user],
  );

  if (authenticating) {
    return <Loader />;
  }

  // if (SignupFlow_LoggedIn) {
  //   return <PatientSelectPlan />;
  // }

  if (urlPath === `${process.env.REACT_APP_BASE_URL}${appRoutes?.Patient_Payment}`) {
    return <PaymentFromPatients />;
  } else if (urlPath === `${process.env.REACT_APP_BASE_URL}${appRoutes?.Set_Password}`) {
    return <SetNewAccountPassword />;
  } else if (urlPath === `${process.env.REACT_APP_BASE_URL}${appRoutes?.Vital_Chart}`) {
    return <VitalChart />;
  } else if (urlPath === `${process.env.REACT_APP_BASE_URL}${appRoutes?.Request_Appointment}`) {
    return <RequestAppointment />;
  } else if (urlPath === `${process.env.REACT_APP_BASE_URL}${appRoutes?.Consent_Details}`) {
    return <ConsentConfirmation />;
  } else if (isLoggedIn) {
    if (code === ROLES?.ADMIN || ROLES?.SUPER_ADMIN) {
      return <AdminRoutes codeOfRoles={code} />;
    }
    return (
      // <Loader loading={loading}>
      <div style={{ backgroundColor: '#FAFBFC' }}>
        <Header />
        <AuthenticatedRoutes />
      </div>
      // </Loader>
    );
  }
  return (
    <>
      <div>
        <UnauthenticatedRoutes />
      </div>
    </>
  );
};
