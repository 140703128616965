/* eslint-disable global-require */
import React, { FC } from 'react';

import Logo from 'assets/icons/Favicon-Light-20211230.png';
import { Image } from 'atoms/Image/Image';

type AdminAuthWrapperProps = {};

export const AdminAuthWrapper: FC<AdminAuthWrapperProps> = ({ children }) => {
  return (
    <div className="flex justify-center items-center h-screen ">
      <div
        className=" grid grid-cols-1 md:grid-cols-5 w-11/12 shadow-out m-auto  "
        style={{ maxWidth: '920px' }}
      >
        <div className="bg-primary flex justify-center items-center md:col-span-2 p-5">
          <Image src={Logo} alt="logo" height="100%" objectfit="scale-down" />
        </div>
        <div className="md:col-span-3">{children || null}</div>
      </div>
    </div>
  );
};
